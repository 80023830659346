import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarDependenciasTarjetasConfiguracion } from '../../../actions/configuracion';

import EditarServicioInstalacion from './EditarServicioInstalacion';

const ServiciosInstalaciones = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Servicios e instalaciones',
            path: '/configuracion/tarjeta_soluciones'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.dependencias);

    const [requerimientos, setRequerimientos] = useState([]);

    const [requerimientoEditar, setRequerimientoEditar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
        },
        {
            name: 'Categoria',
            sortable: true, 
            selector: row => {
                switch(row.categoria){
                    case 0:
                        return 'Instalación';
                    case 1:
                        return 'Servicio';
                    default:
                        return 'Sin definir';
                }
            }
        },
        {
            name: 'Tipo',
            sortable: true, 
            selector: row => {
                switch(row.tipo){
                    case 0:
                        return 'Asesoria psicologica';
                    case 1:
                        return 'Asesoria nutricional';
                    case 2:
                        return 'Asesoria deportiva';
                    case 3:
                        return 'Cabina';
                    case 4:
                        return 'Auditorio';
                    case 5:
                        return 'Gimnasio';
                    default:
                        return 'Sin definir';
                }
            }
        },
        {
            name: 'Tarjeta Soluciones',
            sortable: true, 
            cell: item => `${item.requerida ? 'Requiere tarjeta soluciones' : 'No la requiere'}`
        },
        {
            name: '',
            cell: row => {
                if(row._id){
                    return(
                        <div className="text-right w-100">
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarRequerimiento(row)}><i className="fas fa-edit text-warning"></i></button>
                        </div>
                    )
                }else{
                    return(<></>)
                }
            }
        }
    ];

    /* Editar requerimiento */
    const handleEditarRequerimiento = (requerimiento) => {
        setRequerimientoEditar(requerimiento);
    }

    useEffect(() => {
        dispatch(buscarDependenciasTarjetasConfiguracion());
    }, []);

    useEffect(() => {
        setRequerimientos(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <EditarServicioInstalacion servicioInstalacion={requerimientoEditar} reestablecerServicioInstalacion={handleEditarRequerimiento} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Servicios e instalaciones</h3>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={requerimientos}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ServiciosInstalaciones;