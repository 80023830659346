import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import { formatNumber, sortTableNumberCupo, sortTableNumberSolicitados, sortTableNumberRealizados, sortTableDateLimite, sortTableDateCanje, sortTableStatusStatus } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { buscarPromociones, filtrarPromociones } from '../../actions/promociones';

import FiltrosPromociones from './FiltrosPromociones';
import EscanearCupon from './EscanearCupon';
import NuevaPromocion from './NuevaPromocion';
import EditarPromocion from './EditarPromocion';
import EliminarPromocion from './EliminarPromocion';

const Promociones = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Promociones y descuentos',
            path: '/promociones'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.promociones);

    const [promociones, setPromociones] = useState([]);

    const [cuponEscanear, setCuponEscanear] = useState(null);
    const [promocionAgregar, setPromocionAgregar] = useState(null);
    const [promocionEditar, setPromocionEditar] = useState(null);
    const [promocionEliminar, setPromocionEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
            grow: 2
        },
        {
            name: 'Cupo límite',
            sortable: true, 
            sortFunction: sortTableNumberCupo, 
            selector: row => row.cupo ? formatNumber(row.cupo, 0, '', '') : 'Sin limite'
        },
        {
            name: 'Fecha límite solicitud',
            sortable: true, 
            sortFunction: sortTableDateLimite, 
            cell: item => item.limite ? new Date(item.limite).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin limite'
        },
        {
            name: 'Fecha de límite canje',
            sortable: true, 
            sortFunction: sortTableDateCanje, 
            cell: item => item.canje ? new Date(item.canje).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin limite'
        },
        {
            name: 'Canjes solicitados',
            sortable: true, 
            sortFunction: sortTableNumberSolicitados, 
            selector: row => formatNumber(row.canjes_solicitados, 0, '', '')
        },
        {
            name: 'Canjes realizados',
            sortable: true, 
            sortFunction: sortTableNumberRealizados, 
            selector: row => formatNumber(row.canjes_realizados, 0, '', '')
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusStatus, 
            selector: row => {
                switch(row.status){
                    case 0:
                        return(
                            <h5><Badge pill bg="success">Activa</Badge></h5>
                        )
                    case 1:
                        return(
                            <h5><Badge pill bg="warning">Inactiva</Badge></h5>
                        )
                    case 2:
                        return(
                            <h5><Badge pill bg="danger">Finalizada</Badge></h5>
                        )
                    default:
                        break;
                }
            }
        },
        {
            name: '',
            cell: row => {
                if(row._id){
                    return(
                        <div className="text-right w-100">
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarPromocion(row)}><i className="fas fa-trash text-danger"></i></button>
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarPromocion(row)}><i className="fas fa-edit text-warning"></i></button>
                            <Link className="btn btn-sm btn-link px-2 float-end" to={`/promociones/detalles/${row._id}`}><i className="fas fa-circle-info text-info"></i></Link>
                        </div>
                    )
                }else{
                    return(<></>)
                }
            }
        }
    ];

    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtrarPromociones(true));
    }

    /* Escanear cupon */
    const handleEscanearCupon = (cupon) => {
        setPromocionAgregar(null);
        setPromocionEditar(null);
        setPromocionEliminar(null);

        setCuponEscanear(cupon);    
    }

    /* Crear promocion */
    const handleAgregarPromocion = (promocion) => {
        setCuponEscanear(null);
        setPromocionEditar(null);
        setPromocionEliminar(null);

        setPromocionAgregar(promocion);        
    }

    /* Editar promocion */
    const handleEditarPromocion = (promocion) => {
        setCuponEscanear(null);
        setPromocionAgregar(null);
        setPromocionEliminar(null);

        setPromocionEditar(promocion);
    }

    /* Eliminar promocion */
    const handleEliminarPromocion = (promocion) => {
        setCuponEscanear(null);
        setPromocionAgregar(null);
        setPromocionEditar(null);

        setPromocionEliminar(promocion);
    }

    useEffect(() => {
        dispatch(buscarPromociones({
            nombre: null,
            status: null, 
            fecha_inicio: null,
            fecha_fin: null
        }));
    }, []);

    useEffect(() => {
        setPromociones(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <FiltrosPromociones />

            <EscanearCupon cupon={cuponEscanear} reestablecerCupon={handleEscanearCupon} />
            <NuevaPromocion promocion={promocionAgregar} reestablecerPromocion={handleAgregarPromocion} />
            <EditarPromocion promocion={promocionEditar} reestablecerPromocion={handleEditarPromocion} />
            <EliminarPromocion promocion={promocionEliminar} reestablecerPromocion={handleEliminarPromocion} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Promociones y descuentos</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleEscanearCupon({ })}>Escanear cupon</button>
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarPromocion({ })}>Agregar promoción</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={promociones}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Promociones;