import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { setAlert } from '../../actions/alert';
import { crearBloqueo } from '../../actions/deportiva';
import { buscarJovenes } from '../../actions/jovenes';

const NuevoBloqueo = ({ bloqueo, reestablecerBloqueo }) => {

    const dispatch =  useDispatch();

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [bloqueoData, setBloqueoData] = useState({
        motivo: null, 
        fecha_inicio: null,
        fecha_fin: null
    });

    /* Crear bloqueo */
    const handleInputChange = ({ target }) => {
        setBloqueoData({
            ...bloqueoData,
            [target.name]: target.value
        });
    }

    const handleDatePicker = (name, value) => {
        setBloqueoData({
            ...bloqueoData,
            [name]: value
        });
    }
    
    const handleProcesar = async () => {

        if(bloqueoData.motivo){
            if((bloqueoData.fecha_inicio <= bloqueoData.fecha_fin) && bloqueoData.fecha_inicio && bloqueoData.fecha_inicio){
                await setStatusProcesar(true);
                await dispatch(crearBloqueo(bloqueoData));
                await handleCerrar();
                await setStatusProcesar(false);
            }else{
                await dispatch(setAlert('La fecha de inicio debe ser menor o igual a la de finalización', 'danger'));
            }
        }else{
            await dispatch(setAlert('Ingresa el motivo de bloqueo de fechas', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setBloqueoData({
                motivo: null, 
                fecha_inicio: null,
                fecha_fin: null
            });

            setNuevoModal(false);
            reestablecerBloqueo(null);
        }
    }

    useEffect(() => {
        dispatch(buscarJovenes({
            nombre: null,
            apellido_paterno: null,
            apellido_materno: null,
            celular: null,
            email: null,
            curp: null
        }));
    }, []);

    useEffect(() => {
        if(bloqueo){
            setNuevoModal(true);

            setBloqueoData({
                ...bloqueoData
            });
        }        
    }, [bloqueo]);

    return (
        <Modal show={nuevoModal} size="lg" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar bloqueo de disponibilidad</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Motivo" id="motivo" name="motivo" value={bloqueoData.motivo} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="motivo">Motivo</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_inicio"
                                    name="fecha_inicio"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    selected={ bloqueoData.fecha_inicio != null ? new Date(bloqueoData.fecha_inicio) : '' }
                                    onChange={ date => handleDatePicker('fecha_inicio', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_inicio">Fecha de inicio</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_fin"
                                    name="fecha_fin"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    selected={ bloqueoData.fecha_fin != null ? new Date(bloqueoData.fecha_fin) : '' }
                                    onChange={ date => handleDatePicker('fecha_fin', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_fin">Fecha de finalización</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Agregar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Agregar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default NuevoBloqueo;