import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { setAlert } from '../../../actions/alert';
import { crearExtensionConfiguracion } from '../../../actions/configuracion';

const NuevaExtension = ({ extension, reestablecerExtension }) => {

    const dispatch =  useDispatch();

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [extensionData, setExtensionData] = useState({
        nombre: null
    });

    /* Crear extension */
    const handleInputChange = ({ target }) => {
        setExtensionData({
            ...extensionData,
            [target.name]: target.value
        });
    }
    
    const handleProcesar = async () => {
        if(extensionData.nombre){
            await setStatusProcesar(true);
            await dispatch(crearExtensionConfiguracion(extensionData));
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Completa todos los campos', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setExtensionData({
                nombre: null
            });
            setNuevoModal(false);
            reestablecerExtension(null);
        }
    }

    useEffect(() => {
        if(extension){
            setNuevoModal(true);

            setExtensionData({
                ...extensionData
            });
        }        
    }, [extension]);

    return (
        <Modal show={nuevoModal} size="md" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar extensión</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group mb-2">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={extensionData.nombre} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="nombre">Nombre</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Agregar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Agregar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default NuevaExtension;