import { CONFIGURACION_CATEGORIAS_EMPRESAS, CONFIGURACION_EXTENSIONES, CONFIGURACION_REQUERIMIENTOS, CONFIGURACION_DEPENDENCIAS, CONFIGURACION_GIMNASIOS, CONFIGURACION_CABINAS, CONFIGURACION_AUDITORIOS, CONFIGURACION_ASESORES } from '../actions/types';

const initialState = {
    categorias_empresas: {
        listado: [],
        detalles: null
    }, 
    extensiones: {
        listado: [],
        detalles: null
    }, 
    requerimientos: {
        listado: [],
        detalles: null
    }, 
    dependencias: {
        listado: [],
        detalles: null
    }, 
    cabinas: {
        listado: [],
        detalles: null
    }, 
    gimnasios: {
        listado: [],
        detalles: null
    },
    auditorios: {
        listado: [],
        detalles: null
    }, 
    asesores: {
        listado: [],
        detalles: null
    }
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case CONFIGURACION_CATEGORIAS_EMPRESAS.BUSCAR:
            return {
                ...state,
                categorias_empresas: {
                    ...state.categorias_empresas, 
                    listado: payload
                }
            };

        case CONFIGURACION_CATEGORIAS_EMPRESAS.AGREGAR:
            let categorias_agregar = state.categorias_empresas.listado;

            categorias_agregar = [
                ...categorias_agregar, payload
            ];

            categorias_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                categorias_empresas: {
                    ...state.categorias_empresas, 
                    listado: categorias_agregar
                }
            };

        case CONFIGURACION_CATEGORIAS_EMPRESAS.DETALLES:
            return {
                ...state,
                categorias_empresas: {
                    ...state.categorias_empresas, 
                    detalles: payload
                }
            };

        case CONFIGURACION_CATEGORIAS_EMPRESAS.EDITAR:
            let categorias_editar = state.categorias_empresas.listado;

            categorias_editar = categorias_editar.map(categoria => categoria._id === payload._id ? payload : categoria);

            categorias_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                categorias_empresas: {
                    ...state.categorias_empresas, 
                    listado: categorias_editar
                }
            };

        case CONFIGURACION_CATEGORIAS_EMPRESAS.ELIMINAR:
            return {
                ...state, 
                categorias_empresas: {
                    ...state.categorias_empresas, 
                    listado: state.categorias_empresas.listado.filter( categoria => categoria._id !== payload.id )
                }
            };

        case CONFIGURACION_EXTENSIONES.BUSCAR:
            return {
                ...state,
                extensiones: {
                    ...state.extensiones, 
                    listado: payload
                }
            };

        case CONFIGURACION_EXTENSIONES.AGREGAR:
            let extensiones_agregar = state.extensiones.listado;

            extensiones_agregar = [
                ...extensiones_agregar, payload
            ];

            extensiones_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                extensiones: {
                    ...state.extensiones, 
                    listado: extensiones_agregar
                }
            };

        case CONFIGURACION_EXTENSIONES.DETALLES:
            return {
                ...state,
                extensiones: {
                    ...state.extensiones, 
                    detalles: payload
                }
            };

        case CONFIGURACION_EXTENSIONES.EDITAR:
            let extensiones_editar = state.extensiones.listado;

            extensiones_editar = extensiones_editar.map(extension => extension._id === payload._id ? payload : extension);

            extensiones_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                extensiones: {
                    ...state.extensiones, 
                    listado: extensiones_editar
                }
            };

        case CONFIGURACION_EXTENSIONES.ELIMINAR:
            return {
                ...state, 
                extensiones: {
                    ...state.extensiones, 
                    listado: state.extensiones.listado.filter( extension => extension._id !== payload.id )
                }
            };

        case CONFIGURACION_REQUERIMIENTOS.BUSCAR:
            return {
                ...state,
                requerimientos: {
                    ...state.requerimientos, 
                    listado: payload
                }
            };

        case CONFIGURACION_REQUERIMIENTOS.AGREGAR:
            let requerimientos_agregar = state.requerimientos.listado;

            requerimientos_agregar = [
                ...requerimientos_agregar, payload
            ];

            requerimientos_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                requerimientos: {
                    ...state.requerimientos, 
                    listado: requerimientos_agregar
                }
            };

        case CONFIGURACION_REQUERIMIENTOS.DETALLES:
            return {
                ...state,
                requerimientos: {
                    ...state.requerimientos, 
                    detalles: payload
                }
            };

        case CONFIGURACION_REQUERIMIENTOS.EDITAR:
            let requerimientos_editar = state.requerimientos.listado;

            requerimientos_editar = requerimientos_editar.map(requerimiento => requerimiento._id === payload._id ? payload : requerimiento);

            requerimientos_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                requerimientos: {
                    ...state.requerimientos, 
                    listado: requerimientos_editar
                }
            };

        case CONFIGURACION_REQUERIMIENTOS.ELIMINAR:
            return {
                ...state, 
                requerimientos: {
                    ...state.requerimientos, 
                    listado: state.requerimientos.listado.filter( requerimiento => requerimiento._id !== payload.id )
                }
            };

        case CONFIGURACION_DEPENDENCIAS.BUSCAR:
            return {
                ...state,
                dependencias: {
                    ...state.dependencias, 
                    listado: payload
                }
            };

        case CONFIGURACION_DEPENDENCIAS.EDITAR:
            let dependencias_editar = state.dependencias.listado;

            dependencias_editar = dependencias_editar.map(dependencia => dependencia._id === payload._id ? payload : dependencia);

            return {
                ...state, 
                dependencias: {
                    ...state.dependencias, 
                    listado: dependencias_editar
                }
            };
        
        case CONFIGURACION_GIMNASIOS.BUSCAR:
            return {
                ...state,
                gimnasios: {
                    ...state.gimnasios, 
                    listado: payload
                }
            };

        case CONFIGURACION_GIMNASIOS.AGREGAR:
            let gimnasios_agregar = state.gimnasios.listado;

            gimnasios_agregar = [
                ...gimnasios_agregar, payload
            ];

            gimnasios_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                gimnasios: {
                    ...state.gimnasios, 
                    listado: gimnasios_agregar
                }
            };

        case CONFIGURACION_GIMNASIOS.DETALLES:
            return {
                ...state,
                gimnasios: {
                    ...state.gimnasios, 
                    detalles: payload
                }
            };

        case CONFIGURACION_GIMNASIOS.EDITAR:
            let gimnasios_editar = state.gimnasios.listado;

            gimnasios_editar = gimnasios_editar.map(cabina => cabina._id === payload._id ? payload : cabina);

            gimnasios_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                gimnasios: {
                    ...state.gimnasios, 
                    listado: gimnasios_editar
                }
            };

        case CONFIGURACION_GIMNASIOS.ELIMINAR:
            return {
                ...state, 
                gimnasios: {
                    ...state.gimnasios, 
                    listado: state.gimnasios.listado.filter( cabina => cabina._id !== payload.id )
                }
            };
    
        case CONFIGURACION_CABINAS.BUSCAR:
            return {
                ...state,
                cabinas: {
                    ...state.cabinas, 
                    listado: payload
                }
            };

        case CONFIGURACION_CABINAS.AGREGAR:
            let cabinas_agregar = state.cabinas.listado;

            cabinas_agregar = [
                ...cabinas_agregar, payload
            ];

            cabinas_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                cabinas: {
                    ...state.cabinas, 
                    listado: cabinas_agregar
                }
            };

        case CONFIGURACION_CABINAS.DETALLES:
            return {
                ...state,
                cabinas: {
                    ...state.cabinas, 
                    detalles: payload
                }
            };

        case CONFIGURACION_CABINAS.EDITAR:
            let cabinas_editar = state.cabinas.listado;

            cabinas_editar = cabinas_editar.map(cabina => cabina._id === payload._id ? payload : cabina);

            cabinas_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                cabinas: {
                    ...state.cabinas, 
                    listado: cabinas_editar
                }
            };

        case CONFIGURACION_CABINAS.ELIMINAR:
            return {
                ...state, 
                cabinas: {
                    ...state.cabinas, 
                    listado: state.cabinas.listado.filter( cabina => cabina._id !== payload.id )
                }
            };
        
        case CONFIGURACION_AUDITORIOS.BUSCAR:
            return {
                ...state,
                auditorios: {
                    ...state.auditorios, 
                    listado: payload
                }
            };

        case CONFIGURACION_AUDITORIOS.AGREGAR:
            let auditorios_agregar = state.auditorios.listado;

            auditorios_agregar = [
                ...auditorios_agregar, payload
            ];

            auditorios_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                auditorios: {
                    ...state.auditorios, 
                    listado: auditorios_agregar
                }
            };

        case CONFIGURACION_AUDITORIOS.DETALLES:
            return {
                ...state,
                auditorios: {
                    ...state.auditorios, 
                    detalles: payload
                }
            };

        case CONFIGURACION_AUDITORIOS.EDITAR:
            let auditorios_editar = state.auditorios.listado;

            auditorios_editar = auditorios_editar.map(auditorio => auditorio._id === payload._id ? payload : auditorio);

            auditorios_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                auditorios: {
                    ...state.auditorios, 
                    listado: auditorios_editar
                }
            };

        case CONFIGURACION_AUDITORIOS.ELIMINAR:
            return {
                ...state, 
                auditorios: {
                    ...state.auditorios, 
                    listado: state.auditorios.listado.filter( auditorio => auditorio._id !== payload.id )
                }
            };

        case CONFIGURACION_ASESORES.BUSCAR:
            return {
                ...state,
                asesores: {
                    ...state.asesores, 
                    listado: payload
                }
            };

        case CONFIGURACION_ASESORES.DETALLES:
            return {
                ...state,
                asesores: {
                    ...state.asesores, 
                    detalles: payload
                }
            };

        case CONFIGURACION_ASESORES.EDITAR:
            let asesores_editar = state.asesores.listado;

            asesores_editar = asesores_editar.map(asesor => asesor._id === payload._id ? payload : asesor);

            asesores_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                asesores: {
                    ...state.asesores, 
                    listado: asesores_editar
                }
            };
    
        default:
            return state;
    }
}