import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarRequerimientosConfiguracion } from '../../../actions/configuracion';

import NuevoRequerimiento from './NuevoRequerimiento';
import EditarRequerimiento from './EditarRequerimiento';
import EliminarRequerimiento from './EliminarRequerimiento';

const Requerimientos = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Requerimientos',
            path: '/configuracion/requerimientos'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.requerimientos);

    const [requerimientos, setRequerimientos] = useState([]);

    const [requerimientoAgregar, setRequerimientoAgregar] = useState(null);
    const [requerimientoEditar, setRequerimientoEditar] = useState(null);
    const [requerimientoEliminar, setRequerimientoEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
        },
        {
            name: 'Tipo',
            sortable: true, 
            selector: row => {
                switch(row.tipo){
                    case 0:
                        return 'Texto corto';
                    case 1:
                        return 'Texto lago';
                    case 2:
                        return 'Numero';
                    case 3:
                        return 'Fecha';
                    case 4:
                        if(row.multiple){
                            return 'Opciones (varias elegibles)';
                        }else{
                            return 'Opciones (una elegible)';
                        }
                    case 5:
                        return 'Fotografia o imagen';
                    case 6:
                        return 'Archivo';
                    default:
                        return 'Sin definir';
                }
            }
        },
        {
            name: 'Extensiones',
            sortable: true, 
            cell: item => `${item.extensiones?.length > 0 ? item.extensiones.join(', ') : 'Sin restricciones'}`
        },
        {
            name: 'Vigencia',
            sortable: true, 
            cell: item => `${item.vigencia ? convertVigencia(item.tipo_vigencia, item.vigencia) : 'Sin vigencia'}`
        },
        {
            name: 'Usos',
            sortable: true, 
            selector: row => {
                if(row.usos){
                    return 'De uso global en todas las solicitudes';
                }else{
                    return 'Requerido en cada solicitud';
                }
            }
        },
        {
            name: '',
            cell: row => {
                if(row._id){
                    return(
                        <div className="text-right w-100">
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarRequerimiento(row)}><i className="fas fa-trash text-danger"></i></button>
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarRequerimiento(row)}><i className="fas fa-edit text-warning"></i></button>
                        </div>
                    )
                }else{
                    return(<></>)
                }
            }
        }
    ];

    /* Crear requerimiento */
    const handleAgregarRequerimiento = (requerimiento) => {
        setRequerimientoEditar(null);
        setRequerimientoEliminar(null);

        setRequerimientoAgregar(requerimiento);        
    }

    /* Editar requerimiento */
    const handleEditarRequerimiento = (requerimiento) => {
        setRequerimientoAgregar(null);
        setRequerimientoEliminar(null);

        setRequerimientoEditar(requerimiento);
    }

    /* Eliminar requerimiento */
    const handleEliminarRequerimiento = (requerimiento) => {
        setRequerimientoAgregar(null);
        setRequerimientoEditar(null);

        setRequerimientoEliminar(requerimiento);
    }

    /* Funciones generales */
    const convertVigencia = (tipo, vigencia) => {
        let resultado = '';

        switch(tipo){
            case 0:
            case '0':
                resultado = vigencia == 1 ? 'dia' : 'dias';
                break;

            case 1:
            case '1':
                resultado = vigencia == 1 ? 'mes' : 'meses';
                break;

            case 2:
            case '2':
                resultado = vigencia == 1 ? 'año' : 'años';
                break;
        }

        return vigencia + ' ' + resultado;
    }

    useEffect(() => {
        dispatch(buscarRequerimientosConfiguracion());
    }, []);

    useEffect(() => {
        setRequerimientos(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <NuevoRequerimiento requerimiento={requerimientoAgregar} reestablecerRequerimiento={handleAgregarRequerimiento} />
            <EditarRequerimiento requerimiento={requerimientoEditar} reestablecerRequerimiento={handleEditarRequerimiento} />
            <EliminarRequerimiento requerimiento={requerimientoEliminar} reestablecerRequerimiento={handleEliminarRequerimiento} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Requerimientos</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarRequerimiento({ })}>Agregar requerimiento</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={requerimientos}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Requerimientos;