import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { setAlert } from '../../../actions/alert';
import { limpiarRequerimientoConfiguracion, obtenerRequerimientoConfiguracion, editarRequerimientoConfiguracion, buscarExtensionesConfiguracion } from '../../../actions/configuracion';

const EditarRequerimiento = ({ requerimiento, reestablecerRequerimiento }) => {

    const dispatch =  useDispatch();

    const { detalles } = useSelector(state => state.configuracion.requerimientos);
    const { listado } = useSelector(state => state.configuracion.extensiones);

    const [editarModal, setEditarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [requerimientoData, setRequerimientoData] = useState({
        id: null,
        tipo: null,
        extensiones: [],
        nombre: null, 
        vigencia: null, 
        tipo_vigencia: null, 
        usos: false, 
        opciones: [], 
        multiple: false
    });

    const [tipoRequerimientoData, setTipoRequerimientoData] = useState(null);
    const [tiposRequerimientosFilter, setTiposRequerimientosFilter] = useState([
        { value: 0, label: 'Texto corto', icon: 'fas fa-font' }, 
        { value: 1, label: 'Texto largo', icon: 'fas fa-font' }, 
        { value: 2, label: 'Número', icon: 'fas fa-sort-numeric-up' }, 
        { value: 3, label: 'Fecha', icon: 'fas fa-calendar' }, 
        { value: 4, label: 'Opciones', icon: 'fas fa-list' }, 
        { value: 5, label: 'Imágen', icon: 'fas fa-image' }, 
        { value: 6, label: 'Archivo', icon: 'fas fa-file' }
    ]);

    const [extensionData, setExtensionData] = useState(null);
    const [extensionesFilter, setExtensionesFilter] = useState([]);

    const [tipoVigenciaData, setTipoVigenciaData] = useState(null);
    const [tiposVigenciasFilter, setTiposVigenciasFilter] = useState([
        { value: 0, label: 'Dias' }, 
        { value: 1, label: 'Meses' }, 
        { value: 2, label: 'Años' }
    ]);

    const [usosData, setUsosData] = useState(null);
    const [usosFilter, setUsosFilter] = useState([
        { value: false, label: 'Requerido en cada solicitud' }, 
        { value: true, label: 'De uso global en todas las solicitudes' }
    ]);

    /* Editar requerimiento */
    const handleInputChange = ({ target }) => {
        setRequerimientoData({
            ...requerimientoData,
            [target.name]: target.value
        });
    }

    const handleSelectTipo = (value) => {
        setTipoRequerimientoData(value);

        setExtensionData(null);

        setRequerimientoData({
            ...requerimientoData,
            tipo: value ? value?.value : null,
            opciones: [],
            extensiones: [],
            multiple: false
        });
    }

    const handleSelectExtension = (value) => {
        let extensiones = [];

        for (var i = 0; i < value?.length; i++) {
            extensiones.push(value[i].value);
        }
        
        setExtensionData(value);

        setRequerimientoData({
            ...requerimientoData,
            extensiones
        });
    }

    const handleSelectTipoVigencia = (value) => {
        setTipoVigenciaData(value);

        setRequerimientoData({
            ...requerimientoData,
            tipo_vigencia: value ? value?.value : null
        });
    }

    const handleSelectUsos = (value) => {
        setUsosData(value);

        setRequerimientoData({
            ...requerimientoData,
            usos: value ? value?.value : null
        });
    }

    const handleMultipleChange = () => {
        setRequerimientoData({ 
            ...requerimientoData, 
            multiple: !requerimientoData.multiple
        });
    }

    const handleAgregarOpcion = () => {
        let opciones = requerimientoData.opciones;

        opciones.push({
            contenido: null
        });

        setRequerimientoData({ 
            ...requerimientoData, 
            opciones
        });
    }

    const handleOpcionesCampos = (opciones) => {

        let opciones_campos = [];

        if(opciones){
            if(opciones?.length > 0){
                for (var x = 0; x < opciones?.length; x++) {
                    opciones_campos.push(
                        <div className="col-md-6 form-group mb-3" key={'opcion_' + x}>
                            <div className="input-group h-100">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Opcion" id={'contenidoOpcion_' + x} name={'contenidoOpcion_' + x} value={opciones[x].contenido || ''} onChange={e => handleOpcionChange(e)}/>
                                    <label htmlFor={'contenidoOpcion_' + x}>Opción {x + 1}</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-danger delete" id={'eliminarOpcion_' + x} onClick={e => handleEliminarOpcion(e)}><i className="fa-solid fa-xmark"></i></button>
                                </div>
                            </div>
                        </div>
                    );
                }
            }else{
                opciones_campos.push(
                    <div className="col-md-12 my-2">
                        <p className="text-center py-2">Agrega opciones para el requerimiento de opción múltiple.</p>
                    </div>
                );
            }
        }else{
            opciones_campos.push(
                <div className="col-md-12 my-2">
                    <p className="text-center py-2">Agrega opciones para el requerimiento de opción múltiple.</p>
                </div>
            );
        }

        if(requerimientoData.tipo != 4 || requerimientoData.tipo != '4'){
            opciones_campos = [];
        }

        return opciones_campos;
    }

    const handleOpcionChange = ({ target }) => {
        let nombre_separado = target.name.split('_');

        let posicion = nombre_separado[1];
        
        let opciones = requerimientoData.opciones;

        opciones[posicion].contenido = target.value || '';

        setRequerimientoData({
            ...requerimientoData,
            opciones
        });
    }

    const handleEliminarOpcion = async ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');    

        let opciones = requerimientoData.opciones;

        opciones.splice(id_separado[1], 1);

        setRequerimientoData({ 
            ...requerimientoData, 
            opciones
        });
    }
    
    const handleProcesar = async () => {
        if(requerimientoData.nombre && requerimientoData.tipo != null){            
            if((requerimientoData.tipo == 4 || requerimientoData.tipo == '4') && requerimientoData.opciones.length == 0){
                await dispatch(setAlert('Debe haber al menos una opción agregada', 'danger'));
            }else{
                if(requerimientoData.tipo_vigencia != null && !requerimientoData.vigencia || requerimientoData.tipo_vigencia == null && requerimientoData.vigencia){
                    await dispatch(setAlert('Agrega una vigencia válida', 'danger'));
                }else{
                    if(requerimientoData.usos != null){
                        await setStatusProcesar(true);
                        await dispatch(editarRequerimientoConfiguracion(requerimientoData));
                        await handleCerrar();
                        await setStatusProcesar(false);
                    }else{
                        await dispatch(setAlert('Agrega un modelo de uso válido', 'danger'));
                    }
                }                
            }
        }else{
            await dispatch(setAlert('Completa todos los campos', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setRequerimientoData({
                id: null,
                tipo: 0,
                extensiones: [], 
                nombre: null, 
                vigencia: null, 
                tipo_vigencia: null, 
                usos: false, 
                opciones: [], 
                multiple: false
            });

            setTipoRequerimientoData(null);

            setExtensionData(null);

            setTipoVigenciaData(null);

            setUsosData(null);

            setEditarModal(false);

            reestablecerRequerimiento(null);
        }
    }

    useEffect(() => {
        dispatch(limpiarRequerimientoConfiguracion());
        dispatch(buscarExtensionesConfiguracion());
    }, []);

    useEffect(() => {
        if(requerimiento){
            setEditarModal(true);
            
            dispatch(obtenerRequerimientoConfiguracion(requerimiento?._id));

            setRequerimientoData({
                ...requerimientoData,
                id: requerimiento?._id
            });
        }
    }, [requerimiento]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado.length; i++) {
            options.push({ value: listado[i]._id, label: listado[i].nombre });
        }

        setExtensionesFilter(options);
    }, [listado]);

    useEffect(() => {
        if(detalles){
            setRequerimientoData({
                ...requerimientoData,
                id: detalles?._id, 
                tipo: detalles?.tipo,
                extensiones: detalles?.extensiones,
                nombre: detalles?.nombre, 
                vigencia: detalles?.vigencia,  
                tipo_vigencia: detalles?.tipo_vigencia,  
                usos: detalles?.usos,  
                opciones: detalles?.opciones, 
                multiple: detalles?.multiple
            });

            let tipo_requerimiento_asignado = tiposRequerimientosFilter.find( tipo => String(tipo.value) === String(detalles?.tipo) );
            setTipoRequerimientoData(tipo_requerimiento_asignado);

            let tipo_vigencia_asignado = tiposVigenciasFilter.find( tipo => String(tipo.value) === String(detalles?.tipo_vigencia) );
            setTipoVigenciaData(tipo_vigencia_asignado);

            let usos_asignado = usosFilter.find( usos => String(usos.value) === String(detalles?.usos) );
            setUsosData(usos_asignado);

            if(extensionesFilter.length > 0){
                let extensiones_elegidas = [];

                for (var i = 0; i < detalles?.extensiones.length; i++) {
                    let extension_elegida = extensionesFilter.find( extension => String(extension.value) === String(detalles?.extensiones[i]) );

                    extensiones_elegidas.push({value: extension_elegida.value, label: extension_elegida.label});
                }
    
                setExtensionData(extensiones_elegidas);
            }
        }
    }, [detalles]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .selectIconos, .select-group {
                            height: 100%;
                        }

                        .check {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 0 0 0 !important;
                            height: 100%;
                        }

                        .delete {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 25% 25% 0 !important;
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <Modal show={editarModal} size="lg" backdrop="static" centered onHide={() => handleCerrar()}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar requerimiento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className={'form-group mb-3 col-md-' + (requerimientoData.tipo == 6 || requerimientoData.tipo == '6' ? '4' : '7')}>
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={requerimientoData.nombre} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="nombre">Nombre</label>
                                </div>
                            </div>
                        </div>

                        <div className={'form-group mb-3 col-md-' + (requerimientoData.tipo == 6 || requerimientoData.tipo == '6' ? '4' : '5')}>
                            <div className="input-group h-100">
                                <div className="form-floating">
                                    <Select
                                        name="tipo"
                                        className="select-group"
                                        classNamePrefix="react-select"
                                        placeholder="Tipo de requerimiento"
                                        options={tiposRequerimientosFilter}
                                        value={tipoRequerimientoData}
                                        onChange={handleSelectTipo}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        getOptionLabel={e => (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <i className={e.icon}></i>
                                                <span style={{ marginLeft: 5 }}>{e.label}</span>
                                            </div>
                                        )}
                                    />
                                </div>
                                {
                                    requerimientoData.tipo == 4 || requerimientoData.tipo == '4' ?
                                        <Fragment>
                                            <div className="input-group-append">
                                                <button className={"btn check " + (requerimientoData.multiple ? 'btn-success' : 'btn-outline-success')} onClick={() => handleMultipleChange()}><i className={"fas fa-" + ((requerimientoData.multiple ? 'ellipsis-h' : 'circle'))}></i></button>
                                            </div>
                                            <div className="input-group-append">
                                                <button className="btn delete btn-primary" onClick={() => handleAgregarOpcion()}><i className="fas fa-plus"></i></button>
                                            </div>
                                        </Fragment>
                                    :
                                        null
                                }
                            </div>
                        </div>

                        {
                            requerimientoData.tipo == 6 || requerimientoData.tipo == '6' ?
                                <div className="col-md-4 form-group mb-3">
                                    <div className="input-group h-100">
                                        <div className="form-floating">
                                            <Select
                                                name="extensiones"
                                                className="select-group"
                                                classNamePrefix="react-select"
                                                placeholder="Extensiones"
                                                options={extensionesFilter}
                                                value={extensionData}
                                                onChange={handleSelectExtension}
                                                isClearable 
                                                isMulti={true} 
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                getOptionLabel={e => (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <i className={e.icon}></i>
                                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            :
                                null
                        }

                        <div className="col-md-6 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="number" className="form-control" placeholder="Vigencia" id="vigencia" name="vigencia" value={requerimientoData.vigencia} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="nombre">Vigencia</label>
                                </div>
                                <div className="input-group-append">
                                    <Select
                                        name="tipo_vigencia"
                                        className="select-group"
                                        classNamePrefix="react-select"
                                        placeholder="Tipo de vigencia"
                                        options={tiposVigenciasFilter}
                                        value={tipoVigenciaData}
                                        isClearable={true}
                                        onChange={handleSelectTipoVigencia}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        getOptionLabel={e => (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <i className={e.icon}></i>
                                                <span style={{ marginLeft: 5 }}>{e.label}</span>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group mb-3">
                            <div className="input-group h-100">
                                <div className="form-floating">
                                    <Select
                                        name="usos"
                                        className="select-group"
                                        classNamePrefix="react-select"
                                        placeholder="Tipo de uso"
                                        options={usosFilter}
                                        value={usosData}
                                        onChange={handleSelectUsos}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        getOptionLabel={e => (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <i className={e.icon}></i>
                                                <span style={{ marginLeft: 5 }}>{e.label}</span>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        { handleOpcionesCampos(requerimientoData.opciones) }
                    </div>                    
                </Modal.Body>
                <Modal.Footer>
                    {
                        statusProcesar ?
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Editar</button>
                        :
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Editar</button>
                    }
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default EditarRequerimiento;