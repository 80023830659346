import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { setAlert } from '../../actions/alert';
import { buscarRequerimientos, generarReporteJovenes } from '../../actions/reportes';

const FiltrosJovenes = ({ jovenReporte, reestablecerFiltros }) => {

    const dispatch =  useDispatch();

    const { listado_requerimientos } = useSelector(state => state.reportes);

    const [statusProcesar, setStatusProcesar] = useState(false);

    const [busquedaData, setBusquedaData] = useState({
        fecha_inicio: null,
        fecha_fin: null,
        ids_requerimientos: []
    });

    const [tiposRequerimientoData, setTiposRequerimientoData] = useState(null);
    const [tiposRequerimientosFilter, setTiposRequerimientosFilter] = useState([]);

    /* Buscar */
    const handleAbrir = () => {
        dispatch(buscarRequerimientos());
    }

    const handleDatePicker = (name, value) => {
        setBusquedaData({
            ...busquedaData,
            [name]: value
        });
    }

    const handleSelectTipo = (value) => {
        let ids_requerimientos = [];

        for (var i = 0; i < value?.length; i++) {
            ids_requerimientos.push(value[i].value);
        }

        setBusquedaData({
            ...busquedaData,
            ids_requerimientos
        });

        setTiposRequerimientoData(value);
    }
    
    const handleProcesar = async () => {
        if(new Date(busquedaData.fecha_inicio) <= new Date(busquedaData.fecha_fin)){
            await setStatusProcesar(true);

            await dispatch(generarReporteJovenes(busquedaData));
                        
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Tu fecha de inicio es mayor a tu fecha de finalización', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setBusquedaData({
                fecha_inicio: null,
                fecha_fin: null, 
                ids_requerimientos: []
            });

            setTiposRequerimientoData(null);

            reestablecerFiltros(null);
        }
    }

    useEffect(() => {
        if(listado_requerimientos.length > 0){
            let tipos_requerimientos = [
                'Texto corto', 
                'Texto largo', 
                'Número', 
                'Fecha', 
                'Opciones', 
                'Imágen', 
                'Archivo'
            ];

            let opciones_requerimientos = [];

            for (var x = 0; x < listado_requerimientos?.length; x++) {
                if(listado_requerimientos[x].length > 0){
                    let options = [];

                    for (var i = 0; i < listado_requerimientos[x].length; i++) {
                        options.push({ value: listado_requerimientos[x][i]._id, label: listado_requerimientos[x][i].nombre });
                    }

                    if(options.length > 0){
                        opciones_requerimientos.push({
                            label: tipos_requerimientos[x],
                            options
                        });
                    }
                }
            }

            setTiposRequerimientosFilter(opciones_requerimientos);
        }
    }, [listado_requerimientos]);

    return (
        <Modal show={jovenReporte} size="lg" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Filtros de búsqueda</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_inicio"
                                    name="fecha_inicio"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    locale="es-ES"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={ busquedaData.fecha_inicio != null ? new Date(busquedaData.fecha_inicio) : '' }
                                    onChange={ date => handleDatePicker('fecha_inicio', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_inicio">Fecha de inicio de registro</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_fin"
                                    name="fecha_fin"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    locale="es-ES"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={ busquedaData.fecha_fin != null ? new Date(busquedaData.fecha_fin) : '' }
                                    onChange={ date => handleDatePicker('fecha_fin', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_fin">Fecha de finalización de registro</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mb-3">
                        <hr></hr>
                        <p>Elige los datos extras que deseas agregar al reporte.</p>
                    </div>

                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group">
                            <Select
                                id="ids_requerimientos" 
                                name="ids_requerimientos" 
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Selecciona los datos extras"
                                isClearable 
                                isMulti={true} 
                                options={tiposRequerimientosFilter}
                                value={tiposRequerimientoData}
                                onChange={handleSelectTipo}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                getOptionLabel={e => (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <i className={e.icon}></i>
                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                    </div>
                                )}
                            />
                        </div>
                    </div>                
                </div>                  
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Buscar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-magnifying-glass fa-sm"></i> Buscar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default FiltrosJovenes;