import axios from 'axios';

import { CABINA } from "../actions/types";

import { setAlert } from './alert';
import { changeLoader } from './loader';

/* Reservaciones */
export const buscarReservaciones = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(filtros);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/cabina/listar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CABINA.BUSCAR,
            payload: res.data.eventos
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const buscarHorariosReservacion = (fechas) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(fechas);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/cabina/horarios_reservacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CABINA.BUSCAR_HORARIOS_RESERVACION,
            payload: res.data.horarios_disponibles
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearReservacion = (reservacion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(reservacion);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/cabina/crear_reservacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CABINA.AGREGAR,
            payload: res.data.reservaciones
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerReservacion = (id) => async dispatch => {
    if(id){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const body = {
            id
        };

        try{
            await dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/cabina/detalles_reservacion`, body, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: CABINA.DETALLES,
                payload: res.data.reservacion
            });
        } catch (error){         
            await dispatch(changeLoader(false));

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        dispatch({
            type: CABINA.DETALLES,
            payload: null
        });
    }
}

export const editarReservacion = (reservacion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(reservacion);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/cabina/editar_reservacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CABINA.EDITAR,
            payload: res.data.reservacion
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){   
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const statusReservacion = (status) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(status);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/cabina/status_reservacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CABINA.EDITAR,
            payload: res.data.reservacion
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){  
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarReservacion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/cabina/eliminar_reservacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CABINA.ELIMINAR,
            payload: [body.id]
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const filtarReservaciones = (opcion) => dispatch => {
    dispatch({
        type: CABINA.FILTROS,
        payload: opcion
    });
}

/* Bloqueos */
export const crearBloqueo = (bloqueo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(bloqueo);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/cabina/crear_bloqueo`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CABINA.AGREGAR,
            payload: res.data.bloqueos
        });

        await dispatch({
            type: CABINA.ELIMINAR,
            payload: res.data.reservaciones
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerBloqueo = (id_cabina, id) => async dispatch => {
    if(id){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const body = {
            id_cabina, 
            id
        };

        try{
            await dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/cabina/detalles_bloqueo`, body, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: CABINA.DETALLES,
                payload: res.data.bloqueo
            });
        } catch (error){       
            await dispatch(changeLoader(false));  

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        dispatch({
            type: CABINA.DETALLES,
            payload: null
        });
    }
}

export const editarBloqueo = (bloqueo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(bloqueo);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/cabina/editar_bloqueo`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CABINA.EDITAR,
            payload: res.data.bloqueo
        });

        await dispatch({
            type: CABINA.ELIMINAR,
            payload: res.data.reservaciones
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){   
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarBloqueo = (id_cabina, id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id_cabina, 
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/cabina/eliminar_bloqueo`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CABINA.ELIMINAR,
            payload: [body.id]
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}