import { GIMNASIO } from "../actions/types";

const initialState = {
    listado_gimnasios: [], 
    listado_jovenes: [], 
    detalles_joven: null,
    listado_accesos: [], 
    listado_inscritos: [], 
    ventana_filtros: false, 
    filtros: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case GIMNASIO.LISTAR:
            return {
                ...state,
                listado_gimnasios: payload
            };

        case GIMNASIO.BUSCAR:
            return {
                ...state,
                listado_jovenes: payload
            };

        case GIMNASIO.DETALLES:
            return {
                ...state,
                detalles_joven: payload
            };

        case GIMNASIO.LISTAR_ACCESOS:
            return {
                ...state,
                listado_accesos: payload
            };

        case GIMNASIO.LISTAR_INSCRITOS:
            return {
                ...state,
                listado_inscritos: payload
            };

        case GIMNASIO.INSCRIBIR:
            return {
                ...state,
                listado_inscritos: [
                    payload, ...state.listado_inscritos
                ]
            };

        case GIMNASIO.RENOVAR:
            let listado_inscritos_editar = state.listado_inscritos;

            listado_inscritos_editar = listado_inscritos_editar.map(inscrito => inscrito._id === payload._id ? payload : inscrito);

            return {
                ...state, 
                listado_inscritos: listado_inscritos_editar
            };

        case GIMNASIO.ELIMINAR:
            return {
                ...state,
                listado_inscritos: state.listado_inscritos.filter( inscrito => inscrito._id !== payload.id )
            };

        case GIMNASIO.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };
    
        default:
            return state;
    }
}