import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { editarBreadcrumb } from '../../actions/breadcrumb';

import FiltrosJovenes from './FiltrosJovenes';
import FiltrosEmpresas from './FiltrosEmpresas';
import FiltrosReservaciones from './FiltrosReservaciones';
import FiltrosGimnasio from './FiltrosGimnasio';
import FiltrosSolicitudes from './FiltrosSolicitudes';
import FiltrosEventos from './FiltrosEventos';
import FiltrosAsesorias from './FiltrosAsesorias';

const Reportes = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Reportes',
            path: '/reportes'
        }
    ];

    const dispatch = useDispatch();

    const [jovenReporte, setJovenReporte] = useState(false);
    const [empresaReporte, setEmpresaReporte] = useState(false);
    const [reservacionReporte, setReservacionReporte] = useState(false);
    const [gimnasioReporte, setGimnasioReporte] = useState(false);
    const [solicitudReporte, setSolicitudReporte] = useState(false);
    const [eventoReporte, setEventoReporte] = useState(false);
    const [asesoriaReporte, setAsesoriaReporte] = useState(false);

    /* Buscar */
    const handleFiltrosJoven = (valor) => {
        setJovenReporte(valor);
    }

    const handleFiltrosEmpresa = (valor) => {
        setEmpresaReporte(valor);
    }

    const handleFiltrosReservacion = (valor) => {
        setReservacionReporte(valor);
    }

    const handleFiltrosGimnasio = (valor) => {
        setGimnasioReporte(valor);
    }

    const handleFiltrosSolicitud = (valor) => {
        setSolicitudReporte(valor);
    }

    const handleFiltrosEvento = (valor) => {
        setEventoReporte(valor);
    }

    const handleFiltrosAsesoria = (valor) => {
        setAsesoriaReporte(valor);
    }

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return(
        <Fragment>

            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .titulo {
                            color: #111F62;
                        }

                        .subtitulo {
                            margin: 0px;
                        }
                        
                        .buttonIcon {
                            padding: 20px;
                            font-size: 30px;
                            background-color: #111F62;
                            color: #FFFFFF;
                            border-radius: 10px;
                            margin-bottom: 15px;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <FiltrosJovenes jovenReporte={jovenReporte} reestablecerFiltros={() => handleFiltrosJoven(false)} />
            <FiltrosEmpresas empresaReporte={empresaReporte} reestablecerFiltros={() => handleFiltrosEmpresa(false)} />
            <FiltrosReservaciones reservacionReporte={reservacionReporte} reestablecerFiltros={() => handleFiltrosReservacion(false)} />
            <FiltrosGimnasio gimnasioReporte={gimnasioReporte} reestablecerFiltros={() => handleFiltrosGimnasio(false)} />
            <FiltrosSolicitudes solicitudReporte={solicitudReporte} reestablecerFiltros={() => handleFiltrosSolicitud(false)} />
            <FiltrosEventos eventoReporte={eventoReporte} reestablecerFiltros={() => handleFiltrosEvento(false)} />
            <FiltrosAsesorias asesoriaReporte={asesoriaReporte} reestablecerFiltros={() => handleFiltrosAsesoria(false)} />
                
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="row d-flex align-items-center mb-3 mt-5">
                        <div className="col-md-8">
                            <h3>Reportes</h3>
                        </div>
                    </div>
                    <div className="row mb-3 mt-5">
                        <div className="col-md-6 my-2">
                            <button type="button" className="card bg-light w-100 card-style" onClick={() => handleFiltrosJoven(true)}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0" id="certificaciones_financiero">
                                        <i className="buttonIcon fa-solid fa-users" />
                                        <h5 className="titulo">Jovenes</h5>
                                        <p className="subtitulo">Reporte que permite visualizar el listado de jovenes registrados desde el sistema y desde la app de Tarjeta Joven.</p>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="col-md-6 my-2">
                            <button type="button" className="card bg-light w-100 card-style" onClick={() => handleFiltrosEmpresa(true)}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0" id="certificaciones_academico">
                                        <i className="buttonIcon fa-solid fa-building-columns" />
                                        <h5 className="titulo">Empresas</h5>
                                        <p className="subtitulo">Reporte que permite visualizar el listado de las empresas registradas dentro del sistema.</p>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="col-md-6 my-2">
                            <button type="button" className="card bg-light w-100 card-style" onClick={() => handleFiltrosReservacion(true)}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0" id="estudiantes">
                                        <i className="buttonIcon fa-solid fa-book" />
                                        <h5 className="titulo">Reservaciones</h5>
                                        <p className="subtitulo">Reporte que permite visualizar el listado de las reservaciones hechas a las distintas instalaciones.</p>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="col-md-6 my-2">
                            <button type="button" className="card bg-light w-100 card-style" onClick={() => handleFiltrosGimnasio(true)}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0" id="profesores">
                                        <i className="buttonIcon fa-solid fa-heartbeat" />
                                        <h5 className="titulo">Inscripciones en gimnasio</h5>
                                        <p className="subtitulo">Reporte que permite visualizar el listado de las inscripciones relizadas en los gimnasios por los distintos jovenes.</p>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="col-md-6 my-2">
                            <button type="button" className="card bg-light w-100 card-style"  onClick={() => handleFiltrosSolicitud(true)}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0" id="soporte">
                                        <i className="buttonIcon fa-solid fa-question" />
                                        <h5 className="titulo">Solicitudes</h5>
                                        <p className="subtitulo">Reporte que permite visualizar el listado de las solicitudes hechas para becas, apoyos y programas.</p>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="col-md-6 my-2">
                            <button type="button" className="card bg-light w-100 card-style"  onClick={() => handleFiltrosEvento(true)}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0" id="soporte">
                                        <i className="buttonIcon fa-solid fa-calendar" />
                                        <h5 className="titulo">Eventos</h5>
                                        <p className="subtitulo">Reporte que permite visualizar el listado de las entradas a eventos solicitadas por parte de los jovenes.</p>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="col-md-6 my-2">
                            <button type="button" className="card bg-light w-100 card-style"  onClick={() => handleFiltrosAsesoria(true)}>
                                <div className="card-body centrado_horizontal">
                                    <div className="card-title mb-0" id="soporte">
                                        <i className="buttonIcon fa-solid fa-headset" />
                                        <h5 className="titulo">Asesorías</h5>
                                        <p className="subtitulo">Reporte que permite visualizar el listado de las asesorias realizadas por cada una de las 3 áreas disponibles.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Reportes;