import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Badge } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { formatNumber, sortTableDateSolicitud, sortTableDateConfirmacion, sortTableStatusCanjeado } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { limpiarPromocion, obtenerPromocion, cambioStatus } from '../../actions/promociones';

const DetallesPromocion = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Promociones y descuentos',
            path: '/promociones'
        },
        {
            activo: true,
            nombre: 'Detalles de la promoción',
            path: '/promociones/detalles/'
        }
    ];

    const dispatch = useDispatch();

    const { id } = useParams();

    const { detalles } = useSelector(state => state.promociones);

    const [promocionData, setPromocionData] = useState({
        indicadores_generales: {
            solicitados: 0,
            realizados: 0,
            libres: null,
            dias_restantes: null
        },
        informacion_detallada: {
            _id: null,
            nombre: null,
            inicio: null,
            fin: null,
            limite: null,
            canje: null,
            descripcion: null,
            status: 1,
            url_portada: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/imagen_placeholder.png'
        },
        cupones: []
    });

    const {indicadores_generales, informacion_detallada, cupones} = promocionData;

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const cuponesColumnas = [
        {
            name: 'Joven',
            sortable: true, 
            selector: row => row.joven || 'Sin definir',
            grow: 2
        },
        {
            name: 'Código',
            sortable: true, 
            selector: row => row.codigo,
        },
        {
            name: 'Solicitó',
            selector: row => row.solicitud,
            sortable: true, 
            sortFunction: sortTableDateSolicitud, 
            cell: item => item.solicitud ? new Date(item.solicitud).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'
        },
        {
            name: 'Canjeó',
            selector: row => row.confirmacion,
            sortable: true, 
            sortFunction: sortTableDateConfirmacion, 
            cell: item => item.confirmacion ? new Date(item.confirmacion).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusCanjeado, 
            selector: row => {
                switch(row.canjeado){
                    case false:
                        return(
                            <h5><Badge pill bg="danger">Sin canjear</Badge></h5>
                        )
                    case true:
                        return(
                            <h5><Badge pill bg="success">Canjeado</Badge></h5>
                        )
                    default:
                        break;
                }
            },
        }
    ];

    /* Funciones generales */
    const handleCambioStatus = () => {
        if(informacion_detallada?.status == 0 || informacion_detallada?.status == '0'){
            dispatch(cambioStatus(id, 1, detalles));
        }else{
            if(informacion_detallada?.status == 1 || informacion_detallada?.status == '1'){
                dispatch(cambioStatus(id, 0, detalles));
            }else{
                dispatch(setAlert('La promoción ya ha finalizado', 'danger'));
            }
        }
    }

    useEffect(() => {
        dispatch(limpiarPromocion());

        return () => {
            dispatch(limpiarPromocion());
        }
    }, []);

    useEffect(() => {
        dispatch(obtenerPromocion(id));

        if(id){
            rutasBread[1].path = rutasBread[1].path + id;

            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [id]);

    useEffect(() => {
        if(detalles){
            setPromocionData({
                ...promocionData,
                indicadores_generales: detalles.indicadores_generales, 
                informacion_detallada: {
                    ...detalles.informacion_detallada,
                    imagen: detalles.informacion_detallada.url_portada || process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/imagen_placeholder.png'
                }, 
                cupones: detalles.cupones, 
            });
        }
    }, [detalles]); 

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .contenedor_imagen_portada {
                            border-radius: 5%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            background: #fff;
                            border: 5px solid #111F62;
                            width: 100%;
                        }
                        
                        .contenedor_imagen{
                            position: relative;
                            width: 100%;
                            height: auto;
                            overflow: hidden;
                            border-radius: 5%;
                        }

                        #imagen_portada {
                            width: 100%;
                        }

                        .btn-badge-status{
                            background-color: #fff;
                            border: none;
                            outline: none;
                            padding: 0;
                        }

                        .valor_indicador {
                            font-size: 40px;
                            margin: 0;
                        }

                        .descripcion_indicador {
                            margin: 0;
                        }

                        .textareCampo {
                            height: 100px !important;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Detalles de la promoción</h3>

                    <div className="row mb-4">
                        <div className="col-md-3 text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.solicitados, 0, '', '') }</p>
                            <p className="descripcion_indicador">Canjes solicitados</p>
                        </div>
                        <div className="col-md-3 text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.realizados, 0, '', '') }</p>
                            <p className="descripcion_indicador">Canjes realizados</p>
                        </div>
                        <div className="col-md-3 text-center">
                            <p className="valor_indicador">{ indicadores_generales?.libres != null ? formatNumber(indicadores_generales?.libres, 0, '', '') : 'Sin límite' }</p>
                            <p className="descripcion_indicador">Cupones libres</p>
                        </div>
                        <div className="col-md-3 text-center">
                            <p className="valor_indicador">{ indicadores_generales?.dias_restantes != null ? formatNumber(indicadores_generales?.dias_restantes, 0, '', '') : 'Sin límite' }</p>
                            <p className="descripcion_indicador">Dias restantes</p>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-3 py-3 text-center">
                            <label className="contenedor_imagen_portada">
                                <div className="contenedor_imagen">
                                    <img id="imagen_portada" src={ informacion_detallada?.imagen }/>
                                </div>
                            </label>
                            <div className="mt-2 d-flex justify-content-center">
                                <button className="btn-badge-status" onClick={() => handleCambioStatus()}>
                                    <h3 className="text-muted">
                                        <Badge bg={informacion_detallada?.status == 0 || informacion_detallada?.status == '0' ? 'success' : (informacion_detallada?.status == 1 || informacion_detallada?.status == '1' ? 'warning' : 'danger')}>
                                            {informacion_detallada?.status == 0 || informacion_detallada?.status == '0' ? 'Activa' : (informacion_detallada?.status == 1 || informacion_detallada?.status == '1' ? 'Inactiva' : 'Finalizada')}
                                        </Badge>
                                    </h3>
                                </button>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div className="accordion" id="accordionDetalles">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Información de la promoción
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-3">
                                                <label className="fw-bold">Nombre</label>
                                                <p>{informacion_detallada?.nombre}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Cupones límite</label>
                                                <p>{informacion_detallada?.cupo ? formatNumber(informacion_detallada?.cupo, 0, '', informacion_detallada?.cupo != 1 ? ' cupones' : ' cupon') : 'Sin limite'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Fecha de solicitud</label>
                                                <p>{informacion_detallada?.limite ? new Date(informacion_detallada?.limite).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Fecha de canje</label>
                                                <p>{informacion_detallada?.canje ? new Date(informacion_detallada?.canje).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                                            </div>
                                            <div className='col-md-12'>
                                                <label className='fw-bold'>Descripción</label>
                                                <p>{informacion_detallada?.descripcion || 'Sin definir'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                        Canjes generados
                                    </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body">
                                            <DataTable
                                                columns={cuponesColumnas}
                                                data={cupones}
                                                noDataComponent="No hay canjes registrados"
                                                pagination
                                                paginationPerPage={20}
                                                paginationRowsPerPageOptions={[20, 50, 100]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DetallesPromocion;