import axios from 'axios';

import { PROGRAMA } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

/* Programas */
export const buscarProgramas = (filtros) => async dispatch => {
    if(filtros){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify(filtros);

        try{
            await dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/listar`, body, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: PROGRAMA.BUSCAR,
                payload: res.data.programas
            });
        } catch (error){ 
            await dispatch(changeLoader(false));

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        await dispatch({
            type: PROGRAMA.BUSCAR,
            payload: []
        });
    }
}

export const buscarRequerimientos = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/programas/requerimientos`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROGRAMA.BUSCAR_REQUERIMIENTOS,
            payload: res.data.requerimientos
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearPrograma = (programa) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/crear`, programa, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const informacionPrograma = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/informacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROGRAMA.DETALLES,
            payload: res.data.programa
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerPrograma = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/detalles`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROGRAMA.DETALLES,
            payload: res.data.programa
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarPrograma = () => dispatch => {
    dispatch({
        type: PROGRAMA.DETALLES,
        payload: null
    });
}

export const editarPrograma = (programa) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/editar`, programa, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const cambioStatus = (id, status, detalles) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id, 
        status
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/status`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROGRAMA.DETALLES,
            payload: {
                ...detalles, 
                informacion_detallada: {
                    ...detalles.informacion_detallada, 
                    status
                }
            }
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarPrograma = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/eliminar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROGRAMA.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const filtrarProgramas = (opcion) => dispatch => {
    dispatch({
        type: PROGRAMA.FILTROS,
        payload: opcion
    });
}

/* Solicitudes */
export const buscarSolicitudes = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(filtros);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/listar_solicitudes`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROGRAMA.BUSCAR_SOLICITUDES,
            payload: res.data.solicitudes
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerSolicitud = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/informacion_solicitud`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROGRAMA.SOLICITUDES.DETALLES,
            payload: res.data.solicitud
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerSolicitudCompleta = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/detalles_solicitud`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROGRAMA.SOLICITUDES.DETALLES,
            payload: res.data.solicitud
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarSolicitud = () => dispatch => {
    dispatch({
        type: PROGRAMA.SOLICITUDES.DETALLES,
        payload: null
    });
}

export const cambioStatusSolicitud = ({id, status, detalles}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id, 
        status
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/status_solicitud`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROGRAMA.SOLICITUDES.DETALLES,
            payload: {
                ...detalles, 
                status
            }
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerMensajesSolicitud = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/listar_mensajes_solicitud`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROGRAMA.SOLICITUDES.MENSAJES.LISTAR,
            payload: res.data.mensajes
        });
    } catch (error){    
        await dispatch(changeLoader(false));     

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const createMensajeSolicitud = (mensaje) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(mensaje);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/crear_mensaje_solicitud`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROGRAMA.SOLICITUDES.MENSAJES.CREAR,
            payload: res.data.mensaje
        });
    } catch (error){         
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerRequerimiento = (requerimiento) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(requerimiento);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/detalles_requerimiento`, body, config);
        await dispatch(changeLoader(false));

        dispatch({
            type: PROGRAMA.SOLICITUDES.REQUERIMIENTO.DETALLES,
            payload: res.data.requerimiento
        });
    } catch (error) {
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarRequerimiento = () => dispatch => {
    dispatch({
        type: PROGRAMA.ARCHIVOS.DETALLES,
        payload: null
    });
}

export const cambioStatusRequerimiento = ({id, id_requerimiento, id_solicitud, status}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id, 
        id_solicitud, 
        status
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/status_requerimiento`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROGRAMA.SOLICITUDES.REQUERIMIENTO.STATUS,
            payload: {
                id: id_requerimiento, 
                status
            }
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const filtrarSolicitudes = (opcion) => dispatch => {
    dispatch({
        type: PROGRAMA.SOLICITUDES.FILTROS,
        payload: opcion
    });
}

/* Archivos */
export const crearArchivo = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/crear_archivo`, archivo, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROGRAMA.ARCHIVOS.LISTAR,
            payload: res.data.programa
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const editarArchivo = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/editar_archivo`, archivo, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROGRAMA.ARCHIVOS.LISTAR,
            payload: res.data.programa
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const detallesArchivo = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(archivo);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/detalles_archivo`, body, config);
        await dispatch(changeLoader(false));

        dispatch({
            type: PROGRAMA.ARCHIVOS.DETALLES,
            payload: res.data.archivo
        });
    } catch (error) {
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarArchivo = () => dispatch => {
    dispatch({
        type: PROGRAMA.ARCHIVOS.DETALLES,
        payload: null
    });
}

export const eliminarArchivo = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(archivo);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/programas/eliminar_archivo`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROGRAMA.ARCHIVOS.LISTAR,
            payload: res.data.programa
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}