import { EMPRESAS } from '../actions/types';

const initialState = {
    listado: [],
    detalles: null, 
    listado_usuarios: [],
    detalles_usuario: null,
    listado_promociones: [],
    detalles_promocion: null, 
    ventana_filtros: false, 
    filtros: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case EMPRESAS.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case EMPRESAS.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case EMPRESAS.EDITAR:
            return {
                detalles: {
                    ...state.detalles,
                    informacion_personal: {
                        ...state.detalles.informacion_personal,
                        status: payload.status
                    }
                },
                listado: state.listado.map(
                    empresa => empresa._id === payload._id 
                        ? 
                            {
                                ...empresa,
                                status: payload.status
                            } 
                        : 
                            empresa
                )
            };

        case EMPRESAS.ELIMINAR:
            return {
                ...state,
                listado: state.listado.filter( empresa => empresa._id !== payload.id )
            };

        case EMPRESAS.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };

        case EMPRESAS.USUARIOS.BUSCAR:
            return {
                ...state,
                listado_usuarios: payload
            };

        case EMPRESAS.USUARIOS.AGREGAR:
            return {
                ...state,
                listado_usuarios: [
                    ...state.listado_usuarios, payload
                ]
            };

        case EMPRESAS.USUARIOS.DETALLES:
            return {
                ...state,
                detalles_usuario: payload
            };

        case EMPRESAS.USUARIOS.EDITAR:
            return {
                ...state,
                listado_usuarios: state.listado_usuarios.map(usuario => usuario._id === payload._id ? payload : usuario)
            };

        case EMPRESAS.USUARIOS.ELIMINAR:
            return {
                ...state,
                listado_usuarios: state.listado_usuarios.filter( usuario => usuario._id !== payload.id )
            };

        case EMPRESAS.PROMOCIONES.AGREGAR:
            return {
                ... state,
                detalles: {
                    ...state.detalles,
                    promociones: [...state.detalles.promociones, payload]
                },
            };
            
        case EMPRESAS.PROMOCIONES.DETALLES:
            return {
                ...state,
                detalles_promocion: payload
            };

        case EMPRESAS.PROMOCIONES.EDITAR:
            return {
                ... state,
                detalles: {
                    ...state.detalles,
                    promociones: state.detalles.promociones.map(promocion => promocion._id === payload._id ? payload : promocion)
                },
            };
        
        case EMPRESAS.PROMOCIONES.ELIMINAR:
            return {
                ... state,
                detalles: {
                    ...state.detalles,
                    promociones: state.detalles.promociones.filter( promocion => promocion._id !== payload.id )
                },
            };  

        default:
            return state;
    }
}