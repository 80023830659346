import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarAuditoriosConfiguracion } from '../../../actions/configuracion';

import NuevoAuditorio from './NuevoAuditorio';
import EditarAuditorio from './EditarAuditorio';
import EliminarAuditorio from './EliminarAuditorio';

const Auditorios = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Auditorios',
            path: '/configuracion/auditorio'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.auditorios);

    const [auditorios, setAuditorios] = useState([]);

    const [auditorioAgregar, setAuditorioAgregar] = useState(null);
    const [auditorioEditar, setAuditorioEditar] = useState(null);
    const [auditorioEliminar, setAuditorioEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
        },
        {
            name: 'Intervalo de reserva',
            sortable: true, 
            selector: row => intervaloToText(row.intervalo_reserva)
        },
        {
            name: 'Lunes',
            sortable: true, 
            cell: item => `${item.horarios[1] ? horariosToText(item.horarios[1]) : '-'}`
        },
        {
            name: 'Martes',
            sortable: true, 
            cell: item => `${item.horarios[2] ? horariosToText(item.horarios[2]) : '-'}`
        },
        {
            name: 'Miercoles',
            sortable: true, 
            cell: item => `${item.horarios[3] ? horariosToText(item.horarios[3]) : '-'}`
        },
        {
            name: 'Jueves',
            sortable: true, 
            cell: item => `${item.horarios[4] ? horariosToText(item.horarios[4]) : '-'}`
        },
        {
            name: 'Viernes',
            sortable: true, 
            cell: item => `${item.horarios[5] ? horariosToText(item.horarios[5]) : '-'}`
        },
        {
            name: 'Sabado',
            sortable: true, 
            cell: item => `${item.horarios[6] ? horariosToText(item.horarios[6]) : '-'}`
        },
        {
            name: 'Domingo',
            sortable: true, 
            cell: item => `${item.horarios[0] ? horariosToText(item.horarios[0]) : '-'}`
        },
        {
            name: '',
            cell: row => {
                if(row._id){
                    return(
                        <div className="text-right w-100">
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarAuditorio(row)}><i className="fas fa-trash text-danger"></i></button>
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarAuditorio(row)}><i className="fas fa-edit text-warning"></i></button>
                        </div>
                    )
                }else{
                    return(<></>)
                }
            }
        }
    ];

    /* Crear auditorio */
    const handleAgregarAuditorio = (auditorio) => {
        setAuditorioEditar(null);
        setAuditorioEliminar(null);

        setAuditorioAgregar(auditorio);        
    }

    /* Editar auditorio */
    const handleEditarAuditorio = (auditorio) => {
        setAuditorioAgregar(null);
        setAuditorioEliminar(null);

        setAuditorioEditar(auditorio);
    }

    /* Eliminar auditorio */
    const handleEliminarAuditorio = (auditorio) => {
        setAuditorioAgregar(null);
        setAuditorioEditar(null);

        setAuditorioEliminar(auditorio);
    }

    /* Funciones generales */
    const horariosToText = (horarios) => {
        let horarios_formateados = [];

        for (let i = 0; i < horarios.length; i++) {
            horarios_formateados.push(horarios[i].hora_inicio + ' - ' + horarios[i].hora_fin);
        }

        return horarios_formateados.join(', ');
    }

    const intervaloToText = (intervalo) => {
        let intervalo_texto = '';

        switch(intervalo){
            case 15:
                intervalo_texto = '15 minutos';
                break;

            case 30:
                intervalo_texto = '30 minutos';
                break;

            case 60:
                intervalo_texto = '1 hora';
                break;

            case 90:
                intervalo_texto = '1 hora y media';
                break;

            case 120:
                intervalo_texto = '2 horas';
                break;

            default:
                intervalo_texto = 'Sin definir';
                break;
        }

        return intervalo_texto;
    }

    useEffect(() => {
        dispatch(buscarAuditoriosConfiguracion(true));
    }, []);

    useEffect(() => {
        setAuditorios(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <NuevoAuditorio auditorio={auditorioAgregar} reestablecerAuditorio={handleAgregarAuditorio} />
            <EditarAuditorio auditorio={auditorioEditar} reestablecerAuditorio={handleEditarAuditorio} />
            <EliminarAuditorio auditorio={auditorioEliminar} reestablecerAuditorio={handleEliminarAuditorio} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Auditorio</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarAuditorio({ })}>Agregar auditorio</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={auditorios}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Auditorios;