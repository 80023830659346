import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { setAlert } from '../../actions/alert';
import { crearReservacion, buscarHorariosReservacion } from '../../actions/auditorios';
import { buscarJovenes } from '../../actions/jovenes';
import { buscarAuditoriosConfiguracion } from '../../actions/configuracion';

const NuevaReservacion = ({ reservacion, reestablecerReservacion }) => {

    const dispatch =  useDispatch();

    const jovenes = useSelector(state => state.jovenes);
    const auditorios = useSelector(state => state.configuracion.auditorios);
    const { listado_horarios_reservacion } = useSelector(state => state.auditorios);

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [reservacionData, setReservacionData] = useState({
        id_auditorio: null,
        id_joven: null,
        nombre: null,
        fecha_inicio: null, 
        fecha_fin: null, 
        hora_inicio: null,
        hora_fin: null,
        observaciones: null
    });

    const [jovenData, setJovenData] = useState(null);
    const [jovenesFilter, setJovenesFilter] = useState([]);

    const [auditorioData, setAuditorioData] = useState(null);
    const [auditoriosFilter, setAuditoriosFilter] = useState([]);

    const [horarioData, setHorarioData] = useState(null);
    const [horariosFilter, setHorariosFilter] = useState([]);

    /* Crear reservacion */
    const handleSelectJoven = (option) => {
        setJovenData(option);

        setReservacionData({
            ...reservacionData,
            id_joven: option != null ? option.value : null, 
            nombre: null
        });
    }

    const handleCreateJoven = (value) => {
        setReservacionData({
            ...reservacionData,
            id_joven: null, 
            nombre: value
        });

        setJovenesFilter((prev) => [...prev, {
            value: null, 
            label: value
        }]);

        setJovenData({
            value: null, 
            label: value
        });
    }

    const handleSelectAuditorio = (option) => {
        setAuditorioData(option);

        setReservacionData({
            ...reservacionData,
            id_auditorio: option != null ? option.value : null, 
            fecha_inicio: null,
            fecha_fin: null,
            hora_inicio: null,
            hora_fin: null
        });

        setHorarioData(null);
        setHorariosFilter([]);
    }

    const handleInputChange = ({ target }) => {
        setReservacionData({
            ...reservacionData,
            [target.name]: target.value
        });
    }

    const handleDatePicker = (value) => {
        const [inicio, fin] = value;

        setReservacionData({
            ...reservacionData,
            fecha_inicio: inicio,
            fecha_fin: fin, 
            hora_inicio: null, 
            hora_fin: null
        });

        setHorarioData(null);

        if(inicio && fin){
            dispatch(buscarHorariosReservacion({
                id_reservacion: null, 
                id_auditorio: reservacionData.id_auditorio, 
                fecha_inicio: inicio, 
                fecha_fin: fin
            }));
        }
    }

    const handleSelectHorario = (option) => {
        setHorarioData(option);

        setReservacionData({
            ...reservacionData,
            hora_inicio: option != null ? option.hora_inicio : null,
            hora_fin: option != null ? option.hora_fin : null
        });
    }
    
    const handleProcesar = async () => {
        if(reservacionData.nombre || reservacionData.id_joven){
            if(reservacionData.id_auditorio){
                if((reservacionData.fecha_inicio <= reservacionData.fecha_fin) && reservacionData.fecha_inicio && reservacionData.fecha_inicio){
                    if(reservacionData.hora_inicio <= reservacionData.hora_fin){
                        await setStatusProcesar(true);
                        await dispatch(crearReservacion(reservacionData));
                        await handleCerrar();
                        await setStatusProcesar(false);
                    }else{
                        await dispatch(setAlert('La hora de inicio debe ser menor o igual a la de finalización', 'danger'));
                    }
                }else{
                    await dispatch(setAlert('La fecha de inicio debe ser menor o igual a la de finalización', 'danger'));
                }
            }else{
                await dispatch(setAlert('Selecciona el auditorio', 'danger'));
            }
        }else{
            await dispatch(setAlert('Ingresa el joven al que se le reservara', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setReservacionData({
                id_auditorio: null,
                id_joven: null,
                nombre: null,
                fecha_inicio: null,
                fecha_fin: null,
                hora_inicio: null,
                hora_fin: null,
                observaciones: null
            });

            setJovenData(null);
            setAuditorioData(null);
            setHorarioData(null);
            setHorariosFilter([]);
            setNuevoModal(false);
            reestablecerReservacion(null);
        }
    }

    useEffect(() => {
        dispatch(buscarJovenes({
            nombre: null,
            apellido_paterno: null,
            apellido_materno: null,
            celular: null,
            email: null,
            curp: null
        }));
        dispatch(buscarAuditoriosConfiguracion(true));
    }, []);

    useEffect(() => {
        if(reservacion){
            setNuevoModal(true);

            setReservacionData({
                ...reservacionData
            });
        }        
    }, [reservacion]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < jovenes.listado?.length; i++) {
            options.push({ value: jovenes.listado[i]._id, label: [jovenes.listado[i].nombre, jovenes.listado[i].apellido_paterno, jovenes.listado[i].apellido_materno].join(' ') });
        }

        setJovenesFilter(options);
    }, [jovenes]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < auditorios.listado?.length; i++) {
            options.push({ value: auditorios.listado[i]._id, label: auditorios.listado[i].nombre, horarios: auditorios.listado[i].horarios, bloqueos: auditorios.listado[i].bloqueos });
        }

        setAuditoriosFilter(options);
    }, [auditorios]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_horarios_reservacion.length; i++) {
            options.push({ value: i, label: [listado_horarios_reservacion[i].hora_inicio, listado_horarios_reservacion[i].hora_fin].join(' - '), hora_inicio: listado_horarios_reservacion[i].hora_inicio, hora_fin: listado_horarios_reservacion[i].hora_fin });
        }

        setHorariosFilter(options);
    }, [listado_horarios_reservacion]);

    return (
        <Modal show={nuevoModal} size="lg" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar reservación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <CreatableSelect
                                id="id_joven"
                                name="id_joven"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar joven"
                                isClearable
                                options={jovenesFilter}
                                value={jovenData}
                                onChange={(value) => handleSelectJoven(value)}
                                onCreateOption={handleCreateJoven}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="id_auditorio"
                                name="id_auditorio"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar auditorio"
                                isClearable
                                options={auditoriosFilter}
                                value={auditorioData}
                                onChange={(value) => handleSelectAuditorio(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fechas"
                                    name="fechas"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    selectsRange={true}
                                    startDate={reservacionData.fecha_inicio}
                                    endDate={reservacionData.fecha_fin}
                                    autoComplete="off"
                                    minDate={new Date()}
                                    onChange={ date => handleDatePicker(date) }
                                />
                                <label className="label-datepicker" htmlFor="fechas">Fechas</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="horario"
                                name="horario"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar horario"
                                isClearable
                                options={horariosFilter}
                                value={horarioData}
                                onChange={(value) => handleSelectHorario(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <textarea className="form-control textareCampo" placeholder="Observaciones" id="observaciones" name="observaciones" value={reservacionData.observaciones} onChange={e => handleInputChange(e)}></textarea>
                                <label htmlFor="observaciones">Observaciones</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Agregar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Agregar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default NuevaReservacion;