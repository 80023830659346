import { EVENTO } from "../actions/types";

const initialState = {
    listado: [],
    detalles: null, 
    ventana_filtros: false, 
    filtros: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case EVENTO.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case EVENTO.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case EVENTO.ELIMINAR:
            return {
                ...state,
                listado: state.listado.filter( evento => evento._id !== payload.id )
            };

        case EVENTO.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };

        case EVENTO.RESERVACIONES.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };

        case EVENTO.RESERVACIONES.BUSCAR:
            return {
                ...state,
                detalles: {
                    ...state.detalles, 
                    reservaciones: payload
                }
            };

        default:
            return state;
    }
}