import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { setAlert } from '../../actions/alert';
import { crearCita, buscarAsesoriasCita, buscarHorariosCita } from '../../actions/deportiva';

const NuevaCita = ({ cita, reestablecerCita }) => {

    const dispatch =  useDispatch();

    const { listado_asesorias_cita, listado_horarios_cita } = useSelector(state => state.deportiva);

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [citaData, setCitaData] = useState({
        id_asesoria: null,
        fecha: null,
        hora_inicio: null,
        hora_fin: null,
        observaciones: null
    });

    const [asesoriaData, setAsesoriaData] = useState(null);
    const [asesoriasFilter, setAsesoriasFilter] = useState([]);

    const [horarioData, setHorarioData] = useState(null);
    const [horariosFilter, setHorariosFilter] = useState([]);

    /* Crear cita */
    const handleAbrir = () => {
        dispatch(buscarAsesoriasCita());
    }

    const handleSelectAsesoria = (option) => {
        setAsesoriaData(option);

        setCitaData({
            ...citaData,
            id_asesoria: option != null ? option.value : null
        });
    }

    const handleInputChange = ({ target }) => {
        setCitaData({
            ...citaData,
            [target.name]: target.value
        });
    }

    const handleDatePicker = (name, value) => {
        setCitaData({
            ...citaData,
            [name]: value, 
            hora_inicio: null,
            hora_fin: null
        });

        setHorarioData(null);

        if(value){
            dispatch(buscarHorariosCita(null, value));
        }
    }

    const handleSelectHorario = (option) => {
        setHorarioData(option);

        setCitaData({
            ...citaData,
            hora_inicio: option != null ? option.hora_inicio : null,
            hora_fin: option != null ? option.hora_fin : null
        });
    }
    
    const handleProcesar = async () => {
        if(citaData.id_asesoria){
            if(citaData.fecha){
                if(citaData.hora_inicio <= citaData.hora_fin){
                    await setStatusProcesar(true);
                    await dispatch(crearCita(citaData));
                    await handleCerrar();
                    await setStatusProcesar(false);
                }else{
                    await dispatch(setAlert('La hora de inicio debe ser menor o igual a la de finalización', 'danger'));
                }
            }else{
                await dispatch(setAlert('La fecha de la cita es requerida', 'danger'));
            }
        }else{
            await dispatch(setAlert('Ingresa el joven al que se le reservara', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setCitaData({
                id_asesoria: null,
                fecha: null,
                hora_inicio: null,
                hora_fin: null,
                observaciones: null
            });

            setAsesoriaData(null);
            setHorarioData(null);
            setNuevoModal(false);
            reestablecerCita(null);
        }
    }

    useEffect(() => {
        if(cita){
            setNuevoModal(true);

            setCitaData({
                ...citaData
            });
        }        
    }, [cita]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_asesorias_cita.length; i++) {
            if(!options.some(option => option.id_joven == listado_asesorias_cita[i].joven._id) && listado_asesorias_cita[i].status != 2){
                options.push({ value: listado_asesorias_cita[i]._id, label: [listado_asesorias_cita[i].joven.nombre, listado_asesorias_cita[i].joven.apellido_paterno, listado_asesorias_cita[i].joven.apellido_materno].join(' '), id_joven: listado_asesorias_cita[i].joven._id  });
            }
        }

        setAsesoriasFilter(options);
    }, [listado_asesorias_cita]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_horarios_cita.length; i++) {
            options.push({ value: i, label: [listado_horarios_cita[i].hora_inicio, listado_horarios_cita[i].hora_fin].join(' - '), hora_inicio: listado_horarios_cita[i].hora_inicio, hora_fin: listado_horarios_cita[i].hora_fin });
        }

        setHorariosFilter(options);
    }, [listado_horarios_cita]);

    return (
        <Modal show={nuevoModal} size="lg" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar cita</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="id_asesoria"
                                name="id_asesoria"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar joven"
                                isClearable
                                options={asesoriasFilter}
                                value={asesoriaData}
                                onChange={(value) => handleSelectAsesoria(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha"
                                    name="fecha"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    selected={ citaData.fecha != null ? new Date(citaData.fecha) : '' }
                                    onChange={ date => handleDatePicker('fecha', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha">Fecha</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="horario"
                                name="horario"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar horario"
                                isClearable
                                options={horariosFilter}
                                value={horarioData}
                                onChange={(value) => handleSelectHorario(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <textarea className="form-control textareCampo" placeholder="Observaciones" id="observaciones" name="observaciones" value={citaData.observaciones} onChange={e => handleInputChange(e)}></textarea>
                                <label htmlFor="observaciones">Observaciones</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Agregar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Agregar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default NuevaCita;