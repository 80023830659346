import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Badge } from 'react-bootstrap';
import Select from 'react-select';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { sortTableDateFechaInicio } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { buscarGimnasios, buscarJovenes } from '../../actions/gimnasios';

import NuevoAcceso from './NuevoAcceso';
import EscanearAcceso from './EscanearAcceso';

const Gimnasio = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Gimnasio',
            path: '/gimnasio'
        }
    ];

    const dispatch =  useDispatch();

    const { listado_gimnasios, listado_jovenes } = useSelector(state => state.gimnasios);

    const [jovenes, setJovenes] = useState([]);

    const [gimnasioData, setGimnasioData] = useState(localStorage.getItem('gimnasioElegido') ? JSON.parse(localStorage.getItem('gimnasioElegido')) : null);
    const [gimnasiosFilter, setGimnasiosFilter] = useState([]);

    const [busquedaData, setBusquedaData] = useState('');
    const [accesoAgregar, setAccesoAgregar] = useState(null);

    const [accesoEscanear, setAccesoEscanear] = useState(null);

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
        },
        {
            name: 'Apellido paterno',
            sortable: true, 
            selector: row => row.apellido_paterno,
        },
        {
            name: 'Apellido materno',
            sortable: true, 
            selector: row => row.apellido_materno,
        },
        {
            name: 'CURP',
            sortable: true, 
            selector: row => row.curp ? row.curp : '-',
        },
        {
            name: 'Inscrito desde', 
            sortable: true, 
            sortFunction: sortTableDateFechaInicio, 
            cell: item => new Date(item.fecha_inicio).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        }, 
        {
            name: '',
            grow: 1, 
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleAgregarAcceso({ _id: row._id })}><i className="fas fa-eye text-info"></i></button>
                    </div>
                )
            }
        }
    ];

    /* Buscar joven */
    const handleSelectGimnasio = (option) => {
        setGimnasioData(option);
        localStorage.setItem('gimnasioElegido', JSON.stringify(option));
    }

    const handleBusquedaChange = ({ target }) => {
        setBusquedaData(target.value);
    }

    const handleKeyPress = (e) => {
        if(e.keyCode === 13){
            setBusquedaData(e.target.value);
            dispatch(buscarJovenes(e.target.value));
        }
    }

    const handleBuscarJovenes = async () => {
        if(busquedaData){
            await dispatch(buscarJovenes(busquedaData));
        }else{
            await dispatch(setAlert('No puedes realizar una búsqueda vacia', 'danger'));
        }
    }

    const handleLimpiarBusqueda = async () => {
        await setBusquedaData('');
        await dispatch(buscarJovenes(null));
    }

    /* Escanear acceso */
    const handleEscanearAcceso = (acceso) => {
        setAccesoAgregar(null);

        setAccesoEscanear(acceso);    
    }

    /* Crear acceso */
    const handleAgregarAcceso = (acceso) => {
        if(acceso){
            setAccesoAgregar({
                id_joven: acceso._id || null,
                id_gimnasio: gimnasioData?.value || null
            });
        }else{
            setAccesoAgregar(null);
            setBusquedaData('');
            dispatch(buscarJovenes(null));
        }      
    }

    const handleDescartarAcceso = () => {
        setAccesoAgregar(null);
    }

    /* Funciones generales */
    const dateToStringLocal = (fecha) => {
        const meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
        const dias_semana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        fecha = new Date(fecha);
        
        return dias_semana[fecha.getDay()] + ', ' + fecha.getDate() + ' de ' + meses[fecha.getMonth()] + ' de ' + fecha.getUTCFullYear();
    }

    const horariosToText = (horarios) => {
        if(horarios.length > 0){
            let horarios_formateados = [];

            for (let i = 0; i < horarios.length; i++) {
                horarios_formateados.push(horarios[i].hora_inicio + ' - ' + horarios[i].hora_fin);
            }

            return horarios_formateados.join(', ');
        }else{
            return 'Sin definir';
        }
    }

    const getDisponibilidad = (disponibilidad, motivo) => {
        if(disponibilidad != null && disponibilidad != undefined){
            if(disponibilidad == 0){
                return(
                    <h4 className="text-center"><Badge pill bg="success">{ motivo }</Badge></h4>
                )
            }
            
            if(disponibilidad == 1){
                return(
                    <h4 className="text-center"><Badge pill bg="warning">{ motivo }</Badge></h4>
                )
            }

            if(disponibilidad == 2){
                return(
                    <h4 className="text-center"><Badge pill bg="danger">{ motivo }</Badge></h4>
                )
            }
        }else{
            return(
                <h4 className="text-center"><Badge pill bg="secondary">Sin definir</Badge></h4>
            )
        }
    }

    useEffect(() => {
        dispatch(buscarGimnasios());

        return () => {
            dispatch(buscarJovenes(null));
        }
    }, []);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_gimnasios?.length; i++) {
            options.push({ value: listado_gimnasios[i]._id, label: listado_gimnasios[i].nombre, horarios: listado_gimnasios[i].horarios, disponibilidad: listado_gimnasios[i].disponibilidad, motivo: listado_gimnasios[i].motivo });
        }

        setGimnasiosFilter(options);
    }, [listado_gimnasios, localStorage.getItem('lastView')]);

    useEffect(() => {
        setJovenes(listado_jovenes)
    }, [listado_jovenes]);

    useEffect(() => {
        if(!gimnasioData){
            setBusquedaData('');
        }
    }, [gimnasioData]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .card-subtitle {
                            font-size: 1.5rem;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <NuevoAcceso acceso={accesoAgregar} reestablecerAcceso={handleAgregarAcceso} descartarAcceso={handleDescartarAcceso} />
            <EscanearAcceso cupon={accesoEscanear} reestablecerAcceso={handleAgregarAcceso} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Gimnasio</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleEscanearAcceso({ })}>Escanear acceso</button>
                        <Link to='/gimnasio/accesos'><button type="button" className="btn btn-primary me-2">Historial de accesos</button></Link>
                        <Link to='/gimnasio/inscritos'><button type="button" className="btn btn-primary me-2">Jovenes inscritos</button></Link>
                    </div>

                    <div className="row my-5">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5>Fecha</h5>
                                    </div>
                                    <div className="card-content">
                                        <p className="card-subtitle">{ dateToStringLocal(new Date()) }</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5>Gimnasio</h5>
                                    </div>
                                    <div className="card-content">
                                        <Select
                                            id="id_gimnasio"
                                            name="id_gimnasio"
                                            className="select-group"
                                            classNamePrefix="react-select"
                                            placeholder="Seleccionar gimnasio"
                                            isClearable
                                            options={gimnasiosFilter}
                                            value={gimnasioData}
                                            onChange={(value) => handleSelectGimnasio(value)}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5>Disponibilidad</h5>
                                    </div>
                                    <div className="card-content">
                                        <p className="card-subtitle">{ getDisponibilidad(gimnasioData?.disponibilidad, gimnasioData?.motivo) }</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5>Horario</h5>
                                    </div>
                                    <div className="card-content">
                                        <p className="card-subtitle">{ gimnasioData?.horarios ? horariosToText(gimnasioData.horarios) : 'Sin definir' }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row my-5">
                        <div className="col-md-12">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Ingresa el joven a buscar (nombre, apellido, ...)" value={busquedaData} disabled={gimnasioData ? false : true} onKeyDown={handleKeyPress} onChange={e => handleBusquedaChange(e)}/>
                                    <label htmlFor="nombre">Ingresa el joven a buscar (nombre, apellido, ...)</label>
                                </div>
                                {   
                                    busquedaData ? 
                                        <div className="input-group-append">
                                            <button className="btn btn-danger cleanField" onClick={handleLimpiarBusqueda}><i className="fa-solid fa-xmark"></i></button>
                                        </div>
                                    :
                                        null
                                }
                                <div className="input-group-append">
                                    <button className="btn btn-primary viewPassword" onClick={handleBuscarJovenes}><i className="fa-solid fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row my-5">
                        <DataTable 
                            columns={columns}
                            data={jovenes}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Gimnasio;