import { DASHBOARD } from '../actions/types'

const initialState = {
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {

        case DASHBOARD.OBTENER:
            return {
                ...state,
                detalles: payload
            };

        default:
            return state;
    }

}