import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { obtenerBloqueo } from '../../actions/nutricional';

const DetallesBloqueo = ({ bloqueo, reestablecerBloqueo, editarBloqueo, eliminarBloqueo }) => {

    const dispatch =  useDispatch();

    const { detalles_cita } = useSelector(state => state.nutricional);

    const [detallesModal, setDetallesModal] = useState(false);

    const [bloqueoData, setBloqueoData] = useState({
        id: null,
        asesor: null,
        motivo: null, 
        fecha_inicio: null,
        fecha_fin: null
    });

    const handleCerrar = () => {
        setBloqueoData({
            id: null,
            asesor: null,
            motivo: null, 
            fecha_inicio: null,
            fecha_fin: null
        });
        setDetallesModal(false);
        reestablecerBloqueo(null);
    }
    
    /* Editar bloqueo */
    const handleEditar = () => {
        handleCerrar();
        editarBloqueo(bloqueoData);
    }

    /* Eliminar bloqueo */
    const handleEliminar = () => {
        handleCerrar();
        eliminarBloqueo(bloqueoData);
    }

    /* Funciones generales */
    const getRangoFechas = (inicio, fin) => {
        if(inicio == fin){
            return 'Unicamente el ' + (new Date(inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }))
        }else{
            return 'Del ' + (new Date(inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })) + ' al ' + (new Date(fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }))
        }
    }

    useEffect(() => {
        dispatch(obtenerBloqueo(null));
    }, []);

    useEffect(() => {
        if(bloqueo){
            setDetallesModal(true);
            
            dispatch(obtenerBloqueo(bloqueo?.id));

            setBloqueoData({
                ...bloqueoData,
                id: bloqueo?.id
            });
        }
    }, [bloqueo]);

    useEffect(() => {
        if(detalles_cita){
            setBloqueoData({
                ...bloqueoData,
                id: detalles_cita?._id, 
                asesor: detalles_cita?.asesor,
                motivo: detalles_cita?.motivo, 
                fecha_inicio: detalles_cita?.fecha_inicio,
                fecha_fin: detalles_cita?.fecha_fin
            });
        }
    }, [detalles_cita]);

    return (
        <Modal show={detallesModal} backdrop="static" size="md" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Bloqueo de disponibilidad</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-8">
                        <label className="fw-bold">Periodo</label>
                        <p>{ getRangoFechas(bloqueoData?.fecha_inicio, bloqueoData?.fecha_fin) }</p>
                    </div>

                    <div className="col-md-4">
                        <label className="fw-bold">Asesor</label>
                        <p>{bloqueoData?.asesor?.nombre}</p>
                    </div>

                    <div className="col-md-12">
                        <label className="fw-bold">Motivo</label>
                        <p>{bloqueoData?.motivo}</p>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleEliminar()}><i className="fas fa-trash fa-sm"></i> Eliminar</button>
                <button type="button" className="btn btn-warning" onClick={() => handleEditar()}><i className="fas fa-pencil fa-sm"></i> Editar</button>
            </Modal.Footer>
        </Modal>
    )
}

export default DetallesBloqueo;