import React, { useEffect, Fragment } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Badge } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { formatNumber, sortTableDateFechaInicio, sortTableDateFechaFin, sortTableDateReservacion, sortTableDateConfirmacion, sortTableDateSolicitud, sortTableStatusStatus, sortTableStatusConfirmado, sortTableStatusCanjeado } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { obtenerJoven, limpiarJoven } from '../../actions/jovenes';

const DetallesJoven = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Jovenes',
            path: '/jovenes'
        },
        {
            activo: true,
            nombre: 'Perfil del joven',
            path: '/jovenes/detalles/'
        }
    ];

    const dispatch = useDispatch();

    const { id } = useParams();

    const { detalles } = useSelector(state => state.jovenes);

    const [jovenData, setJovenData] = useState({
        indicadores_generales: {
            asesorias: 0,
            reservaciones: 0,
            eventos: 0,
            solicitudes: 0,
            promociones: 0
        },
        informacion_personal: {
            _id: null,
            nombre: null,
            creacion: null,
            correo: null,
            celular: null,
            fecha_nacimiento : null,
            curp : null,
            soluciones : null,
            status: false,
            url_perfil: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
        },
        asesorias: [],
        reservaciones: []
    });

    const {indicadores_generales, informacion_personal, asesorias, reservaciones, gimnasio, eventos, solicitudes, promociones} = jovenData;

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const asesoriasColumnas = [
        {
            name: 'Tipo',
            sortable: true, 
            selector: row => row.tipo == 0 ? 'Psicológica' : (row.tipo == 1 ? 'Deportiva' : 'Nutricional')
        },
        {
            name: 'Asesor',
            sortable: true, 
            selector: row => row.asesor, 
            grow: 2
        },
        {
            name: 'Fecha de inicio',
            sortable: true, 
            sortFunction: sortTableDateFechaInicio, 
            cell: item => item.fecha_inicio ? new Date(item.fecha_inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }): 'Sin definir'
        },
        {
            name: 'Fecha de finalización',
            sortable: true, 
            sortFunction: sortTableDateFechaFin, 
            cell: item => item.fecha_fin ? new Date(item.fecha_fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }): 'Sin definir'
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusStatus, 
            selector: row => {
                switch(row.status){
                    case 0:
                        return(
                            <h5><Badge pill bg="warning">Pendiente</Badge></h5>
                        )
                    case 1:
                        return(
                            <h5><Badge pill bg="primary">Activa</Badge></h5>
                        )
                    case 2:
                        return(
                            <h5><Badge pill bg="success">Finalizada</Badge></h5>
                        )
                    default:
                        break;
                }
            }
        }
    ];

    const reservacionesColumnas = [
        {
            name: 'Tipo',
            sortable: true, 
            selector: row => row.tipo == 0 ? 'Cabina' : 'Auditorio'
        },
        {
            name: 'Instalación',
            sortable: true, 
            selector: row => row.nombre,
        },
        {
            name: 'Periodo',
            sortable: true, 
            selector: row => handleRangoFechas(row.fecha_inicio, row.fecha_fin),
            grow: 3
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusStatus, 
            selector: row => {
                switch(row.status){
                    case 0:
                        return(
                            <h5><Badge pill bg="warning">Pendiente</Badge></h5>
                        )
                    case 1:
                        return(
                            <h5><Badge pill bg="success">Aprobada</Badge></h5>
                        )
                    case 2:
                        return(
                            <h5><Badge pill bg="danger">Rechazada</Badge></h5>
                        )
                    default:
                        break;
                }
            },
        }
    ];

    const eventosColumnas = [
        {
            name: 'Evento',
            sortable: true, 
            selector: row => row.nombre,
        },
        {
            name: 'Reservación',
            sortable: true, 
            sortFunction: sortTableDateReservacion, 
            cell: item => item.reservacion ? new Date(item.reservacion).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }): 'Sin definir'
        },
        {
            name: 'Confirmación',
            sortable: true, 
            sortFunction: sortTableDateConfirmacion, 
            cell: item => item.confirmacion ? new Date(item.confirmacion).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusConfirmado, 
            selector: row => {
                switch(row.confirmado){
                    case false:
                        return(
                            <h5><Badge pill bg="danger">Sin confirmar</Badge></h5>
                        )
                    case true:
                        return(
                            <h5><Badge pill bg="success">Confirmado</Badge></h5>
                        )
                    default:
                        break;
                }
            },
        }
    ];

    const solicitudesColumnas = [
        {
            name: 'Tipo',
            sortable: true, 
            selector: row => row.tipo == 0 ? 'Programa' : (row.tipo == 1 ? 'Apoyo' : 'Beca')
        },
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
            grow: 1.5
        },
        {
            name: 'Solicitud', 
            sortable: true, 
            sortFunction: sortTableDateSolicitud, 
            cell: item => item.solicitud ? new Date(item.solicitud).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }): 'Sin definir'
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusStatus, 
            selector: row => {
                switch(row.status){
                    case 0:
                        return(
                            <h5><Badge pill bg="warning" text="dark">Pendiente de revisión</Badge></h5>
                        );
                    case 1:
                        return(
                            <h5><Badge pill bg="primary">En revisión</Badge></h5>
                        );
                    case 2:
                        return(
                            <h5><Badge pill bg="success">Aprobada</Badge></h5>
                        );
                    case 3:
                        return(
                            <h5><Badge pill bg="danger">Rechazada</Badge></h5>
                        );
                    default:
                        break;
                }
            }, 
            grow: 1.5
        }
    ];

    const promocionesColumnas = [
        {
            name: 'Promoción',
            sortable: true, 
            selector: row => row.nombre,
            grow: 1.5
        },
        {
            name: 'Solicitud', 
            sortable: true, 
            sortFunction: sortTableDateSolicitud, 
            cell: item => item.solicitud ? new Date(item.solicitud).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }): 'Sin definir'
        },
        {
            name: 'Confirmación',
            sortable: true, 
            sortFunction: sortTableDateConfirmacion, 
            cell: item => item.confirmacion ? new Date(item.confirmacion).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusCanjeado, 
            selector: row => {
                switch(row.canjeado){
                    case false:
                        return(
                            <h5><Badge pill bg="danger">Sin canjear</Badge></h5>
                        )
                    case true:
                        return(
                            <h5><Badge pill bg="success">Canjeado</Badge></h5>
                        )
                    default:
                        break;
                }
            },
        }
    ];

    /* Funciones generales */
    const handleRangoFechas = (inicio, fin) => {
        let diferencia = new Date(fin).getTime() - new Date(inicio).getTime();
        let total_dias = Math.ceil(diferencia / (1000 * 3600 * 24));

        if(total_dias <= 1){
            return 'Unicamente el ' + (new Date(inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }))
        }else{
            return 'Del ' + (new Date(inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })) + ' al ' + (new Date(fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }))
        }
    }

    const handleStatusGimnasio = (status, meses) => {
        switch(status){
            case 0:
                return(
                    <h5><Badge pill bg="success">Activo</Badge></h5>
                )
            case 1:
                let mensaje_meses = 'meses';

                if(meses == 1){
                    mensaje_meses = 'mes';
                }

                return(
                    <h5><Badge pill bg="warning">Adeuda {meses + ' ' + mensaje_meses}</Badge></h5>
                )
            case 2:
                return(
                    <h5><Badge pill bg="danger">Dado de baja</Badge></h5>
                )
            case 3:
                return(
                    <h5><Badge pill bg="secondary">Sin inscribir</Badge></h5>
                )
            default:
                break;
        }
    }

    useEffect(() => {
        dispatch(limpiarJoven());

        return () => {
            dispatch(limpiarJoven());
        }
    }, []);

    useEffect(() => {        
        if(id){
            dispatch(obtenerJoven(id));

            rutasBread[1].path = rutasBread[1].path + id;

            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [id]);

    useEffect(() => {
        if(detalles){
            setJovenData({
                ...jovenData,
                indicadores_generales: detalles.indicadores_generales, 
                informacion_personal: {
                    ...detalles.informacion_personal,
                    url_perfil: detalles.informacion_personal.url_perfil || process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
                }, 
                asesorias: detalles.asesorias, 
                reservaciones: detalles.reservaciones, 
                gimnasio: detalles.gimnasio, 
                eventos: detalles.eventos, 
                solicitudes: detalles.solicitudes, 
                promociones: detalles.promociones
            });
        }
    }, [detalles]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .contenedor_imagen_perfil {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            background: #fff;
                            border: 5px solid #111F62;
                        }
                        
                        .contenedor_imagen{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        #imagen_perfil {
                            width: auto;
                            height: 100%;
                            display: block;
                            position: absolute;
                            margin: auto;
                            top: -100%;
                            right: -100%;
                            bottom: -100%;
                            left: -100%;
                        }

                        .btn-badge-status{
                            background-color: #fff;
                            border: none;
                            outline: none;
                            padding: 0;
                        }

                        .valor_indicador {
                            font-size: 40px;
                            margin: 0;
                        }

                        .descripcion_indicador {
                            margin: 0;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Perfil del joven</h3>
                    
                    <div className="row mb-4">
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.asesorias, 0, '', '') }</p>
                            <p className="descripcion_indicador">Asesorías</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.reservaciones, 0, '', '') }</p>
                            <p className="descripcion_indicador">Reservaciones de instalaciones</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.eventos, 0, '', '') }</p>
                            <p className="descripcion_indicador">Eventos</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.solicitudes, 0, '', '') }</p>
                            <p className="descripcion_indicador">Programas, becas y apoyos</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.promociones, 0, '', '') }</p>
                            <p className="descripcion_indicador">Promociones</p>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-3 py-3 text-center">
                            <label className="contenedor_imagen_perfil">
                                <div className="contenedor_imagen">
                                    <img id="imagen_perfil" src={ informacion_personal?.url_perfil }/>
                                </div>
                            </label>
                        </div>

                        <div className="col-md-9">
                            <div className="accordion" id="accordionDetalles">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Información de perfil
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-7">
                                                <label className="fw-bold">Nombre</label>
                                                <p>{[informacion_personal?.nombre, informacion_personal?.apellido_paterno, informacion_personal?.apellido_materno].join(' ')}</p>
                                            </div>
                                            <div className="col-md-5">
                                                <label className="fw-bold">Celular</label>
                                                <p>{informacion_personal?.celular || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-7">
                                                <label className="fw-bold">Email</label>
                                                <p>{informacion_personal?.email || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-5">
                                                <label className="fw-bold">CURP</label>
                                                <p>{informacion_personal?.curp || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-7">
                                                <label className="fw-bold">Fecha de registro</label>
                                                <p>{new Date(informacion_personal?.creacion).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                            </div>
                                            <div className="col-md-5">
                                                <label className="fw-bold">Tarjeta Soluciones</label>
                                                <p>{informacion_personal?.soluciones || 'Sin asociar'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                        Asesorías
                                    </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body">
                                            <DataTable
                                                columns={asesoriasColumnas}
                                                data={asesorias}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                        Reservaciones de instalaciones
                                    </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body">
                                            <DataTable
                                                columns={reservacionesColumnas}
                                                data={reservaciones}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                        Gimnasio
                                    </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md">
                                                <label className="fw-bold">Status</label>
                                                <p>{ handleStatusGimnasio(gimnasio?.status, gimnasio?.meses_adeudo) }</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="fw-bold">Fecha de inscripción</label>
                                                <p>{gimnasio?.fecha_inscripcion ? new Date(gimnasio?.fecha_inscripcion).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="fw-bold">Último acceso</label>
                                                <p>{gimnasio?.fecha_acceso ? new Date(gimnasio?.fecha_acceso).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md">
                                                <label className="fw-bold">Accesos</label>
                                                <p>{ gimnasio?.numero_accesos != null ? formatNumber(gimnasio?.numero_accesos, 0, '', '') : 'Sin definir'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFive">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                        Eventos
                                    </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body">
                                            <DataTable
                                                columns={eventosColumnas}
                                                data={eventos}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingSix">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                        Programas, becas y apoyos
                                    </button>
                                    </h2>
                                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body">
                                            <DataTable
                                                columns={solicitudesColumnas}
                                                data={solicitudes}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingSeven">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                            Promociones
                                        </button>
                                    </h2>
                                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body">
                                            <DataTable
                                                columns={promocionesColumnas}
                                                data={promociones}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DetallesJoven;