import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { setAlert } from '../../actions/alert';
import { enviarNotificacion } from '../../actions/notificaciones';

const NuevaNotificacion = ({ nuevaNotificacion, reestablecerNotificacion }) => {

    const dispatch =  useDispatch();

    const [statusProcesar, setStatusProcesar] = useState(false);

    const [notificacionData, setNotificacionData] = useState({
        titulo: null, 
        mensaje: null
    });

    /* Buscar */
    const handleAbrir = () => {}

    const handleInputChange = ({ target }) => {
        setNotificacionData({
            ...notificacionData,
            [target.name]: target.value
        });
    }
    
    const handleProcesar = async () => {
        if(notificacionData.titulo && notificacionData.mensaje){
            await setStatusProcesar(true);

            await dispatch(enviarNotificacion(notificacionData));
                        
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Se debe de enviar el título y el mensaje', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setNotificacionData({
                titulo: null, 
                mensaje: null
            });

            reestablecerNotificacion(null);
        }
    }

    return (
        <Modal show={nuevaNotificacion} size="md" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Enviar nueva notificación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Título" id="titulo" name="titulo" value={notificacionData.titulo} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="titulo">Título</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Mensaje" id="mensaje" name="mensaje" value={notificacionData.mensaje} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="mensaje">Mensaje</label>
                            </div>
                        </div>
                    </div>
                </div>                  
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Enviar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Enviar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default NuevaNotificacion;