import React, { Fragment, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import DataTable from 'react-data-table-component';

import { formatNumber, sortTableDateFecha, sortTableDateFechaInicio, sortTableDateFechaFin, sortTableStatusStatus } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { obtenerCompletaAsesoria, statusAsesoria } from '../../actions/psicologica';

import NuevaNota from './NuevaNota';
import EditarNota from './EditarNota';
import EliminarNota from './EliminarNota';

const InformacionAsesoria = () => {
    let rutasBread = [
        {
            activo: false,
            nombre: 'Asesorias psicológicas',
            path: '/psicologica'
        },
        {
            activo: true,
            nombre: 'Detalles de asesoria',
            path: '/psicologica/detalles/'
        }
    ];
    
    const dispatch =  useDispatch();

    const { id } = useParams();

    const { detalles_asesoria } = useSelector(state => state.psicologica);

    const [asesoriaData, setAsesoriaData] = useState({
        id: null, 
        asesor: {
            id: null, 
            nombre: null, 
            apellido_paterno: null, 
            apellido_materno: null
        }, 
        joven: {
            id: null, 
            nombre: null, 
            apellido_paterno: null, 
            apellido_materno: null, 
            nacimiento: null, 
            celular: null, 
            curp: null, 
            email: null, 
            url_perfil: null
        }, 
        observaciones: null, 
        fecha_inicio: null, 
        fecha_fin: null, 
        mensajes_nuevos: 0,
        status: null, 
        citas: [], 
        notas: []
    });

    const [notas, setNotas] = useState([]);
    const [citas, setCitas] = useState([]);

    const [notaAgregar, setNotaAgregar] = useState(null);
    const [notaEditar, setNotaEditar] = useState(null);
    const [notaEliminar, setNotaEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const notasColumnas = [
        {
            name: 'Fecha',
            sortable: true, 
            sortFunction: sortTableDateFecha, 
            cell: item => new Date(item.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' ' + new Date(item.fecha).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
        },
        {
            name: 'Nota',
            sortable: true, 
            selector: row => row.contenido, 
            wrap: true
        }, 
        {
            name: '',
            cell: row => {
                if(asesoriaData.status === 0 || asesoriaData.status === 1){
                    return(
                        <div className="text-right w-100">
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarNota(row)}><i className="fas fa-trash text-danger"></i></button>
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarNota(row)}><i className="fas fa-edit text-warning"></i></button>
                        </div>
                    )
                }else{
                    return(<></>)
                }
            }
        }
    ];

    const citasColumnas = [
        {
            name: 'Fecha',
            sortable: true, 
            sortFunction: sortTableDateFechaInicio, 
            cell: item => new Date(item.fecha_inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })
        },
        {
            name: 'Inicio',
            sortable: true, 
            sortFunction: sortTableDateFechaInicio, 
            cell: item => new Date(item.fecha_inicio).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
        },
        {
            name: 'Finalización',
            sortable: true, 
            sortFunction: sortTableDateFechaFin, 
            cell: item => new Date(item.fecha_fin).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
        },
        {
            name: 'Observaciones',
            sortable: true, 
            selector: row => row.observaciones,
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusStatus, 
            selector: row => {
                switch(row.status){
                    case 0:
                        return(
                            <h5><Badge pill bg="warning">Pendiente</Badge></h5>
                        )
                    case 1:
                        return(
                            <h5><Badge pill bg="success">Autorizada</Badge></h5>
                        )
                    case 2:
                        return(
                            <h5><Badge pill bg="danger">Rechazada</Badge></h5>
                        )
                    default:
                        break;
                }
            }
        }
    ];

    /* Crear nota */
    const handleAgregarNota = (nota) => {
        setNotaEditar(null);
        setNotaEliminar(null);

        setNotaAgregar(nota);
    }

    /* Editar nota */
    const handleEditarNota = (nota) => {
        setNotaAgregar(null);
        setNotaEliminar(null);

        if(nota){
            setNotaEditar({
                ...nota, 
                id_asesoria: id
            });
        }else{
            setNotaEditar(null);
        }
    }

    /* Eliminar nota */
    const handleEliminarNota = (nota) => {
        setNotaAgregar(null);
        setNotaEditar(null);

        if(nota){
            setNotaEliminar({
                ...nota, 
                id_asesoria: id
            });
        }else{
            setNotaEliminar(null);
        }
    }

    /* Cambiar status */
    const handleCambiarStatus = (status) => {
        if(id){
            dispatch(statusAsesoria(id, status, detalles_asesoria));
        }
    }

    /* Funciones generales */
    const calculateEdad = (fecha) => {
        const today = new Date();
        const age = today.getFullYear() - fecha.getFullYear() - 
                   (today.getMonth() < fecha.getMonth() || 
                   (today.getMonth() === fecha.getMonth() && today.getDate() < fecha.getDate()));
        return age != 1 ? age + ' años' : age + ' año';
    }

    const handleStatus = (status) => {
        switch(status){
            case 0:
                return(
                    <h5><Badge pill bg="warning text-black">Pendiente</Badge></h5>
                )
            case 1:
                return(
                    <h5><Badge pill bg="success">Activa</Badge></h5>
                )

            case 2:
                return(
                    <h5><Badge pill bg="danger">Finalizada</Badge></h5>
                )
            default:
                return(
                    <h5><Badge pill bg="secondary">Sin definir</Badge></h5>
                )
                break;
        }
    }

    useEffect(() => {        
        if(id){
            dispatch(obtenerCompletaAsesoria(id));

            rutasBread[1].path = rutasBread[1].path + id;

            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [id]);

    useEffect(() => {
        if(detalles_asesoria){
            setAsesoriaData({
                ...asesoriaData, 
                id: detalles_asesoria._id, 
                asesor: {
                    id: detalles_asesoria.usuario?._id, 
                    nombre: detalles_asesoria.usuario?.nombre, 
                    apellido_paterno: detalles_asesoria.usuario?.apellido_paterno, 
                    apellido_materno: detalles_asesoria.usuario?.apellido_materno
                }, 
                joven: {
                    id: detalles_asesoria.joven?.id, 
                    nombre: detalles_asesoria.joven?.nombre, 
                    apellido_paterno: detalles_asesoria.joven?.apellido_paterno, 
                    apellido_materno: detalles_asesoria.joven?.apellido_materno, 
                    nacimiento: detalles_asesoria.joven?.nacimiento, 
                    celular: detalles_asesoria.joven?.celular, 
                    curp: detalles_asesoria.joven?.curp, 
                    email: detalles_asesoria.joven?.email, 
                    url_perfil: detalles_asesoria.joven?.url_perfil || process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
                }, 
                observaciones: detalles_asesoria.observaciones, 
                fecha_inicio: detalles_asesoria.fecha_inicio, 
                fecha_fin: detalles_asesoria.fecha_fin, 
                mensajes_nuevos: detalles_asesoria.mensajes_nuevos, 
                status: detalles_asesoria.status, 
                notas: detalles_asesoria.notas, 
                citas: detalles_asesoria.citas
            });

            setNotas(detalles_asesoria.notas);
            setCitas(detalles_asesoria.citas);
        }
    }, [detalles_asesoria]);
    
    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .contenedor_imagen_perfil {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            background: #fff;
                            border: 5px solid #111F62;
                        }
                        
                        .contenedor_imagen{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        #imagen_perfil {
                            width: auto;
                            height: 100%;
                        }

                        .btn-badge-status{
                            background-color: #fff;
                            border: none;
                            outline: none;
                            padding: 0;
                        }

                        .valor_indicador {
                            font-size: 40px;
                            margin: 0;
                        }

                        .descripcion_indicador {
                            margin: 0;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <NuevaNota nota={notaAgregar} reestablecerNota={handleAgregarNota} />
            <EditarNota nota={notaEditar} reestablecerNota={handleEditarNota} />
            <EliminarNota nota={notaEliminar} reestablecerNota={handleEliminarNota} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Detalles de asesoria</h3>

                    <div className="d-flex justify-content-end">
                        <Link to={'/psicologica/chat/' + id}><button type="button" className="btn btn-primary me-2">Chat</button></Link>
                        {
                            asesoriaData.status === 0 ?
                                <button type="button" className="btn btn-success" onClick={() => handleCambiarStatus(1)}>Iniciar</button>
                            :
                                asesoriaData.status === 1 ?
                                    <button type="button" className="btn btn-warning" onClick={() => handleCambiarStatus(2)}>Finalizar</button>
                                :
                                    null
                        }
                    </div>
                    
                    <div className="row my-5">
                        <div className="col-md-3 py-3 text-center">
                            <label className="contenedor_imagen_perfil">
                                <div className="contenedor_imagen">
                                    <img id="imagen_perfil" src={ asesoriaData.joven.url_perfil }/>
                                </div>
                            </label>
                            <div className="mt-2 d-flex justify-content-center">
                                <p className='fw-bold'>{asesoriaData.joven.nombre ? [asesoriaData.joven.nombre, asesoriaData.joven.apellido_paterno, asesoriaData.joven.apellido_materno].join(' ') : 'Sin definir'}</p>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div className="accordion" id="accordionDetalles">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Información de la asesoria
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-4">
                                                <label className="fw-bold">Asesor</label>
                                                <p>{asesoriaData.asesor.nombre ? [asesoriaData.asesor.nombre, asesoriaData.asesor.apellido_paterno, asesoriaData.asesor.apellido_materno].join(' ') : 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="fw-bold">Fecha de inicio</label>
                                                <p>{asesoriaData.fecha_inicio ? new Date(asesoriaData.fecha_inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="fw-bold">Fecha de finalización</label>
                                                <p>{asesoriaData.fecha_fin ? new Date(asesoriaData.fecha_fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="fw-bold">Próxima cita</label>
                                                <p>{asesoriaData.fecha_cita ? new Date(asesoriaData.fecha_cita).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="fw-bold">Mensajes sin leer</label>
                                                <p>{formatNumber(asesoriaData.mensajes_nuevos, 0, '', asesoriaData.mensajes_nuevos != 1 ? ' mensajes' : ' mensaje')}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="fw-bold">Status</label>
                                                <p>{handleStatus(asesoriaData.status)}</p>
                                            </div>
                                            <div className="col-md-12">
                                                <label className="fw-bold">Observaciones</label>
                                                <p>{asesoriaData.observaciones}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>    

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne">
                                            Información del paciente
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-6">
                                                <label className="fw-bold">Nombre</label>
                                                <p>{asesoriaData.joven.nombre ? [asesoriaData.joven.nombre, asesoriaData.joven.apellido_paterno, asesoriaData.joven.apellido_materno].join(' ') : 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="fw-bold">Fecha de nacimiento</label>
                                                <p>{asesoriaData.joven.nacimiento ? new Date(asesoriaData.joven.nacimiento).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="fw-bold">Edad</label>
                                                <p>{asesoriaData.joven.nacimiento ? calculateEdad(new Date(asesoriaData.joven.nacimiento)) : 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="fw-bold">CURP</label>
                                                <p>{asesoriaData.joven.curp || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="fw-bold">Número de celular</label>
                                                <p>{asesoriaData.joven.celular || 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="fw-bold">Email</label>
                                                <p>{asesoriaData.joven.email || 'Sin definir'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                        Notas personales
                                    </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body">

                                        <div className="d-flex justify-content-end">
                                            {
                                                asesoriaData.status === 0 || asesoriaData.status === 1 ?
                                                    <button type="button" className="btn btn-success" onClick={() => handleAgregarNota({ id_asesoria: id })}>Agregar</button>
                                                :
                                                    null
                                            }
                                        </div>

                                            <DataTable
                                                columns={notasColumnas}
                                                data={notas}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFor" aria-expanded="true" aria-controls="collapseFor">
                                        Historial de citas
                                    </button>
                                    </h2>
                                    <div id="collapseFor" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body">
                                            <DataTable
                                                columns={citasColumnas}
                                                data={citas}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}

export default InformacionAsesoria;