import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { editarDependenciaTarjetaConfiguracion } from '../../../actions/configuracion';

const EditarServicioInstalacion = ({ servicioInstalacion, reestablecerServicioInstalacion }) => {

    const dispatch =  useDispatch();

    const [editarModal, setEditarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [servicioInstalacionData, setServicioInstalacionData] = useState({
        id: null,
        categoria: null, 
        tipo: null, 
        nombre: null, 
        requerida: false
    });

    /* Editar requerimiento */
    const handleCheckbox = ({ target }) => {
        setServicioInstalacionData({
            ...servicioInstalacionData,
            requerida: target.checked
        });
    }
    
    const handleProcesar = async () => {
        await setStatusProcesar(true);
        await dispatch(editarDependenciaTarjetaConfiguracion(servicioInstalacionData));
        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setServicioInstalacionData({
                id: null,
                categoria: null, 
                tipo: null, 
                nombre: null, 
                requerida: false
            });

            setEditarModal(false);

            reestablecerServicioInstalacion(null);
        }
    }

    useEffect(() => {
        if(servicioInstalacion){
            setEditarModal(true);

            setServicioInstalacionData({
                ...servicioInstalacionData,
                id: servicioInstalacion?._id,
                categoria: servicioInstalacion?.categoria,
                tipo: servicioInstalacion?.tipo,
                nombre: servicioInstalacion?.nombre, 
                requerida: servicioInstalacion?.requerida
            });
        }
    }, [servicioInstalacion]);

    return (
        <Fragment>
            <Modal show={editarModal} size="md" backdrop="static" centered onHide={() => handleCerrar()}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar { servicioInstalacionData.categoria != null ? (servicioInstalacionData.categoria == 0 ? 'instalación' : 'servicio') : 'Sin definir'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <p>Variables de funcionamiento de <b>{servicioInstalacionData.nombre}</b>: </p>
                        <div className="col-md-6 offset-md-3 form-group mb-3">
                            <div className="input-group h-100">
                                <div className="input-group-preppend w-100">
                                    <input type="checkbox" className="btn-check w-100" id="requerida_check" autoComplete="off" onChange={e => handleCheckbox(e)} checked={servicioInstalacionData.requerida ? true : false}/>
                                    <label className="btn btn-outline-primary w-100 h-100" id="requerida_check" htmlFor="requerida_check">{servicioInstalacionData.requerida ? 'Tarjeta Soluciones requerida' : 'Tarjeta Soluciones sin requerir'}</label>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </Modal.Body>
                <Modal.Footer>
                    {
                        statusProcesar ?
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Editar</button>
                        :
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Editar</button>
                    }
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default EditarServicioInstalacion;