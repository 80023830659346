import axios from 'axios';

import { NOTIFICACIONES } from '../actions/types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

/* Notificaciones */
export const buscarNotificaciones = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/notificaciones/listar`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: NOTIFICACIONES.BUSCAR,
            payload: res.data.notificaciones
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const enviarNotificacion = (categoria) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(categoria);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/notificaciones/enviar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: NOTIFICACIONES.ENVIAR,
            payload: res.data.notificacion
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}