import axios from 'axios';

import { APOYO } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

/* Apoyos */
export const buscarApoyos = (filtros) => async dispatch => {
    if(filtros){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify(filtros);

        try{
            await dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/listar`, body, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: APOYO.BUSCAR,
                payload: res.data.apoyos
            });
        } catch (error){ 
            await dispatch(changeLoader(false));

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        await dispatch({
            type: APOYO.BUSCAR,
            payload: []
        });
    }
}

export const buscarRequerimientos = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/apoyos/requerimientos`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: APOYO.BUSCAR_REQUERIMIENTOS,
            payload: res.data.requerimientos
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearApoyo = (apoyo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/crear`, apoyo, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const informacionApoyo = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/informacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: APOYO.DETALLES,
            payload: res.data.apoyo
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerApoyo = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/detalles`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: APOYO.DETALLES,
            payload: res.data.apoyo
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarApoyo = () => dispatch => {
    dispatch({
        type: APOYO.DETALLES,
        payload: null
    });
}

export const editarApoyo = (apoyo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/editar`, apoyo, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const cambioStatus = (id, status, detalles) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id, 
        status
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/status`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: APOYO.DETALLES,
            payload: {
                ...detalles, 
                informacion_detallada: {
                    ...detalles.informacion_detallada, 
                    status
                }
            }
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarApoyo = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/eliminar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: APOYO.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const filtrarApoyos = (opcion) => dispatch => {
    dispatch({
        type: APOYO.FILTROS,
        payload: opcion
    });
}

/* Solicitudes */
export const buscarSolicitudes = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(filtros);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/listar_solicitudes`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: APOYO.BUSCAR_SOLICITUDES,
            payload: res.data.solicitudes
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerSolicitud = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/informacion_solicitud`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: APOYO.SOLICITUDES.DETALLES,
            payload: res.data.solicitud
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerSolicitudCompleta = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/detalles_solicitud`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: APOYO.SOLICITUDES.DETALLES,
            payload: res.data.solicitud
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarSolicitud = () => dispatch => {
    dispatch({
        type: APOYO.SOLICITUDES.DETALLES,
        payload: null
    });
}

export const cambioStatusSolicitud = ({id, status, detalles}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id, 
        status
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/status_solicitud`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: APOYO.SOLICITUDES.DETALLES,
            payload: {
                ...detalles, 
                status
            }
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerMensajesSolicitud = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/listar_mensajes_solicitud`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: APOYO.SOLICITUDES.MENSAJES.LISTAR,
            payload: res.data.mensajes
        });
    } catch (error){         
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const createMensajeSolicitud = (mensaje) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(mensaje);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/crear_mensaje_solicitud`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: APOYO.SOLICITUDES.MENSAJES.CREAR,
            payload: res.data.mensaje
        });
    } catch (error){         
        await dispatch(changeLoader(false));
        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerRequerimiento = (requerimiento) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(requerimiento);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/detalles_requerimiento`, body, config);
        await dispatch(changeLoader(false));

        dispatch({
            type: APOYO.SOLICITUDES.REQUERIMIENTO.DETALLES,
            payload: res.data.requerimiento
        });
    } catch (error) {
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarRequerimiento = () => dispatch => {
    dispatch({
        type: APOYO.ARCHIVOS.DETALLES,
        payload: null
    });
}

export const cambioStatusRequerimiento = ({id, id_requerimiento, id_solicitud, status}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id, 
        id_solicitud, 
        status
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/status_requerimiento`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: APOYO.SOLICITUDES.REQUERIMIENTO.STATUS,
            payload: {
                id: id_requerimiento, 
                status
            }
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const filtrarSolicitudes = (opcion) => dispatch => {
    dispatch({
        type: APOYO.SOLICITUDES.FILTROS,
        payload: opcion
    });
}

/* Archivos */
export const crearArchivo = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/crear_archivo`, archivo, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: APOYO.ARCHIVOS.LISTAR,
            payload: res.data.apoyo
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const editarArchivo = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/editar_archivo`, archivo, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: APOYO.ARCHIVOS.LISTAR,
            payload: res.data.apoyo
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const detallesArchivo = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(archivo);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/detalles_archivo`, body, config);
        await dispatch(changeLoader(false));

        dispatch({
            type: APOYO.ARCHIVOS.DETALLES,
            payload: res.data.archivo
        });
    } catch (error) {
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarArchivo = () => dispatch => {
    dispatch({
        type: APOYO.ARCHIVOS.DETALLES,
        payload: null
    });
}

export const eliminarArchivo = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(archivo);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/apoyos/eliminar_archivo`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: APOYO.ARCHIVOS.LISTAR,
            payload: res.data.apoyo
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}