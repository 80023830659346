import axios from 'axios';

import { PSICOLOGICA } from "../actions/types";

import { setAlert } from './alert';
import { changeLoader } from './loader';

/* Asesorias */
export const buscarAsesorias = (busqueda) => async dispatch => {
    if(busqueda){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify(busqueda);

        try{
            await dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/listar_asesorias`, body, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: PSICOLOGICA.BUSCAR_ASESORIAS,
                payload: res.data.asesorias
            });
        } catch (error){ 
            await await dispatch(changeLoader(false));

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        await dispatch({
            type: PSICOLOGICA.BUSCAR_ASESORIAS,
            payload: []
        });
    }
}

export const crearAsesoria = (asesoria) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(asesoria);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/crear_asesoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.AGREGAR_ASESORIA,
            payload: res.data.asesoria
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerAsesoria = (id) => async dispatch => {
    if(id){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const body = {
            id
        };

        try{
            await dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/detalles_asesoria`, body, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: PSICOLOGICA.DETALLES_ASESORIA,
                payload: res.data.asesoria
            });
        } catch (error){         
            await dispatch(changeLoader(false));

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        dispatch({
            type: PSICOLOGICA.DETALLES_ASESORIA,
            payload: null
        });
    }
}

export const obtenerCompletaAsesoria = (id) => async dispatch => {
    if(id){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const body = {
            id
        };

        try{
            await dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/informacion_asesoria`, body, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: PSICOLOGICA.DETALLES_ASESORIA,
                payload: res.data.asesoria
            });
        } catch (error){         
            await dispatch(changeLoader(false));

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        dispatch({
            type: PSICOLOGICA.DETALLES_ASESORIA,
            payload: null
        });
    }
}

export const obtenerMensajesAsesoria = (id) => async dispatch => {
    if(id){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const body = {
            id
        };

        try{
            await dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/listar_mensajes`, body, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: PSICOLOGICA.MENSAJES_ASESORIA,
                payload: res.data.mensajes
            });
        } catch (error){        
            await dispatch(changeLoader(false)); 

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        dispatch({
            type: PSICOLOGICA.DETALLES_ASESORIA,
            payload: null
        });
    }
}

export const createMensajeAsesoria = (mensaje) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(mensaje);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/crear_mensaje`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.CREAR_MENSAJE,
            payload: res.data.mensaje
        });
    } catch (error){         
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const statusAsesoria = (id, status, asesoria) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id, 
        status
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/status_asesoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.DETALLES_ASESORIA,
            payload: {
                ...asesoria, 
                status
            }
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){  
        await dispatch(changeLoader(false));      

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const editarAsesoria = (asesoria) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(asesoria);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/editar_asesoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.EDITAR_ASESORIA,
            payload: res.data.asesoria
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){      
        await dispatch(changeLoader(false));  

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarAsesoria = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/eliminar_asesoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.ELIMINAR_ASESORIA,
            payload: body.id
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Notas de asesoria */
export const crearNota = (nota) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(nota);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/crear_nota`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.AGREGAR_NOTA,
            payload: res.data.nota
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerNota = (id_asesoria, id) => async dispatch => {
    if(id){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const body = {
            id_asesoria, 
            id
        };

        try{
            await dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/detalles_nota`, body, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: PSICOLOGICA.DETALLES_NOTA,
                payload: res.data.nota
            });
        } catch (error){    
            await dispatch(changeLoader(false));     

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        dispatch({
            type: PSICOLOGICA.DETALLES_NOTA,
            payload: null
        });
    }
}

export const editarNota = (nota) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(nota);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/editar_nota`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.EDITAR_NOTA,
            payload: res.data.nota
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){      
        await dispatch(changeLoader(false));  

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarNota = (id_asesoria, id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id_asesoria, 
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/eliminar_nota`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.ELIMINAR_NOTA,
            payload: body.id
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Citas */
export const buscarCitas = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(filtros);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/listar_citas`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.BUSCAR_CITAS,
            payload: res.data.eventos
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const buscarAsesoriasCita = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/psicologica/asesorias_citas`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.BUSCAR_ASESORIAS_CITA,
            payload: res.data.asesorias
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const buscarHorariosCita = (id_cita, fecha) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id_cita, 
        fecha
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/horarios_citas`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.BUSCAR_HORARIOS_CITA,
            payload: res.data.horarios_disponibles
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearCita = (cita) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(cita);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/crear_cita`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.AGREGAR_CITA,
            payload: res.data.cita
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerCita = (id) => async dispatch => {
    if(id){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const body = {
            id
        };

        try{
            await dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/detalles_cita`, body, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: PSICOLOGICA.DETALLES_CITA,
                payload: res.data.cita
            });
        } catch (error){     
            await dispatch(changeLoader(false));

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        dispatch({
            type: PSICOLOGICA.DETALLES_CITA,
            payload: null
        });
    }
}

export const editarCita = (cita) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(cita);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/editar_cita`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.EDITAR_CITA,
            payload: res.data.cita
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){   
        await dispatch(changeLoader(false));    

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const statusCita = (status) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(status);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/status_cita`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.EDITAR_CITA,
            payload: res.data.cita
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){       
        await dispatch(changeLoader(false)); 

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarCita = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/eliminar_cita`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.ELIMINAR_CITA,
            payload: [body.id]
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const filtarCitas = (opcion) => dispatch => {
    dispatch({
        type: PSICOLOGICA.FILTROS,
        payload: opcion
    });
}

/* Bloqueos */
export const crearBloqueo = (bloqueo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(bloqueo);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/crear_bloqueo`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.AGREGAR_CITA,
            payload: res.data.bloqueo
        });

        await dispatch({
            type: PSICOLOGICA.ELIMINAR_CITA,
            payload: res.data.citas
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerBloqueo = (id) => async dispatch => {
    if(id){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const body = {
            id
        };

        try{
            await dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/detalles_bloqueo`, body, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: PSICOLOGICA.DETALLES_CITA,
                payload: res.data.bloqueo
            });
        } catch (error){         
            await dispatch(changeLoader(false));

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        dispatch({
            type: PSICOLOGICA.DETALLES_CITA,
            payload: null
        });
    }
}

export const editarBloqueo = (bloqueo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(bloqueo);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/editar_bloqueo`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.EDITAR_CITA,
            payload: res.data.bloqueo
        });

        await dispatch({
            type: PSICOLOGICA.ELIMINAR_CITA,
            payload: res.data.citas
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){   
        await dispatch(changeLoader(false));     

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarBloqueo = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/psicologica/eliminar_bloqueo`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PSICOLOGICA.ELIMINAR_CITA,
            payload: [body.id]
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}