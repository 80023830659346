import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarExtensionesConfiguracion } from '../../../actions/configuracion';

import NuevaExtension from './NuevaExtension';
import EditarExtension from './EditarExtension';
import EliminarExtension from './EliminarExtension';

const Extensiones = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Extensiones de requerimientos',
            path: '/configuracion/extensiones'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.extensiones);

    const [extensiones, setExtensiones] = useState([]);

    const [extensionAgregar, setExtensionAgregar] = useState(null);
    const [extensionEditar, setExtensionEditar] = useState(null);
    const [extensionEliminar, setExtensionEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
        },
        {
            name: 'Requerimientos relacionados',
            sortable: true, 
            selector: row => row.requerimientos,
        },
        {
            name: '',
            cell: row => {
                if(row._id){
                    return(
                        <div className="text-right w-100">
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarExtension(row)}><i className="fas fa-trash text-danger"></i></button>
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarExtension(row)}><i className="fas fa-edit text-warning"></i></button>
                        </div>
                    )
                }else{
                    return(<></>)
                }
            }
        }
    ];

    /* Crear extension */
    const handleAgregarExtension = (extension) => {
        setExtensionEditar(null);
        setExtensionEliminar(null);

        setExtensionAgregar(extension);        
    }

    /* Editar extension */
    const handleEditarExtension = (extension) => {
        setExtensionAgregar(null);
        setExtensionEliminar(null);

        setExtensionEditar(extension);
    }

    /* Eliminar extension */
    const handleEliminarExtension = (extension) => {
        setExtensionAgregar(null);
        setExtensionEditar(null);

        setExtensionEliminar(extension);
    }

    useEffect(() => {
        dispatch(buscarExtensionesConfiguracion());
    }, []);

    useEffect(() => {
        setExtensiones(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <NuevaExtension extension={extensionAgregar} reestablecerExtension={handleAgregarExtension} />
            <EditarExtension extension={extensionEditar} reestablecerExtension={handleEditarExtension} />
            <EliminarExtension extension={extensionEliminar} reestablecerExtension={handleEliminarExtension} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Extensiones de requerimientos</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarExtension({ })}>Agregar extension</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={extensiones}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Extensiones;