import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { formatNumber } from '../../utils/generalFunctions';

import { buscarDashboard } from '../../actions/dashboard';

const DashboardCabina = () => {

    const dispatch =  useDispatch();

    const { detalles } = useSelector(state => state.dashboard);
    const { usuario } = useSelector(state => state.auth);

    const [dashboardData, setDashboardData] = useState({
        cabina_mas: null, 
        cabina_menos: null, 
        dia_mas: null, 
        dia_menos: null, 
        horario_mas: null, 
        horario_menos: null, 
        reservaciones_totales: null, 
        reservaciones_pendientes: null, 
        bloqueos_totales: null, 
        bloqueos_pendientes: null, 
        proxima_reservacion: null, 
        usuario_mas: null, 
        usuarios_reservas: null
    });

    useEffect(() => {
        dispatch(buscarDashboard('cabina'));
    }, []);

    useEffect(() => {
        if(detalles){
            setDashboardData({
                ...dashboardData, 
                cabina_mas: detalles.cabina_mas, 
                cabina_menos: detalles.cabina_menos, 
                dia_mas: detalles.dia_mas, 
                dia_menos: detalles.dia_menos, 
                horario_mas: detalles.horario_mas, 
                horario_menos: detalles.horario_menos, 
                reservaciones_totales: detalles.reservaciones_totales, 
                reservaciones_pendientes: detalles.reservaciones_pendientes, 
                bloqueos_totales: detalles.bloqueos_totales, 
                bloqueos_pendientes: detalles.bloqueos_pendientes, 
                proxima_reservacion: detalles.proxima_reservacion, 
                usuario_mas: detalles.usuario_mas, 
                usuarios_reservas: detalles.usuarios_reservas
            });
        }        
    }, [detalles]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .imagen_perfil {
                            height: 100px;
                            width: 100px;
                            border-radius: 50%;
                            border: 2px solid #111f62;
                            margin-right: 15px;
                        }

                        .titulo {
                            color: #111f62;
                        }

                        .indicador {
                            font-size: 18px;
                            margin: 0;
                        }

                        .subtitulo {
                            margin: 0;
                            font-weight: 700;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
        
            <div className="col-md-12 d-flex align-items-center mb-2">
                <img className="imagen_perfil" src={usuario.url_perfil_g != null ? usuario.url_perfil_g : process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'}/>
                <h4 className="titulo">
                    Bievenido {[usuario.nombre, usuario.apellido_paterno, usuario.apellido_materno].join(' ')}
                </h4>
            </div>

            <div className="col-md-4 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Cabina</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.cabina_mas != null && dashboardData.cabina_mas != undefined ? dashboardData.cabina_mas : 'Sin definir'}</p>
                                <p className="subtitulo">más reservada</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.cabina_menos != null && dashboardData.cabina_menos != undefined ? dashboardData.cabina_menos : 'Sin definir'}</p>
                                <p className="subtitulo">menos reservada</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Día</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.dia_mas != null && dashboardData.dia_mas != undefined ? dashboardData.dia_mas : 'Sin definir'}</p>
                                <p className="subtitulo">más reservado</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.dia_menos != null && dashboardData.dia_menos != undefined ? dashboardData.dia_menos : 'Sin definir'}</p>
                                <p className="subtitulo">menos reservado</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Horario</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.horario_mas != null && dashboardData.horario_mas != undefined ? dashboardData.horario_mas : 'Sin definir'}</p>
                                <p className="subtitulo">más reservado</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.horario_menos != null && dashboardData.horario_menos != undefined ? dashboardData.horario_menos : 'Sin definir'}</p>
                                <p className="subtitulo">menos reservado</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-12 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Reservas y bloqueos</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.reservaciones_totales != null && dashboardData.reservaciones_totales != undefined ? formatNumber(dashboardData.reservaciones_totales, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">reservaciones totales</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.reservaciones_pendientes != null && dashboardData.reservaciones_pendientes != undefined ? formatNumber(dashboardData.reservaciones_pendientes, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">reservaciones pendientes</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.bloqueos_totales != null && dashboardData.bloqueos_totales != undefined ? formatNumber(dashboardData.bloqueos_totales, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">bloqueos totales</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.bloqueos_pendientes != null && dashboardData.bloqueos_pendientes != undefined ? formatNumber(dashboardData.bloqueos_pendientes, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">bloqueos próximos</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-12 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Indicadores generales</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.proxima_reservacion != null && dashboardData.proxima_reservacion != undefined ? dashboardData.proxima_reservacion : 'Sin definir'}</p>
                                <p className="subtitulo">próxima reservacíon</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.usuario_mas != null && dashboardData.usuario_mas != undefined ? dashboardData.usuario_mas : 'Sin definir'}</p>
                                <p className="subtitulo">joven que más reserva</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.usuarios_reservas != null && dashboardData.usuarios_reservas != undefined ? formatNumber(dashboardData.usuarios_reservas, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">jovenes han reservado</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DashboardCabina;