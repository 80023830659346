import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Badge } from 'react-bootstrap';

import { sortTableDateFechaInicio, sortTableDateFechaAcceso, sortTableStatusStatus } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { buscarInscritos, filtrarInscritos } from '../../actions/gimnasios';

import FiltrosInscritos from './FiltrosInscritos';
import PerfilJoven from './PerfilJoven';
import NuevaInscripcion from './NuevaInscripcion';

const InscritosGimnasio = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Gimnasio',
            path: '/gimnasio'
        },
        {
            activo: true,
            nombre: 'Jóvenes inscritos',
            path: '/gimnasio/inscritos'
        }
    ];

    const dispatch = useDispatch();

    const { listado_inscritos } = useSelector(state => state.gimnasios);

    const [inscritos, setInscritos] = useState([]);

    const [inscripcionAgregar, setInscripcionAgregar] = useState(null);
    const [jovenPerfil, setJovenPerfil] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
        },
        {
            name: 'Apellido paterno',
            sortable: true, 
            selector: row => row.apellido_paterno,
        },
        {
            name: 'Apellido materno',
            sortable: true, 
            selector: row => row.apellido_materno,
        },
        {
            name: 'CURP',
            sortable: true, 
            selector: row => row.curp ? row.curp : '-',
        },
        {
            name: 'Inscrito desde',
            sortable: true, 
            sortFunction: sortTableDateFechaInicio, 
            cell: item => new Date(item.fecha_inicio).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        },
        {
            name: 'Último acceso',
            sortable: true, 
            sortFunction: sortTableDateFechaAcceso, 
            cell: item => item.fecha_acceso ? new Date(item.fecha_acceso).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' a las ' + new Date(item.fecha_acceso).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Sin acceso'
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusStatus, 
            selector: row => {
                switch(row.status){
                    case 0:
                        return(
                            <h5><Badge pill bg="success">{row.status_mensaje}</Badge></h5>
                        )
                    case 1:
                        return(
                            <h5><Badge pill bg="danger">{row.status_mensaje}</Badge></h5>
                        )
        
                    case 2:
                        return(
                            <h5><Badge pill bg="secondary">{row.status_mensaje}</Badge></h5>
                        )
                    default:
                        return(
                            <h5><Badge pill bg="secondary">Sin definir</Badge></h5>
                        )
                        break;
                }
            }
        }, 
        {
            name: '',
            grow: 0.5, 
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleViewPerfil({ _id: row._id })}><i className="fas fa-eye text-info"></i></button>
                    </div>
                )
            }
        }
    ];

    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtrarInscritos(true));
    }

    /* Perfil inscrito */
    const handleViewPerfil = (perfil) => {
        setJovenPerfil(perfil?._id || null);
    }

    /* Crear inscripcion */
    const handleAgregarInscripcion = (reservacion) => {
        setInscripcionAgregar(reservacion);        
    }

    useEffect(() => {
        dispatch(buscarInscritos({
            nombre: null,
            apellido_paterno: null,
            apellido_materno: null,
            curp: null,
            fecha_inicio: null,
            fecha_fin: null, 
            status: null
        }));
    }, []);

    useEffect(() => {
        setInscritos(listado_inscritos);
    }, [listado_inscritos]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);    

    return (
        <Fragment>

            <FiltrosInscritos/>
            <PerfilJoven perfil={jovenPerfil} reestablecerPerfil={handleViewPerfil} />
            <NuevaInscripcion inscripcion={inscripcionAgregar} reestablecerInscripcion={handleAgregarInscripcion} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Jóvenes inscritos</h3>
                    
                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleAgregarInscripcion({ })}>Nueva inscripción</button>
                    </div>

                    <div className="row my-5">
                        <DataTable 
                            columns={columns}
                            data={inscritos}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default InscritosGimnasio;