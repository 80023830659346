import React, { useState, useEffect, createRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { buscarRequerimientos, limpiarPrograma, informacionPrograma, editarPrograma } from '../../actions/programas';

const EditarPrograma = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Programas',
            path: '/programas'
        },
        {
            activo: true,
            nombre: 'Editar programa',
            path: '/programas/editar/'
        }
    ];

    const dispatch = useDispatch();
    
    const navigate = useNavigate();

    const { id } = useParams();

    const { listado_requerimientos, detalles } = useSelector(state => state.programas);

    const [programaData, setProgramaData] = useState({
        id: null,
        nombre: null, 
        inicio: null,
        fin: null,
        tiene_limite: false,
        limite: null,
        soluciones: false,
        descripcion: null,
        requerimientos: [], 
        imagen: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/imagen_placeholder.png'
    });

    const [imagePicture, setImagePicture] = useState({
        img: undefined
    });

    const [tiposRequerimientoData, setTiposRequerimientoData] = useState([]);
    const [tiposRequerimientosFilter, setTiposRequerimientosFilter] = useState([]);

    let img = createRef();

    /* Editar programa */
    const handleChangeImg = () => {
        setImagePicture({
            ...imagePicture, 
            img: img.current.files[0]
        });
    }

    const handleInputChange = ({ target }) => {
        setProgramaData({
            ...programaData,
            [target.name]: target.value
        });
    }
    
    const handleDatePicker = (name, value) => {
        setProgramaData({
            ...programaData,
            [name]: value
        });
    }

    const handleCheckbox = ({ target }, name) => {
        if(name == 'tiene_limite'){
            setProgramaData({
                ...programaData,
                tiene_limite: target.checked, 
                limite: null
            });
        }else{
            setProgramaData({
                ...programaData,
                soluciones: target.checked
            });
        }
    }

    const handleSelectTipo = (value, action) => {
        let nombre_separado = action.name.split('_');

        let posicion = nombre_separado[1];

        let requerimientos = programaData.requerimientos;
        let tipos_elegidos = tiposRequerimientoData;

        requerimientos[posicion].requerimiento = value ? value?.value : null;
        requerimientos[posicion].vigencia = value ? value?.vigencia : null;
        requerimientos[posicion].tipo_vigencia = value ? value?.tipo_vigencia : null;
        tipos_elegidos[posicion] = value;

        setTiposRequerimientoData(tipos_elegidos);

        setProgramaData({
            ...programaData,
            requerimientos
        });
    }

    const handleObligatorioChange = ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');    

        let requerimientos = programaData.requerimientos;

        requerimientos[id_separado[1]].obligatorio = !requerimientos[id_separado[1]].obligatorio;

        setProgramaData({ 
            ...programaData, 
            requerimientos
        });
    }

    const handleGuardarPrograma = async () => {
        if(programaData.nombre && programaData.descripcion){
            if(programaData.requerimientos.length > 0){
                let requerimientos_opciones = programaData.requerimientos.filter( requerimiento => requerimiento.tipo == 4 && requerimiento.opciones.length == 0 );

                if(requerimientos_opciones.length == 0){
                    let formData = new FormData();

                    formData.append('id', programaData.id);
                    formData.append('nombre', programaData.nombre);
                    formData.append('inicio', programaData.inicio);
                    formData.append('fin', programaData.fin);
                    formData.append('limite', programaData.limite);
                    formData.append('soluciones', programaData.soluciones);
                    formData.append('descripcion', programaData.descripcion);
                    formData.append('requerimientos', JSON.stringify(programaData.requerimientos));
                    formData.append('portada', imagePicture.img);

                    await dispatch(editarPrograma(formData));

                    await navigate("/programas");
                }else{
                    await dispatch(setAlert('Todos los requerimientos del tipo "opciones" deben tener al menos una', 'danger'));
                }
            }else{
                await dispatch(setAlert('Debe haber al menos un requerimiento', 'danger'));
            }
        }else{
            await dispatch(setAlert('Nombre y descripción son obligatorios', 'danger'));
        }
    }

    /* Funciones generales */
    const handleAgregarRequerimiento = () => {
        let requerimientos = programaData.requerimientos;
        let tipos_requerimientos = tiposRequerimientoData;

        requerimientos.push({
            requerimiento: null, 
            vigencia: null, 
            tipo_vigencia: null, 
            obligatorio: true
        });

        tipos_requerimientos.push(null);

        setProgramaData({ 
            ...programaData, 
            requerimientos
        });

        setTiposRequerimientoData(tipos_requerimientos);
    }

    const handleOnDragEnd = (results) => {
        if(results.destination !== null && results.source.index !== results.destination.index){

            let requerimientos_temporales = [...programaData.requerimientos];
            let tipos_requerimientos_temporales = [...tiposRequerimientoData];

            let [requerimientos_posteriores] = requerimientos_temporales.splice(results.source.index, 1);
            let [tipos_requerimientos_posteriores] = tipos_requerimientos_temporales.splice(results.source.index, 1);

            requerimientos_temporales.splice(results.destination.index, 0, requerimientos_posteriores);
            tipos_requerimientos_temporales.splice(results.destination.index, 0, tipos_requerimientos_posteriores);

            setTiposRequerimientoData(tipos_requerimientos_temporales);
            
            setProgramaData({
                ...programaData,
                requerimientos: requerimientos_temporales
            });

        }else{
            return;
        }
    }

    const handleRequerimientosCampos = (requerimientos) => {

        let requerimientos_campos = [];

        if(requerimientos){            
            requerimientos.map((requerimiento, key_requerimiento) => {
                requerimientos_campos.push(
                    
                    <Draggable draggableId={'requerimiento_' + key_requerimiento} index={key_requerimiento} key={'requerimiento_' + key_requerimiento}>
                        {(draggableProvided) => (
                            <div
                                {...draggableProvided.draggableProps}
                                ref={draggableProvided.innerRef}
                                className="col-md-12 form-group mb-3"
                                key={'requerimiento_' + key_requerimiento}
                            >
                                <div className="input-group h-100">
                                    <div className="input-group-preppend">
                                        <span className="btn btn-outline-primary" {...draggableProvided.dragHandleProps}><i className="fas fa-grip-vertical"></i></span>
                                    </div>
                                    <div className="form-floating">
                                        <Select
                                            id={'tipoRequerimiento_' + key_requerimiento}
                                            name={'tipoRequerimiento_' + key_requerimiento}
                                            className="select-group"
                                            classNamePrefix="react-select"
                                            placeholder="Tipo"
                                            options={tiposRequerimientosFilter}
                                            value={tiposRequerimientoData[key_requerimiento]}
                                            onChange={handleSelectTipo}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            getOptionLabel={e => (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <i className={e.icon}></i>
                                                    <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="input-group-append">
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip>{convertVigencia(requerimiento?.tipo_vigencia, requerimiento?.vigencia)}</Tooltip>}
                                        >
                                            <button type="button" className={"btn check " + (requerimiento?.vigencia ? 'btn-warning' : 'btn-secondary')} id={'vigenciaRequerimiento_' + key_requerimiento}><i className="fas fa-clock"></i></button>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="input-group-append">
                                        <button type="button" className={"btn check " + (requerimiento?.obligatorio ? 'btn-primary' : 'btn-outline-primary')} id={'obligatorioRequerimiento_' + key_requerimiento} onClick={e => handleObligatorioChange(e)}><i className={"fas fa-lock" + ((requerimiento?.obligatorio ? '' : '-open'))}></i></button>
                                    </div>
                                    <div className="input-group-append">
                                        <button type="button" className="btn btn-danger delete" id={'eliminarRequerimiento_' + key_requerimiento} onClick={e => handleEliminarRequerimiento(e)}><i className="fa-solid fa-xmark"></i></button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Draggable>
                    
                );                
            
            });
        }

        return requerimientos_campos;
    }

    const handleEliminarRequerimiento = async ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');    

        let requerimientos = programaData.requerimientos;
        let tipos_requerimientos = tiposRequerimientoData;

        requerimientos.splice(id_separado[1], 1);
        tipos_requerimientos.splice(id_separado[1], 1);

        setProgramaData({ 
            ...programaData, 
            requerimientos
        });

        setTiposRequerimientoData(tipos_requerimientos);
    }

    const convertVigencia = (tipo, vigencia) => {
        let resultado = 'Sin vigencia';

        switch(tipo){
            case 0:
            case '0':
                resultado = vigencia == 1 ? 'dia' : 'dias';
                break;

            case 1:
            case '1':
                resultado = vigencia == 1 ? 'mes' : 'meses';
                break;

            case 2:
            case '2':
                resultado = vigencia == 1 ? 'año' : 'años';
                break;
        }

        return vigencia ? vigencia + ' ' + resultado : resultado;
    }

    useEffect(() => {
        dispatch(limpiarPrograma());
        dispatch(buscarRequerimientos());

        return () => {
            dispatch(limpiarPrograma());
        }
    }, []);

    useEffect(() => {
        dispatch(informacionPrograma(id));

        if(id){
            rutasBread[1].path = rutasBread[1].path + id;

            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [id]);

    useEffect(() => {
        if(listado_requerimientos.length > 0){
            let tipos_requerimientos = [
                'Texto corto', 
                'Texto largo', 
                'Número', 
                'Fecha', 
                'Opciones', 
                'Imágen', 
                'Archivo'
            ];

            let opciones_requerimientos = [];

            for (var x = 0; x < listado_requerimientos?.length; x++) {
                if(listado_requerimientos[x].length > 0){
                    let options = [];

                    for (var i = 0; i < listado_requerimientos[x].length; i++) {

                        let requerimiento_elegido = programaData.requerimientos.find( requerimiento => requerimiento.requerimiento === listado_requerimientos[x][i]._id );

                        if(!requerimiento_elegido){
                            options.push({ value: listado_requerimientos[x][i]._id, label: listado_requerimientos[x][i].nombre, vigencia: listado_requerimientos[x][i].vigencia, tipo_vigencia: listado_requerimientos[x][i].tipo_vigencia });
                        }
                    }

                    if(options.length > 0){
                        opciones_requerimientos.push({
                            label: tipos_requerimientos[x],
                            options
                        });
                    }
                }
            }

            setTiposRequerimientosFilter(opciones_requerimientos);
        }
    }, [listado_requerimientos, programaData]);

    useEffect(() => {
        if(detalles){
            setProgramaData({
                ...programaData,
                id: detalles?._id,
                nombre: detalles?.nombre,
                inicio: detalles?.inicio,
                fin: detalles?.fin,
                tiene_limite: detalles?.limite ? true : false,
                limite: detalles?.limite,
                soluciones: detalles?.soluciones ? true : false,
                descripcion: detalles?.descripcion,
                requerimientos: detalles?.requerimientos,
                imagen: detalles?.url_portada_p ? detalles?.url_portada_p : process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/imagen_placeholder.png'
            });

            let tipos_requerimientos = [];

            for (var x = 0; x < detalles?.requerimientos?.length; x++) {
                if(tiposRequerimientosFilter.length > 0){
                    for (var i = 0; i < tiposRequerimientosFilter.length; i++) {
                        let tipo_requerimiento_asignado = tiposRequerimientosFilter[i].options.find( tipo => String(tipo.value) === String(detalles?.requerimientos[x]?.requerimiento) );

                        if(tipo_requerimiento_asignado){
                            tipos_requerimientos.push({value: tipo_requerimiento_asignado.value, label: tipo_requerimiento_asignado.label, vigencia: tipo_requerimiento_asignado.vigencia, tipo_vigencia: tipo_requerimiento_asignado.tipo_vigencia});
                        }
                    }
                }
            }

            setTiposRequerimientoData(tipos_requerimientos);
        }
    }, [detalles]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        input[type="file"] {
                            display: none;
                        }
                        
                        .custom-file-upload {
                            border-radius: 5%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            cursor: pointer;
                            background: #fff;
                            margin-bottom: 25px;
                            border: 5px solid #111F62;
                        }
                        
                        .img-wrap{
                            position: relative;
                            //width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 5%;
                        }

                        .img-upload:before{
                            content: "";
                            font-family: "Font Awesome 6 Free" !important;
                            font-weight: 900;
                            font-size: 90px;
                            position: absolute;
                            padding-top: 50px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-align: center;
                            color: #21B4E9;
                            width: 100%;
                            height: 200px;
                            border-radius: 5%;
                            opacity: 0;
                            transition: .5s ease;
                            background-color: #fff;
                        }

                        .img-upload:hover:before{
                            opacity: 1;
                        }

                        #img-photo {
                            width: auto;
                            height: 100%;
                        }

                        .selectIconos, .select-group {
                            height: 100%;
                        }

                        .check {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 0 0 0 !important;
                            height: 100%;
                        }

                        .delete {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 25% 25% 0 !important;
                            height: 100%;
                        }

                        #label_limite_check:before {
                            content: "";
                            display: inline-block;
                            height: 100%;
                            vertical-align: middle;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Editar programa</h3>
                    
                    <div className="row mb-5">
                        <div className="col-12 form-group text-center">
                            <label htmlFor="photo-upload" className="custom-file-upload fas">
                                <div className="img-wrap img-upload">
                                    <img id="img-photo" htmlFor="photo-upload" src={imagePicture.img != null ? URL.createObjectURL(imagePicture.img) : programaData.imagen}/>
                                </div>
                                <input id="photo-upload" type="file" accept="image/*" ref={img} onChange={handleChangeImg}/> 
                            </label>
                        </div>
                    </div>
                
                    <div className="row">
                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={programaData.nombre} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="nombre">Nombre</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <DatePicker
                                        id="inicio"
                                        name="inicio"
                                        className="form-control text-center input-datepícker"
                                        timeInputLabel="Time:"
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        showTimeInput
                                        minDate={new Date()}
                                        autoComplete="off"
                                        selected={ programaData.inicio != null ? new Date(programaData.inicio) : '' }
                                        onChange={ date => handleDatePicker('inicio', date) }
                                    />
                                    <label className="label-datepicker" htmlFor="inicio">Inicio</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <DatePicker
                                        id="fin"
                                        name="fin"
                                        className="form-control text-center input-datepícker"
                                        timeInputLabel="Time:"
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        showTimeInput
                                        minDate={programaData.inicio ? new Date(programaData.inicio) : new Date()}
                                        autoComplete="off"
                                        selected={ programaData.fin != null ? new Date(programaData.fin) : '' }
                                        onChange={ date => handleDatePicker('fin', date) }
                                    />
                                    <label className="label-datepicker" htmlFor="fin">Finalización</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group h-100">
                                <div className="input-group-preppend">
                                    <input type="checkbox" className="btn-check" id="limite_check" autoComplete="off" onChange={e => handleCheckbox(e, 'tiene_limite')} checked={programaData.tiene_limite ? true : false}/>
                                    <label className="btn btn-outline-primary h-100" id="label_limite_check" htmlFor="limite_check">{programaData.tiene_limite ? 'Con limite' : 'Sin limite'}</label>
                                </div>
                                <div className="form-floating">
                                    <input type="number" className="form-control" placeholder="Capacidad" id="limite" name="limite" value={programaData.limite} onChange={e => handleInputChange(e)} disabled={!programaData.tiene_limite}/>
                                    <label htmlFor="capacidad">Capacidad</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2 form-group mb-3">
                            <div className="input-group h-100">
                                <div className="input-group-preppend w-100">
                                    <input type="checkbox" className="btn-check w-100" id="soluciones_check" autoComplete="off" onChange={e => handleCheckbox(e, 'soluciones')} checked={programaData.soluciones ? true : false}/>
                                    <label className="btn btn-outline-primary w-100 h-100" id="label_soluciones_check" htmlFor="soluciones_check">{programaData.soluciones ? 'Tarjeta soluciones requerida' : 'Tarjeta soluciones sin requerir'}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <textarea className="form-control textareCampo" placeholder="Descripción" id="descripcion" name="descripcion" value={programaData.descripcion} onChange={e => handleInputChange(e)}></textarea>
                                    <label htmlFor="descripcion">Descripción</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <DragDropContext onDragEnd={(results) => handleOnDragEnd(results)}>
                            <Droppable
                                droppableId="droppable"
                            >
                                {(droppableProvided) => (
                                    <div
                                        { ...droppableProvided.droppableProps }
                                        ref={ droppableProvided.innerRef }
                                        style={ { width: '100%', paddingBottom: '40px' } }
                                    >
                                        { handleRequerimientosCampos(programaData.requerimientos) }
                                        { droppableProvided.placeholder }
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleGuardarPrograma}>Guardar</button>
                            <button className="btn btn-primary float-end me-2" onClick={() => handleAgregarRequerimiento()}><i className="fa-solid fa-plus"></i> Requerimiento</button>
                            <Link to='/programas'><button className="btn btn-danger float-end me-2">Cancelar</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default EditarPrograma;