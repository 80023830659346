import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { setAlert } from '../../actions/alert';
import { editarNota } from '../../actions/psicologica';

const EditarNota = ({ nota, reestablecerNota }) => {

    const dispatch =  useDispatch();

    const [editarModal, setEditarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [notaData, setNotaData] = useState({
        id: null,
        id_asesoria: null,
        contenido: ''
    });

    /* Editar nota */
    const handleInputChange = ({ target }) => {
        setNotaData({
            ...notaData,
            [target.name]: target.value
        });
    }
    
    const handleProcesar = async () => {
        if(notaData.contenido){
            await setStatusProcesar(true);
            await dispatch(editarNota(notaData));
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('No puedes guardar una nota vacia', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setNotaData({
                id_asesoria: null,
                id: null, 
                observaciones: ''
            });

            setEditarModal(false);
            reestablecerNota(null);
        }
    }

    useEffect(() => {
        if(nota){
            setEditarModal(true);

            setNotaData({
                ...notaData, 
                id: nota._id,
                id_asesoria: nota.id_asesoria, 
                contenido: nota.contenido
            });
        }        
    }, [nota]);

    return (
        <Modal show={editarModal} size="md" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Editar nota</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <textarea className="form-control textareCampo" placeholder="Contenido" id="contenido" name="contenido" value={notaData.contenido} onChange={e => handleInputChange(e)}></textarea>
                                <label htmlFor="email">Contenido</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Editar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Editar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EditarNota;