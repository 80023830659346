import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { setAlert } from '../../actions/alert';
import { generarReporteInscripciones } from '../../actions/reportes';

const FiltrosGimnasio = ({ gimnasioReporte, reestablecerFiltros }) => {

    const dispatch =  useDispatch();

    const [statusProcesar, setStatusProcesar] = useState(false);

    const [busquedaData, setBusquedaData] = useState({
        fecha_inicio: null,
        fecha_fin: null, 
        status: null
    });

    const [statusData, setStatusData] = useState(null);
    const statusFilter = [
        { value: 0, label: 'Activo' }, 
        { value: 1, label: 'Adeuda' }
    ];

    /* Buscar */
    const handleAbrir = () => {}

    const handleDatePicker = (name, value) => {
        setBusquedaData({
            ...busquedaData,
            [name]: value
        });
    }

    const handleSelectStatus = (option) => {
        setStatusData(option);

        setBusquedaData({
            ...busquedaData,
            status: option != null ? option.value : null
        });
    }
    
    const handleProcesar = async () => {
        if(new Date(busquedaData.fecha_inicio) <= new Date(busquedaData.fecha_fin)){
            await setStatusProcesar(true);

            await dispatch(generarReporteInscripciones(busquedaData));
                        
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Tu fecha de inicio es mayor a tu fecha de finalización', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setBusquedaData({
                fecha_inicio: null,
                fecha_fin: null, 
                status: null
            });

            setStatusData(null);

            reestablecerFiltros(null);
        }
    }

    return (
        <Modal show={gimnasioReporte} size="lg" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Filtros de búsqueda</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_inicio"
                                    name="fecha_inicio"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    locale="es-ES"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={ busquedaData.fecha_inicio != null ? new Date(busquedaData.fecha_inicio) : '' }
                                    onChange={ date => handleDatePicker('fecha_inicio', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_inicio">Fecha de inicio de reservación</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_fin"
                                    name="fecha_fin"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    locale="es-ES"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={ busquedaData.fecha_fin != null ? new Date(busquedaData.fecha_fin) : '' }
                                    onChange={ date => handleDatePicker('fecha_fin', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_fin">Fecha de finalización de reservación</label>
                            </div>
                        </div>
                    </div>  

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="status"
                                name="status"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar status"
                                isClearable
                                options={statusFilter}
                                value={statusData}
                                onChange={(value) => handleSelectStatus(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div> 
                </div>                  
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Buscar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-magnifying-glass fa-sm"></i> Buscar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default FiltrosGimnasio;