import axios from 'axios';

import { EMPRESAS } from '../actions/types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

/* Empresas */
export const buscarEmpresas = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(filtros);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/empresas/listar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EMPRESAS.BUSCAR,
            payload: res.data.empresas
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearEmpresa = (empresa) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/empresas/crear`, empresa, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const informacionEmpresa = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/empresas/detalles`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EMPRESAS.DETALLES,
            payload: res.data.empresa
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerEmpresa = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/empresas/perfil`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EMPRESAS.DETALLES,
            payload: res.data.empresa
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarEmpresa = () => dispatch => {
    dispatch({
        type: EMPRESAS.DETALLES,
        payload: null
    });
}

export const editarEmpresa = (empresa) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/empresas/editar`, empresa, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const cambioStatusEmpresa = (id, status) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id, 
        status
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/empresas/status`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EMPRESAS.EDITAR,
            payload: {
                _id: id,
                status
            }
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarEmpresa = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/empresas/eliminar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EMPRESAS.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const filtarEmpresas = (opcion) => dispatch => {
    dispatch({
        type: EMPRESAS.FILTROS,
        payload: opcion
    });
}

/* Promociones */
export const buscarPromociones = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/empresas/promociones/listar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EMPRESAS.PROMOCIONES.BUSCAR,
            payload: res.data.promociones
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearPromocion = (promocion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(promocion);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/empresas/promociones/crear`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EMPRESAS.PROMOCIONES.AGREGAR,
            payload: res.data.promocion
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const editarPromocion = (promocion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(promocion);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/empresas/promociones/editar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EMPRESAS.PROMOCIONES.EDITAR,
            payload: res.data.promocion
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerPromocion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/empresas/promociones/detalles`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EMPRESAS.PROMOCIONES.DETALLES,
            payload: res.data.promocion
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarPromocion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/empresas/promociones/eliminar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EMPRESAS.PROMOCIONES.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const cambioStatusPromocion = (id, estado) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id, 
        estado
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/empresas/promociones/status`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EMPRESAS.PROMOCIONES.EDITAR,
            payload: res.data.promocion
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}