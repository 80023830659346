import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { setAlert } from '../../actions/alert';
import { crearRenovacion, crearAcceso, obtenerInscrito } from '../../actions/gimnasios';

const NuevoAcceso = ({ acceso, reestablecerAcceso, descartarAcceso }) => {

    const dispatch =  useDispatch();

    const { detalles_joven } = useSelector(state => state.gimnasios);

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [accesoData, setAccesoData] = useState({
        id_joven: null,
        id_gimnasio: null
    });

    const [jovenData, setJovenData] = useState({
        id: null, 
        nombre: null, 
        apellido_paterno: null, 
        apellido_materno: null, 
        curp: null, 
        fecha_inicio: null, 
        fecha_acceso: null, 
        status: null, 
        status_mensaje: null, 
        url_perfil: null
    });

    /* Confirmar renovacion */
    const handleRenovar = async () => {
        if(jovenData.id){
            await setStatusProcesar(true);
            await dispatch(crearRenovacion(jovenData.id));
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Selecciona primero a un joven', 'danger'));
        }
    }

    /* Crear acceso */    
    const handleProcesar = async () => {
        if(accesoData.id_joven && accesoData.id_gimnasio){
            await setStatusProcesar(true);
            await dispatch(crearAcceso(accesoData));
            await setStatusProcesar(false);
            await handleCerrar(true);
        }else{
            await dispatch(setAlert('Selecciona primero a un joven', 'danger'));
        }
    }

    const handleCerrar = (reestablecer) => {
        if(!statusProcesar){
            setAccesoData({
                id_joven: null,
                id_gimnasio: null
            });

            setJovenData({
                id: null, 
                nombre: null, 
                apellido_paterno: null, 
                apellido_materno: null, 
                curp: null, 
                fecha_inicio: null, 
                fecha_acceso: null, 
                status: null, 
                status_mensaje: null, 
                url_perfil: null
            });

            setNuevoModal(false);

            if(reestablecer){
                reestablecerAcceso(null);
            }else{
                descartarAcceso();
            }
        }
    }

    /* Funciones generales */
    const handleStatus = (status, mensaje) => {
        switch(status){
            case 0:
                return(
                    <h5><Badge pill bg="success">{mensaje}</Badge></h5>
                )
            case 1:
                return(
                    <h5><Badge pill bg="danger">{mensaje}</Badge></h5>
                )

            case 2:
                return(
                    <h5><Badge pill bg="secondary">{mensaje}</Badge></h5>
                )
            default:
                return(
                    <h5><Badge pill bg="secondary">Sin definir</Badge></h5>
                )
                break;
        }
    }

    useEffect(() => {
        if(acceso){
            setNuevoModal(true);

            dispatch(obtenerInscrito(acceso.id_joven));

            setAccesoData({
                ...accesoData, 
                id_joven: acceso.id_joven,
                id_gimnasio: acceso.id_gimnasio
            });
        }        
    }, [acceso]);

    useEffect(() => {
        if(detalles_joven){
            setJovenData({
                ...jovenData,
                id: detalles_joven?._id, 
                nombre: detalles_joven?.nombre,
                apellido_paterno: detalles_joven?.apellido_paterno, 
                apellido_materno: detalles_joven?.apellido_materno,
                curp: detalles_joven?.curp, 
                fecha_inicio: detalles_joven?.fecha_inicio, 
                fecha_acceso: detalles_joven?.fecha_acceso, 
                status: detalles_joven?.status, 
                status_mensaje: detalles_joven?.status_mensaje, 
                url_perfil: detalles_joven.url_perfil || process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
            });
        }
    }, [detalles_joven]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .contenedor_imagen_perfil {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            background: #fff;
                            border: 5px solid #111F62;
                            width: auto;
                            margin: auto;
                        }
                        
                        .contenedor_imagen{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        #imagen_perfil {
                            width: auto;
                            height: 100%;
                            display: block;
                            position: absolute;
                            margin: auto;
                            top: -100%;
                            right: -100%;
                            bottom: -100%;
                            left: -100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
            <Modal show={nuevoModal} size="lg" backdrop="static" centered onHide={() => handleCerrar(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalle de usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-5 row">
                            <label className="contenedor_imagen_perfil">
                                <div className="contenedor_imagen">
                                    <img id="imagen_perfil" src={ jovenData?.url_perfil }/>
                                </div>
                            </label>
                        </div>

                        <div className="col-md-7 row">
                            <div className="col-md-12">
                                <label className="fw-bold">Nombre</label>
                                <p>{[jovenData?.nombre, jovenData?.apellido_paterno, jovenData?.apellido_materno].join(' ')}</p>
                            </div>

                            <div className="col-md-8">
                                <label className="fw-bold">Inscrito desde</label>
                                <p>{jovenData?.fecha_inicio ? new Date(jovenData?.fecha_inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                            </div>

                            <div className="col-md-4">
                                <label className="fw-bold">CURP</label>
                                <p>{jovenData?.curp || 'Sin definir'}</p>
                            </div>

                            <div className="col-md-12">
                                <label className="fw-bold">Último acceso</label>
                                <p>{jovenData?.fecha_acceso ? new Date(jovenData?.fecha_acceso).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' a las ' + new Date(jovenData?.fecha_acceso).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Sin definir'}</p>
                            </div>

                            <div className="col-md-12">
                                <label className="fw-bold">Situación actual</label>
                                <p>{handleStatus(jovenData?.status, jovenData?.status_mensaje)}</p>
                            </div>
                        </div>                    
                    </div>                    
                </Modal.Body>
                <Modal.Footer>
                    {
                        jovenData?.status == 0 ?
                            statusProcesar ?
                                <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Ingresar</button>
                            :
                                <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Ingresar</button>
                        :
                            statusProcesar ?
                                <button type="button" className="btn btn-warning" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Confirmar renovación</button>
                            :
                                <button type="button" className="btn btn-warning" onClick={() => handleRenovar()}><i className="fas fa-check fa-sm"></i> Confirmar renovación</button>
                    }

                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default NuevoAcceso;