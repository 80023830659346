import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { eliminarBloqueo } from '../../actions/psicologica';

const EliminarBloqueo = ({ bloqueo, reestablecerBloqueo }) => {

    const dispatch =  useDispatch();

    const [eliminarModal, setEliminarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Eliminar bloquero */
    const handleProcesar = async () => {
        await setStatusProcesar(true);
        await dispatch(eliminarBloqueo(bloqueo?.id));
        await handleCerrar();
        await setStatusProcesar(false);
    }

    /* Funciones generales */
    const getRangoFechas = (inicio, fin) => {
        if(inicio == fin){
            return 'de unicamente el ' + (new Date(inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }))
        }else{
            return 'del ' + (new Date(inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })) + ' al ' + (new Date(fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }))
        }
    }
    
    const handleCerrar = () => {
        if(!statusProcesar){
            reestablecerBloqueo(null);
            setEliminarModal(false);
        }
    }

    useEffect(() => {
        if(bloqueo){
            setEliminarModal(true);
        }
    }, [bloqueo]);

    return (
        <Modal show={eliminarModal} backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar bloqueo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Deseas eliminar el bloqueo { getRangoFechas(bloqueo?.fecha_inicio, bloqueo?.fecha_fin) }?</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={ statusProcesar }><i className="fas fa-times fa-sm"></i> No</button>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Si</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EliminarBloqueo;