import { PROMOCIONES } from '../actions/types';

const initialState = {
    listado: [],
    detalles: null, 
    escaner: null, 
    ventana_filtros: false, 
    filtros: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case PROMOCIONES.BUSCAR:
            return {
                ...state, 
                listado: payload
            };

        case PROMOCIONES.AGREGAR:
            let promociones_agregar = state.listado;

            promociones_agregar = [
                ...promociones_agregar, payload
            ];

            return {
                ...state, 
                listado: promociones_agregar
            };

        case PROMOCIONES.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case PROMOCIONES.EDITAR:
            let promociones_editar = state.listado;

            promociones_editar = promociones_editar.map(promocion => promocion._id === payload._id ? payload : promocion);

            return {
                ...state, 
                listado: promociones_editar
            };

        case PROMOCIONES.ELIMINAR:
            return {
                ...state, 
                listado: state.listado.filter( promocion => promocion._id !== payload.id )
            };

        case PROMOCIONES.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };

        case PROMOCIONES.ESCANER:
            return {
                ...state,
                escaner: payload
            };
    
        default:
            return state;
    }
}