import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Badge } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { formatNumber, sortTableDateSolicitud, sortTableDateFecha } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { limpiarBeca, obtenerBeca, cambioStatus, detallesArchivo, limpiarArchivo, filtrarSolicitudes } from '../../actions/becas';

import FiltrosSolicitudes from './FiltrosSolicitudes';
import NuevoArchivo from './NuevoArchivo';
import EditarArchivo from './EditarArchivo';
import EliminarArchivo from './EliminarArchivo';

const DetallesBeca = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Becas',
            path: '/becas'
        },
        {
            activo: true,
            nombre: 'Detalles de la beca',
            path: '/becas/detalles/'
        }
    ];

    const dispatch = useDispatch();

    const { id } = useParams();

    const { detalles, detalles_archivo } = useSelector(state => state.becas);

    const [becaData, setBecaData] = useState({
        indicadores_generales: {
            solicitudes: 0,
            seleccionados: 0,
            solicitudes_restantes: 0,
            dias_restantes: 0
        },
        informacion_detallada: {
            _id: null,
            nombre: null,
            inicio: null,
            fin: null,
            limite: null,
            descripcion: null,
            status: false,
            requerimientos: [], 
            archivos: [], 
            url_portada: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/imagen_placeholder.png'
        },
        solicitudes: []
    });

    /* Archivos */
    const [nuevoArchivo, setNuevoArchivo] = useState({
        beca: null
    });
    const [editarArchivo, setEditarArchivo] = useState(null);
    const [eliminarArchivo, setEliminarArchivo] = useState(null);

    const solicitudesColumnas = [
        {
            name: 'Solicitante',
            sortable: true, 
            selector: row => row.joven || 'Sin definir',
            grow: 2
        },
        {
            name: 'Fecha de solicitud',
            selector: row => row.solicitud, 
            sortable: true, 
            sortFunction: sortTableDateSolicitud, 
            cell: item => new Date(item.solicitud).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        },
        {
            name: 'Mensajes sin leer',
            sortable: true, 
            selector: row => row.mensajes_sin_leer,
        },
        {
            name: 'Estado',
            sortable: true, 
            selector: row => {
                switch(row.status){
                    case 0:
                        return(
                            <h5><Badge pill bg="warning" text="dark">Pendiente de revisión</Badge></h5>
                        );
                    case 1:
                        return(
                            <h5><Badge pill bg="primary">En revisión</Badge></h5>
                        );
                    case 2:
                        return(
                            <h5><Badge pill bg="success">Aprobada</Badge></h5>
                        );
                    case 3:
                        return(
                            <h5><Badge pill bg="danger">Rechazada</Badge></h5>
                        );
                    default:
                        break;
                }
            },
            grow: 1.5
        }, 
        {
            name: '',
            grow: 0.5, 
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/becas/solicitud/${row._id}`}><i className="fas fa-eye text-info"></i></Link>
                    </div>
                )
            }
        }
    ];

    const requerimientosColumnas = [
        {
            name: 'Tipo',
            sortable: true, 
            selector: row => {
                switch(row.requerimiento.tipo){
                    case 0:
                        return( 'Texto corto' );
                    case 1:
                        return( 'Texto lago' );
                    case 2:
                        return( 'Numero' );
                    case 3:
                        return( 'Fecha' );
                    case 4:
                        if(row.requerimiento.multiple){
                            return 'Opciones (varias elegibles)';
                        }else{
                            return 'Opciones (una elegible)';
                        }
                    case 5:
                        return( 'Fotografia o imagen' );
                    case 6:
                        return( 'Archivo' );
                    default:
                        break;
                }
            }
        }, 
        {
            name: 'Obligatorio',
            sortable: true, 
            selector: row => row.obligatorio ? 'Si' : 'No'
        },
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.requerimiento.nombre,
            grow: 3
        }
    ];

    const archivosColumnas = [
        {
            name: 'Nombre',
            sortable: true, 
            grow: 2, 
            selector: row => row.nombre || 'Sin definir'
        },
        {
            name: 'Descripción',
            sortable: true, 
            grow: 2, 
            selector: row => row.descripcion || 'Sin definir'
        },
        {
            name: 'Tipo',
            sortable: true, 
            selector: row => row.extension ? row.extension.toUpperCase() : 'Sin definir'
        },
        {
            name: 'Fecha de carga',
            sortable: true, 
            sortFunction: sortTableDateFecha, 
            cell: item => item.fecha ? new Date(item.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'
        },
        {
            name: '',
            grow: 1, 
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarArchivo({ id_beca: id, id_archivo: row._id, nombre: row.nombre })}><i className="fas fa-times text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarArchivo({ id_beca: id, id_archivo: row._id, ...row })}><i className="fas fa-edit text-primary"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleDetallesArchivo({ id_beca: id, id_archivo: row._id })}><i className="fas fa-eye text-info"></i></button>
                    </div>
                )
            }
        }
    ];

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const {indicadores_generales, informacion_detallada, solicitudes} = becaData;
    
    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtrarSolicitudes(true));
    }

    /* Archivos */
    const handleAgregarArchivo = (beca) => {
        setNuevoArchivo({
            beca
        });
    }

    const handleDetallesArchivo = (archivo) => {
        dispatch(detallesArchivo(archivo));
    }

    const handleAbrirArchivo = () => {
        if(!editarArchivo){
            window.open(detalles_archivo.url);
        }
    }

    const handleEditarArchivo = (beca) => {
        setEditarArchivo(beca);
    }

    const handleEliminarArchivo = (beca) => {
        setEliminarArchivo(beca);
    }

    /* Funciones generales */
    const generateStatus = (status) => {
        switch(status){
            case 0:
                return(
                    <Badge pill bg="success">Activo</Badge>
                )
            case 1:
                return(
                    <Badge pill bg="danger">Inactivo</Badge>
                )
            case 2:
                return(
                    <Badge pill bg="warning">Finalizado y activo</Badge>
                )
            case 3:
                return(
                    <Badge pill bg="danger">Finalizado e inactivo</Badge>
                )
            default:
                break;
        }
    }

    const handleCambioStatus = () => {
        if(informacion_detallada?.status == 0 || informacion_detallada?.status == 2){
            dispatch(cambioStatus(id, informacion_detallada?.status == 0 ? 1 : 3, detalles));
        }else{
            dispatch(cambioStatus(id, informacion_detallada?.status == 1 ? 0 : 2, detalles));
        }
    }

    useEffect(() => {
        dispatch(limpiarBeca());
        dispatch(limpiarArchivo());

        return () => {
            dispatch(limpiarBeca());
            dispatch(limpiarArchivo());
        }
    }, []);

    useEffect(() => {
        dispatch(obtenerBeca(id));

        if(id){
            rutasBread[1].path = rutasBread[1].path + id;

            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [id]);

    useEffect(() => {
        if(detalles){
            setBecaData({
                ...becaData,
                indicadores_generales: detalles.indicadores_generales, 
                informacion_detallada: {
                    ...detalles.informacion_detallada,
                    imagen: detalles.informacion_detallada.url_portada || process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/imagen_placeholder.png'
                }, 
                solicitudes: detalles.solicitudes, 
            });
        }
    }, [detalles]); 

    useEffect(() => {        
        if(detalles_archivo && detalles_archivo?.extension && detalles_archivo?.url){
            handleAbrirArchivo();
        }
    }, [detalles_archivo]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                <style>{`
                        .contenedor_imagen_portada {
                            border-radius: 5%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            background: #fff;
                            border: 5px solid #111F62;
                            width: 100%;
                        }
                        
                        .contenedor_imagen{
                            position: relative;
                            width: 100%;
                            height: auto;
                            overflow: hidden;
                            border-radius: 5%;
                        }

                        #imagen_portada {
                            width: 100%;
                        }

                        .btn-badge-status{
                            background-color: #fff;
                            border: none;
                            outline: none;
                            padding: 0;
                        }

                        .valor_indicador {
                            font-size: 40px;
                            margin: 0;
                        }

                        .descripcion_indicador {
                            margin: 0;
                        }

                        .textareCampo {
                            height: 100px !important;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
            
            <FiltrosSolicitudes />
            <NuevoArchivo archivo={nuevoArchivo} reestablecerArchivo={handleAgregarArchivo} />
            <EditarArchivo archivo={editarArchivo} reestablecerArchivo={handleEditarArchivo} />
            <EliminarArchivo archivo={eliminarArchivo} reestablecerArchivo={handleEliminarArchivo} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Detalles de la beca</h3>

                    <div className="row mb-4">
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.solicitudes, 0, '', '') }</p>
                            <p className="descripcion_indicador">Solicitudes realizadas</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.seleccionados, 0, '', '') }</p>
                            <p className="descripcion_indicador">Solicitudes aprobadas</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ indicadores_generales?.solicitudes_restantes != null ? formatNumber(indicadores_generales?.solicitudes_restantes, 0, '', '') : 'Sin límite' }</p>
                            <p className="descripcion_indicador">Solicitudes libres</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.dias_restantes, 0, '', '') }</p>
                            <p className="descripcion_indicador">Dias restantes</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ informacion_detallada.soluciones ? 'Si' : 'No' }</p>
                            <p className="descripcion_indicador">Tarjeta soluciones requerida</p>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-3 py-3 text-center">
                            <label className="contenedor_imagen_portada">
                                <div className="contenedor_imagen">
                                    <img id="imagen_portada" src={ informacion_detallada?.imagen }/>
                                </div>
                            </label>
                            <div className="mt-2 d-flex justify-content-center">
                                <button className="btn-badge-status" onClick={() => handleCambioStatus()}>
                                    <h3 className="text-muted">
                                        { generateStatus(informacion_detallada?.status) }
                                    </h3>
                                </button>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div className="accordion" id="accordionDetalles">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Información de la beca
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-3">
                                                <label className="fw-bold">Nombre</label>
                                                <p>{informacion_detallada?.nombre}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Inicio</label>
                                                <p>{informacion_detallada?.inicio ? new Date(informacion_detallada?.inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' a las ' + new Date(informacion_detallada?.inicio).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Finalización</label>
                                                <p>{informacion_detallada?.fin ? new Date(informacion_detallada?.fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' a las ' + new Date(informacion_detallada?.fin).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Capacidad</label>
                                                <p>{informacion_detallada?.limite ? formatNumber(informacion_detallada?.limite, 0, '', informacion_detallada?.limite != 1 ? ' asistentes' : ' asistente') : 'Sin limite'}</p>
                                            </div>

                                            <div className='col-md-12'>
                                                <label className='fw-bold'>Descripción</label>
                                                <p>{informacion_detallada?.descripcion}</p>
                                            </div>                                           
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                        Solicitudes realizadas
                                    </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-12 text-end mb-3">
                                                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                    <button type="button" className="btn btn-primary" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>
                                                </div>
                                            </div>
                                            <DataTable
                                                columns={solicitudesColumnas}
                                                data={solicitudes}
                                                noDataComponent="No hay solicitudes registradas"
                                                pagination
                                                paginationPerPage={20}
                                                paginationRowsPerPageOptions={[20, 50, 100]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                        Requerimientos
                                    </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body">
                                            <DataTable
                                                columns={requerimientosColumnas}
                                                data={informacion_detallada.requerimientos}
                                                noDataComponent="No hay requerimientos asignados"w
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                            Archivos
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-12 text-end mb-3">
                                                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                    <button type="button" className="btn btn-primary" onClick={() => handleAgregarArchivo(id)}><i className="fa-solid fa-plus"></i> Archivo</button>
                                                </div>
                                            </div>
                                            <DataTable
                                                columns={archivosColumnas}
                                                data={informacion_detallada.archivos}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DetallesBeca;