import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { setAlert } from '../../actions/alert';
import { obtenerBloqueo, editarBloqueo } from '../../actions/auditorios';
import { buscarJovenes } from '../../actions/jovenes';
import { buscarAuditoriosConfiguracion } from '../../actions/configuracion';

const EditarBloqueo = ({ bloqueo, reestablecerBloqueo }) => {

    const dispatch =  useDispatch();

    const auditorios = useSelector(state => state.configuracion.auditorios);
    const { detalles } = useSelector(state => state.auditorios);

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [bloqueoData, setBloqueoData] = useState({
        id_auditorio: null,
        motivo: null, 
        fecha_inicio: null,
        fecha_fin: null
    });

    const [auditorioData, setAuditorioData] = useState(null);
    const [auditoriosFilter, setAuditoriosFilter] = useState([]);

    /* Editar bloqueo */
    const handleSelectAuditorio = (option) => {
        setAuditorioData(option);

        setBloqueoData({
            ...bloqueoData,
            id_auditorio: option.value
        });
    }

    const handleInputChange = ({ target }) => {
        setBloqueoData({
            ...bloqueoData,
            [target.name]: target.value
        });
    }

    const handleDatePicker = (name, value) => {
        setBloqueoData({
            ...bloqueoData,
            [name]: value
        });
    }
    
    const handleProcesar = async () => {
        if(bloqueoData.motivo){
            if(bloqueoData.id_auditorio){
                if((new Date(bloqueoData.fecha_inicio) <= new Date(bloqueoData.fecha_fin)) && new Date(bloqueoData.fecha_inicio) && new Date(bloqueoData.fecha_inicio)){
                    await setStatusProcesar(true);
                    await dispatch(editarBloqueo(bloqueoData));
                    await handleCerrar();
                    await setStatusProcesar(false);
                }else{
                    await dispatch(setAlert('La fecha de inicio debe ser menor o igual a la de finalización', 'danger'));
                }
            }else{
                await dispatch(setAlert('Selecciona una auditorio', 'danger'));
            }
        }else{
            await dispatch(setAlert('Ingresa el motivo de bloqueo de fechas', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setBloqueoData({
                id_auditorio: null,
                motivo: null, 
                fecha_inicio: null,
                fecha_fin: null
            });

            setAuditorioData(null);
            setNuevoModal(false);
            reestablecerBloqueo(null);
        }
    }

    useEffect(() => {
        dispatch(buscarJovenes({
            nombre: null,
            apellido_paterno: null,
            apellido_materno: null,
            celular: null,
            email: null,
            curp: null
        }));
        dispatch(buscarAuditoriosConfiguracion(true));
    }, []);

    useEffect(() => {
        if(bloqueo){
            setNuevoModal(true);

            dispatch(obtenerBloqueo(bloqueo?.auditorio?._id, bloqueo?.id));

            setBloqueoData({
                ...bloqueoData,
                id: bloqueo?.id
            });
        }        
    }, [bloqueo]);

    useEffect(() => {
        if(detalles){
            setBloqueoData({
                ...bloqueoData,
                id: detalles?._id, 
                id_auditorio: detalles?.auditorio?._id,
                motivo: detalles?.motivo, 
                fecha_inicio: detalles?.fecha_inicio,
                fecha_fin: detalles?.fecha_fin
            });

            if(auditoriosFilter.length > 0){

                let auditorio_elegida = auditoriosFilter.find( auditorio => String(auditorio.value) === String(detalles?.auditorio?._id) );
    
                if(auditorio_elegida){
                    setAuditorioData({value: auditorio_elegida.value, label: auditorio_elegida.label});
                }
            }
        }
    }, [detalles]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < auditorios.listado?.length; i++) {
            options.push({ value: auditorios.listado[i]._id, label: auditorios.listado[i].nombre, horarios: auditorios.listado[i].horarios, bloqueos: auditorios.listado[i].bloqueos });
        }

        setAuditoriosFilter(options);
    }, [auditorios]);

    return (
        <Modal show={nuevoModal} size="lg" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Editar bloqueo de disponibilidad</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Motivo" id="motivo" name="motivo" value={bloqueoData.motivo} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="motivo">Motivo</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="id_auditorio"
                                name="id_auditorio"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar auditorio"
                                isClearable
                                options={auditoriosFilter}
                                value={auditorioData}
                                onChange={(value) => handleSelectAuditorio(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_inicio"
                                    name="fecha_inicio"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    selected={ bloqueoData.fecha_inicio != null ? new Date(bloqueoData.fecha_inicio) : '' }
                                    onChange={ date => handleDatePicker('fecha_inicio', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_inicio">Fecha de inicio</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_fin"
                                    name="fecha_fin"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    selected={ bloqueoData.fecha_fin != null ? new Date(bloqueoData.fecha_fin) : '' }
                                    onChange={ date => handleDatePicker('fecha_fin', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_fin">Fecha de finalización</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Guardar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Guardar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EditarBloqueo;