import { NUTRICIONAL } from "../actions/types";

const initialState = {
    listado_asesorias: [],
    detalles_asesoria: null, 
    listado_citas: [],
    detalles_cita: null, 
    listado_asesorias_cita: [],
    listado_horarios_cita: [], 
    ventana_filtros: false, 
    filtros: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case NUTRICIONAL.BUSCAR_ASESORIAS:
            return {
                ...state,
                listado_asesorias: payload
            };

        case NUTRICIONAL.AGREGAR_ASESORIA:
            let asesorias_agregar = state.listado_asesorias;

            asesorias_agregar = [
                payload, ...asesorias_agregar
            ];

            return {
                ...state, 
                listado_asesorias: asesorias_agregar
            };

        case NUTRICIONAL.DETALLES_ASESORIA:

            let detalles_actuales = state.detalles_asesoria;

            if(detalles_actuales){
                detalles_actuales = {
                    ...detalles_actuales, 
                    ...payload
                }
            }else{
                detalles_actuales = payload;
            }

            return {
                ...state,
                detalles_asesoria: detalles_actuales
            };

        case NUTRICIONAL.MENSAJES_ASESORIA:
            return {
                ...state, 
                detalles_asesoria: {
                    ...state.detalles_asesoria, 
                    mensajes: payload
                }
            };

        case NUTRICIONAL.CREAR_MENSAJE:
            let mensajes_agregar = state.detalles_asesoria.mensajes;

            mensajes_agregar = [
                ...mensajes_agregar, payload
            ];

            return {
                ...state, 
                detalles_asesoria: {
                    ...state.detalles_asesoria, 
                    mensajes: mensajes_agregar
                }
            };

        case NUTRICIONAL.EDITAR_ASESORIA:
            let asesorias_editar = state.listado_asesorias;

            asesorias_editar = asesorias_editar.map(asesoria => asesoria._id === payload._id ? payload : asesoria);

            return {
                ...state, 
                listado_asesorias: asesorias_editar
            };

        case NUTRICIONAL.ELIMINAR_ASESORIA:
            let asesorias_eliminar = state.listado_asesorias;

            asesorias_eliminar = asesorias_eliminar.filter(asesoria => asesoria._id !== payload)

            return {
                ...state, 
                listado_asesorias: asesorias_eliminar
            };

        case NUTRICIONAL.AGREGAR_NOTA:
            let notas_agregar = state.detalles_asesoria.notas;

            notas_agregar = [
                payload, ...notas_agregar
            ];

            return {
                ...state, 
                detalles_asesoria: {
                    ...state.detalles_asesoria, 
                    notas: notas_agregar
                }
            };

        case NUTRICIONAL.DETALLES_NOTA:
            return {
                ...state,
                detalles_cita: payload
            };

        case NUTRICIONAL.EDITAR_NOTA:
            let notas_editar = state.detalles_asesoria.notas;

            notas_editar = notas_editar.map(nota => nota._id === payload._id ? payload : nota);

            return {
                ...state, 
                detalles_asesoria: {
                    ...state.detalles_asesoria, 
                    notas: notas_editar
                }
            };

        case NUTRICIONAL.ELIMINAR_NOTA:
            let nota_eliminar = state.detalles_asesoria.notas;

            nota_eliminar = nota_eliminar.filter(nota => nota._id !== payload)

            return {
                ...state, 
                detalles_asesoria: {
                    ...state.detalles_asesoria, 
                    notas: nota_eliminar
                }
            };

        case NUTRICIONAL.BUSCAR_CITAS:
            return {
                ...state,
                listado_citas: payload
            };

        case NUTRICIONAL.BUSCAR_ASESORIAS_CITA:
            return {
                ...state,
                listado_asesorias_cita: payload
            };

        case NUTRICIONAL.BUSCAR_HORARIOS_CITA:
            return {
                ...state,
                listado_horarios_cita: payload
            };

        case NUTRICIONAL.AGREGAR_CITA:
            let citas_agregar = state.listado_citas;

            citas_agregar = [
                ...citas_agregar, payload
            ];

            return {
                ...state, 
                listado_citas: citas_agregar
            };

        case NUTRICIONAL.DETALLES_CITA:
            return {
                ...state,
                detalles_cita: payload
            };

        case NUTRICIONAL.EDITAR_CITA:
            let citas_editar = state.listado_citas;

            citas_editar = citas_editar.map(cita => cita._id === payload._id ? payload : cita);

            return {
                ...state, 
                listado_citas: citas_editar
            };

        case NUTRICIONAL.ELIMINAR_CITA:
            let citas_eliminar = state.listado_citas;

            for (var i = 0; i < payload.length; i++) {
                citas_eliminar = citas_eliminar.filter(cita => cita._id !== payload[i])
            }

            return {
                ...state, 
                listado_citas: citas_eliminar
            };

        case NUTRICIONAL.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };
    
        default:
            return state;
    }
}