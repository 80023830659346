import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarCategoriasEmpresasConfiguracion } from '../../../actions/configuracion';

import NuevaCategoria from './NuevaCategoria';
import EditarCategoria from './EditarCategoria';
import EliminarCategoria from './EliminarCategoria';

const CategoriasEmpresas = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Categorias de empresas',
            path: '/configuracion/categorias_empresas'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.categorias_empresas);

    const [categorias, setCategorias] = useState([]);

    const [categoriaAgregar, setCategoriaAgregar] = useState(null);
    const [categoriaEditar, setCategoriaEditar] = useState(null);
    const [categoriaEliminar, setCategoriaEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
        },
        {
            name: 'Empresas relacionadas',
            sortable: true, 
            selector: row => row.empresas,
        },
        {
            name: 'Jovenes interesados',
            sortable: true, 
            selector: row => row.jovenes,
        },
        {
            name: '',
            cell: row => {
                if(row._id){
                    return(
                        <div className="text-right w-100">
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarCategoria(row)}><i className="fas fa-trash text-danger"></i></button>
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarCategoria(row)}><i className="fas fa-edit text-warning"></i></button>
                        </div>
                    )
                }else{
                    return(<></>)
                }
            }
        }
    ];

    /* Crear categoria */
    const handleAgregarCategoria = (categoria) => {
        setCategoriaEditar(null);
        setCategoriaEliminar(null);

        setCategoriaAgregar(categoria);        
    }

    /* Editar categoria */
    const handleEditarCategoria = (categoria) => {
        setCategoriaAgregar(null);
        setCategoriaEliminar(null);

        setCategoriaEditar(categoria);
    }

    /* Eliminar categoria */
    const handleEliminarCategoria = (categoria) => {
        setCategoriaAgregar(null);
        setCategoriaEditar(null);

        setCategoriaEliminar(categoria);
    }

    useEffect(() => {
        dispatch(buscarCategoriasEmpresasConfiguracion());
    }, []);

    useEffect(() => {
        setCategorias(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <NuevaCategoria categoria={categoriaAgregar} reestablecerCategoria={handleAgregarCategoria} />
            <EditarCategoria categoria={categoriaEditar} reestablecerCategoria={handleEditarCategoria} />
            <EliminarCategoria categoria={categoriaEliminar} reestablecerCategoria={handleEliminarCategoria} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Categorias de empresas</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarCategoria({ })}>Agregar categoria</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={categorias}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CategoriasEmpresas;