import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { setAlert } from '../../actions/alert';
import { obtenerAsesoria, editarAsesoria } from '../../actions/nutricional';
import { buscarJovenes } from '../../actions/jovenes';

const EditarAsesoria = ({ asesoria, reestablecerAsesoria }) => {

    const dispatch =  useDispatch();

    const jovenes = useSelector(state => state.jovenes);
    const { detalles_asesoria } = useSelector(state => state.nutricional);

    const [editarModal, setEditarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [asesoriaData, setAsesoriaData] = useState({
        id: null,
        id_joven: null,
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        nacimiento: null,
        curp: '',
        email: '', 
        observaciones: ''
    });

    const [jovenData, setJovenData] = useState(null);
    const [jovenesFilter, setJovenesFilter] = useState([]);

    /* Crear asesoria */
    const handleSelectJoven = (option) => {
        setJovenData(option);

        setAsesoriaData({
            ...asesoriaData,
            id_joven: option != null ? option.value : null
        });
    }

    const handleInputChange = ({ target }) => {
        setAsesoriaData({
            ...asesoriaData,
            [target.name]: target.value
        });
    }

    const handleDatePicker = (name, value) => {
        setAsesoriaData({
            ...asesoriaData,
            [name]: value
        });
    }
    
    const handleProcesar = async () => {
        if(!asesoriaData.id_joven){
            if(asesoriaData.nombre && asesoriaData.apellido_paterno && asesoriaData.nacimiento && (asesoriaData.curp || asesoriaData.email)){
                await setStatusProcesar(true);
                await dispatch(editarAsesoria(asesoriaData));
                await handleCerrar();
                await setStatusProcesar(false);
            }else{
                await dispatch(setAlert('Completa los campos para realizar el registro', 'danger'));
            }
        }else{
            await setStatusProcesar(true);
            await dispatch(editarAsesoria(asesoriaData));
            await handleCerrar();
            await setStatusProcesar(false);
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setAsesoriaData({
                id_joven: null,
                nombre: '',
                apellido_paterno: '',
                apellido_materno: '',
                nacimiento: null,
                curp: '',
                email: '', 
                observaciones: ''
            });

            setJovenData(null);
            setEditarModal(false);
            reestablecerAsesoria(null);
        }
    }

    useEffect(() => {
        dispatch(buscarJovenes({
            nombre: null,
            apellido_paterno: null,
            apellido_materno: null,
            celular: null,
            email: null,
            curp: null
        }));
    }, []);

    useEffect(() => {
        if(asesoria){
            setEditarModal(true);

            dispatch(obtenerAsesoria(asesoria));

            setAsesoriaData({
                ...asesoriaData, 
                id: asesoria
            });
        }        
    }, [asesoria]);

    useEffect(() => {
        if(detalles_asesoria){
            setAsesoriaData({
                ...asesoriaData,
                id: detalles_asesoria?._id, 
                id_joven: detalles_asesoria?.joven?._id,
                nombre: '',
                apellido_paterno: '',
                apellido_materno: '',
                nacimiento: null,
                curp: '',
                email: '', 
                observaciones: detalles_asesoria?.observaciones
            });

            if(jovenesFilter.length > 0){

                let joven_elegido = jovenesFilter.find( joven => String(joven.value) === String(detalles_asesoria?.joven?._id) );
    
                if(joven_elegido){
                    setJovenData({value: joven_elegido.value, label: joven_elegido.label});
                }
            }
        }
    }, [detalles_asesoria]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < jovenes.listado?.length; i++) {
            options.push({ value: jovenes.listado[i]._id, label: [jovenes.listado[i].nombre, jovenes.listado[i].apellido_paterno, jovenes.listado[i].apellido_materno].join(' ') });
        }

        setJovenesFilter(options);
    }, [jovenes]);

    useEffect(() => {
        if(jovenData){
            setAsesoriaData({
                ...asesoriaData, 
                nombre: '',
                apellido_paterno: '',
                apellido_materno: '',
                nacimiento: null,
                curp: '',
                email: ''
            });
        }        
    }, [jovenData]);

    return (
        <Modal show={editarModal} size="xl" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Editar asesoria</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="id_joven"
                                name="id_joven"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar joven"
                                isClearable
                                options={jovenesFilter}
                                value={jovenData}
                                onChange={(value) => handleSelectJoven(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-12 mb-3">
                        <hr></hr>
                        <p>O bien, crea un registro nuevo desde cero. La contraseña temporal de esta nueva cuenta será "iaju".</p>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={asesoriaData.nombre} disabled={jovenData ? true : false} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="nombre">Nombre</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Apellido paterno" id="apellido_paterno" name="apellido_paterno" value={asesoriaData.apellido_paterno} disabled={jovenData ? true : false} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="apellido_paterno">Apellido paterno</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Apellido materno" id="apellido_materno" name="apellido_materno" value={asesoriaData.apellido_materno} disabled={jovenData ? true : false} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="apellido_materno">Apellido materno</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="nacimiento"
                                    name="nacimiento"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    locale="es-ES"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={ asesoriaData.nacimiento != null ? new Date(asesoriaData.nacimiento) : '' }
                                    disabled={jovenData ? true : false}
                                    onChange={ date => handleDatePicker('nacimiento', date) }
                                />
                                <label className="label-datepicker" htmlFor="nacimiento">Fecha de nacimiento</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="CURP" id="curp" name="curp" value={asesoriaData.curp} disabled={jovenData ? true : false} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="curp">CURP</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Correo" id="email" name="email" value={asesoriaData.email} disabled={jovenData ? true : false} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="email">Correo</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <textarea className="form-control textareCampo" placeholder="Observaciones" id="observaciones" name="observaciones" value={asesoriaData.observaciones} onChange={e => handleInputChange(e)}></textarea>
                                <label htmlFor="email">Observaciones</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Editar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Editar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EditarAsesoria;