import axios from 'axios';

import { PROMOCIONES } from '../actions/types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

//Promociones
export const buscarPromociones = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(filtros);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/promociones/listar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROMOCIONES.BUSCAR,
            payload: res.data.promociones
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearPromocion = (promocion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/promociones/crear`, promocion, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROMOCIONES.AGREGAR,
            payload: res.data.promocion
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const informacionPromocion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/promociones/informacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROMOCIONES.DETALLES,
            payload: res.data.promocion
        });
    } catch (error){        
        await dispatch(changeLoader(false)); 

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerPromocion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/promociones/detalles`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROMOCIONES.DETALLES,
            payload: res.data.promocion
        });
    } catch (error){      
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarPromocion = () => dispatch => {
    dispatch({
        type: PROMOCIONES.DETALLES,
        payload: null
    });
}

export const editarPromocion = (promocion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/promociones/editar`, promocion, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROMOCIONES.EDITAR,
            payload: res.data.promocion
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){    
        await dispatch(changeLoader(false));    

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const cambioStatus = (id, status, detalles) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id, 
        status
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/promociones/status`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROMOCIONES.DETALLES,
            payload: {
                ...detalles, 
                informacion_detallada: {
                    ...detalles.informacion_detallada, 
                    status
                }
            }
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarPromocion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/promociones/eliminar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROMOCIONES.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const filtrarPromociones = (opcion) => dispatch => {
    dispatch({
        type: PROMOCIONES.FILTROS,
        payload: opcion
    });
}

//Cupones
export const canjearCupon = (codigo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/promociones/canjear`, codigo, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));

        await dispatch({
            type: PROMOCIONES.ESCANER,
            payload: true
        });
    } catch (error){         
        await dispatch(changeLoader(false));
        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarEscaner = () => dispatch => {
    dispatch({
        type: PROMOCIONES.ESCANER,
        payload: null
    });
}