import { AUDITORIO } from "../actions/types";

const initialState = {
    listado: [],
    detalles: null, 
    listado_horarios_reservacion: [], 
    ventana_filtros: false, 
    filtros: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case AUDITORIO.BUSCAR:
            let reservaciones_listar = payload;

            reservaciones_listar.sort(function (a, b) {
                if (a.fecha_inicio > b.fecha_inicio) {
                    return -1;
                }
                if (a.fecha_inicio < b.fecha_inicio) {
                    return 1;
                }
                return 0;
            });

            return {
                ...state,
                listado: reservaciones_listar
            };

        case AUDITORIO.BUSCAR_HORARIOS_RESERVACION:
            return {
                ...state,
                listado_horarios_reservacion: payload
            };

        case AUDITORIO.AGREGAR:
            let reservaciones_agregar = state.listado;

            reservaciones_agregar = [
                ...reservaciones_agregar, ...payload
            ];

            reservaciones_agregar.sort(function (a, b) {
                if (a.fecha_inicio > b.fecha_inicio) {
                    return -1;
                }
                if (a.fecha_inicio < b.fecha_inicio) {
                    return 1;
                }
                return 0;
            });

            return {
                ...state, 
                listado: reservaciones_agregar
            };

        case AUDITORIO.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case AUDITORIO.EDITAR:
            let reservaciones_editar = state.listado;

            reservaciones_editar = reservaciones_editar.map(reservacion => reservacion._id === payload._id ? payload : reservacion);

            reservaciones_editar.sort(function (a, b) {
                if (a.fecha_inicio > b.fecha_inicio) {
                    return -1;
                }
                if (a.fecha_inicio < b.fecha_inicio) {
                    return 1;
                }
                return 0;
            });

            return {
                ...state, 
                listado: reservaciones_editar
            };

        case AUDITORIO.ELIMINAR:
            let reservaciones_eliminar = state.listado;

            for (var i = 0; i < payload.length; i++) {
                reservaciones_eliminar = reservaciones_eliminar.filter(reservacion => reservacion._id !== payload[i])
            }

            return {
                ...state, 
                listado: reservaciones_eliminar
            };

        case AUDITORIO.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };
    
        default:
            return state;
    }
}