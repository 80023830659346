import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { buscarJovenes, filtarJovenes } from '../../actions/jovenes';

const FiltrosJovenes = () => {

    const dispatch =  useDispatch();

    const { ventana_filtros } = useSelector(state => state.jovenes);

    const [statusProcesar, setStatusProcesar] = useState(false);

    const [filtrosData, setFiltrosData] = useState({
        nombre: null,
        apellido_paterno: null,
        apellido_materno: null,
        celular: null,
        email: null,
        curp: null
    });

    /* Filtros */
    const handleAbrir = () => {}

    const handleInputChange = ({ target }) => {
        setFiltrosData({
            ...filtrosData,
            [target.name]: target.value
        });
    }
    
    const handleProcesar = async (filtros) => {
        await setStatusProcesar(true);

        if(!filtros){
            await dispatch(buscarJovenes(filtrosData));
        }else{
            let datos_base = {
                ...filtrosData, 
                nombre: null,
                apellido_paterno: null,
                apellido_materno: null,
                celular: null,
                email: null,
                curp: null
            };

            await dispatch(buscarJovenes(datos_base));

            await setFiltrosData(datos_base);
        } 
        
        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            dispatch(filtarJovenes(false));
        }
    }

    return (
        <Modal show={ventana_filtros} size="lg" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Filtrar jovenes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={filtrosData.nombre} onChange={e => handleInputChange(e)} />
                                <label htmlFor="nombre">Nombre</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Apellido paterno" id="apellido_paterno" name="apellido_paterno" value={filtrosData.apellido_paterno} onChange={e => handleInputChange(e)} />
                                <label htmlFor="apellido_paterno">Apellido paterno</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Apellido materno" id="apellido_materno" name="apellido_materno" value={filtrosData.apellido_materno} onChange={e => handleInputChange(e)} />
                                <label htmlFor="apellido_materno">Apellido materno</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Celular" id="celular" name="celular" value={filtrosData.celular} onChange={e => handleInputChange(e)} />
                                <label htmlFor="celular">Celular</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Email" id="email" name="email" value={filtrosData.email} onChange={e => handleInputChange(e)} />
                                <label htmlFor="email">Email</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="CURP" id="curp" name="curp" value={filtrosData.curp} onChange={e => handleInputChange(e)} />
                                <label htmlFor="curp">CURP</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <>
                            <button type="button" className="btn btn-primary" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Buscar todos</button>
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Buscar</button>
                        </>
                    :
                        <>
                            <button type="button" className="btn btn-primary" onClick={() => handleProcesar(true)}><i className="fas fa-search fa-sm"></i> Buscar todos</button>
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar(false)}><i className="fas fa-search fa-sm"></i> Buscar</button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default FiltrosJovenes;