import { LOGIN, LOGIN_ERROR, OBTENER_USUARIO, OBTENER_USUARIO_ERROR, LOGOUT, RECUPERAR_USUARIO, RESTABLECER_USUARIO, CONFIGURACION_PERFIL } from '../actions/types';

import setAuthToken from '../utils/setAuthToken';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: false,
    usuario: {},
    empresa: {},
    codigo: null
}

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case LOGIN:
            localStorage.setItem('token', payload.token); 
            setAuthToken(localStorage.token);

            return {
                ...state, 
                ...payload,
                isAuthenticated: true
            };

        case LOGIN_ERROR:
        case OBTENER_USUARIO_ERROR: 
        case LOGOUT: 
            localStorage.removeItem('token');
            
            return {
                ...state,
                token: null,
                isAuthenticated: false
            };

        case OBTENER_USUARIO:
            return {
                ...state,
                isAuthenticated: true,
                usuario: payload.usuario, 
                empresa: payload.empresa
            };

        case RECUPERAR_USUARIO:
            return {
                ...state,
                codigo: payload,
            };

        case RESTABLECER_USUARIO:
            return {
                ...state, payload
            };

        case CONFIGURACION_PERFIL.EDITAR_PERSONAL:
            return {
                ...state,
                usuario: payload
            };

        case CONFIGURACION_PERFIL.EDITAR_EMPRESARIAL:
            return {
                ...state,
                empresa: payload
            };
        default:
            return state;
    }
}