import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { sortTableDateFecha } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { buscarAccesos } from '../../actions/gimnasios';

import FiltrosAccesos from './FiltrosAccesos';

const AccesosGimnasio = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Gimnasio',
            path: '/gimnasio'
        },
        {
            activo: true,
            nombre: 'Historial de accesos',
            path: '/gimnasio/accesos'
        }
    ];

    const dispatch = useDispatch();

    const { listado_accesos } = useSelector(state => state.gimnasios);

    const [accesos, setAccesos] = useState([]);
    const [busquedaData, setBusquedaData] = useState({
        fecha: new Date(), 
        id_gimnasio: null,
        gimnasio: null, 
        horario_total: true, 
        hora_inicio: null,
        hora_fin: null, 
        busqueda: false
    });

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.joven.nombre || '-'
        },
        {
            name: 'Apellido paterno',
            sortable: true, 
            selector: row => row.joven.apellido_paterno || '-'
        },
        {
            name: 'Apellido materno',
            sortable: true, 
            selector: row => row.joven.apellido_materno || '-'
        },
        {
            name: 'Ingreso',
            sortable: true, 
            sortFunction: sortTableDateFecha, 
            cell: item => new Date(item.fecha).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
        },
        {
            name: 'Responsable',
            sortable: true, 
            selector: row => row.usuario ? [row.usuario.nombre, row.usuario.apellido_paterno, row.usuario.apellido_materno].join(' ') : 'Sin definir'
        }
    ];

    /* Filtros */
    const handleFiltrosBusqueda = (busqueda) => {
        setBusquedaData({
            ...busqueda
        });
    }

    /* Funciones generales */
    const dateToStringLocal = (fecha) => {
        const meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
        const dias_semana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        fecha = new Date(fecha);
        
        return dias_semana[fecha.getDay()] + ', ' + fecha.getDate() + ' de ' + meses[fecha.getMonth()] + ' de ' + fecha.getUTCFullYear();
    }

    const horariosToText = (hora_inicio, hora_fin) => {
        return hora_inicio + ' - ' + hora_fin;
    }

    useEffect(() => {
        dispatch(buscarAccesos(busquedaData));
    }, []);

    useEffect(() => {
        setAccesos(listado_accesos);
    }, [listado_accesos]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);    

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .card-subtitle {
                            font-size: 1.5rem;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <FiltrosAccesos busqueda={busquedaData} reestablecerFiltros={handleFiltrosBusqueda} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Historial de accesos</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={() => handleFiltrosBusqueda({ ...busquedaData, busqueda: true })}>Filtros de búsqueda</button>
                    </div>

                    <div className="row my-5">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5>Fecha</h5>
                                    </div>
                                    <div className="card-content">
                                        <p className="card-subtitle">{ dateToStringLocal(busquedaData.fecha) }</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5>Gimnasio</h5>
                                    </div>
                                    <div className="card-content">
                                        <p className="card-subtitle">{ busquedaData.id_gimnasio ? busquedaData.gimnasio : 'Todos los gimnasios'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5>Horario</h5>
                                    </div>
                                    <div className="card-content">
                                        <p className="card-subtitle">{ busquedaData.horario_total ? 'Todo el día' : horariosToText(busquedaData.hora_inicio, busquedaData.hora_fin) }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row my-5">
                        <DataTable 
                            columns={columns}
                            data={accesos}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AccesosGimnasio;