import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { setAlert } from '../../actions/alert';
import { obtenerBloqueo, editarBloqueo } from '../../actions/cabinas';
import { buscarJovenes } from '../../actions/jovenes';
import { buscarCabinasConfiguracion } from '../../actions/configuracion';

const EditarBloqueo = ({ bloqueo, reestablecerBloqueo }) => {

    const dispatch =  useDispatch();

    const cabinas = useSelector(state => state.configuracion.cabinas);
    const { detalles } = useSelector(state => state.cabinas);

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [bloqueoData, setBloqueoData] = useState({
        id_cabina: null,
        motivo: null, 
        fecha_inicio: null,
        fecha_fin: null
    });

    const [cabinaData, setCabinaData] = useState(null);
    const [cabinasFilter, setCabinasFilter] = useState([]);

    /* Editar bloqueo */
    const handleSelectCabina = (option) => {
        setCabinaData(option);

        setBloqueoData({
            ...bloqueoData,
            id_cabina: option.value
        });
    }

    const handleInputChange = ({ target }) => {
        setBloqueoData({
            ...bloqueoData,
            [target.name]: target.value
        });
    }

    const handleDatePicker = (name, value) => {
        setBloqueoData({
            ...bloqueoData,
            [name]: value
        });
    }
    
    const handleProcesar = async () => {
        if(bloqueoData.motivo){
            if(bloqueoData.id_cabina){
                if((new Date(bloqueoData.fecha_inicio) <= new Date(bloqueoData.fecha_fin)) && new Date(bloqueoData.fecha_inicio) && new Date(bloqueoData.fecha_inicio)){
                    await setStatusProcesar(true);
                    await dispatch(editarBloqueo(bloqueoData));
                    await handleCerrar();
                    await setStatusProcesar(false);
                }else{
                    await dispatch(setAlert('La fecha de inicio debe ser menor o igual a la de finalización', 'danger'));
                }
            }else{
                await dispatch(setAlert('Selecciona una cabina', 'danger'));
            }
        }else{
            await dispatch(setAlert('Ingresa el motivo de bloqueo de fechas', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setBloqueoData({
                id_cabina: null,
                motivo: null, 
                fecha_inicio: null,
                fecha_fin: null
            });

            setCabinaData(null);
            setNuevoModal(false);
            reestablecerBloqueo(null);
        }
    }

    useEffect(() => {
        dispatch(buscarJovenes({
            nombre: null,
            apellido_paterno: null,
            apellido_materno: null,
            celular: null,
            email: null,
            curp: null
        }));
        dispatch(buscarCabinasConfiguracion(true));
    }, []);

    useEffect(() => {
        if(bloqueo){
            setNuevoModal(true);

            dispatch(obtenerBloqueo(bloqueo?.cabina?._id, bloqueo?.id));

            setBloqueoData({
                ...bloqueoData,
                id: bloqueo?.id
            });
        }        
    }, [bloqueo]);

    useEffect(() => {
        if(detalles){
            setBloqueoData({
                ...bloqueoData,
                id: detalles?._id, 
                id_cabina: detalles?.cabina?._id,
                motivo: detalles?.motivo, 
                fecha_inicio: detalles?.fecha_inicio,
                fecha_fin: detalles?.fecha_fin
            });

            if(cabinasFilter.length > 0){

                let cabina_elegida = cabinasFilter.find( cabina => String(cabina.value) === String(detalles?.cabina?._id) );
    
                if(cabina_elegida){
                    setCabinaData({value: cabina_elegida.value, label: cabina_elegida.label});
                }
            }
        }
    }, [detalles]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < cabinas.listado?.length; i++) {
            options.push({ value: cabinas.listado[i]._id, label: cabinas.listado[i].nombre, horarios: cabinas.listado[i].horarios, bloqueos: cabinas.listado[i].bloqueos });
        }

        setCabinasFilter(options);
    }, [cabinas]);

    return (
        <Modal show={nuevoModal} size="lg" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Editar bloqueo de disponibilidad</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Motivo" id="motivo" name="motivo" value={bloqueoData.motivo} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="motivo">Motivo</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="id_cabina"
                                name="id_cabina"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar cabina"
                                isClearable
                                options={cabinasFilter}
                                value={cabinaData}
                                onChange={(value) => handleSelectCabina(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_inicio"
                                    name="fecha_inicio"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    selected={ bloqueoData.fecha_inicio != null ? new Date(bloqueoData.fecha_inicio) : '' }
                                    onChange={ date => handleDatePicker('fecha_inicio', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_inicio">Fecha de inicio</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_fin"
                                    name="fecha_fin"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    selected={ bloqueoData.fecha_fin != null ? new Date(bloqueoData.fecha_fin) : '' }
                                    onChange={ date => handleDatePicker('fecha_fin', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_fin">Fecha de finalización</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Guardar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Guardar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EditarBloqueo;