import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { obtenerReservacion, statusReservacion } from '../../actions/cabinas';

const DetallesReservacion = ({ reservacion, reestablecerReservacion, editarReservacion, eliminarReservacion }) => {

    const dispatch =  useDispatch();

    const { detalles } = useSelector(state => state.cabinas);

    const [detallesModal, setDetallesModal] = useState(false);

    const [reservacionData, setReservacionData] = useState({
        id: null,
        cabina: null,
        joven: null, 
        fecha_inicio: null,
        fecha_fin: null, 
        hora_inicio: null,
        hora_fin: null, 
        observaciones: null, 
        status: 0, 
        url_perfil: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
    });

    const handleCerrar = () => {
        setReservacionData({
            id: null,
            cabina: null,
            joven: null, 
            fecha_inicio: null,
            fecha_fin: null, 
            hora_inicio: null,
            hora_fin: null, 
            observaciones: null, 
            status: 0, 
            url_perfil: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
        });
        setDetallesModal(false);
        reestablecerReservacion(null);
    }
    
    /* Editar reservacion */
    const handleEditar = () => {
        handleCerrar();
        editarReservacion(reservacionData);
    }

    /* Status reservacion */
    const handleStatus = (status) => {
        dispatch(statusReservacion({
            id: reservacionData.id, 
            status
        }));
        handleCerrar();
    }

    /* Eliminar reservacion */
    const handleEliminar = () => {
        handleCerrar();
        eliminarReservacion(reservacionData);
    }

    /* Funciones generales */
    const getRangoFechas = (inicio, fin) => {
        let diferencia = new Date(fin).getTime() - new Date(inicio).getTime();
        let total_dias = Math.ceil(diferencia / (1000 * 3600 * 24));

        if(total_dias <= 1){
            return 'Unicamente el ' + (new Date(inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }))
        }else{
            return 'Del ' + (new Date(inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })) + ' al ' + (new Date(fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }))
        }
    }

    useEffect(() => {
        dispatch(obtenerReservacion(null));
    }, []);

    useEffect(() => {
        if(reservacion){
            setDetallesModal(true);
            
            dispatch(obtenerReservacion(reservacion?.id));

            setReservacionData({
                ...reservacionData,
                id: reservacion?.id
            });
        }
    }, [reservacion]);

    useEffect(() => {
        if(detalles){
            setReservacionData({
                ...reservacionData,
                id: detalles?._id, 
                cabina: detalles?.cabina,
                joven: {
                    id: detalles?.joven?._id || null,
                    nombre: detalles?.joven ? [detalles?.joven.nombre, detalles?.joven.apellido_paterno, detalles?.joven.apellido_materno].join(' ') : detalles?.nombre
                }, 
                fecha_inicio: detalles?.fecha_inicio,
                fecha_fin: detalles?.fecha_fin, 
                hora_inicio: detalles?.hora_inicio,
                hora_fin: detalles?.hora_fin, 
                observaciones: detalles?.observaciones, 
                status: detalles?.status, 
                url_perfil: detalles.url_perfil_p || process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
            });
        }
    }, [detalles]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .contenedor_imagen_perfil {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            background: #fff;
                            border: 5px solid #111F62;
                            width: auto;
                            margin: auto;
                        }
                        
                        .contenedor_imagen{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        #imagen_perfil {
                            width: auto;
                            height: 100%;
                            display: block;
                            position: absolute;
                            margin: auto;
                            top: -100%;
                            right: -100%;
                            bottom: -100%;
                            left: -100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <Modal show={detallesModal} backdrop="static" size="lg" centered onHide={() => handleCerrar()}>
                <Modal.Header closeButton>
                    <Modal.Title>Reservación de cabina</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-5 row">
                            <label className="contenedor_imagen_perfil">
                                <div className="contenedor_imagen">
                                    <img id="imagen_perfil" src={ reservacionData?.url_perfil }/>
                                </div>
                            </label>
                        </div>

                        <div className="col-md-7 row">
                            <div className="col-md-8">
                                <label className="fw-bold">Periodo</label>
                                <p>{ getRangoFechas(reservacionData?.fecha_inicio, reservacionData?.fecha_fin) }</p>
                            </div>

                            <div className="col-md-4">
                                <label className="fw-bold">Horario</label>
                                <p>{reservacionData?.hora_inicio + ' - ' + reservacionData?.hora_fin}</p>
                            </div>

                            <div className="col-md-8">
                                <label className="fw-bold">Joven</label>
                                <p>{reservacionData?.joven?.nombre}</p>
                            </div>

                            <div className="col-md-4">
                                <label className="fw-bold">Cabina</label>
                                <p>{reservacionData?.cabina?.nombre}</p>
                            </div>

                            <div className="col-md-12">
                                <label className="fw-bold">Observaciones</label>
                                <p>{reservacionData?.observaciones ? reservacionData?.observaciones : 'Sin observaciones'}</p>
                            </div>
                        </div>
                    </div>                    
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-group" role="group">
                        <button type="button" className={'btn dropdown-toggle btn-' + (reservacionData.status == 0 ? 'warning' : (reservacionData.status == 1 ? 'success' : 'danger'))} data-bs-toggle="dropdown" aria-expanded="false">
                        <i className={'fas fa-sm fa-' + (reservacionData.status == 0 ? 'pause' : (reservacionData.status == 1 ? 'check' : 'times'))}></i>
                            { reservacionData.status == 0 ? ' Pendiente' : (reservacionData.status == 1 ? ' Aprobada' : ' Rechazada') }
                        </button>
                        <ul class="dropdown-menu">
                            {
                                reservacionData.status != 0 ?
                                    <li><button type="button" class="dropdown-item" onClick={() => handleStatus(0)}>Pendiente</button></li>
                                :
                                    null
                            }

                            {
                                reservacionData.status != 1 ?
                                    <li><button type="button" class="dropdown-item" onClick={() => handleStatus(1)}>Autorizar</button></li>
                                :
                                    null
                            }

                            {
                                reservacionData.status != 2 ?
                                    <li><button type="button" class="dropdown-item" onClick={() => handleStatus(2)}>Rechazar</button></li>
                                :
                                    null
                            }
                        </ul>
                    </div>
                    <button type="button" className="btn btn-danger mr-3" onClick={() => handleEliminar()}><i className="fas fa-trash fa-sm"></i> Eliminar</button>
                    <button type="button" className="btn btn-warning" onClick={() => handleEditar()}><i className="fas fa-pencil fa-sm"></i> Editar</button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default DetallesReservacion;