import React, { useEffect, useState, createRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { guardarPerfilEmpresa } from '../../../actions/configuracion';

const PerfilEmpresa = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Perfil de la empresa',
            path: '/perfil_empresa'
        }
    ];

    const dispatch = useDispatch();

    const { empresa } = useSelector(state => state.auth);
    
    const [perfilData, setPerfilData] = useState({
        nombre: '',
        direccion: '',
        descripcion: '',
        contactos: [], 
        imagen: null
    });

    const [profilePicture, setProfilePicture] = useState({
        img: undefined,
    });

    const [tiposContactoData, setTiposContactoData] = useState([]);
    const [tiposContactosFilter, setTiposContactosFilter] = useState([
        { value: 0, label: 'Teléfono', icon: 'fa-solid fa-phone' }, 
        { value: 1, label: 'WhatsApp', icon: 'fa-brands fa-whatsapp' }, 
        { value: 2, label: 'Facebook', icon: 'fa-brands fa-facebook' }, 
        { value: 3, label: 'Instagram', icon: 'fa-brands fa-instagram' }, 
        { value: 4, label: 'TikTok', icon: 'fa-brands fa-tiktok' }, 
        { value: 5, label: 'Twitter', icon: 'fa-brands fa-twitter' }, 
        { value: 6, label: 'Correo', icon: 'fa-solid fa-envelope' }, 
        { value: 7, label: 'Sitio web', icon: 'fa-solid fa-globe' }
    ]);

    let img = createRef();

    /* Guardar perfil */
    const onChangeImg = () => {
        setProfilePicture({
            ...profilePicture, img: img.current.files[0]
        })
    }

    const handleInputChange = (e) => {
        setPerfilData({
            ...perfilData, [e.target.name]: e.target.value
        })
    }

    const handleSelectTipo = (value, action) => {
        let nombre_separado = action.name.split('_');

        let posicion = nombre_separado[1];

        let contactos = perfilData.contactos;
        let tipos_elegidos = tiposContactoData;

        contactos[posicion].tipo = value?.value != null && value?.value != undefined ? value?.value : null;
        tipos_elegidos[posicion] = value;

        setTiposContactoData(tipos_elegidos);

        setPerfilData({
            ...perfilData,
            contactos
        });
    }

    const handleContactoChange = ({ target }) => {
        let nombre_separado = target.name.split('_');

        let posicion = nombre_separado[1];
        
        let contactos = perfilData.contactos;

        contactos[posicion].contenido = target.value || '';

        setPerfilData({
            ...perfilData,
            contactos
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let formData = new FormData();

            formData.append('nombre', perfilData.nombre);
            formData.append('direccion', perfilData.direccion);
            formData.append('descripcion', perfilData.descripcion);
            formData.append('contactos', JSON.stringify(perfilData.contactos));
            formData.append('perfil', profilePicture.img);

            dispatch(guardarPerfilEmpresa(formData));
        } catch (error) {
            return false;
        }
    }

    /* Funciones generales */
    const handleAgregarContacto = () => {
        let contactos = perfilData.contactos;
        let tipos_contactos = tiposContactoData;

        contactos.push({
            tipo: 0,
            contenido: null
        });

        tipos_contactos.push({ value: 0, label: 'Teléfono', icon: 'fa-solid fa-phone' });

        setPerfilData({ 
            ...perfilData, 
            contactos
        });

        setTiposContactoData(tipos_contactos);
    }

    const contactosCampos = (contactos) => {

        let contactos_campos = [];

        if(contactos){
            for (var x = 0; x < contactos?.length; x++) {
                contactos_campos.push(
                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group h-100">
                            <div className="input-group-preppend">
                                <Select
                                    id={'tipoContacto_' + x}
                                    name={'tipoContacto_' + x}
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Tipo"
                                    options={tiposContactosFilter}
                                    value={tiposContactoData[x]}
                                    onChange={handleSelectTipo}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    getOptionLabel={e => (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <i className={e.icon}></i>
                                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Contacto" id={'contenidoContacto_' + x} name={'contenidoContacto_' + x} value={contactos[x].contenido || ''} onChange={e => handleContactoChange(e)}/>
                                <label htmlFor={'contenidoContacto_' + x}>Contacto {x + 1}</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-danger deleteField" id={'eliminarContacto_' + x} onClick={e => handleEliminarContacto(e)}><i className="fa-solid fa-xmark"></i></button>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return contactos_campos;
    }

    const handleEliminarContacto = async ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');    

        let contactos = perfilData.contactos;
        let tipos_contactos = tiposContactoData;

        contactos.splice(id_separado[1], 1);
        tipos_contactos.splice(id_separado[1], 1);

        setPerfilData({ 
            ...perfilData, 
            contactos
        });

        setTiposContactoData(tipos_contactos);
    }

    useEffect(() => {
        setPerfilData({
            ...perfilData,
            id: empresa?._id,
            nombre: empresa?.nombre,
            direccion: empresa?.direccion,
            descripcion: empresa?.descripcion,
            contactos: empresa?.contactos, 
            imagen: empresa?.url_perfil_g ? empresa?.url_perfil_g : process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
        });

        let tipos_contactos = [];

        for (var x = 0; x < empresa?.contactos?.length; x++) {
            if(tiposContactosFilter.length > 0){

                let tipo_contacto_asignado = tiposContactosFilter.find( tipo => String(tipo.value) === String(empresa?.contactos[x]?.tipo) );

                if(tipo_contacto_asignado){
                    tipos_contactos.push({value: tipo_contacto_asignado.value, label: tipo_contacto_asignado.label, icon: tipo_contacto_asignado.icon});
                }
            }
        }

        setTiposContactoData(tipos_contactos);
    }, [empresa]);    

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return(
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        input[type="file"] {
                            display: none;
                        }
                        
                        .custom-file-upload {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            cursor: pointer;
                            background: #fff;
                            margin-bottom: 25px;
                            border: 5px solid #111F62;
                        }
                        
                        .img-wrap{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        .img-upload:before{
                            content: "";
                            font-family: "Font Awesome 6 Free" !important;
                            font-weight: 900;
                            font-size: 90px;
                            position: absolute;
                            padding-top: 50px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-align: center;
                            color: #21B4E9;
                            width: 200px;
                            height: 200px;
                            border-radius: 50%;
                            opacity: 0;
                            transition: .5s ease;
                            background-color: #fff;
                        }

                        .img-upload:hover:before{
                            opacity: 1;
                        }

                        #img-photo {
                            width: auto;
                            height: 100%;
                        }

                        .selectIconos, .select-group {
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-8 offset-md-2 pb-4">
                    <h3 className="mb-3 mt-5">Información empresarial</h3>

                    <div className="row mb-5">
                        <div className="col-12 form-group text-center">
                            <label htmlFor="photo-upload" className="custom-file-upload fas">
                                <div className="img-wrap img-upload">
                                    <img id="img-photo" htmlFor="photo-upload" src={profilePicture.img ? URL.createObjectURL(profilePicture.img) : perfilData.imagen}/>
                                </div>
                                <input id="photo-upload" type="file" accept="image/*" ref={img} onChange={onChangeImg}/> 
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-5 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={perfilData.nombre} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="nombre">Nombre</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-7 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Apellido materno" id="direccion" name="direccion" value={perfilData.direccion} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="direccion">Dirección</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <textarea className="form-control textareCampo" placeholder="Descripción" id="descripcion" name="descripcion" value={perfilData.descripcion} onChange={e => handleInputChange(e)}></textarea>
                                    <label htmlFor="descripcion">Descripción</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        { contactosCampos(perfilData.contactos) }
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button type="button" className="btn btn-success float-end" onClick={handleSubmit}>Guardar perfil</button>   
                            <button type="button" className="btn btn-primary float-end me-2" onClick={() => handleAgregarContacto()}><i className="fa-solid fa-plus"></i> Contacto</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PerfilEmpresa;