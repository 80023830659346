import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { eliminarJoven } from '../../actions/jovenes';

const EliminarJoven = ({ joven, reestablecerJoven }) => {

    const dispatch =  useDispatch();

    const [eliminarModal, setEliminarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Eliminar empresa */
    const handleProcesar = async () => {
        await setStatusProcesar(true);
        await dispatch(eliminarJoven(joven?._id));
        await handleCerrar(null);
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            reestablecerJoven(null);
            setEliminarModal(false);
        }
    }

    useEffect(() => {
        if(joven != null){
            setEliminarModal(true);
        }
    }, [joven]);

    return (
        <Modal show={eliminarModal} backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar joven</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Deseas eliminar a {[joven?.nombre, joven?.apellido_paterno, joven?.apellido_materno].join(' ')}?</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={ statusProcesar }><i className="fas fa-times fa-sm"></i> No</button>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Si</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EliminarJoven;