import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { sortTableDateCreacion } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { informacionEmpresa, limpiarEmpresa } from '../../actions/empresas';
import { buscarUsuarios, limpiarUsuarios, filtarUsuarios } from '../../actions/usuarios';

import FiltrosUsuariosEmpresa from './FiltrosUsuariosEmpresa';
import NuevoUsuario from './NuevoUsuario';
import EditarUsuario from './EditarUsuario';
import EliminarUsuario from './EliminarUsuario';

const UsuariosEmpresa = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Empresas',
            path: '/empresas'
        },
        {
            activo: true,
            nombre: 'Cuentas de usuario',
            path: '/empresas/usuarios/'
        }
    ];

    const dispatch =  useDispatch();

    const { id } = useParams();

    const { listado } = useSelector(state => state.usuarios);
    const { detalles } = useSelector(state => state.empresas);

    const [usuarios, setUsuarios] = useState([]);

    const [usuarioAgregar, setUsuarioAgregar] = useState(null);
    const [usuarioEditar, setUsuarioEditar] = useState(null);
    const [usuarioEliminar, setUsuarioEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
        },
        {
            name: 'Apellido paterno',
            sortable: true, 
            selector: row => row.apellido_paterno,
        },
        {
            name: 'Apellido materno',
            sortable: true, 
            selector: row => row.apellido_materno,
        },
        {
            name: 'Correo',
            sortable: true, 
            selector: row => row.email,
        },
        {
            name: 'Fecha de creación',
            sortable: true, 
            sortFunction: sortTableDateCreacion, 
            cell: item => new Date(item.creacion).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarUsuario(row)}><i className="fas fa-trash text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarUsuario(row)}><i className="fas fa-edit text-warning"></i></button>
                    </div>
                )
            }
        }
    ];

    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtarUsuarios(true));
    }

    /* Crear usuario */
    const handleAgregarUsuario = (usuario) => {
        setUsuarioEditar(null);
        setUsuarioEliminar(null);

        setUsuarioAgregar(usuario);        
    }

    /* Editar usuario */
    const handleEditarUsuario = (usuario) => {
        setUsuarioAgregar(null);
        setUsuarioEliminar(null);

        setUsuarioEditar(usuario);
    }

    /* Eliminar usuario */
    const handleEliminarUsuario = (usuario) => {
        setUsuarioAgregar(null);
        setUsuarioEditar(null);

        setUsuarioEliminar(usuario);
    }

    useEffect(() => {
        return () => {
            dispatch(limpiarEmpresa());
            dispatch(limpiarUsuarios());
        }
    }, []);

    useEffect(() => {        
        if(id){
            dispatch(informacionEmpresa(id));
            dispatch(buscarUsuarios({
                id, 
                nombre: null, 
                apellido_paterno: null, 
                apellido_materno: null, 
                tipo: null, 
                fecha_inicio: null, 
                fecha_fin: null
            }));

            rutasBread[1].path = rutasBread[1].path + id;

            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [id]);

    useEffect(() => {
        setUsuarios(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <FiltrosUsuariosEmpresa />
            <NuevoUsuario usuario={usuarioAgregar} reestablecerUsuario={handleAgregarUsuario} />
            <EditarUsuario usuario={usuarioEditar} reestablecerUsuario={handleEditarUsuario} />
            <EliminarUsuario usuario={usuarioEliminar} reestablecerUsuario={handleEliminarUsuario} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Usuarios {detalles?.nombre ? 'de ' + detalles?.nombre : ''}</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarUsuario({ id_empresa: id })}>Agregar usuario</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={usuarios}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default UsuariosEmpresa;