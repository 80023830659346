import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { buscarPromociones, filtrarPromociones } from '../../actions/promociones';

const FiltrosPromociones = () => {

    const dispatch =  useDispatch();

    const { ventana_filtros } = useSelector(state => state.promociones);

    const [statusProcesar, setStatusProcesar] = useState(false);

    const [filtrosData, setFiltrosData] = useState({
        nombre: null,
        status: null, 
        fecha_inicio: null,
        fecha_fin: null
    });

    const [statusData, setStatusData] = useState(null);
    const statusFilter = [
        { value: 0, label: 'Activa' }, 
        { value: 1, label: 'Inactiva' }, 
        { value: 2, label: 'Finalizada' }
    ];

    /* Filtros */
    const handleAbrir = () => {}

    const handleSelectStatus = (option) => {
        setStatusData(option);

        setFiltrosData({
            ...filtrosData,
            status: option != null ? option.value : null
        });
    }

    const handleInputChange = ({ target }) => {
        setFiltrosData({
            ...filtrosData,
            [target.name]: target.value
        });
    }

    const handleDatePicker = (name, value) => {
        setFiltrosData({
            ...filtrosData,
            [name]: value
        });
    }
    
    const handleProcesar = async (limpiar) => {
        await setStatusProcesar(true);

        if(!limpiar){
            await dispatch(buscarPromociones(filtrosData));
        }else{
            let datos_base = {
                ...filtrosData, 
                nombre: null,
                status: null, 
                fecha_inicio: null,
                fecha_fin: null
            };

            await dispatch(buscarPromociones(datos_base));

            await setFiltrosData(datos_base);

            await setStatusData(null);
        }

        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            dispatch(filtrarPromociones(false));
        }
    }

    return (
        <Modal show={ventana_filtros} size="md" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Filtrar promociones y descuentos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-7 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={filtrosData.nombre} onChange={e => handleInputChange(e)} />
                                <label htmlFor="nombre">Nombre</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="status"
                                name="status"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar status"
                                isClearable
                                options={statusFilter}
                                value={statusData}
                                onChange={(value) => handleSelectStatus(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_inicio"
                                    name="fecha_inicio"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    selected={ filtrosData.fecha_inicio != null ? new Date(filtrosData.fecha_inicio) : '' }
                                    onChange={ date => handleDatePicker('fecha_inicio', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_inicio">Inicio del límite</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_fin"
                                    name="fecha_fin"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    selected={ filtrosData.fecha_fin != null ? new Date(filtrosData.fecha_fin) : '' }
                                    onChange={ date => handleDatePicker('fecha_fin', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_fin">Fin del límite</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <>
                            <button type="button" className="btn btn-primary" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Buscar todos</button>
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Buscar</button>
                        </>
                    :
                        <>
                            <button type="button" className="btn btn-primary" onClick={() => handleProcesar(true)}><i className="fas fa-search fa-sm"></i> Buscar todos</button>
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar(false)}><i className="fas fa-search fa-sm"></i> Buscar</button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default FiltrosPromociones;