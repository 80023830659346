import { NOTIFICACIONES } from '../actions/types';

const initialState = {
    listado: []
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case NOTIFICACIONES.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case NOTIFICACIONES.ENVIAR:
            let notificaciones_agregar = state.listado;

            notificaciones_agregar = [
                payload, ...notificaciones_agregar
            ];

            return {
                ...state, 
                listado: notificaciones_agregar
            };

        default:
            return state;
    }
}