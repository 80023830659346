import axios from 'axios';

import { CONFIGURACION_CATEGORIAS_EMPRESAS, CONFIGURACION_EXTENSIONES, CONFIGURACION_REQUERIMIENTOS, CONFIGURACION_DEPENDENCIAS, CONFIGURACION_GIMNASIOS, CONFIGURACION_CABINAS, CONFIGURACION_AUDITORIOS, CONFIGURACION_ASESORES, CONFIGURACION_PERFIL } from '../actions/types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

/* Categorias de empresas */
export const buscarCategoriasEmpresasConfiguracion = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_categorias`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_CATEGORIAS_EMPRESAS.BUSCAR,
            payload: res.data.categorias
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearCategoriaEmpresaConfiguracion = (categoria) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(categoria);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/crear_categoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_CATEGORIAS_EMPRESAS.AGREGAR,
            payload: res.data.categoria
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerCategoriaEmpresaConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_categoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_CATEGORIAS_EMPRESAS.DETALLES,
            payload: res.data.categoria
        });
    } catch (error){  
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarCategoriaEmpresaConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_CATEGORIAS_EMPRESAS.DETALLES,
        payload: null
    });
}

export const editarCategoriaEmpresaConfiguracion = (categoria) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(categoria);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_categoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_CATEGORIAS_EMPRESAS.EDITAR,
            payload: res.data.categoria
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){     
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarCategoriaEmpresaConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_categoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_CATEGORIAS_EMPRESAS.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Extensiones de requerimientos */
export const buscarExtensionesConfiguracion = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_extensiones`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_EXTENSIONES.BUSCAR,
            payload: res.data.extensiones
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearExtensionConfiguracion = (extension) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(extension);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/crear_extension`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_EXTENSIONES.AGREGAR,
            payload: res.data.extension
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerExtensionConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_extension`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_EXTENSIONES.DETALLES,
            payload: res.data.extension
        });
    } catch (error){  
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarExtensionConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_EXTENSIONES.DETALLES,
        payload: null
    });
}

export const editarExtensionConfiguracion = (extension) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(extension);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_extension`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_EXTENSIONES.EDITAR,
            payload: res.data.extension
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){     
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarExtensionConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_extension`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_EXTENSIONES.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Requerimientos */
export const buscarRequerimientosConfiguracion = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_requerimientos`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_REQUERIMIENTOS.BUSCAR,
            payload: res.data.requerimientos
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearRequerimientoConfiguracion = (requerimiento) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(requerimiento);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/crear_requerimiento`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_REQUERIMIENTOS.AGREGAR,
            payload: res.data.requerimiento
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerRequerimientoConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_requerimiento`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_REQUERIMIENTOS.DETALLES,
            payload: res.data.requerimiento
        });
    } catch (error){      
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarRequerimientoConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_REQUERIMIENTOS.DETALLES,
        payload: null
    });
}

export const editarRequerimientoConfiguracion = (requerimiento) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(requerimiento);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_requerimiento`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_REQUERIMIENTOS.EDITAR,
            payload: res.data.requerimiento
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){  
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarRequerimientoConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_requerimiento`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_REQUERIMIENTOS.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Dependencias de tarjeta */
export const buscarDependenciasTarjetasConfiguracion = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_dependencias`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_DEPENDENCIAS.BUSCAR,
            payload: res.data.dependencias
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const editarDependenciaTarjetaConfiguracion = (dependencia) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(dependencia);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_dependencia`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_DEPENDENCIAS.EDITAR,
            payload: res.data.dependencia
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){     
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Gimnasios */
export const buscarGimnasiosConfiguracion = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_gimnasios`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_GIMNASIOS.BUSCAR,
            payload: res.data.gimnasios
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearGimnasioConfiguracion = (gimnasio) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(gimnasio);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/crear_gimnasio`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_GIMNASIOS.AGREGAR,
            payload: res.data.gimnasio
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerGimnasioConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_gimnasio`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_GIMNASIOS.DETALLES,
            payload: res.data.gimnasio
        });
    } catch (error){     
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarGimnasioConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_GIMNASIOS.DETALLES,
        payload: null
    });
}

export const editarGimnasioConfiguracion = (gimnasio) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(gimnasio);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_gimnasio`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_GIMNASIOS.EDITAR,
            payload: res.data.gimnasio
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){  
        await dispatch(changeLoader(false));      

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarGimnasioConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_gimnasio`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_GIMNASIOS.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Cabinas */
export const buscarCabinasConfiguracion = (valor_busqueda) => async dispatch => {
    if(valor_busqueda){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        try{
            await dispatch(changeLoader(true));
            const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_cabinas`, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: CONFIGURACION_CABINAS.BUSCAR,
                payload: res.data.cabinas
            });
        } catch (error){ 
            await await dispatch(changeLoader(false));

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        await dispatch({
            type: CONFIGURACION_CABINAS.BUSCAR,
            payload: []
        });
    }
}

export const crearCabinaConfiguracion = (cabina) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(cabina);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/crear_cabina`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_CABINAS.AGREGAR,
            payload: res.data.cabina
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerCabinaConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_cabina`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_CABINAS.DETALLES,
            payload: res.data.cabina
        });
    } catch (error){    
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarCabinaConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_CABINAS.DETALLES,
        payload: null
    });
}

export const editarCabinaConfiguracion = (cabina) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(cabina);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_cabina`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_CABINAS.EDITAR,
            payload: res.data.cabina
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){   
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarCabinaConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_cabina`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_CABINAS.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Auditorios */
export const buscarAuditoriosConfiguracion = (valor_busqueda) => async dispatch => {
    if(valor_busqueda){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        try{
            await dispatch(changeLoader(true));
            const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_auditorios`, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: CONFIGURACION_AUDITORIOS.BUSCAR,
                payload: res.data.auditorios
            });
        } catch (error){ 
            await await dispatch(changeLoader(false));

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        await dispatch({
            type: CONFIGURACION_AUDITORIOS.BUSCAR,
            payload: []
        });
    }
}

export const crearAuditorioConfiguracion = (auditorio) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(auditorio);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/crear_auditorio`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_AUDITORIOS.AGREGAR,
            payload: res.data.auditorio
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerAuditorioConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_auditorio`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_AUDITORIOS.DETALLES,
            payload: res.data.auditorio
        });
    } catch (error){     
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarAuditorioConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_AUDITORIOS.DETALLES,
        payload: null
    });
}

export const editarAuditorioConfiguracion = (auditorio) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(auditorio);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_auditorio`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_AUDITORIOS.EDITAR,
            payload: res.data.auditorio
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){   
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarAuditorioConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_auditorio`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_AUDITORIOS.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Asesores */
export const buscarAsesoresConfiguracion = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_asesores`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_ASESORES.BUSCAR,
            payload: res.data.asesores
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerAsesorConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_asesor`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_ASESORES.DETALLES,
            payload: res.data.asesor
        });
    } catch (error){         
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarAsesorConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_ASESORES.DETALLES,
        payload: null
    });
}

export const editarAsesorConfiguracion = (asesor) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(asesor);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_asesor`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_ASESORES.EDITAR,
            payload: res.data.asesor
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){        
        await dispatch(changeLoader(false));
        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Perfil */
export const guardarPerfil = (perfil) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_perfil_personal`, perfil, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_PERFIL.EDITAR_PERSONAL,
            payload: res.data.perfil
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const guardarPerfilEmpresa = (perfil) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_perfil_empresarial`, perfil, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_PERFIL.EDITAR_EMPRESARIAL,
            payload: res.data.perfil
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}