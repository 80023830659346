import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import { sortTableDateFechaInicio, sortTableDateFechaFin, sortTableStatusStatus } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { buscarCitas, obtenerCita, filtarCitas } from '../../actions/psicologica';

import FiltrosCitas from './FiltrosCitas';
import NuevaCita from './NuevaCita';
import DetallesCita from './DetallesCita';
import EditarCita from './EditarCita';
import EliminarCita from './EliminarCita';
import NuevoBloqueo from './NuevoBloqueo';
import DetallesBloqueo from './DetallesBloqueo';
import EditarBloqueo from './EditarBloqueo';
import EliminarBloqueo from './EliminarBloqueo';

const CitasListado = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Asesorias psicologicas',
            path: '/psicologica'
        },
        {
            activo: true,
            nombre: 'Citas',
            path: '/psicologica/citas_listado'
        }
    ];

    const dispatch =  useDispatch();
    const navigate = useNavigate();

    const { listado_citas } = useSelector(state => state.psicologica);

    const [citas, setCitas] = useState([]);
    const [citaAgregar, setCitaAgregar] = useState(null);
    const [citaDetalles, setCitaDetalles] = useState(null);
    const [citaEditar, setCitaEditar] = useState(null);
    const [citaEliminar, setCitaEliminar] = useState(null);
    const [bloqueoAgregar, setBloqueoAgregar] = useState(null);
    const [bloqueoDetalles, setBloqueoDetalles] = useState(null);
    const [bloqueoEditar, setBloqueoEditar] = useState(null);
    const [bloqueoEliminar, setBloqueoEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Tipo',
            sortable: true, 
            selector: row => {
                if(row.tipo == 0){
                    return(
                        'Cita'
                    );
                }else{
                    return(
                        'Bloqueo'
                    );
                }
            }
        },
        {
            name: 'Razón o responsable',
            sortable: true, 
            selector: row => row.titulo, 
            grow: 2
        },
        {
            name: 'Asesor',
            sortable: true, 
            selector: row => row.asesor ? row.asesor.nombre : 'Sin definir', 
            grow: 2
        },
        {
            name: 'Fecha de inicio',
            sortable: true, 
            sortFunction: sortTableDateFechaInicio, 
            cell: item => new Date(item.fecha_inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' a las ' + new Date(item.fecha_inicio).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }), 
            grow: 2
        },
        {
            name: 'Fecha de finalización',
            sortable: true, 
            sortFunction: sortTableDateFechaFin, 
            cell: item => new Date(item.fecha_fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' a las ' + new Date(item.fecha_fin).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }), 
            grow: 2
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusStatus, 
            selector: row => {
                switch(row.status){
                    case 0:
                        return(
                            <h5><Badge pill bg="warning">Pendiente</Badge></h5>
                        )
                    case 1:
                        return(
                            <h5><Badge pill bg="success">Autorizada</Badge></h5>
                        )
                    case 2:
                        return(
                            <h5><Badge pill bg="danger">Rechazada</Badge></h5>
                        )
                    default:
                        break;
                }
            }
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleSeleccionarEvento(row)}><i className="fas fa-eye fa-sm text-info"></i></button>
                    </div>
                )
            }, 
            grow: 0.5
        }
    ];

    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtarCitas(true));
    }

    /* Seleccionar evento */
    const handleSeleccionarEvento = (evento) => {
        if(evento.tipo == 1){
            handleDetallesBloqueo({ 
                id: evento.id, 
                joven: evento.joven, 
                asesor: evento.asesor
            });
        }else{
            handleDetallesCita({ 
                id: evento.id, 
                joven: evento.joven, 
                asesor: evento.asesor
            });
        }
    }

    /* Crear cita */
    const handleAgregarCita = (cita) => {
        setCitaDetalles(null);
        setCitaEditar(null);
        setCitaEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setCitaAgregar(cita);        
    }

    /* Detalles cita */
    const handleDetallesCita = (cita) => {
        setCitaAgregar(null);
        setCitaEditar(null);
        setCitaEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setCitaDetalles(cita);        
    }

    /* Detalles completos asesoria */
    const handleDetallesCompletosAsesoria = (asesoria) => {
        setCitaAgregar(null);
        setCitaDetalles(null);
        setCitaEditar(null);
        setCitaEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);
        
        navigate('/psicologica/detalles/' + asesoria);
    }

    /* Editar cita */
    const handleEditarCita = (cita) => {
        setCitaAgregar(null);
        setCitaDetalles(null);
        setCitaEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setCitaEditar(cita);        
    }

    /* Eliminar cita */
    const handleEliminarCita = (cita) => {
        setCitaAgregar(null);
        setCitaDetalles(null);
        setCitaEditar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setCitaEliminar(cita);        
    }

    /* Crear bloqueo */
    const handleAgregarBloqueo = (bloqueo) => {
        setCitaAgregar(null);
        setCitaDetalles(null);
        setCitaEditar(null);
        setCitaEliminar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setBloqueoAgregar(bloqueo);        
    }

    /* Detalles bloqueo */
    const handleDetallesBloqueo = (bloqueo) => {
        setCitaAgregar(null);
        setCitaDetalles(null);
        setCitaEditar(null);
        setCitaEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setBloqueoDetalles(bloqueo);        
    }

    /* Editar bloqueo */
    const handleEditarBloqueo = (bloqueo) => {
        setCitaAgregar(null);
        setCitaDetalles(null);
        setCitaEditar(null);
        setCitaEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEliminar(null);

        setBloqueoEditar(bloqueo);
    }

    /* Eliminar bloqueo */
    const handleEliminarBloqueo = (bloqueo) => {
        setCitaAgregar(null);
        setCitaDetalles(null);
        setCitaEditar(null);
        setCitaEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);

        setBloqueoEliminar(bloqueo);
    }

    useEffect(() => {
        dispatch(buscarCitas({
            tipo: null,
            status: null,
            fecha_inicio: null,
            fecha_fin: null
        }));

        return () => {
            dispatch(obtenerCita(null));
        }
    }, []);

    useEffect(() => {
        let listado_eventos = [];

        for (var i = 0; i < listado_citas?.length; i++) {
            listado_eventos.push({
                id: listado_citas[i]?._id,
                fecha_inicio: listado_citas[i]?.fecha_inicio,
                fecha_fin: listado_citas[i]?.fecha_fin,
                titulo: listado_citas[i]?.titulo,
                tipo: listado_citas[i]?.tipo,
                joven: listado_citas[i]?.joven,
                asesor: listado_citas[i]?.asesor, 
                status: listado_citas[i]?.status
            });
        }

        setCitas(listado_eventos);
    }, [listado_citas]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <FiltrosCitas/>

            <NuevaCita cita={citaAgregar} reestablecerCita={handleAgregarCita} />
            <DetallesCita cita={citaDetalles} reestablecerCita={handleDetallesCita} detallesCompletosAsesoria={handleDetallesCompletosAsesoria} editarCita={handleEditarCita} eliminarCita={handleEliminarCita} />
            <EditarCita cita={citaEditar} reestablecerCita={handleEditarCita} />
            <EliminarCita cita={citaEliminar} reestablecerCita={handleEliminarCita} />

            <NuevoBloqueo bloqueo={bloqueoAgregar} reestablecerBloqueo={handleAgregarBloqueo} />
            <DetallesBloqueo bloqueo={bloqueoDetalles} reestablecerBloqueo={handleDetallesBloqueo} editarBloqueo={handleEditarBloqueo} eliminarBloqueo={handleEliminarBloqueo} />
            <EditarBloqueo bloqueo={bloqueoEditar} reestablecerBloqueo={handleEditarBloqueo} />
            <EliminarBloqueo bloqueo={bloqueoEliminar} reestablecerBloqueo={handleEliminarBloqueo} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Citas y bloqueos</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-warning me-2" onClick={() => handleAgregarBloqueo({ })}>Agregar bloqueo</button>
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleAgregarCita({ })}>Agregar cita</button>
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>
                        <Link to="/psicologica/citas_calendario"><button type="button" className="btn btn-secondary">Calendario</button></Link>
                    </div>

                    <div className="mt-4">
                        <DataTable 
                            columns={columns}
                            data={citas}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CitasListado;