import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { buscarJovenes, filtarJovenes } from '../../actions/jovenes';

import FiltrosJovenes from './FiltrosJovenes';
import EliminarJoven from './EliminarJoven';

const Jovenes = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Jovenes',
            path: '/jovenes'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.jovenes);

    const [jovenes, setJovenes] = useState([]);

    const [jovenEliminar, setJovenEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre || '-'
        },
        {
            name: 'Apellido Paterno',
            sortable: true, 
            selector: row => row.apellido_paterno || '-'
        },
        {
            name: 'Apellido Materno',
            sortable: true, 
            selector: row => row.apellido_materno || '-'
        },
        {
            name: 'Celular',
            sortable: true, 
            selector: row => row.celular || '-'
        },
        {
            name: 'Email',
            sortable: true, 
            selector: row => row.email || '-'
        },
        {
            name: 'CURP',
            sortable: true, 
            selector: row => row.curp || '-'
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarJoven(row)}><i className="fas fa-trash text-danger"></i></button>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/jovenes/detalles/${row._id}`}><i className="fas fa-user text-info"></i></Link>
                    </div>
                )
            }
        }
    ];

    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtarJovenes(true));
    }

    /* Eliminar joven */
    const handleEliminarJoven = (joven) => {
        setJovenEliminar(joven);
    }

    useEffect(() => {
        dispatch(buscarJovenes({
            nombre: null,
            apellido_paterno: null,
            apellido_materno: null,
            celular: null,
            email: null,
            curp: null
        }));
    }, []);

    useEffect(() => {
        setJovenes(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <FiltrosJovenes />
            <EliminarJoven joven={jovenEliminar} reestablecerJoven={handleEliminarJoven} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Jovenes</h3>        

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>
                    </div>            

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={jovenes}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Jovenes;