import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import { sortTableDateFechaInicio, sortTableDateFechaFin, sortTableStatusStatus } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { buscarReservaciones, obtenerReservacion, filtarReservaciones } from '../../actions/cabinas';

import FiltrosReservaciones from './FiltrosReservaciones';
import NuevaReservacion from './NuevaReservacion';
import DetallesReservacion from './DetallesReservacion';
import EditarReservacion from './EditarReservacion';
import EliminarReservacion from './EliminarReservacion';
import NuevoBloqueo from './NuevoBloqueo';
import DetallesBloqueo from './DetallesBloqueo';
import EditarBloqueo from './EditarBloqueo';
import EliminarBloqueo from './EliminarBloqueo';

const CabinaListado = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Cabina de grabación',
            path: '/cabina/listado'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.cabinas);

    const [reservaciones, setReservaciones] = useState([]);
    const [reservacionAgregar, setReservacionAgregar] = useState(null);
    const [reservacionDetalles, setReservacionDetalles] = useState(null);
    const [reservacionEditar, setReservacionEditar] = useState(null);
    const [reservacionEliminar, setReservacionEliminar] = useState(null);
    const [bloqueoAgregar, setBloqueoAgregar] = useState(null);
    const [bloqueoDetalles, setBloqueoDetalles] = useState(null);
    const [bloqueoEditar, setBloqueoEditar] = useState(null);
    const [bloqueoEliminar, setBloqueoEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Tipo',
            sortable: true, 
            selector: row => {
                if(row.tipo == 0){
                    return(
                        'Reservación'
                    );
                }else{
                    return(
                        'Bloqueo'
                    );
                }
            }
        },
        {
            name: 'Razón o responsable',
            sortable: true, 
            selector: row => row.titulo, 
            grow: 2
        },
        {
            name: 'Cabina',
            sortable: true, 
            selector: row => row.cabina ? row.cabina.nombre : 'Sin definir'
        },
        {
            name: 'Fecha de inicio', 
            sortable: true, 
            sortFunction: sortTableDateFechaInicio, 
            cell: item => new Date(item.fecha_inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' a las ' + new Date(item.fecha_inicio).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }), 
            grow: 2
        },
        {
            name: 'Fecha de finalización',
            sortable: true, 
            sortFunction: sortTableDateFechaFin, 
            cell: item => new Date(item.fecha_fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' a las ' + new Date(item.fecha_fin).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }), 
            grow: 2
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusStatus, 
            selector: row => {
                switch(row.status){
                    case 0:
                        return(
                            <h5><Badge pill bg="warning">Pendiente</Badge></h5>
                        )
                    case 1:
                        return(
                            <h5><Badge pill bg="success">Autorizada</Badge></h5>
                        )
                    case 2:
                        return(
                            <h5><Badge pill bg="danger">Rechazada</Badge></h5>
                        )
                    default:
                        break;
                }
            }
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleSeleccionarEvento(row)}><i className="fas fa-eye fa-sm text-info"></i></button>
                    </div>
                )
            }, 
            grow: 0.5
        }
    ];

    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtarReservaciones(true));
    }

    /* Seleccionar evento */
    const handleSeleccionarEvento = (evento) => {
        if(evento.tipo == 1){
            handleDetallesBloqueo({ 
                id: evento.id, 
                cabina: evento.cabina 
            });
        }else{
            handleDetallesReservacion({ 
                id: evento.id, 
                cabina: evento.cabina 
            });
        }
    }

    /* Crear reservacion */
    const handleAgregarReservacion = (reservacion) => {
        setReservacionDetalles(null);
        setReservacionEditar(null);
        setReservacionEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setReservacionAgregar(reservacion);        
    }

    /* Detalles reservacion */
    const handleDetallesReservacion = (reservacion) => {
        setReservacionAgregar(null);
        setReservacionEditar(null);
        setReservacionEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setReservacionDetalles(reservacion);        
    }

    /* Editar reservacion */
    const handleEditarReservacion = (reservacion) => {
        setReservacionAgregar(null);
        setReservacionDetalles(null);
        setReservacionEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setReservacionEditar(reservacion);        
    }

    /* Eliminar reservacion */
    const handleEliminarReservacion = (reservacion) => {
        setReservacionAgregar(null);
        setReservacionDetalles(null);
        setReservacionEditar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setReservacionEliminar(reservacion);        
    }

    /* Crear bloqueo */
    const handleAgregarBloqueo = (bloqueo) => {
        setReservacionAgregar(null);
        setReservacionDetalles(null);
        setReservacionEditar(null);
        setReservacionEliminar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setBloqueoAgregar(bloqueo);        
    }

    /* Detalles bloqueo */
    const handleDetallesBloqueo = (bloqueo) => {
        setReservacionAgregar(null);
        setReservacionDetalles(null);
        setReservacionEditar(null);
        setReservacionEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setBloqueoDetalles(bloqueo);        
    }

    /* Editar bloqueo */
    const handleEditarBloqueo = (bloqueo) => {
        setReservacionAgregar(null);
        setReservacionDetalles(null);
        setReservacionEditar(null);
        setReservacionEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEliminar(null);

        setBloqueoEditar(bloqueo);
    }

    /* Eliminar bloqueo */
    const handleEliminarBloqueo = (bloqueo) => {
        setReservacionAgregar(null);
        setReservacionDetalles(null);
        setReservacionEditar(null);
        setReservacionEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);

        setBloqueoEliminar(bloqueo);
    }

    useEffect(() => {
        dispatch(buscarReservaciones({
            tipo: null,
            cabina: null,
            status: null,
            fecha_inicio: null,
            fecha_fin: null
        }));

        return () => {
            dispatch(obtenerReservacion(null));
        }
    }, []);

    useEffect(() => {
        let listado_eventos = [];

        for (var i = 0; i < listado?.length; i++) {
            listado_eventos.push({
                id: listado[i]?._id,
                fecha_inicio: listado[i]?.fecha_inicio,
                fecha_fin: listado[i]?.fecha_fin,
                titulo: listado[i]?.titulo,
                tipo: listado[i]?.tipo,
                cabina: listado[i]?.cabina, 
                status: listado[i]?.status
            });
        }

        setReservaciones(listado_eventos);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <FiltrosReservaciones/>

            <NuevaReservacion reservacion={reservacionAgregar} reestablecerReservacion={handleAgregarReservacion} />
            <DetallesReservacion reservacion={reservacionDetalles} reestablecerReservacion={handleDetallesReservacion} editarReservacion={handleEditarReservacion} eliminarReservacion={handleEliminarReservacion} />
            <EditarReservacion reservacion={reservacionEditar} reestablecerReservacion={handleEditarReservacion} />
            <EliminarReservacion reservacion={reservacionEliminar} reestablecerReservacion={handleEliminarReservacion} />

            <NuevoBloqueo bloqueo={bloqueoAgregar} reestablecerBloqueo={handleAgregarBloqueo} />
            <DetallesBloqueo bloqueo={bloqueoDetalles} reestablecerBloqueo={handleDetallesBloqueo} editarBloqueo={handleEditarBloqueo} eliminarBloqueo={handleEliminarBloqueo} />
            <EditarBloqueo bloqueo={bloqueoEditar} reestablecerBloqueo={handleEditarBloqueo} />
            <EliminarBloqueo bloqueo={bloqueoEliminar} reestablecerBloqueo={handleEliminarBloqueo} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Reservaciones y bloqueos</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-warning me-2" onClick={() => handleAgregarBloqueo({ })}>Agregar bloqueo</button>
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleAgregarReservacion({ })}>Agregar reservación</button>
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>
                        <Link to="/cabina/calendario"><button type="button" className="btn btn-secondary">Calendario</button></Link>
                    </div>

                    <div className="mt-4">
                        <DataTable 
                            columns={columns}
                            data={reservaciones}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CabinaListado;