import React, { Fragment, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Components
import Alert from './components/layout/Alert';
import Layout from './components/layout/Layout';
import PrivateRoute from './components/routing/PrivateRoute';
import ValidateSession from './components/routing/ValidateSession';
import NoMatch from './components/routing/NoMatch';

// Login
import Login from './views/auth/Login';

// Dashboard
import Dashboard from './views/dashboard/Dashboard';

// Empresas
import Empresas from './views/empresas/Empresas';
import NuevaEmpresa from './views/empresas/NuevaEmpresa';
import EditarEmpresa from './views/empresas/EditarEmpresa';
import DetallesEmpresa from './views/empresas/DetallesEmpresa';
import UsuariosEmpresa from './views/empresas/UsuariosEmpresa';

// Jovenes
import Jovenes from './views/jovenes/Jovenes';
import DetallesJoven from './views/jovenes/DetallesJoven';

// Usuarios
import Usuarios from './views/usuarios/Usuarios';
import NuevoUsuario from './views/usuarios/NuevoUsuario';
import EditarUsuario from './views/usuarios/EditarUsuario';

// Promociones
import Promociones from './views/promociones/Promociones';
import DetallesPromocion from './views/promociones/DetallesPromocion';

// Eventos
import Eventos from './views/eventos/Eventos';
import NuevoEvento from './views/eventos/NuevoEvento';
import EditarEvento from './views/eventos/EditarEvento';
import DetallesEvento from './views/eventos/DetallesEvento';

// Programas
import Programas from './views/programas/Programas';
import NuevoPrograma from './views/programas/NuevoPrograma';
import EditarPrograma from './views/programas/EditarPrograma';
import DetallesPrograma from './views/programas/DetallesPrograma';
import DetallesSolicitudPrograma from './views/programas/DetallesSolicitud';
import ChatSolicitudPrograma from './views/programas/Chat';

// Apoyos
import Apoyos from './views/apoyos/Apoyos';
import NuevoApoyo from './views/apoyos/NuevoApoyo';
import EditarApoyo from './views/apoyos/EditarApoyo';
import DetallesApoyo from './views/apoyos/DetallesApoyo';
import DetallesSolicitudApoyo from './views/apoyos/DetallesSolicitud';
import ChatSolicitudApoyo from './views/apoyos/Chat';

// Becas
import Becas from './views/becas/Becas';
import NuevaBeca from './views/becas/NuevaBeca';
import EditarBeca from './views/becas/EditarBeca';
import DetallesBeca from './views/becas/DetallesBeca';
import DetallesSolicitudBeca from './views/becas/DetallesSolicitud';
import ChatSolicitudBeca from './views/becas/Chat';

//Asesoria Psicologica
import AsesoriasPsicologica from './views/psicologica/Asesorias';
import CitasPsicologicaCalendario from './views/psicologica/CitasCalendario';
import CitasPsicologicaListado from './views/psicologica/CitasListado';
import InformacionAsesoriaPsicologica from './views/psicologica/InformacionAsesoria';
import ChatPsicologica from './views/psicologica/Chat';

//Asesoria nutricional
import AsesoriasNutricional from './views/nutricional/Asesorias';
import CitasNutricionalCalendario from './views/nutricional/CitasCalendario';
import CitasNutricionalListado from './views/nutricional/CitasListado';
import InformacionAsesoriaNutricional from './views/nutricional/InformacionAsesoria';
import ChatNutricional from './views/nutricional/Chat';

//Asesoria deportiva
import AsesoriasDeportiva from './views/deportiva/Asesorias';
import CitasDeportivaCalendario from './views/deportiva/CitasCalendario';
import CitasDeportivaListado from './views/deportiva/CitasListado';
import InformacionAsesoriaDeportiva from './views/deportiva/InformacionAsesoria';
import ChatDeportiva from './views/deportiva/Chat';

// Gimnasio
import Gimnasio from './views/gimnasio/Gimnasio';
import AccesosGimnasio from './views/gimnasio/AccesosGimnasio';
import InscritosGimnasio from './views/gimnasio/InscritosGimnasio';

// Cabina de grabación
import CabinaCalendario from './views/cabina/CabinaCalendario';
import CabinaListado from './views/cabina/CabinaListado';

// Auditorio
import AuditorioCalendario from './views/auditorio/AuditorioCalendario';
import AuditorioListado from './views/auditorio/AuditorioListado';

// Reportes
import Reportes from './views/reportes/Reportes';

// Notificaciones
import Notificaciones from './views/notificaciones/Notificaciones';

// Configuración
import CategoriasEmpresas from './views/configuracion/categorias_empresas/CategoriasEmpresas';
import Extensiones from './views/configuracion/extensiones/Extensiones';
import Requerimientos from './views/configuracion/requerimientos/Requerimientos';
import ServiciosInstalaciones from './views/configuracion/tarjeta/ServiciosInstalaciones';
import Gimnasios from './views/configuracion/gimnasio/Gimnasios';
import Cabinas from './views/configuracion/cabina/Cabinas';
import Auditorios from './views/configuracion/auditorio/Auditorios';
import Asesores from './views/configuracion/asesores/Asesores';
import Perfil from './views/configuracion/perfil/Perfil';
import PerfilEmpresa from './views/configuracion/perfil_empresa/Perfil';

// Redux
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

function App() {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	useEffect(() => {
		store.dispatch(loadUser());
	}, []);

	return (
		<Provider store={store}>
			<BrowserRouter>
				<Fragment>
					<Layout></Layout>
					<section className="container-fluid principal-section">
						<Alert></Alert>

						<Routes>
							{/* Login */}
							<Route exact path="/" element={<ValidateSession><Login /></ValidateSession>}/>
							<Route exact path="/login" element={<ValidateSession><Login /></ValidateSession>}/>

							{/* Dashboard */}
							<Route path="/dashboard" element={<PrivateRoute routePermission=''><Dashboard /></PrivateRoute>}/>

							{/* Jovenes */}
							<Route path="/jovenes" element={<PrivateRoute routePermission='jovenes.ver'><Jovenes /></PrivateRoute>}/>
							<Route path="/jovenes/detalles/:id" element={<PrivateRoute routePermission='jovenes.detalles'><DetallesJoven /></PrivateRoute>}/>

							{/* Empresas */}
							<Route path="/empresas" element={<PrivateRoute routePermission='empresas.ver'><Empresas /></PrivateRoute>}/>
							<Route path="/empresas/nueva" element={<PrivateRoute routePermission='empresas.agregar'><NuevaEmpresa /></PrivateRoute>}/>
							<Route path="/empresas/editar/:id" element={<PrivateRoute routePermission='empresas.editar'><EditarEmpresa /></PrivateRoute>}/>
							<Route path="/empresas/detalles/:id" element={<PrivateRoute routePermission='empresas.detalles'><DetallesEmpresa /></PrivateRoute>}/>
							<Route path="/empresas/usuarios/:id" element={<PrivateRoute routePermission='empresas.usuarios.ver'><UsuariosEmpresa /></PrivateRoute>}/>

							{/* Usuarios */}
							<Route path="/usuarios" element={<PrivateRoute routePermission='usuarios.ver'><Usuarios /></PrivateRoute>}/>
							<Route path="/usuarios/nuevo" element={<PrivateRoute routePermission='usuarios.agregar'><NuevoUsuario /></PrivateRoute>}/>
							<Route path="/usuarios/editar/:id" element={<PrivateRoute routePermission='usuarios.editar'><EditarUsuario /></PrivateRoute>}/>

							{/* Promociones */}
							<Route path="/promociones" element={<PrivateRoute routePermission='promociones.ver'><Promociones /></PrivateRoute>}/>
							<Route path="/promociones/detalles/:id" element={<PrivateRoute routePermission='promociones.detalles'><DetallesPromocion /></PrivateRoute>}/>

							{/* Eventos */}
							<Route path="/eventos" element={<PrivateRoute routePermission='eventos.ver'><Eventos /></PrivateRoute>}/>
							<Route path="/eventos/nuevo" element={<PrivateRoute routePermission='eventos.agregar'><NuevoEvento /></PrivateRoute>}/>
							<Route path="/eventos/editar/:id" element={<PrivateRoute routePermission='eventos.editar'><EditarEvento /></PrivateRoute>}/>
							<Route path="/eventos/detalles/:id" element={<PrivateRoute routePermission='eventos.detalles'><DetallesEvento /></PrivateRoute>}/>

							{/* Programa */}
							<Route path="/programas" element={<PrivateRoute routePermission='programas.ver'><Programas /></PrivateRoute>}/>
							<Route path="/programas/nuevo" element={<PrivateRoute routePermission='programas.agregar'><NuevoPrograma /></PrivateRoute>}/>
							<Route path="/programas/editar/:id" element={<PrivateRoute routePermission='programas.editar'><EditarPrograma /></PrivateRoute>}/>
							<Route path="/programas/detalles/:id" element={<PrivateRoute routePermission='programas.detalles'><DetallesPrograma /></PrivateRoute>}/>
							<Route path="/programas/solicitud/:id" element={<PrivateRoute routePermission='programas.solicitudes.detalles'><DetallesSolicitudPrograma /></PrivateRoute>}/>
							<Route path="/programas/chat/:id" element={<PrivateRoute routePermission='programas.solicitudes.chat'><ChatSolicitudPrograma /></PrivateRoute>}/>

							{/* Apoyo */}
							<Route path="/apoyos" element={<PrivateRoute routePermission='apoyos.ver'><Apoyos /></PrivateRoute>}/>
							<Route path="/apoyos/nuevo" element={<PrivateRoute routePermission='apoyos.agregar'><NuevoApoyo /></PrivateRoute>}/>
							<Route path="/apoyos/editar/:id" element={<PrivateRoute routePermission='apoyos.editar'><EditarApoyo /></PrivateRoute>}/>
							<Route path="/apoyos/detalles/:id" element={<PrivateRoute routePermission='apoyos.detalles'><DetallesApoyo /></PrivateRoute>}/>
							<Route path="/apoyos/solicitud/:id" element={<PrivateRoute routePermission='apoyos.solicitudes.detalles'><DetallesSolicitudApoyo /></PrivateRoute>}/>
							<Route path="/apoyos/chat/:id" element={<PrivateRoute routePermission='apoyos.solicitudes.chat'><ChatSolicitudApoyo /></PrivateRoute>}/>

							{/* Beca */}
							<Route path="/becas" element={<PrivateRoute routePermission='becas.ver'><Becas /></PrivateRoute>}/>
							<Route path="/becas/nueva" element={<PrivateRoute routePermission='becas.agregar'><NuevaBeca /></PrivateRoute>}/>
							<Route path="/becas/editar/:id" element={<PrivateRoute routePermission='becas.editar'><EditarBeca /></PrivateRoute>}/>
							<Route path="/becas/detalles/:id" element={<PrivateRoute routePermission='becas.detalles'><DetallesBeca /></PrivateRoute>}/>
							<Route path="/becas/solicitud/:id" element={<PrivateRoute routePermission='becas.solicitudes.detalles'><DetallesSolicitudBeca /></PrivateRoute>}/>
							<Route path="/becas/chat/:id" element={<PrivateRoute routePermission='becas.solicitudes.chat'><ChatSolicitudBeca /></PrivateRoute>}/>

							{/* Asesoria psicologica */}
							<Route path="/psicologica" element={<PrivateRoute routePermission='asesoria_psicologica.ver'><AsesoriasPsicologica /></PrivateRoute>}/>
							<Route path="/psicologica/citas_calendario" element={<PrivateRoute routePermission='asesoria_psicologica.citas.ver'><CitasPsicologicaCalendario /></PrivateRoute>}/>
							<Route path="/psicologica/citas_listado" element={<PrivateRoute routePermission='asesoria_psicologica.citas.ver'><CitasPsicologicaListado /></PrivateRoute>}/>
							<Route path="/psicologica/detalles/:id" element={<PrivateRoute routePermission='asesoria_psicologica.detalles.ver'><InformacionAsesoriaPsicologica /></PrivateRoute>}/>
							<Route path="/psicologica/chat/:id" element={<PrivateRoute routePermission='asesoria_psicologica.chat'><ChatPsicologica /></PrivateRoute>}/>

							{/* Asesoria nutricional */}
							<Route path="/nutricional" element={<PrivateRoute routePermission='asesoria_nutricional.ver'><AsesoriasNutricional /></PrivateRoute>}/>
							<Route path="/nutricional/citas_calendario" element={<PrivateRoute routePermission='asesoria_nutricional.citas.ver'><CitasNutricionalCalendario /></PrivateRoute>}/>
							<Route path="/nutricional/citas_listado" element={<PrivateRoute routePermission='asesoria_nutricional.citas.ver'><CitasNutricionalListado /></PrivateRoute>}/>
							<Route path="/nutricional/detalles/:id" element={<PrivateRoute routePermission='asesoria_nutricional.detalles.ver'><InformacionAsesoriaNutricional /></PrivateRoute>}/>
							<Route path="/nutricional/chat/:id" element={<PrivateRoute routePermission='asesoria_nutricional.chat'><ChatNutricional /></PrivateRoute>}/>

							{/* Asesoria deportiva */}
							<Route path="/deportiva" element={<PrivateRoute routePermission='asesoria_deportiva.ver'><AsesoriasDeportiva /></PrivateRoute>}/>
							<Route path="/deportiva/citas_calendario" element={<PrivateRoute routePermission='asesoria_deportiva.citas.ver'><CitasDeportivaCalendario /></PrivateRoute>}/>
							<Route path="/deportiva/citas_listado" element={<PrivateRoute routePermission='asesoria_deportiva.citas.ver'><CitasDeportivaListado /></PrivateRoute>}/>
							<Route path="/deportiva/detalles/:id" element={<PrivateRoute routePermission='asesoria_deportiva.detalles.ver'><InformacionAsesoriaDeportiva /></PrivateRoute>}/>
							<Route path="/deportiva/chat/:id" element={<PrivateRoute routePermission='asesoria_deportiva.chat'><ChatDeportiva /></PrivateRoute>}/>
							
							{/* Gimnasio */}
							<Route path="/gimnasio" element={<PrivateRoute routePermission='gimnasio.accesar.ver'><Gimnasio /></PrivateRoute>}/>
							<Route path="/gimnasio/accesos" element={<PrivateRoute routePermission='gimnasio.historial_accesos'><AccesosGimnasio /></PrivateRoute>}/>
							<Route path="/gimnasio/inscritos" element={<PrivateRoute routePermission='gimnasio.inscritos.ver'><InscritosGimnasio /></PrivateRoute>}/>
							
							{/* Cabina de grabación */}
							<Route path="/cabina/calendario" element={<PrivateRoute routePermission='cabina.ver'><CabinaCalendario /></PrivateRoute>}/>
							<Route path="/cabina/listado" element={<PrivateRoute routePermission='cabina.ver'><CabinaListado /></PrivateRoute>}/>

							{/* Auditorio */}
							<Route path="/auditorio/calendario" element={<PrivateRoute routePermission='auditorio.ver'><AuditorioCalendario /></PrivateRoute>}/>
							<Route path="/auditorio/listado" element={<PrivateRoute routePermission='auditorio.ver'><AuditorioListado /></PrivateRoute>}/>

							{/* Reportes */}
							<Route path="/reportes" element={<PrivateRoute><Reportes /></PrivateRoute>}/>

							{/* Notificaciones */}
							<Route path="/notificaciones" element={<PrivateRoute><Notificaciones /></PrivateRoute>}/>

							{/* Configuración */}
							<Route path="/configuracion/categorias_empresas" element={<PrivateRoute routePermission='configuracion.categorias_empresas.ver'><CategoriasEmpresas /></PrivateRoute>}/>
							<Route path="/configuracion/extensiones" element={<PrivateRoute routePermission='configuracion.extensiones.ver'><Extensiones /></PrivateRoute>}/>
							<Route path="/configuracion/requerimientos" element={<PrivateRoute routePermission='configuracion.requerimientos.ver'><Requerimientos /></PrivateRoute>}/>
							<Route path="/configuracion/tarjeta_soluciones" element={<PrivateRoute routePermission='configuracion.tarjeta_soluciones.ver'><ServiciosInstalaciones /></PrivateRoute>}/>
							<Route path="/configuracion/gimnasio" element={<PrivateRoute routePermission='configuracion.gimnasio.ver'><Gimnasios /></PrivateRoute>}/>
							<Route path="/configuracion/cabina" element={<PrivateRoute routePermission='configuracion.cabina.ver'><Cabinas /></PrivateRoute>}/>
							<Route path="/configuracion/auditorio" element={<PrivateRoute routePermission='configuracion.auditorio.ver'><Auditorios /></PrivateRoute>}/>
							<Route path="/configuracion/asesores" element={<PrivateRoute routePermission='configuracion.asesores.ver'><Asesores /></PrivateRoute>}/>
							<Route path="/configuracion/perfil" element={<PrivateRoute routePermission='configuracion.perfil.ver'><Perfil /></PrivateRoute>}/>
							<Route path="/configuracion/perfil_empresa" element={<PrivateRoute routePermission='configuracion.perfil_empresa.ver'><PerfilEmpresa /></PrivateRoute>}/>
							
							{/* Cuando no matchea con nada*/}
							<Route path="*" element={<NoMatch />}/>
						</Routes>
					</section>
				</Fragment>
			</BrowserRouter>
		</Provider>
	);
}

export default App;