import React, { useState, useEffect, createRef, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { setAlert } from '../../actions/alert';
import { crearPromocion } from '../../actions/promociones';

const NuevaPromocion = ({ promocion, reestablecerPromocion }) => {

    const dispatch =  useDispatch();

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [promocionData, setPromocionData] = useState({
        nombre: null, 
        descripcion: null, 
        cupo: null, 
        limite: null, 
        canje: null, 
        imagen: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/imagen_placeholder.png'
    });

    const [imagePicture, setImagePicture] = useState({
        img: undefined
    });

    let img = createRef();

    /* Crear promociones */
    const handleChangeImg = () => {
        setImagePicture({
            ...imagePicture, 
            img: img.current.files[0]
        });
    }

    const handleInputChange = ({ target }) => {
        setPromocionData({
            ...promocionData,
            [target.name]: target.value
        });
    }

    const handleDatePicker = (value, nombre) => {
        setPromocionData({
            ...promocionData,
            [nombre]: value
        });
    }
    
    const handleProcesar = async () => {
        if(promocionData.nombre){
            await setStatusProcesar(true);

            let formData = new FormData();

            formData.append('nombre', promocionData.nombre);
            formData.append('descripcion', promocionData.descripcion);
            formData.append('cupo', promocionData.cupo);
            formData.append('limite', promocionData.limite);
            formData.append('canje', promocionData.canje);
            formData.append('portada', imagePicture.img);

            await dispatch(crearPromocion(formData));
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Completa todos los campos', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setPromocionData({
                nombre: null, 
                descripcion: null, 
                cupo: null, 
                limite: null, 
                canje: null, 
                imagen: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/imagen_placeholder.png'
            });

            setImagePicture({
                img: undefined
            });

            setNuevoModal(false);

            reestablecerPromocion(null);
        }
    }

    useEffect(() => {
        if(promocion){
            setNuevoModal(true);

            setPromocionData({
                ...promocionData
            });
        }        
    }, [promocion]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        input[type="file"] {
                            display: none;
                        }
                        
                        .custom-file-upload {
                            border-radius: 5%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            cursor: pointer;
                            background: #fff;
                            margin-bottom: 25px;
                            border: 5px solid #111F62;
                        }
                        
                        .img-wrap{
                            position: relative;
                            //width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 5%;
                        }

                        .img-upload:before{
                            content: "";
                            font-family: "Font Awesome 6 Free" !important;
                            font-weight: 900;
                            font-size: 90px;
                            position: absolute;
                            padding-top: 50px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-align: center;
                            color: #21B4E9;
                            width: 100%;
                            height: 200px;
                            border-radius: 5%;
                            opacity: 0;
                            transition: .5s ease;
                            background-color: #fff;
                        }

                        .img-upload:hover:before{
                            opacity: 1;
                        }

                        #img-photo {
                            width: auto;
                            height: 100%;
                        }

                        .selectIconos, .select-group {
                            height: 100%;
                        }

                        .delete {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 25% 25% 0 !important;
                            height: 100%;
                        }

                        #label_limite_check:before {
                            content: "";
                            display: inline-block;
                            height: 100%;
                            vertical-align: middle;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
            
            <Modal show={nuevoModal} size="lg" backdrop="static" centered onHide={() => handleCerrar()}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar promoción / descuento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 form-group text-center">
                            <label htmlFor="photo-upload" className="custom-file-upload fas">
                                <div className="img-wrap img-upload">
                                    <img id="img-photo" htmlFor="photo-upload" src={imagePicture.img != null ? URL.createObjectURL(imagePicture.img) : promocionData.imagen}/>
                                </div>
                                <input id="photo-upload" type="file" accept="image/*" ref={img} onChange={handleChangeImg}/> 
                            </label>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Titulo" id="nombre" name="nombre" value={promocionData.nombre} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="nombre">Titulo de la promocion</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Canjes totales" id="cupo" name="cupo" value={promocionData.cupo} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="cupo">Canjes totales</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <DatePicker
                                        id="limite"
                                        name="limite"
                                        className="form-control text-center input-datepícker"
                                        dateFormat="dd/MM/yyyy"
                                        showTimeInput
                                        minDate={new Date()}
                                        selected={ promocionData.limite != null ? new Date(promocionData.limite) : '' }
                                        onChange={ date => handleDatePicker(date, 'limite') }
                                    />
                                    <label className="label-datepicker" htmlFor="limite">Fecha de solicitud</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <DatePicker
                                        id="canje"
                                        name="canje"
                                        className="form-control text-center input-datepícker"
                                        dateFormat="dd/MM/yyyy"
                                        showTimeInput
                                        minDate={new Date()}
                                        selected={ promocionData.canje != null ? new Date(promocionData.canje) : '' }
                                        onChange={ date => handleDatePicker(date, 'canje') }
                                    />
                                    <label className="label-datepicker" htmlFor="canje">Fecha de canje</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <textarea className="form-control textareCampo" placeholder="Descripción" id="descripcion" name="descripcion" value={promocionData.descripcion} onChange={e => handleInputChange(e)} rows="3"></textarea>
                                    <label htmlFor="descripcion">Descripcion</label>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </Modal.Body>
                <Modal.Footer>
                    {
                        statusProcesar ?
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Agregar</button>
                        :
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Agregar</button>
                    }
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default NuevaPromocion;