import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { setAlert } from '../../actions/alert';
import { buscarProgramas } from '../../actions/programas';
import { buscarBecas } from '../../actions/becas';
import { buscarApoyos } from '../../actions/apoyos';
import { generarReporteSolicitudes } from '../../actions/reportes';

const FiltrosSolicitudes = ({ solicitudReporte, reestablecerFiltros }) => {

    const dispatch =  useDispatch();

    const { listado: programas } = useSelector(state => state.programas);
    const { listado: becas } = useSelector(state => state.becas);
    const { listado: apoyos } = useSelector(state => state.apoyos);

    const [statusProcesar, setStatusProcesar] = useState(false);

    const [busquedaData, setBusquedaData] = useState({
        tipo: null,
        programa: null,
        status: null,
        fecha_inicio: null,
        fecha_fin: null
    });

    const [tipoData, setTipoData] = useState(null);
    const tiposFilter = [
        { value: 0, label: 'Programa' }, 
        { value: 1, label: 'Beca' }, 
        { value: 2, label: 'Apoyo' }
    ];

    const [programaData, setProgramaData] = useState(null);
    const [programasFilter, setProgramasFilter] = useState([]);

    const [statusData, setStatusData] = useState(null);
    const statusFilter = [
        { value: 0, label: 'Pendiente de revisión' }, 
        { value: 1, label: 'En revisión' }, 
        { value: 2, label: 'Aprobada' }, 
        { value: 3, label: 'Rechazada' }
    ];

    /* Buscar */
    const handleAbrir = () => {}

    const handleDatePicker = (name, value) => {
        setBusquedaData({
            ...busquedaData,
            [name]: value
        });
    }

    const handleSelectTipo = (option) => {
        setTipoData(option);
        setProgramaData(null);

        setBusquedaData({
            ...busquedaData,
            tipo: option != null ? option.value : null, 
            programa: null
        });

        let valor_programa_buscar = null;
        let valor_beca_buscar = null;
        let valor_apoyo_buscar = null;

        let valor_busqueda_completa = {
            nombre: null,
            status: null, 
            fecha_inicio: null,
            fecha_fin: null
        };

        if(option){
            if(option.value == 0){
                valor_programa_buscar = valor_busqueda_completa;
            }
            
            if(option.value == 1){
                valor_beca_buscar = valor_busqueda_completa;
            }

            if(option.value == 2){
                valor_apoyo_buscar = valor_busqueda_completa;
            }
        }

        dispatch(buscarProgramas(valor_programa_buscar));
        dispatch(buscarBecas(valor_beca_buscar));
        dispatch(buscarApoyos(valor_apoyo_buscar));
    }

    const handleSelectPrograma = (option) => {
        setProgramaData(option);

        setBusquedaData({
            ...busquedaData,
            programa: option != null ? option.value : null
        });
    }

    const handleSelectStatus = (option) => {
        setStatusData(option);

        setBusquedaData({
            ...busquedaData,
            status: option != null ? option.value : null
        });
    }
    
    const handleProcesar = async () => {
        if(new Date(busquedaData.fecha_inicio) <= new Date(busquedaData.fecha_fin)){
            await setStatusProcesar(true);

            await dispatch(generarReporteSolicitudes(busquedaData));
                        
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Tu fecha de inicio es mayor a tu fecha de finalización', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setBusquedaData({
                tipo: null,
                programa: null,
                status: null,
                fecha_inicio: null,
                fecha_fin: null
            });

            setTipoData(null);
            setProgramaData(null);
            setStatusData(null);

            dispatch(buscarProgramas(null));
            dispatch(buscarBecas(null));
            dispatch(buscarApoyos(null));

            reestablecerFiltros(null);
        }
    }

    useEffect(() => {
        let options = [];

        for (var i = 0; i < programas?.length; i++) {
            options.push({ value: programas[i]._id, label: programas[i].nombre });
        }

        setProgramasFilter(options);
    }, [programas]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < becas?.length; i++) {
            options.push({ value: becas[i]._id, label: becas[i].nombre });
        }

        setProgramasFilter(options);
    }, [becas]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < apoyos?.length; i++) {
            options.push({ value: apoyos[i]._id, label: apoyos[i].nombre });
        }

        setProgramasFilter(options);
    }, [apoyos]);

    return (
        <Modal show={solicitudReporte} size="lg" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Filtros de búsqueda</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="tipo"
                                name="tipo"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar tipo de solicitud"
                                isClearable
                                options={tiposFilter}
                                value={tipoData}
                                onChange={(value) => handleSelectTipo(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-8 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="programa"
                                name="programa"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder={'Seleccionar ' + (tipoData ? (tipoData.value == 0 ? 'programa' : (tipoData.value == 1 ? 'beca' : 'apoyo')) : '(programa/beca/apoyo)')}
                                isClearable
                                options={programasFilter}
                                value={programaData}
                                onChange={(value) => handleSelectPrograma(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_inicio"
                                    name="fecha_inicio"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    locale="es-ES"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={ busquedaData.fecha_inicio != null ? new Date(busquedaData.fecha_inicio) : '' }
                                    onChange={ date => handleDatePicker('fecha_inicio', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_inicio">Fecha de inicio de solicitud</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_fin"
                                    name="fecha_fin"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    locale="es-ES"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={ busquedaData.fecha_fin != null ? new Date(busquedaData.fecha_fin) : '' }
                                    onChange={ date => handleDatePicker('fecha_fin', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_fin">Fecha de finalización de solicitud</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="status"
                                name="status"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar status"
                                isClearable
                                options={statusFilter}
                                value={statusData}
                                onChange={(value) => handleSelectStatus(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>   
                </div>                  
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Buscar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-magnifying-glass fa-sm"></i> Buscar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default FiltrosSolicitudes;