import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { formatNumber, sortTableNumberJovenes } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { buscarNotificaciones } from '../../actions/notificaciones';

import NuevaNotificacion from './NuevaNotificacion';

const Notificaciones = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Notificaciones',
            path: '/notificaciones'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.notificaciones);

    const [notificaciones, setNotificaciones] = useState([]);

    const [nuevaNotificacion, setNuevaNotificacion] = useState(false);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Remitente',
            sortable: true, 
            selector: row => row.usuario || '-', 
            grow: 1
        },
        {
            name: 'Título',
            sortable: true, 
            selector: row => row.titulo || '-', 
            grow: 1
        },
        {
            name: 'Mensaje',
            sortable: true, 
            selector: row => row.mensaje || '-', 
            grow: 3
        },
        {
            name: 'Jóvenes alcanzados',
            sortable: true, 
            sortFunction: sortTableNumberJovenes, 
            selector: row => formatNumber(row.jovenes, 0, '', ''), 
            grow: 1
        }
    ];

     /* Nueva */
     const handleNuevaNotificacion = (valor) => {
        setNuevaNotificacion(valor);
    }

    useEffect(() => {
        dispatch(buscarNotificaciones());
    }, []);

    useEffect(() => {
        setNotificaciones(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <NuevaNotificacion nuevaNotificacion={nuevaNotificacion} reestablecerNotificacion={handleNuevaNotificacion} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Notificaciones</h3>        

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleNuevaNotificacion(true)}>Enviar notificación</button>
                    </div>            

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={notificaciones}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Notificaciones;