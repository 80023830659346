import React, { useEffect, Fragment } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Badge } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { formatNumber, sortTableNumberCupo, sortTableNumberSolicitados, sortTableNumberRealizados, sortTableDateLimite, sortTableStatusStatus } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { obtenerEmpresa, limpiarEmpresa, cambioStatusEmpresa, cambioStatusPromocion} from '../../actions/empresas';

const DetallesEmpresa = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Empresas',
            path: '/empresas'
        },
        {
            activo: true,
            nombre: 'Perfil de empresa',
            path: '/empresas/detalles/'
        }
    ];

    const dispatch = useDispatch();

    const { id } = useParams();

    const { detalles } = useSelector(state => state.empresas);

    const tiposContactosFilter = [
        { value: 0, label: 'Teléfono', icon: 'fa-solid fa-phone' }, 
        { value: 1, label: 'WhatsApp', icon: 'fa-brands fa-whatsapp' }, 
        { value: 2, label: 'Facebook', icon: 'fa-brands fa-facebook' }, 
        { value: 3, label: 'Instagram', icon: 'fa-brands fa-instagram' }, 
        { value: 4, label: 'TikTok', icon: 'fa-brands fa-tiktok' }, 
        { value: 5, label: 'Twitter', icon: 'fa-brands fa-twitter' }, 
        { value: 6, label: 'Correo', icon: 'fa-solid fa-envelope' }, 
        { value: 7, label: 'Sitio web', icon: 'fa-solid fa-globe' }
    ];

    const [empresaData, setEmpresaData] = useState({
        indicadores_generales: {
            calificacion_promedio: 0,
            promociones_realizadas: 0,
            canjes_realizados: 0,
            porcentaje_canje: 0
        },
        informacion_personal: {
            _id: null,
            nombre: null,
            categoria_empresa: null,
            direccion: null,
            soluciones: false,
            descripcion: null,
            status: false,
            contactos: [],
            url_perfil: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
        },
        promociones: [],
        calificaciones: []
    });

    const {indicadores_generales, promociones, informacion_personal, calificaciones} = empresaData;

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const promocionesColumnas = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
        },
        {
            name: 'Cupo límite',
            sortable: true, 
            sortFunction: sortTableNumberCupo, 
            selector: row => row.cupo ? formatNumber(row.cupo, 0, '', '') : 'Sin limite'
        },
        {
            name: 'Fecha límite', 
            sortable: true, 
            sortFunction: sortTableDateLimite, 
            cell: item => item.limite ? new Date(item.limite).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin limite'
        },
        {
            name: 'Canjes solicitados',
            sortable: true, 
            sortFunction: sortTableNumberSolicitados, 
            selector: row => formatNumber(row.canjes_solicitados, 0, '', '')
        },
        {
            name: 'Canjes realizados',
            sortable: true, 
            sortFunction: sortTableNumberRealizados, 
            selector: row => formatNumber(row.canjes_realizados, 0, '', '')
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusStatus, 
            selector: row => {
                switch(row.status){
                    case 0:
                        return(
                            <h5><Badge pill bg="success">Activa</Badge></h5>
                        )
                    case 1:
                        return(
                            <h5><Badge pill bg="warning">Inactiva</Badge></h5>
                        )
                    case 2:
                        return(
                            <h5><Badge pill bg="danger">Finalizada</Badge></h5>
                        )
                    default:
                        break;
                }
            }
        }
    ];

    const calificacionesColumnas = [
        {
            name: 'Joven',
            sortable: true, 
            selector: row => row.joven ? [row.joven.nombre, row.joven.apellido_paterno, row.joven.apellido_materno].join(' ') : 'Sin definir', 
            grow: 2
        },
        {
            name: 'Comentario',
            sortable: true, 
            selector: row => row.comentario,
            grow: 5
        },
        {
            name: 'Calificación',
            sortable: true, 
            selector: row => row.calificacion
        }
    ];

    /* Funciones generales */
    const handleCambioStatus = () => {
        if(informacion_personal?.status){
            dispatch(cambioStatusEmpresa(id, false));
        }else{
            dispatch(cambioStatusEmpresa(id, true));
        }
    }

    const createFilasContactos = () => {

        let contactos_campos = [];

        for (var x = 0; x < empresaData.informacion_personal.contactos?.length; x++) {
            if(empresaData.informacion_personal.contactos[x].tipo != null && empresaData.informacion_personal.contactos[x].tipo != undefined){
                contactos_campos.push(
                    <div className="col-md-4 form-group" key={'contacto_' + x}>
                        <label className="fw-bold">{tiposContactosFilter[empresaData.informacion_personal.contactos[x].tipo]?.label}</label>
                        <p>{empresaData.informacion_personal.contactos[x].contenido}</p>
                    </div>
                );
            }
        }

        return contactos_campos;
    }

    useEffect(() => {
        dispatch(limpiarEmpresa());

        return () => {
            dispatch(limpiarEmpresa());
        }
    }, []);

    useEffect(() => {        
        if(id){
            dispatch(obtenerEmpresa(id));

            rutasBread[1].path = rutasBread[1].path + id;

            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [id]);

    useEffect(() => {
        if(detalles){
            setEmpresaData({
                ...empresaData,
                indicadores_generales: detalles.indicadores_generales, 
                informacion_personal: {
                    ...detalles.informacion_personal,
                    url_perfil: detalles.informacion_personal.url_perfil || process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
                }, 
                promociones: detalles.promociones, 
                calificaciones: detalles.calificaciones
            });
        }
    }, [detalles]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .contenedor_imagen_perfil {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            background: #fff;
                            border: 5px solid #111F62;
                        }
                        
                        .contenedor_imagen{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        #imagen_perfil {
                            width: auto;
                            height: 100%;
                            display: block;
                            position: absolute;
                            margin: auto;
                            top: -100%;
                            right: -100%;
                            bottom: -100%;
                            left: -100%;
                        }

                        .btn-badge-status{
                            background-color: #fff;
                            border: none;
                            outline: none;
                            padding: 0;
                        }

                        .valor_indicador {
                            font-size: 40px;
                            margin: 0;
                        }

                        .descripcion_indicador {
                            margin: 0;
                        }

                        .textareCampo {
                            height: 100px !important;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Perfil de empresa</h3>
                    
                    <div className="row mb-4">
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.calificacion_promedio, 0, '', '') }</p>
                            <p className="descripcion_indicador">Calificación</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.promociones_realizadas, 0, '', '') }</p>
                            <p className="descripcion_indicador">Promociones realizadas</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.canjes_realizados, 0, '', '') }</p>
                            <p className="descripcion_indicador">Canjes realizados</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.porcentaje_canje, 2, '', ' %') }</p>
                            <p className="descripcion_indicador">Promedio de canje</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ informacion_personal.soluciones ? 'Si' : 'No' }</p>
                            <p className="descripcion_indicador">Tarjeta soluciones requerida</p>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-3 py-3 text-center">
                            <label className="contenedor_imagen_perfil">
                                <div className="contenedor_imagen">
                                    <img id="imagen_perfil" src={ informacion_personal?.url_perfil }/>
                                </div>
                            </label>
                            <div className="mt-2 d-flex justify-content-center">
                                <button className="btn-badge-status" onClick={() => handleCambioStatus()}>
                                    <h3 className="text-muted">
                                        <Badge bg={informacion_personal?.status ? 'success' : 'danger'}>
                                            {informacion_personal?.status ? 'Activa' : 'Inactiva'}
                                        </Badge>
                                    </h3>
                                </button>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div className="accordion" id="accordionDetalles">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Información de perfil
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-7">
                                                <label className="fw-bold">Nombre</label>
                                                <p>{informacion_personal?.nombre}</p>
                                            </div>
                                            <div className="col-md-5">
                                                <label className="fw-bold">Categoria</label>
                                                <p>{informacion_personal?.categoria_empresa?.nombre}</p>
                                            </div>
                                            <div className="col-md-7">
                                                <label className="fw-bold">Dirección</label>
                                                <p>{informacion_personal?.direccion}</p>
                                            </div>
                                            <div className="col-md-5">
                                                <label className="fw-bold">Fecha de registro</label>
                                                <p>{new Date(informacion_personal?.creacion).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                            </div>

                                            { createFilasContactos() }

                                            <div className='col-md-12'>
                                                <label className='fw-bold'>Descripción</label>
                                                <p>{informacion_personal?.descripcion}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            Promociones realizadas
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body">
                                            <DataTable
                                                columns={promocionesColumnas}
                                                data={promociones}
                                                noDataComponent="No hay promociones registradas"
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                        Calificaciones y comentarios
                                    </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body">
                                            <DataTable
                                                columns={calificacionesColumnas}
                                                data={calificaciones}
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DetallesEmpresa;