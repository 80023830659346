import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Badge } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { formatNumber, sortTableStatusValorStatus } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { limpiarSolicitud, obtenerSolicitudCompleta, cambioStatusSolicitud, limpiarRequerimiento } from '../../actions/becas';

import DetallesRequerimiento from './DetallesRequerimiento';

const DetallesSolicitud = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Becas',
            path: '/becas'
        },
        {
            activo: false,
            nombre: 'Detalles de la beca',
            path: '/becas/detalles/'
        }, 
        {
            activo: true,
            nombre: 'Detalles de la solicitud',
            path: '/becas/solicitud/'
        }
    ];

    const dispatch = useDispatch();

    const { id } = useParams();

    const { detalles_solicitud } = useSelector(state => state.becas);

    const [solicitudData, setSolicitudData] = useState({
        id: null,
        id_beca: null,
        joven: null,
        fecha: null,
        status: false,
        mensajes_nuevos: 0,
        url_perfil: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png', 
        requerimientos: []
    });

    const [requerimientoDetalles, setRequerimientoDetalles] = useState(null);

    const requerimientosColumnas = [
        {
            name: 'Tipo',
            sortable: true, 
            selector: row => {
                switch(row.tipo){
                    case 0:
                        return( 'Texto corto' );
                    case 1:
                        return( 'Texto lago' );
                    case 2:
                        return( 'Numero' );
                    case 3:
                        return( 'Fecha' );
                    case 4:
                        return( 'Opciones' );
                    case 5:
                        return( 'Fotografia o imagen' );
                    case 6:
                        return( 'Archivo' );
                    default:
                        break;
                }
            }
        }, 
        {
            name: 'Obligatorio',
            sortable: true, 
            selector: row => row.obligatorio ? 'Si' : 'No'
        },
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
            grow: 3
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusValorStatus, 
            selector: row => {
                switch(row.valor?.status){
                    case 0:
                        return(
                            <h5><Badge pill bg="warning" text="dark">Pendiente de revisión</Badge></h5>
                        );
                    case 1:
                        return(
                            <h5><Badge pill bg="primary">En revisión</Badge></h5>
                        );
                    case 2:
                        return(
                            <h5><Badge pill bg="success">Aprobada</Badge></h5>
                        );
                    case 3:
                        return(
                            <h5><Badge pill bg="danger">Rechazado</Badge></h5>
                        );
                    case 4:
                        return(
                            <h5><Badge pill bg="danger">Vigencia expirada</Badge></h5>
                        );
                    default:
                        return(
                            <h5><Badge pill bg="secondary">Sin valor</Badge></h5>
                        );
                }
            },
            grow: 2
        }, 
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleDetallesRequerimiento({ _id: row._id })}><i className="fas fa-eye text-info"></i></button>
                    </div>
                )
            }
        }
    ];

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    /* Detalles de requerimiento */
    const handleDetallesRequerimiento = (requerimiento) => {
        if(requerimiento){
            setRequerimientoDetalles({
                id: requerimiento._id, 
                id_solicitud: id
            });
        }else{
            setRequerimientoDetalles(null);
        }      
    }

    /* Funciones generales */
    const handleCambioStatus = (status) => {
        dispatch(cambioStatusSolicitud({
            id: detalles_solicitud.id,
            status, 
            detalles: detalles_solicitud
        }));
    }

    const handkeCalculateEdad = (fecha) => {
        const today = new Date();
        const age = today.getFullYear() - fecha.getFullYear() - 
                   (today.getMonth() < fecha.getMonth() || 
                   (today.getMonth() === fecha.getMonth() && today.getDate() < fecha.getDate()));
        return age != 1 ? age + ' años' : age + ' año';
    }

    const handkeStatusSolicitud = (status) => {
        switch(status){
            case 0:
                return(
                    <h5><Badge pill bg="warning" text="dark">Pendiente de revisión</Badge></h5>
                );
            case 1:
                return(
                    <h5><Badge pill bg="primary">En revisión</Badge></h5>
                );
            case 2:
                return(
                    <h5><Badge pill bg="success">Aprobada</Badge></h5>
                );
            case 3:
                return(
                    <h5><Badge pill bg="danger">Rechazada</Badge></h5>
                );
            default:
                return(
                    <p></p>
                );
        }
    }

    const handleBotonStatusRequerimiento = (status) => {
        switch(status){
            case 0:
                return(
                    <button type="button" className="btn btn-primary" onClick={() => handleCambioStatus(1)}>
                         Comenzar revisión
                    </button>
                );

            case 1:
                return(
                    <Fragment>
                        <button type="button" className="btn btn-danger mx-1" onClick={() => handleCambioStatus(3)}>
                             Rechazar
                        </button>

                        <button type="button" className="btn btn-success mx-1" onClick={() => handleCambioStatus(2)}>
                             Aprobar
                        </button>
                    </Fragment>
                );

            case 2:
            case 3:
                return(
                    <button type="button" className="btn btn-primary" onClick={() => handleCambioStatus(1)}>
                         Revisar nuevamente
                    </button>
                );

            default:
                return(
                    <p></p>
                );
        }
    }

    useEffect(() => {
        dispatch(limpiarSolicitud());
        dispatch(limpiarRequerimiento());

        return () => {
            dispatch(limpiarSolicitud());
            dispatch(limpiarRequerimiento());
        }
    }, []);

    useEffect(() => {
        dispatch(obtenerSolicitudCompleta(id));

        if(id){
            rutasBread[2].path = rutasBread[2].path + id;

            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [id]);

    useEffect(() => {
        if(detalles_solicitud){
            rutasBread[1].path = rutasBread[1].path + detalles_solicitud.id_beca;

            dispatch(editarBreadcrumb(rutasBread));

            setSolicitudData({
                ...solicitudData, 
                id: detalles_solicitud._id, 
                id_beca: detalles_solicitud.id_beca, 
                joven: detalles_solicitud.joven, 
                fecha: detalles_solicitud.fecha, 
                status: detalles_solicitud.status, 
                mensajes_nuevos: detalles_solicitud.mensajes_nuevos, 
                url_perfil: detalles_solicitud.url_perfil || process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png', 
                requerimientos: detalles_solicitud.requerimientos
            });
        }
    }, [detalles_solicitud]); 

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .contenedor_imagen_perfil {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            background: #fff;
                            border: 5px solid #111F62;
                        }
                        
                        .contenedor_imagen{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        #imagen_perfil {
                            width: auto;
                            height: 100%;
                        }

                        .btn-badge-status{
                            background-color: #fff;
                            border: none;
                            outline: none;
                            padding: 0;
                        }

                        .valor_indicador {
                            font-size: 40px;
                            margin: 0;
                        }

                        .descripcion_indicador {
                            margin: 0;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <DetallesRequerimiento requerimiento={requerimientoDetalles} reestablecerRequerimiento={handleDetallesRequerimiento} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Detalles de la solicitud</h3>

                    <div className="d-flex justify-content-end">
                        <Link to={'/becas/chat/' + id}><button type="button" className="btn btn-primary me-2">Chat</button></Link>
                    </div>
                    
                    <div className="row my-5">
                        <div className="col-md-3 py-3 text-center">
                            <label className="contenedor_imagen_perfil">
                                <div className="contenedor_imagen">
                                    <img id="imagen_perfil" src={ solicitudData.url_perfil }/>
                                </div>
                            </label>
                            <div className="mt-2 d-flex justify-content-center">
                                { handleBotonStatusRequerimiento(solicitudData.status) }
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div className="accordion" id="accordionDetalles">

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Información de la solicitud
                                        </button>
                                    </h2>
                                    
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-4">
                                                <label className="fw-bold">Solicitante</label>
                                                <p>{solicitudData.joven ? [solicitudData.joven.nombre, solicitudData.joven.apellido_paterno, solicitudData.joven.apellido_materno].join(' ') : 'Sin definir'}</p>
                                            </div>

                                            <div className="col-md-4">
                                                <label className="fw-bold">Fecha de solcitud</label>
                                                <p>{solicitudData.fecha ? new Date(solicitudData.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' a las ' + new Date(solicitudData.fecha).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Sin definir'}</p>
                                            </div>

                                            <div className="col-md-4">
                                                <label className="fw-bold">Mensajes sin leer</label>
                                                <p>{formatNumber(solicitudData.mensajes_nuevos, 0, '', solicitudData.mensajes_nuevos != 1 ? ' mensajes' : ' mensaje')}</p>
                                            </div>

                                            <div className="col-md-4">
                                                <label className="fw-bold">Fecha de nacimiento</label>
                                                <p>{solicitudData.joven?.nacimiento ? new Date(solicitudData.joven.nacimiento).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                                            </div>

                                            <div className="col-md-4">
                                                <label className="fw-bold">Edad</label>
                                                <p>{solicitudData.joven?.nacimiento ? handkeCalculateEdad(new Date(solicitudData.joven.nacimiento)) : 'Sin definir'}</p>
                                            </div>

                                            <div className="col-md-4">
                                                <label className="fw-bold">CURP</label>
                                                <p>{solicitudData.joven?.curp || 'Sin definir'}</p>
                                            </div>

                                            <div className="col-md-4">
                                                <label className="fw-bold">Número de celular</label>
                                                <p>{solicitudData.joven?.celular || 'Sin definir'}</p>
                                            </div>

                                            <div className="col-md-4">
                                                <label className="fw-bold">Email</label>
                                                <p>{solicitudData.joven?.email || 'Sin definir'}</p>
                                            </div>

                                            <div className="col-md-4">
                                                <label className="fw-bold">Status</label>
                                                <p>{ handkeStatusSolicitud(solicitudData.status) }</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            Información de los requerimientos
                                        </button>
                                    </h2>
                                    
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="accordion-body">
                                                <DataTable
                                                    columns={requerimientosColumnas}
                                                    data={solicitudData.requerimientos}
                                                    noDataComponent="No hay requerimientos registrados"
                                                    pagination
                                                    paginationPerPage={15}
                                                    paginationRowsPerPageOptions={[15, 30, 50]}
                                                    paginationComponentOptions={paginationOptions}
                                                    highlightOnHover={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DetallesSolicitud;