import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Link } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment-timezone';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { buscarReservaciones, obtenerReservacion } from '../../actions/auditorios';

import { messages } from '../../utils/calendar-messages-español';
import { CalendarEvent } from '../../components/big_calendar/CalendarEvent';

import NuevaReservacion from './NuevaReservacion';
import DetallesReservacion from './DetallesReservacion';
import EditarReservacion from './EditarReservacion';
import EliminarReservacion from './EliminarReservacion';
import NuevoBloqueo from './NuevoBloqueo';
import DetallesBloqueo from './DetallesBloqueo';
import EditarBloqueo from './EditarBloqueo';
import EliminarBloqueo from './EliminarBloqueo';

moment.locale('es');
moment.tz.setDefault('America/Mexico_City');

const AuditorioCalendario = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Auditorio',
            path: '/auditorio'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.auditorios);

    const [lastView, setLastView] = useState(localStorage.getItem('lastView') || 'month');
    const [reservaciones, setReservaciones] = useState([]);
    const [reservacionAgregar, setReservacionAgregar] = useState(null);
    const [reservacionDetalles, setReservacionDetalles] = useState(null);
    const [reservacionEditar, setReservacionEditar] = useState(null);
    const [reservacionEliminar, setReservacionEliminar] = useState(null);
    const [bloqueoAgregar, setBloqueoAgregar] = useState(null);
    const [bloqueoDetalles, setBloqueoDetalles] = useState(null);
    const [bloqueoEditar, setBloqueoEditar] = useState(null);
    const [bloqueoEliminar, setBloqueoEliminar] = useState(null);

    const localizer = momentLocalizer(moment);

    /* Crear reservacion */
    const handleAgregarReservacion = (reservacion) => {
        setReservacionDetalles(null);
        setReservacionEditar(null);
        setReservacionEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setReservacionAgregar(reservacion);        
    }

    /* Detalles reservacion */
    const handleDetallesReservacion = (reservacion) => {
        setReservacionAgregar(null);
        setReservacionEditar(null);
        setReservacionEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setReservacionDetalles(reservacion);        
    }

    /* Editar reservacion */
    const handleEditarReservacion = (reservacion) => {
        setReservacionAgregar(null);
        setReservacionDetalles(null);
        setReservacionEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setReservacionEditar(reservacion);        
    }

    /* Eliminar reservacion */
    const handleEliminarReservacion = (reservacion) => {
        setReservacionAgregar(null);
        setReservacionDetalles(null);
        setReservacionEditar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setReservacionEliminar(reservacion);        
    }

    /* Crear bloqueo */
    const handleAgregarBloqueo = (bloqueo) => {
        setReservacionAgregar(null);
        setReservacionDetalles(null);
        setReservacionEditar(null);
        setReservacionEliminar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setBloqueoAgregar(bloqueo);        
    }

    /* Detalles bloqueo */
    const handleDetallesBloqueo = (bloqueo) => {
        setReservacionAgregar(null);
        setReservacionDetalles(null);
        setReservacionEditar(null);
        setReservacionEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setBloqueoDetalles(bloqueo);        
    }

    /* Editar bloqueo */
    const handleEditarBloqueo = (bloqueo) => {
        setReservacionAgregar(null);
        setReservacionDetalles(null);
        setReservacionEditar(null);
        setReservacionEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEliminar(null);

        setBloqueoEditar(bloqueo);
    }

    /* Eliminar bloqueo */
    const handleEliminarBloqueo = (bloqueo) => {
        setReservacionAgregar(null);
        setReservacionDetalles(null);
        setReservacionEditar(null);
        setReservacionEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);

        setBloqueoEliminar(bloqueo);
    }

    /* Funciones generales */
    const onViewChange = (view) => {
        setLastView(view);
        localStorage.setItem('lastView', view);
    }

    const eventStyleGetter = (event, start, end, isSelected) =>{
        const style = {
            backgroundColor: event.bgColor,
            borderRadius: '0px',
            opacity: 0.8,
            display: 'block',
            color: event.txColor
        }

        return  {
            style
        }
    }

    const onSelectEvent = (event) => {
        if(event.tipo == 1){
            handleDetallesBloqueo({ 
                id: event.id, 
                auditorio: event.auditorio 
            });
        }else{
            handleDetallesReservacion({ 
                id: event.id, 
                auditorio: event.auditorio 
            });
        }
    }

    useEffect(() => {
        dispatch(buscarReservaciones({
            tipo: null,
            auditorio: null,
            status: null,
            fecha_inicio: null,
            fecha_fin: null
        }));

        return () => {
            dispatch(obtenerReservacion(null));
        }
    }, []);

    useEffect(() => {
        let listado_eventos = [];

        for (var i = 0; i < listado?.length; i++) {
            listado_eventos.push({
                id: listado[i]?._id,
                start: moment(listado[i]?.fecha_inicio).toDate(),
                end: moment(listado[i]?.fecha_fin).toDate(),
                title: listado[i]?.titulo,
                tipo: listado[i]?.tipo,
                allDay: listado[i]?.allDay,
                auditorio: listado[i]?.auditorio,
                bgColor: listado[i]?.bgColor,
                txColor: listado[i]?.txColor
            });
        }

        setReservaciones(listado_eventos);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <NuevaReservacion reservacion={reservacionAgregar} reestablecerReservacion={handleAgregarReservacion} />
            <DetallesReservacion reservacion={reservacionDetalles} reestablecerReservacion={handleDetallesReservacion} editarReservacion={handleEditarReservacion} eliminarReservacion={handleEliminarReservacion} />
            <EditarReservacion reservacion={reservacionEditar} reestablecerReservacion={handleEditarReservacion} />
            <EliminarReservacion reservacion={reservacionEliminar} reestablecerReservacion={handleEliminarReservacion} />

            <NuevoBloqueo bloqueo={bloqueoAgregar} reestablecerBloqueo={handleAgregarBloqueo} />
            <DetallesBloqueo bloqueo={bloqueoDetalles} reestablecerBloqueo={handleDetallesBloqueo} editarBloqueo={handleEditarBloqueo} eliminarBloqueo={handleEliminarBloqueo} />
            <EditarBloqueo bloqueo={bloqueoEditar} reestablecerBloqueo={handleEditarBloqueo} />
            <EliminarBloqueo bloqueo={bloqueoEliminar} reestablecerBloqueo={handleEliminarBloqueo} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Auditorio</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-warning me-2" onClick={() => handleAgregarBloqueo({ })}>Agregar bloqueo</button>
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleAgregarReservacion({ })}>Agregar reservación</button>
                        <Link to="/auditorio/listado"><button type="button" className="btn btn-secondary">Listado</button></Link>
                    </div>
                </div>
                <div className="col-md-10 offset-md-1 pb-5">
                    <Calendar
                        components={{
                            event: CalendarEvent
                        }}
                        culture="es"
                        endAccessor="end"
                        eventPropGetter={eventStyleGetter}
                        events={reservaciones} 
                        localizer={localizer}
                        messages={messages}
                        onSelectEvent={onSelectEvent}
                        onView={onViewChange}
                        startAccessor="start"
                        style={{ height: '100vh' }}
                        view={lastView}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default AuditorioCalendario;