import axios from 'axios';

import { BECA } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

/* Becas */
export const buscarBecas = (filtros) => async dispatch => {
    if(filtros){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify(filtros);

        try{
            await dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/listar`, body, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: BECA.BUSCAR,
                payload: res.data.becas
            });
        } catch (error){ 
            await dispatch(changeLoader(false));

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        await dispatch({
            type: BECA.BUSCAR,
            payload: []
        });
    }
}

export const buscarRequerimientos = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/becas/requerimientos`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BECA.BUSCAR_REQUERIMIENTOS,
            payload: res.data.requerimientos
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearBeca = (beca) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/crear`, beca, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const informacionBeca = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/informacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BECA.DETALLES,
            payload: res.data.beca
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerBeca = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/detalles`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BECA.DETALLES,
            payload: res.data.beca
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarBeca = () => dispatch => {
    dispatch({
        type: BECA.DETALLES,
        payload: null
    });
}

export const editarBeca = (beca) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/editar`, beca, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const cambioStatus = (id, status, detalles) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id, 
        status
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/status`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BECA.DETALLES,
            payload: {
                ...detalles, 
                informacion_detallada: {
                    ...detalles.informacion_detallada, 
                    status
                }
            }
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarBeca = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/eliminar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BECA.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const filtrarBecas = (opcion) => dispatch => {
    dispatch({
        type: BECA.FILTROS,
        payload: opcion
    });
}

/* Solicitudes */
export const buscarSolicitudes = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(filtros);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/listar_solicitudes`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BECA.BUSCAR_SOLICITUDES,
            payload: res.data.solicitudes
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerSolicitud = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/informacion_solicitud`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BECA.SOLICITUDES.DETALLES,
            payload: res.data.solicitud
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerSolicitudCompleta = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/detalles_solicitud`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BECA.SOLICITUDES.DETALLES,
            payload: res.data.solicitud
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarSolicitud = () => dispatch => {
    dispatch({
        type: BECA.SOLICITUDES.DETALLES,
        payload: null
    });
}

export const cambioStatusSolicitud = ({id, status, detalles}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id, 
        status
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/status_solicitud`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BECA.SOLICITUDES.DETALLES,
            payload: {
                ...detalles, 
                status
            }
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerMensajesSolicitud = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/listar_mensajes_solicitud`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BECA.SOLICITUDES.MENSAJES.LISTAR,
            payload: res.data.mensajes
        });
    } catch (error){         
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const createMensajeSolicitud = (mensaje) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(mensaje);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/crear_mensaje_solicitud`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BECA.SOLICITUDES.MENSAJES.CREAR,
            payload: res.data.mensaje
        });
    } catch (error){   
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerRequerimiento = (requerimiento) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(requerimiento);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/detalles_requerimiento`, body, config);
        await dispatch(changeLoader(false));

        dispatch({
            type: BECA.SOLICITUDES.REQUERIMIENTO.DETALLES,
            payload: res.data.requerimiento
        });
    } catch (error) {
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarRequerimiento = () => dispatch => {
    dispatch({
        type: BECA.ARCHIVOS.DETALLES,
        payload: null
    });
}

export const cambioStatusRequerimiento = ({id, id_requerimiento, id_solicitud, status}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id, 
        id_solicitud, 
        status
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/status_requerimiento`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BECA.SOLICITUDES.REQUERIMIENTO.STATUS,
            payload: {
                id: id_requerimiento, 
                status
            }
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const filtrarSolicitudes = (opcion) => dispatch => {
    dispatch({
        type: BECA.SOLICITUDES.FILTROS,
        payload: opcion
    });
}

/* Archivos */
export const crearArchivo = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/crear_archivo`, archivo, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BECA.ARCHIVOS.LISTAR,
            payload: res.data.beca
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const editarArchivo = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/editar_archivo`, archivo, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BECA.ARCHIVOS.LISTAR,
            payload: res.data.beca
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const detallesArchivo = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(archivo);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/detalles_archivo`, body, config);
        await dispatch(changeLoader(false));

        dispatch({
            type: BECA.ARCHIVOS.DETALLES,
            payload: res.data.archivo
        });
    } catch (error) {
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarArchivo = () => dispatch => {
    dispatch({
        type: BECA.ARCHIVOS.DETALLES,
        payload: null
    });
}

export const eliminarArchivo = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(archivo);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/becas/eliminar_archivo`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BECA.ARCHIVOS.LISTAR,
            payload: res.data.beca
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}