import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';

import { logout } from '../../actions/auth';

const Navbar = () => {

    const dispatch = useDispatch();

    const { permisos } = useSelector(state => state.auth.usuario);

    const handleLogout = () => {
        dispatch(logout());
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
            <div className="container-fluid">
                <div className="w-100">
                    <Link className='navbar-brand' to='/dashboard'>
                        <img src={process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/logo_IAJU-Blanco2.png'} alt="logo" height="35" style={{ marginRight: '0.5rem', verticalAlign: 'baseline' }} />
                    </Link>
                    <button className="navbar-toggler float-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="collapse navbar-collapse d-lg-flex justify-content-lg-center px-3" id="navbarNavDropdown">
                    <ul className="navbar-nav text-right">
                        <li className="nav-item">
                            <NavLink className={'nav-link'} to='/dashboard'>Inicio</NavLink>
                        </li>
                        {
                            permisos?.jovenes?.ver ?
                                <li className="nav-item"><NavLink className={'nav-link'} to='/jovenes'>Jovenes</NavLink></li>
                            :
                                null
                        }
                        {
                            permisos?.empresas?.ver ?
                                <li className="nav-item"><NavLink className={'nav-link'} to='/empresas'>Empresas</NavLink></li>
                            :
                                null
                        }
                        {
                            permisos?.usuarios?.ver ?
                                <li className="nav-item"><NavLink className={'nav-link'} to='/usuarios'>Usuarios</NavLink></li>
                            :
                                null
                        }
                        {
                            permisos?.promociones?.ver ?
                                <li className="nav-item"><NavLink className={'nav-link'} to='/promociones'>Promociones</NavLink></li>
                            :
                                null
                        }
                        {
                            permisos?.eventos?.ver || permisos?.programas?.ver || permisos?.apoyos?.ver || permisos?.becas?.ver ?
                                <li className="nav-item dropdown mr-3">
                                    <a className={'nav-link dropdown-toggle'} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Eventos y programas
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                                        {
                                            permisos?.eventos?.ver ?
                                                <li><NavLink className={'dropdown-item'} to='/eventos'>Eventos</NavLink></li>
                                            :
                                                null
                                        }
                                        {
                                            permisos?.programas?.ver ?
                                                <li><NavLink className={'dropdown-item'} to='/programas'>Programas</NavLink></li>
                                            :
                                                null
                                        }
                                        {
                                            permisos?.apoyos?.ver ?
                                                <li><NavLink className={'dropdown-item'} to='/apoyos'>Apoyos</NavLink></li>
                                            :
                                                null
                                        }
                                        {
                                            permisos?.becas?.ver ?
                                                <li><NavLink className={'dropdown-item'} to='/becas'>Becas</NavLink></li>
                                            :
                                                null
                                        }
                                    </ul>
                                </li>
                            :
                                null
                        }
                        {
                            permisos?.reportes?.ver ?
                                <li className="nav-item"><NavLink className={'nav-link'} to='/reportes'>Reportes</NavLink></li>
                            :
                                null
                        }
                        {
                            permisos?.notificaciones?.ver ?
                                <li className="nav-item"><NavLink className={'nav-link'} to='/notificaciones'>Notificaciones</NavLink></li>
                            :
                                null
                        }
                        {
                            permisos?.asesoria_deportiva?.ver || permisos?.asesoria_psicologica?.ver || permisos?.asesoria_nutricional?.ver ?
                                <li className="nav-item dropdown mr-3">
                                    <a className={'nav-link dropdown-toggle'} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Asesorias
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                                        {
                                            permisos?.asesoria_deportiva?.ver ?
                                                <li><NavLink className={'dropdown-item'} to='/deportiva'>Deportiva</NavLink></li>
                                            :
                                                null
                                        }
                                        {
                                            permisos?.asesoria_psicologica?.ver ?
                                                <li><NavLink className={'dropdown-item'} to='/psicologica'>Psicológica</NavLink></li>
                                            :
                                                null
                                        }
                                        {
                                            permisos?.asesoria_nutricional?.ver ?
                                                <li><NavLink className={'dropdown-item'} to='/nutricional'>Nutricional</NavLink></li>
                                            :
                                                null
                                        }
                                    </ul>
                                </li>
                            :
                                null
                        }
                        {
                            permisos?.gimnasio?.accesar?.ver || permisos?.cabina?.ver || permisos?.auditorio?.ver ?
                                <li className="nav-item dropdown mr-3">
                                    <a className={'nav-link dropdown-toggle'} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Instalaciones
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                                        {
                                            permisos?.gimnasio?.accesar?.ver ?
                                                <li><NavLink className={'dropdown-item'} to='/gimnasio'>Gimnasio</NavLink></li>
                                            :
                                                null
                                        }
                                        {
                                            permisos?.cabina?.ver ?
                                                <li><NavLink className={'dropdown-item'} to='/cabina/calendario'>Cabinas de grabación</NavLink></li>
                                            :
                                                null
                                        }
                                        {
                                            permisos?.auditorio?.ver ?
                                                <li><NavLink className={'dropdown-item'} to='/auditorio/calendario'>Auditorio</NavLink></li>
                                            :
                                                null
                                        }
                                    </ul>
                                </li>
                            :
                                null
                        }
                        <li className="nav-item dropdown mr-3">
                            <a className={'nav-link dropdown-toggle'} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Configuración
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                                {
                                    permisos?.configuracion?.categorias_empresas?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/configuracion/categorias_empresas'>Categorias de empresas</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.configuracion?.extensiones?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/configuracion/extensiones'>Extensiones</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.configuracion?.requerimientos?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/configuracion/requerimientos'>Requerimientos</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.configuracion?.tarjeta_soluciones?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/configuracion/tarjeta_soluciones'>Tarjeta soluciones</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.configuracion?.gimnasio?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/configuracion/gimnasio'>Gimnasio</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.configuracion?.cabina?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/configuracion/cabina'>Cabina de grabación</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.configuracion?.auditorio?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/configuracion/auditorio'>Auditorio</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.configuracion?.asesores?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/configuracion/asesores'>Asesores</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.configuracion?.perfil?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/configuracion/perfil'>Perfil</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.configuracion?.perfil_empresa?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/configuracion/perfil_empresa'>Perfil de empresa</NavLink></li>
                                    :
                                        null
                                }
                                <li><a className="dropdown-item" onClick={handleLogout} href="/">Cerrar sesión</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;