import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

import { buscarGimnasios, buscarAccesos } from '../../actions/gimnasios';

const FiltrosAccesos = ({ busqueda, reestablecerFiltros }) => {

    const dispatch =  useDispatch();

    const { listado_gimnasios } = useSelector(state => state.gimnasios);

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [busquedaData, setBusquedaData] = useState({
        fecha: new Date(),
        id_gimnasio: null,
        gimnasio: null, 
        horario_total: true, 
        hora_inicio: null,
        hora_fin: null, 
        busqueda: false
    });

    const [gimnasioData, setGimnasioData] = useState(null);
    const [gimnasiosFilter, setGimnasiosFilter] = useState([]);

    /* Buscar */
    const handleDatePicker = (name, value) => {
        setBusquedaData({
            ...busquedaData,
            [name]: value
        });
    }

    const handleSelectGimnasio = (option) => {
        setGimnasioData(option);

        setBusquedaData({
            ...busquedaData,
            id_gimnasio: option != null ? option.value : null, 
            gimnasio: option != null ? option.label : null
        });
    }

    const handleCheckbox = ({ target }) => {
        if(target.checked){
            setBusquedaData({
                ...busquedaData,
                horario_total: true, 
                hora_inicio: null,
                hora_fin: null
            });
        }else{
            setBusquedaData({
                ...busquedaData,
                horario_total: false, 
                hora_inicio: new Date(),
                hora_fin: new Date()
            });
        }
    }

    const handleTimePicker = (hora, tipo) => {
        if(tipo == 1){
            setBusquedaData({
                ...busquedaData,
                hora_inicio: hora
            });
        }else{
            setBusquedaData({
                ...busquedaData,
                hora_fin: hora
            });
        }
    }
    
    const handleProcesar = async () => {
        await setStatusProcesar(true);
        await dispatch(buscarAccesos(busquedaData));
        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setNuevoModal(false);
            reestablecerFiltros({
                ...busquedaData, 
                busqueda: false
            });
        }
    }

    useEffect(() => {
        dispatch(buscarGimnasios());
    }, []);

    useEffect(() => {
        if(busqueda.busqueda){
            setNuevoModal(true);

            setBusquedaData({
                ...busquedaData, 
                ...busqueda
            });
        }        
    }, [busqueda]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_gimnasios?.length; i++) {
            options.push({ value: listado_gimnasios[i]._id, label: listado_gimnasios[i].nombre });
        }

        setGimnasiosFilter(options);
    }, [listado_gimnasios]);

    return (
        <Modal show={nuevoModal} size="lg" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Filtros de búsqueda</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha"
                                    name="fecha"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    locale="es-ES"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={ busquedaData.fecha != null ? new Date(busquedaData.fecha) : '' }
                                    onChange={ date => handleDatePicker('fecha', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha">Fecha de acceso</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="id_gimnasio"
                                name="id_gimnasio"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar gimnasio"
                                isClearable
                                options={gimnasiosFilter}
                                value={gimnasioData}
                                onChange={(value) => handleSelectGimnasio(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-4 form-group mb-3">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" id="horario_total" onChange={e => handleCheckbox(e)} checked={busquedaData.horario_total ? true : false}/>
                            <label className="form-check-label" htmlFor="horario_total">Todo el día</label>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="form-floating">
                            <TimePicker className="form-control text-center" clearAriaLabel="Reestablecer" dateFormat="h:m" value={busquedaData.hora_inicio || new Date()} onChange={time => handleTimePicker(time, 1)} disabled={busquedaData.horario_total ? true : false} />
                            <label className="font-weight-bold">Hora inicial</label>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="form-floating">
                            <TimePicker className="form-control text-center" clearAriaLabel="Reestablecer" dateFormat="h:m" value={busquedaData.hora_fin || new Date()} onChange={time => handleTimePicker(time, 2)} disabled={busquedaData.horario_total ? true : false} />
                            <label className="font-weight-bold">Hora final</label>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Buscar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-magnifying-glass fa-sm"></i> Buscar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default FiltrosAccesos;