import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment-timezone';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { buscarCitas, obtenerCita } from '../../actions/deportiva';

import { messages } from '../../utils/calendar-messages-español';
import { CalendarEvent } from '../../components/big_calendar/CalendarEvent';

import NuevaCita from './NuevaCita';
import DetallesCita from './DetallesCita';
import EditarCita from './EditarCita';
import EliminarCita from './EliminarCita';
import NuevoBloqueo from './NuevoBloqueo';
import DetallesBloqueo from './DetallesBloqueo';
import EditarBloqueo from './EditarBloqueo';
import EliminarBloqueo from './EliminarBloqueo';

moment.locale('es');
moment.tz.setDefault('America/Mexico_City');

const CitasCalendario = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Asesorias deportivas',
            path: '/deportiva'
        },
        {
            activo: true,
            nombre: 'Citas',
            path: '/deportiva/citas_calendario'
        }
    ];

    const dispatch =  useDispatch();
    const navigate = useNavigate();

    const { listado_citas } = useSelector(state => state.deportiva);

    const [lastView, setLastView] = useState(localStorage.getItem('lastView') || 'month');
    const [citas, setCitas] = useState([]);
    const [citaAgregar, setCitaAgregar] = useState(null);
    const [citaDetalles, setCitaDetalles] = useState(null);
    const [citaEditar, setCitaEditar] = useState(null);
    const [citaEliminar, setCitaEliminar] = useState(null);
    const [bloqueoAgregar, setBloqueoAgregar] = useState(null);
    const [bloqueoDetalles, setBloqueoDetalles] = useState(null);
    const [bloqueoEditar, setBloqueoEditar] = useState(null);
    const [bloqueoEliminar, setBloqueoEliminar] = useState(null);

    const localizer = momentLocalizer(moment);

    /* Crear cita */
    const handleAgregarCita = (cita) => {
        setCitaDetalles(null);
        setCitaEditar(null);
        setCitaEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setCitaAgregar(cita);        
    }

    /* Detalles cita */
    const handleDetallesCita = (cita) => {
        setCitaAgregar(null);
        setCitaEditar(null);
        setCitaEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setCitaDetalles(cita);        
    }

    /* Detalles completos asesoria */
    const handleDetallesCompletosAsesoria = (asesoria) => {
        setCitaAgregar(null);
        setCitaDetalles(null);
        setCitaEditar(null);
        setCitaEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);
        
        navigate('/deportiva/detalles/' + asesoria);
    }

    /* Editar cita */
    const handleEditarCita = (cita) => {
        setCitaAgregar(null);
        setCitaDetalles(null);
        setCitaEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setCitaEditar(cita);        
    }

    /* Eliminar cita */
    const handleEliminarCita = (cita) => {
        setCitaAgregar(null);
        setCitaDetalles(null);
        setCitaEditar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setCitaEliminar(cita);        
    }

    /* Crear bloqueo */
    const handleAgregarBloqueo = (bloqueo) => {
        setCitaAgregar(null);
        setCitaDetalles(null);
        setCitaEditar(null);
        setCitaEliminar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setBloqueoAgregar(bloqueo);        
    }

    /* Detalles bloqueo */
    const handleDetallesBloqueo = (bloqueo) => {
        setCitaAgregar(null);
        setCitaDetalles(null);
        setCitaEditar(null);
        setCitaEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoEditar(null);
        setBloqueoEliminar(null);

        setBloqueoDetalles(bloqueo);        
    }

    /* Editar bloqueo */
    const handleEditarBloqueo = (bloqueo) => {
        setCitaAgregar(null);
        setCitaDetalles(null);
        setCitaEditar(null);
        setCitaEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEliminar(null);

        setBloqueoEditar(bloqueo);
    }

    /* Eliminar bloqueo */
    const handleEliminarBloqueo = (bloqueo) => {
        setCitaAgregar(null);
        setCitaDetalles(null);
        setCitaEditar(null);
        setCitaEliminar(null);
        setBloqueoAgregar(null);
        setBloqueoDetalles(null);
        setBloqueoEditar(null);

        setBloqueoEliminar(bloqueo);
    }

    /* Funciones generales */
    const onViewChange = (view) => {
        setLastView(view);
        localStorage.setItem('lastView', view);
    }

    const eventStyleGetter = (event, start, end, isSelected) =>{
        const style = {
            backgroundColor: event.bgColor,
            borderRadius: '0px',
            opacity: 0.8,
            display: 'block',
            color: event.txColor
        }

        return  {
            style
        }
    }

    const onSelectEvent = (event) => {
        if(event.tipo == 1){
            handleDetallesBloqueo({ 
                id: event.id, 
                joven: event.joven, 
                asesor: event.asesor
            });
        }else{
            handleDetallesCita({ 
                id: event.id, 
                joven: event.joven, 
                asesor: event.asesor
            });
        }
    }

    useEffect(() => {
        dispatch(buscarCitas({
            tipo: null,
            status: null,
            fecha_inicio: null,
            fecha_fin: null
        }));

        return () => {
            dispatch(obtenerCita(null));
        }
    }, []);

    useEffect(() => {
        let listado_eventos = [];

        for (var i = 0; i < listado_citas?.length; i++) {
            listado_eventos.push({
                id: listado_citas[i]?._id,
                start: moment(listado_citas[i]?.fecha_inicio).toDate(),
                end: moment(listado_citas[i]?.fecha_fin).toDate(),
                title: listado_citas[i]?.titulo,
                tipo: listado_citas[i]?.tipo,
                allDay: listado_citas[i]?.allDay,
                joven: listado_citas[i]?.joven,
                asesor: listado_citas[i]?.asesor,
                bgColor: listado_citas[i]?.bgColor,
                txColor: listado_citas[i]?.txColor
            });
        }

        setCitas(listado_eventos);
    }, [listado_citas]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <NuevaCita cita={citaAgregar} reestablecerCita={handleAgregarCita} />
            <DetallesCita cita={citaDetalles} reestablecerCita={handleDetallesCita} detallesCompletosAsesoria={handleDetallesCompletosAsesoria} editarCita={handleEditarCita} eliminarCita={handleEliminarCita} />
            <EditarCita cita={citaEditar} reestablecerCita={handleEditarCita} />
            <EliminarCita cita={citaEliminar} reestablecerCita={handleEliminarCita} />

            <NuevoBloqueo bloqueo={bloqueoAgregar} reestablecerBloqueo={handleAgregarBloqueo} />
            <DetallesBloqueo bloqueo={bloqueoDetalles} reestablecerBloqueo={handleDetallesBloqueo} editarBloqueo={handleEditarBloqueo} eliminarBloqueo={handleEliminarBloqueo} />
            <EditarBloqueo bloqueo={bloqueoEditar} reestablecerBloqueo={handleEditarBloqueo} />
            <EliminarBloqueo bloqueo={bloqueoEliminar} reestablecerBloqueo={handleEliminarBloqueo} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Citas y bloqueos</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-warning me-2" onClick={() => handleAgregarBloqueo({ })}>Agregar bloqueo</button>
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleAgregarCita({ })}>Agregar cita</button>
                        <Link to="/deportiva/citas_listado"><button type="button" className="btn btn-secondary">Listado</button></Link>
                    </div>
                </div>
                <div className="col-md-10 offset-md-1 pb-5">
                    <Calendar
                        components={{
                            event: CalendarEvent
                        }}
                        culture="es"
                        endAccessor="end"
                        eventPropGetter={eventStyleGetter}
                        events={citas} 
                        localizer={localizer}
                        messages={messages}
                        onSelectEvent={onSelectEvent}
                        onView={onViewChange}
                        startAccessor="start"
                        style={{ height: '100vh' }}
                        view={lastView}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default CitasCalendario;