import { APOYO } from "../actions/types";

const initialState = {
    listado: [],
    listado_requerimientos: [],
    detalles: null, 
    detalles_solicitud: null, 
    detalles_requerimiento: null, 
    detalles_archivo: null, 
    ventana_filtros: false, 
    filtros: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case APOYO.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case APOYO.BUSCAR_REQUERIMIENTOS:
            return {
                ...state,
                listado_requerimientos: payload
            };

        case APOYO.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case APOYO.ELIMINAR:
            return {
                ...state,
                listado: state.listado.filter( apoyo => apoyo._id !== payload.id )
            };

        case APOYO.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };

        case APOYO.BUSCAR_SOLICITUDES:
            return {
                ...state,
                detalles: {
                    ...state.detalles, 
                    solicitudes: payload
                }
            };

        case APOYO.SOLICITUDES.DETALLES:
            return {
                ...state,
                detalles_solicitud: payload
            };

        case APOYO.SOLICITUDES.REQUERIMIENTO.DETALLES:
            return {
                ...state, 
                detalles_requerimiento: payload
            };

        case APOYO.SOLICITUDES.REQUERIMIENTO.STATUS:
            let solicitud_editar = state.detalles_solicitud;
            let requerimientos_editar = state.detalles_solicitud ? state.detalles_solicitud.requerimientos : [];
            requerimientos_editar.find( requerimiento => requerimiento._id === payload.id ).valor.status = payload.status;

            if(solicitud_editar){
                solicitud_editar = {
                    ...solicitud_editar, 
                    requerimientos: requerimientos_editar
                }
            }

            return {
                ...state,
                detalles_requerimiento: {
                    ...state.detalles_requerimiento, 
                    valor: {
                        ...state.detalles_requerimiento.valor, 
                        status: payload.status
                    }
                }, 
                detalles_solicitud: solicitud_editar
            };

        case APOYO.SOLICITUDES.MENSAJES.LISTAR:
            return {
                ...state, 
                detalles_solicitud: {
                    ...state.detalles_solicitud, 
                    mensajes: payload
                }
            };

        case APOYO.SOLICITUDES.MENSAJES.CREAR:
            let mensajes_agregar = state.detalles_solicitud.mensajes;

            mensajes_agregar = [
                ...mensajes_agregar, payload
            ];

            return {
                ...state, 
                detalles_solicitud: {
                    ...state.detalles_solicitud, 
                    mensajes: mensajes_agregar
                }
            };

        case APOYO.SOLICITUDES.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };

        case APOYO.ARCHIVOS.LISTAR:
            return {
                detalles: {
                    ...state.detalles,
                    informacion_detallada: {
                        ...state.detalles.informacion_detallada,
                        archivos: [
                            ...payload.archivos
                        ]
                    }
                }
            };

        case APOYO.ARCHIVOS.DETALLES:
            return {
                ...state,
                detalles_archivo: payload
            };

        default:
            return state;
    }
}