import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { MessageList } from 'react-chat-elements';
import "react-chat-elements/dist/main.css"

import { setAlert } from '../../actions/alert';
import { editarBreadcrumb } from '../../actions/breadcrumb';
import { obtenerCompletaAsesoria, obtenerMensajesAsesoria, createMensajeAsesoria } from '../../actions/psicologica';

const Chat = () => {
    let rutasBread = [
        {
            activo: false,
            nombre: 'Asesorias psicológicas',
            path: '/psicologica'
        },
        {
            activo: false,
            nombre: 'Detalles de asesoria',
            path: '/psicologica/detalles/'
        }, 
        {
            activo: true,
            nombre: 'Chat',
            path: '/psicologica/chat/'
        }
    ];
    
    const dispatch =  useDispatch();

    const { id } = useParams();

    const { detalles_asesoria } = useSelector(state => state.psicologica);

    const [conversacionData, setConversacionData] = useState({
        id: null,
        asesor: {
            id: null, 
            nombre: null, 
            apellido_paterno: null, 
            apellido_materno: null
        }, 
        joven: {
            id: null, 
            nombre: null, 
            apellido_paterno: null, 
            apellido_materno: null, 
            nacimiento: null, 
            celular: null, 
            curp: null, 
            email: null, 
            url_perfil: null
        }, 
        observaciones: null, 
        fecha_inicio: null, 
        fecha_fin: null, 
        status: null, 
        mensajes: []
    });

    const [mensajeData, setMensajeData] = useState({
        id: null,
        mensaje: ''
    });

    const containerRef = useRef(null);

    /* Enviar mensaje */
    const onChangeMensaje = (e) => {
        setMensajeData({
            ...mensajeData, 
            mensaje: e.target.value
        });
    }

    const handleKeyPress = (e) => {
        if(e.keyCode === 13){
            handleEnviarMensaje();
        }
    }

    const handleEnviarMensaje = async () => {
        if(mensajeData.id){
            if(mensajeData.mensaje){
                await dispatch(createMensajeAsesoria(mensajeData));

                await setMensajeData({
                    ...mensajeData, 
                    mensaje: ''
                });
            }else{
                dispatch(setAlert('No puedes mandar un mensaje vacio', 'danger'));
            }
        }else{
            dispatch(setAlert('No puedes mandar un mensaje sin asesoria', 'danger'));
        }
    }

    /* Funciones generales */
    const calculateEdad = (fecha) => {
        const today = new Date();
        const age = today.getFullYear() - fecha.getFullYear() - 
                   (today.getMonth() < fecha.getMonth() || 
                   (today.getMonth() === fecha.getMonth() && today.getDate() < fecha.getDate()));
        return age != 1 ? age + ' años' : age + ' año';
    }

    const handleStatus = (status) => {
        switch(status){
            case 0:
                return(
                    <h5><Badge pill bg="warning text-black">Pendiente</Badge></h5>
                )
            case 1:
                return(
                    <h5><Badge pill bg="success">Activa</Badge></h5>
                )

            case 2:
                return(
                    <h5><Badge pill bg="danger">Finalizada</Badge></h5>
                )
            default:
                return(
                    <h5><Badge pill bg="secondary">Sin definir</Badge></h5>
                )
                break;
        }
    }

    const handleStatusMin = (status) => {
        switch(status){
            case 0:
                return(
                    <h6><Badge pill bg="warning text-black">Pendiente</Badge></h6>
                )
            case 1:
                return(
                    <h6><Badge pill bg="success">Activa</Badge></h6>
                )

            case 2:
                return(
                    <h6><Badge pill bg="danger">Finalizada</Badge></h6>
                )
            default:
                return(
                    <h6><Badge pill bg="secondary">Sin definir</Badge></h6>
                )
                break;
        }
    }

    useEffect(() => {        
        if(id){
            setMensajeData({
                ...mensajeData, 
                id
            });

            dispatch(obtenerCompletaAsesoria(id));
            dispatch(obtenerMensajesAsesoria(id));

            rutasBread[1].path = rutasBread[1].path.charAt(rutasBread[1].path.length - 1) === '/' ? rutasBread[1].path + id : rutasBread[1].path;
            rutasBread[2].path = rutasBread[2].path.charAt(rutasBread[2].path.length - 1) === '/' ? rutasBread[2].path + id : rutasBread[2].path;

            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [id]);

    useEffect(() => {
        if(detalles_asesoria){

            let nuevos_datos = {
                ...conversacionData, 
                id: detalles_asesoria._id, 
                asesor: {
                    id: detalles_asesoria.usuario?._id, 
                    nombre: detalles_asesoria.usuario?.nombre, 
                    apellido_paterno: detalles_asesoria.usuario?.apellido_paterno, 
                    apellido_materno: detalles_asesoria.usuario?.apellido_materno
                }, 
                joven: {
                    id: detalles_asesoria.joven?.id, 
                    nombre: detalles_asesoria.joven?.nombre, 
                    apellido_paterno: detalles_asesoria.joven?.apellido_paterno, 
                    apellido_materno: detalles_asesoria.joven?.apellido_materno, 
                    nacimiento: detalles_asesoria.joven?.nacimiento, 
                    celular: detalles_asesoria.joven?.celular, 
                    curp: detalles_asesoria.joven?.curp, 
                    email: detalles_asesoria.joven?.email, 
                    url_perfil: detalles_asesoria.joven?.url_perfil || process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
                }, 
                observaciones: detalles_asesoria.observaciones, 
                fecha_inicio: detalles_asesoria.fecha_inicio, 
                fecha_fin: detalles_asesoria.fecha_fin, 
                status: detalles_asesoria.status
            };

            if(detalles_asesoria.mensajes){
                nuevos_datos.mensajes = detalles_asesoria.mensajes;
            }

            setConversacionData(nuevos_datos);
        }
    }, [detalles_asesoria]);

    useEffect(() => {
        if(containerRef && containerRef.current){
            const element = containerRef.current;
            element.scroll({
                top: element.scrollHeight,
                left: 0,
                behavior: "smooth"
            });
        }
    }, [containerRef, conversacionData]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        //Chat

                        .chat_general {
                            display: flex;
                            flex-direction: column;
                            min-height: 100%;
                        }
                        
                        .contenedor_chat {
                            align-items: center;
                            background-color: #fff;
                            color: #000;
                            color-scheme: light;
                            display: flex;
                            height: 86vh;
                            justify-content: space-between;
                            position: fixed;
                            width: 100%;
                        }
                        
                        .seccion_conversaciones {
                            border-right: solid #e7e7e7;
                            height: 100%;
                            width: 20%;
                        }
                        
                        .perfil_conversacion {
                            border-top: solid #e7e7e7;
                            height: 100%;
                            overflow-y: auto;
                            overflow-x: hidden;
                            width: 100%;
                            background-color: #dcf7fa;
                            word-break: break-all;
                        }
                        
                        .seccion_conversacion {
                            height: 100%;
                            width: 80%;
                        }
                        
                        .contenedor_conversacion {
                            height: 100%;
                            width: 100%;
                        }
                        
                        .contenedor_sin_conversacion {
                            height: 100%;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #dcf7fa;
                        }
                        
                        .header_conversacion {
                            align-items: center;
                            border-bottom: solid #e7e7e7;
                            display: flex;
                            height: 15%;
                            padding: 15px;
                        }
                        
                        .contenedor_imagen_conversacion {
                            border-radius: 100%;
                            align-items: center;
                            display: flex;
                            justify-content: center;
                            overflow: hidden;
                        }
                        
                        .contenedor_datos_conversacion {
                            width: 80%;
                        }
                        
                        .imagen_conversacion {
                            width: 60px;
                            height: 60px;
                            -webkit-border-radius: 60px;
                            -webkit-background-clip: padding-box;
                            -moz-border-radius: 60px;
                            -moz-background-clip: padding;
                            border-radius: 60px;
                            background-clip: padding-box;
                            margin: 7px 0 0 5px;
                            float: left;
                            background-size: cover;
                            background-position: center center;
                        }
                        
                        .titulo_conversacion {
                            font-weight: 700;
                            margin-left: 2%;
                        }
                        
                        .subtitulo_conversacion {
                            display: block;
                            font-size: small;
                            margin-left: 2%;
                        }
                        
                        .body_conversacion {
                            height: 70%;
                            overflow-y: scroll;
                            overflow-x: hidden;
                            border-bottom: solid #e7e7e7;
                            background-color: #e6dfdf;
                            width: 100%;
                        }
                        
                        .footer_conversacion {
                            align-items: flex-start;
                            display: flex;
                            height: 15%;
                            width: 100%;
                        }
                        
                        .contenedor_campo_envio {
                            align-items: center;
                            background: #fff;
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: row;
                            min-width: 100%;
                            padding: 20px;
                        }
                        
                        .campo_envio {
                            border: none;
                            border-radius: 5px;
                            box-sizing: border-box;
                            color: #333;
                            flex: 1;
                            font-size: 14px;
                            height: 40px;
                            outline: 0;
                            padding: 0 5px;
                            margin-right: 10px;
                        }

                        @media only screen and (max-width: 600px) {
                            .contenedor_chat {
                                padding-right: 20px;
                                height: 80vh;
                            }

                            .seccion_conversaciones {
                                visibility: hidden;
                                width: 0%;
                            }
                            
                            .seccion_conversacion {
                                width: 100%;
                            }
                        }

                        @media only screen and (min-width: 601px) and (max-width: 950px) {
                            .seccion_conversaciones {
                                width: 30%;
                            }
                            
                            .seccion_conversacion {
                                width: 70%;
                            }
                        }

                        @media only screen and (min-width: 601px) {
                            .header_conversacion {
                                visibility: hidden;
                                padding: 0px;
                                height: 0%;
                            }

                            .body_conversacion {
                                height: 85%;
                            }
                        }

                        //Perfil

                        .contenedor_imagen_perfil {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            background: #fff;
                            border: 5px solid #111F62;
                        }
                        
                        .contenedor_imagen{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                            background-color: #fff;
                        }

                        #imagen_perfil {
                            width: auto;
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="chat_general">
                <div className="contenedor_chat">
                    <div className="seccion_conversaciones">
                        <div className="perfil_conversacion">
                            <div className="col-md-12 px-2 row">
                                <div className="col-md-12 py-4 text-center">
                                    <label className="contenedor_imagen_perfil">
                                        <div className="contenedor_imagen">
                                            <img id="imagen_perfil" src={ conversacionData.joven.url_perfil }/>
                                        </div>
                                    </label>

                                    <div className="mt-2 d-flex justify-content-center">
                                        <p>{handleStatus(conversacionData.status)}</p>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <label className="fw-bold">Jóven</label>
                                    <p>{conversacionData.joven.nombre ? [conversacionData.joven.nombre, conversacionData.joven.apellido_paterno, conversacionData.joven.apellido_materno].join(' ') : 'Sin definir'}</p>
                                </div>

                                <div className="col-md-12">
                                    <label className="fw-bold">Fecha de nacimiento</label>
                                    <p>{conversacionData.joven.nacimiento ? new Date(conversacionData.joven.nacimiento).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                                </div>

                                <div className="col-md-12">
                                    <label className="fw-bold">Edad</label>
                                    <p>{conversacionData.joven.nacimiento ? calculateEdad(new Date(conversacionData.joven.nacimiento)) : 'Sin definir'}</p>
                                </div>

                                <div className="col-md-12">
                                    <label className="fw-bold">CURP</label>
                                    <p>{conversacionData.joven.curp || 'Sin definir'}</p>
                                </div>

                                <div className="col-md-12">
                                    <label className="fw-bold">Número de celular</label>
                                    <p>{conversacionData.joven.celular || 'Sin definir'}</p>
                                </div>

                                <div className="col-md-12">
                                    <label className="fw-bold">Email</label>
                                    <p>{conversacionData.joven.email || 'Sin definir'}</p>
                                </div>

                                <div className="col-md-12">
                                    <label className="fw-bold">Asesor</label>
                                    <p>{conversacionData.asesor.nombre ? [conversacionData.asesor.nombre, conversacionData.asesor.apellido_paterno, conversacionData.asesor.apellido_materno].join(' ') : 'Sin definir'}</p>
                                </div>

                                <div className="col-md-12">
                                    <label className="fw-bold">Fecha de inicio</label>
                                    <p>{conversacionData.fecha_inicio ? new Date(conversacionData.fecha_inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                                </div>

                                <div className="col-md-12">
                                    <label className="fw-bold">Fecha de finalización</label>
                                    <p>{conversacionData.fecha_fin ? new Date(conversacionData.fecha_fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                                </div>

                                <div className="col-md-12">
                                    <label className="fw-bold">Próxima cita</label>
                                    <p>{conversacionData.fecha_cita ? new Date(conversacionData.fecha_cita).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="seccion_conversacion">
                        <div className="contenedor_conversacion">
                            <div className="header_conversacion">
                                <div className="contenedor_imagen_conversacion">
                                    <div className="imagen_conversacion" style={{ backgroundImage: `url('${conversacionData.joven.url_perfil}')` }}></div>
                                </div>
                                <div className="contenedor_datos_conversacion">
                                    <label className="titulo_conversacion">{[conversacionData.joven?.nombre, conversacionData.joven?.apellido_paterno, conversacionData.joven?.apellido_materno].join(' ')}</label>
                                    <label className="subtitulo_conversacion">
                                        <p>{handleStatusMin(conversacionData.status)}</p>
                                    </label>
                                </div>
                            </div>

                            <div className="body_conversacion" ref={containerRef}>
                                <MessageList
                                    id="chat" 
                                    className="message-list"
                                    lockable={true}
                                    toBottomHeight={'100%'}
                                    dataSource={conversacionData.mensajes}
                                />
                            </div>
                            
                            <div className="footer_conversacion">
                                <div className="contenedor_campo_envio">
                                    <input className="form-control campo_envio" type="text" placeholder="Escribe aquí..." onChange={e => onChangeMensaje(e)} onKeyDown={handleKeyPress} value={mensajeData.mensaje} disabled={conversacionData.status === 2 ? true : false} />
                                    <button className="btn btn-success boton_envio" type="button" onClick={handleEnviarMensaje} disabled={conversacionData.status === 2 ? true : false}>Enviar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}

export default Chat;