import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { setAlert } from '../../actions/alert';
import { crearNota } from '../../actions/deportiva';

const NuevaNota = ({ nota, reestablecerNota }) => {

    const dispatch =  useDispatch();

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [notaData, setNotaData] = useState({
        id_asesoria: null,
        contenido: ''
    });

    /* Crear nota */
    const handleInputChange = ({ target }) => {
        setNotaData({
            ...notaData,
            [target.name]: target.value
        });
    }
    
    const handleProcesar = async () => {
        if(notaData.contenido){
            await setStatusProcesar(true);
            await dispatch(crearNota(notaData));
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('No puedes guardar una nota vacia', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setNotaData({
                id_asesoria: null,
                contenido: ''
            });

            setNuevoModal(false);
            reestablecerNota(null);
        }
    }

    useEffect(() => {
        if(nota){
            setNuevoModal(true);

            setNotaData({
                ...notaData, 
                id_asesoria: nota.id_asesoria
            });
        }        
    }, [nota]);

    return (
        <Modal show={nuevoModal} size="md" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Nueva nota</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <textarea className="form-control textareCampo" placeholder="Contenido" id="contenido" name="contenido" onChange={e => handleInputChange(e)}>{notaData.contenido}</textarea>
                                <label htmlFor="email">Contenido</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Agregar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Agregar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default NuevaNota;