import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { formatNumber } from '../../utils/generalFunctions';

import { obtenerRequerimiento, cambioStatusRequerimiento } from '../../actions/apoyos';

const DetallesRequerimiento = ({ requerimiento, reestablecerRequerimiento }) => {

    const dispatch =  useDispatch();

    const { detalles_requerimiento } = useSelector(state => state.apoyos);

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [requerimientoData, setRequerimientoData] = useState({
        id: null, 
        nombre: null, 
        tipo: null, 
        multiple: false, 
        obligatorio: false, 
        vigencia: null, 
        tipo_vigencia: null, 
        usos: false, 
        opciones: [], 
        valor: null, 
        url_perfil: null
    });

    const handleAbrirArchivo = (valor) => {
        if(valor){
            window.open(valor);
        }
    }

    const handleStatusRequerimiento = async (status) => {
        await setStatusProcesar(true);
        await dispatch(cambioStatusRequerimiento({
            id: requerimientoData.valor?._id || null,
            id_requerimiento: requerimiento.id, 
            id_solicitud: requerimiento.id_solicitud, 
            status
        }));
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setRequerimientoData({
                id: null, 
                nombre: null, 
                tipo: null, 
                multiple: false, 
                obligatorio: false, 
                vigencia: null, 
                tipo_vigencia: null, 
                usos: false, 
                opciones: [], 
                valor: null, 
                url_perfil: null
            });

            setNuevoModal(false);

            reestablecerRequerimiento(null);
        }
    }

    /* Funciones generales */
    const handleTipo = (tipo, multiple) => {
        switch(tipo){
            case 0:
                return( 'Texto corto' );
            case 1:
                return( 'Texto lago' );
            case 2:
                return( 'Numero' );
            case 3:
                return( 'Fecha' );
            case 4:
                if(multiple){
                    return 'Opciones (varias elegibles)';
                }else{
                    return 'Opciones (una elegible)';
                }
            case 5:
                return( 'Fotografia o imagen' );
            case 6:
                return( 'Archivo' );
            default:
                break;
        }
    }

    const handleStatus = (status) => {
        switch(status){
            case 0:
                return(
                    <h5><Badge pill bg="warning text-black">Pendiente de revisión</Badge></h5>
                );

            case 1:
                return(
                    <h5><Badge pill bg="primary">En revisión</Badge></h5>
                );

            case 2:
                return(
                    <h5><Badge pill bg="success">Aprobado</Badge></h5>
                );

            case 3:
                return(
                    <h5><Badge pill bg="danger">Rechazado</Badge></h5>
                );

            case 4:
                return(
                    <h5><Badge pill bg="danger">Vigencia expirada</Badge></h5>
                );

            default:
                return(
                    <h5><Badge pill bg="secondary">Sin valor</Badge></h5>
                );
        }
    }

    const handleVigencia = (tipo, vigencia) => {
        let resultado = '';

        switch(tipo){
            case 0:
            case '0':
                resultado = vigencia == 1 ? 'dia' : 'dias';
                break;

            case 1:
            case '1':
                resultado = vigencia == 1 ? 'mes' : 'meses';
                break;

            case 2:
            case '2':
                resultado = vigencia == 1 ? 'año' : 'años';
                break;
        }

        return vigencia + ' ' + resultado;
    }

    const handleValor = (tipo, valor, opciones) => {
        switch(tipo){
            case 0:
            case 1:
                return(
                    <p>{ valor }</p>
                );

            case 2:
                return(
                    <p>{ formatNumber(valor, 0, '', '') }</p>
                );

            case 3:
                return(
                    <p>{ valor ? new Date(valor).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir' }</p>
                );

            case 4:
                let texto_opciones = [];

                for (var x = 0; x < opciones.length; x++) {
                    texto_opciones.push(
                        <li className={ 'text-' + (valor.includes(opciones[x]._id) ? 'success fw-bold' : 'black') } key={'opcion_' + x}>
                            <p>{ opciones[x].contenido }</p>
                        </li>
                    );
                }

                return(
                    <ul>{ texto_opciones }</ul>
                );

            case 5:
            case 6:
                if(valor){
                    return(
                        <>
                            <br></br>
                            <button type="button" className="btn btn-primary px-2" onClick={() => handleAbrirArchivo(valor)}>Abrir archivo</button>
                        </>
                    );
                }else{
                    return(
                        <p>No se reconoce el tipo de archivo o imágen guardada.</p>
                    );
                }
                

            default:
                return(
                    <p>No se reconoce el tipo de requerimiento guardado.</p>
                );
        }
    }

    const handleBotonStatusRequerimiento = (status) => {
        switch(status){
            case 0:
                return(
                    <button type="button" className="btn btn-primary" onClick={() => handleStatusRequerimiento(1)} disabled={ statusProcesar ? true : false }>
                        {
                            statusProcesar && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        }
                         Comenzar revisión
                    </button>
                );

            case 1:
                return(
                    <Fragment>
                        <button type="button" className="btn btn-danger" onClick={() => handleStatusRequerimiento(3)} disabled={ statusProcesar ? true : false }>
                            {
                                statusProcesar && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            }
                             Rechazar
                        </button>

                        <button type="button" className="btn btn-success" onClick={() => handleStatusRequerimiento(2)} disabled={ statusProcesar ? true : false }>
                            {
                                statusProcesar && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            }
                             Aprobar
                        </button>
                    </Fragment>
                );

            case 2:
            case 3:
            case 4:
                return(
                    <button type="button" className="btn btn-primary" onClick={() => handleStatusRequerimiento(1)} disabled={ statusProcesar ? true : false }>
                        {
                            statusProcesar && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        }
                         Revisar nuevamente
                    </button>
                );

            default:
                return(
                    <p>No se reconoce el tipo de requerimiento guardado.</p>
                );
        }
    }

    useEffect(() => {
        if(requerimiento){
            setNuevoModal(true);

            dispatch(obtenerRequerimiento({
                id: requerimiento.id,
                id_solicitud: requerimiento.id_solicitud
            }));
        }        
    }, [requerimiento]);

    useEffect(() => {
        if(detalles_requerimiento){
            setRequerimientoData({
                ...requerimientoData,
                id: detalles_requerimiento?._id, 
                nombre: detalles_requerimiento?.nombre,
                tipo: detalles_requerimiento?.tipo, 
                multiple: detalles_requerimiento?.multiple,
                obligatorio: detalles_requerimiento?.obligatorio, 
                vigencia: detalles_requerimiento?.vigencia, 
                tipo_vigencia: detalles_requerimiento?.tipo_vigencia, 
                usos: detalles_requerimiento?.usos, 
                opciones: detalles_requerimiento?.opciones, 
                valor: detalles_requerimiento?.valor, 
                url_perfil: detalles_requerimiento.url_perfil || process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
            });
        }
    }, [detalles_requerimiento]);

    return (
        <Modal show={nuevoModal} size="lg" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Detalle de requerimiento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <label className="fw-bold">Nombre</label>
                        <p>{[requerimientoData?.nombre, requerimientoData?.apellido_paterno, requerimientoData?.apellido_materno].join(' ')}</p>
                    </div>

                    <div className="col-md-4">
                        <label className="fw-bold">Tipo</label>
                        <p>{ handleTipo(requerimientoData?.tipo, requerimientoData?.multiple) }</p>
                    </div>

                    <div className="col-md-4">
                        <label className="fw-bold">Obligatorio</label>
                        <p>{ requerimientoData?.obligatorio ? 'Si' : 'No' }</p>
                    </div>

                    <div className="col-md-4">
                        <label className="fw-bold">Vigencia</label>
                        <p>{ requerimientoData?.vigencia ? handleVigencia(requerimientoData?.tipo_vigencia, requerimientoData?.vigencia) : 'Sin vigencia' }</p>
                    </div>

                    <div className="col-md-4">
                        <label className="fw-bold">Usos</label>
                        <p>{ requerimientoData?.usos ? 'De uso global en todas las solicitudes' : 'Requerido en cada solicitud' }</p>
                    </div>

                    <div className="col-md-4">
                        <label className="fw-bold">Última Actualización</label>
                        <p>{requerimientoData?.valor?.fecha ? new Date(requerimientoData?.valor?.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                    </div>

                    <div className="col-md-4">
                        <label className="fw-bold">Situación actual</label>
                        <p>{ handleStatus(requerimientoData?.valor?.status) }</p>
                    </div>

                    {
                        requerimientoData?.valor ?
                            <Fragment>
                                <hr></hr>

                                <div className="col-md-12">
                                    <label className="fw-bold">Valor</label>
                                    { handleValor(requerimientoData?.tipo, requerimientoData?.valor?.valor, requerimientoData?.opciones) }
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>            
            </Modal.Body>
            <Modal.Footer>
                {
                    requerimientoData?.valor ?
                        handleBotonStatusRequerimiento(requerimientoData?.valor?.status)
                    :
                        null
                }
            </Modal.Footer>
        </Modal>
    )
}

export default DetallesRequerimiento;