import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { eliminarCategoriaEmpresaConfiguracion } from '../../../actions/configuracion';

const EliminarCategoria = ({ categoria, reestablecerCategoria }) => {

    const dispatch =  useDispatch();

    const [eliminarModal, setEliminarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Eliminar categoria */
    const handleProcesar = async () => {
        await setStatusProcesar(true);
        await dispatch(eliminarCategoriaEmpresaConfiguracion(categoria?._id));
        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            reestablecerCategoria(null);
            setEliminarModal(false);
        }
    }

    useEffect(() => {
        if(categoria){
            setEliminarModal(true);
        }
    }, [categoria]);

    return (
        <Modal show={eliminarModal} backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar categoria</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    ¿Deseas eliminar a 
                    {' ' + categoria?.nombre}
                    {' con ' + (categoria?.empresas == 1 ? categoria?.empresas + ' empresa relacionada' : categoria?.empresas + ' empresas relacionadas')}
                    {' y '}
                    {' con ' + (categoria?.jovenes == 1 ? categoria?.jovenes + ' joven interesado' : categoria?.jovenes + ' jovenes interesados')}
                    ?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={ statusProcesar }><i className="fas fa-times fa-sm"></i> No</button>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Si</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EliminarCategoria;