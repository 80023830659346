import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { eliminarCita } from '../../actions/psicologica';

const EliminarCita = ({ cita, reestablecerCita }) => {

    const dispatch =  useDispatch();

    const [eliminarModal, setEliminarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Eliminar cita */
    const handleProcesar = async () => {
        await setStatusProcesar(true);
        await dispatch(eliminarCita(cita?.id));
        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            reestablecerCita(null);
            setEliminarModal(false);
        }
    }

    useEffect(() => {
        if(cita){
            setEliminarModal(true);
        }
    }, [cita]);

    return (
        <Modal show={eliminarModal} backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar cita</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Deseas eliminar la cita de {cita?.joven ? cita?.joven.nombre : cita?.nombre}?</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={ statusProcesar }><i className="fas fa-times fa-sm"></i> No</button>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Si</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EliminarCita;