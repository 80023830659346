import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { setAlert } from '../../actions/alert';
import { crearInscripcion } from '../../actions/gimnasios';
import { buscarJovenes } from '../../actions/jovenes';

const NuevaInscripcion = ({ inscripcion, reestablecerInscripcion }) => {

    const dispatch =  useDispatch();

    const jovenes = useSelector(state => state.jovenes);

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [inscripcionData, setInscripcionData] = useState({
        id_joven: null,
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        nacimiento: null,
        curp: '',
        celular: '',
        email: ''
    });

    const [jovenData, setJovenData] = useState(null);
    const [jovenesFilter, setJovenesFilter] = useState([]);

    /* Crear inscripcion */
    const handleSelectJoven = (option) => {
        setJovenData(option);

        setInscripcionData({
            ...inscripcionData,
            id_joven: option != null ? option.value : null
        });
    }

    const handleInputChange = ({ target }) => {
        setInscripcionData({
            ...inscripcionData,
            [target.name]: target.value
        });
    }

    const handleDatePicker = (name, value) => {
        setInscripcionData({
            ...inscripcionData,
            [name]: value
        });
    }
    
    const handleProcesar = async () => {
        if(!inscripcionData.id_joven){
            if(inscripcionData.nombre && inscripcionData.apellido_paterno && inscripcionData.nacimiento && (inscripcionData.curp || inscripcionData.celular || inscripcionData.email)){
                await setStatusProcesar(true);
                await dispatch(crearInscripcion(inscripcionData));
                await handleCerrar();
                await setStatusProcesar(false);
            }else{
                await dispatch(setAlert('Completa los campos para realizar el registro', 'danger'));
            }
        }else{
            await setStatusProcesar(true);
            await dispatch(crearInscripcion(inscripcionData));
            await handleCerrar();
            await setStatusProcesar(false);
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setInscripcionData({
                id_joven: null,
                nombre: '',
                apellido_paterno: '',
                apellido_materno: '',
                nacimiento: null,
                curp: '',
                celular: '', 
                email: ''
            });

            setJovenData(null);
            setNuevoModal(false);
            reestablecerInscripcion(null);
        }
    }

    useEffect(() => {
        dispatch(buscarJovenes({
            nombre: null,
            apellido_paterno: null,
            apellido_materno: null,
            celular: null,
            email: null,
            curp: null
        }));
    }, []);

    useEffect(() => {
        if(inscripcion){
            setNuevoModal(true);

            setInscripcionData({
                ...inscripcionData
            });
        }        
    }, [inscripcion]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < jovenes.listado?.length; i++) {
            options.push({ value: jovenes.listado[i]._id, label: [jovenes.listado[i].nombre, jovenes.listado[i].apellido_paterno, jovenes.listado[i].apellido_materno].join(' ') });
        }

        setJovenesFilter(options);
    }, [jovenes]);

    useEffect(() => {
        if(jovenData){
            setInscripcionData({
                ...inscripcionData, 
                nombre: '',
                apellido_paterno: '',
                apellido_materno: '',
                nacimiento: null,
                curp: '',
                celular: '', 
                email: ''
            });
        }        
    }, [jovenData]);

    return (
        <Modal show={nuevoModal} size="xl" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Nueva inscripción</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="id_joven"
                                name="id_joven"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar joven"
                                isClearable
                                options={jovenesFilter}
                                value={jovenData}
                                onChange={(value) => handleSelectJoven(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-12 mb-3">
                        <hr></hr>
                        <p>O bien, crea un registro nuevo desde cero. La contraseña temporal de esta nueva cuenta será "iaju".</p>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={inscripcionData.nombre} disabled={jovenData ? true : false} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="nombre">Nombre</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Apellido paterno" id="apellido_paterno" name="apellido_paterno" value={inscripcionData.apellido_paterno} disabled={jovenData ? true : false} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="apellido_paterno">Apellido paterno</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Apellido materno" id="apellido_materno" name="apellido_materno" value={inscripcionData.apellido_materno} disabled={jovenData ? true : false} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="apellido_materno">Apellido materno</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="nacimiento"
                                    name="nacimiento"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    locale="es-ES"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={ inscripcionData.nacimiento != null ? new Date(inscripcionData.nacimiento) : '' }
                                    disabled={jovenData ? true : false}
                                    onChange={ date => handleDatePicker('nacimiento', date) }
                                />
                                <label className="label-datepicker" htmlFor="nacimiento">Fecha de nacimiento</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="CURP" id="curp" name="curp" value={inscripcionData.curp} disabled={jovenData ? true : false} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="curp">CURP</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Celular" id="celular" name="celular" value={inscripcionData.celular} disabled={jovenData ? true : false} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="celular">Celular</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Correo" id="email" name="email" value={inscripcionData.email} disabled={jovenData ? true : false} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="email">Correo</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Agregar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Agregar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default NuevaInscripcion;