import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { setAlert } from '../../actions/alert';
import { obtenerCita, statusCita } from '../../actions/psicologica';

const DetallesCita = ({ cita, reestablecerCita, detallesCompletosAsesoria, editarCita, eliminarCita }) => {

    const dispatch =  useDispatch();

    const { detalles_cita } = useSelector(state => state.psicologica);

    const [detallesModal, setDetallesModal] = useState(false);

    const [citaData, setCitaData] = useState({
        id: null,
        id_asesoria: null, 
        joven: null, 
        asesor: null, 
        fecha_inicio: null,
        fecha_fin: null, 
        observaciones: null, 
        status: 0, 
        url_perfil: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
    });

    const handleCerrar = () => {
        setCitaData({
            id: null, 
            id_asesoria: null, 
            joven: null, 
            asesor: null, 
            fecha_inicio: null,
            fecha_fin: null, 
            observaciones: null, 
            status: 0, 
            url_perfil: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
        });
        setDetallesModal(false);
        reestablecerCita(null);
    }

    /* Detalles completos */
    const handleDetalles = async () => {
        if(citaData.id_asesoria){
            detallesCompletosAsesoria(citaData.id_asesoria);
            handleCerrar();
        }else{
            dispatch(setAlert('Selecciona primero una asesoria', 'danger'));
        }
    }
    
    /* Editar cita */
    const handleEditar = () => {
        handleCerrar();
        editarCita(citaData);
    }

    /* Status cita */
    const handleStatus = (status) => {
        dispatch(statusCita({
            id: citaData.id, 
            status
        }));
        handleCerrar();
    }

    /* Eliminar cita */
    const handleEliminar = () => {
        handleCerrar();
        eliminarCita(citaData);
    }

    /* Funciones generales */
    const getRangoFechas = (inicio, fin) => {
        return new Date(inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' de ' + new Date(inicio).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) + ' a ' + new Date(fin).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    }

    useEffect(() => {
        dispatch(obtenerCita(null));
    }, []);

    useEffect(() => {
        if(cita){
            setDetallesModal(true);
            
            dispatch(obtenerCita(cita?.id));

            setCitaData({
                ...citaData,
                id: cita?.id
            });
        }
    }, [cita]);

    useEffect(() => {
        if(detalles_cita){
            setCitaData({
                ...citaData,
                id: detalles_cita?._id, 
                id_asesoria: detalles_cita?.asesoria?._id, 
                status_asesoria: detalles_cita?.asesoria?.status, 
                joven: {
                    id: detalles_cita?.joven?._id || null,
                    nombre: detalles_cita?.joven ? [detalles_cita?.joven.nombre, detalles_cita?.joven.apellido_paterno, detalles_cita?.joven.apellido_materno].join(' ') : 'Sin definir'
                }, 
                asesor: {
                    id: detalles_cita?.usuario?._id || null,
                    nombre: detalles_cita?.usuario ? [detalles_cita?.usuario.nombre, detalles_cita?.usuario.apellido_paterno, detalles_cita?.usuario.apellido_materno].join(' ') : 'Sin definir'
                }, 
                fecha_inicio: detalles_cita?.fecha_inicio,
                fecha_fin: detalles_cita?.fecha_fin, 
                observaciones: detalles_cita?.observaciones, 
                status: detalles_cita?.status, 
                url_perfil: detalles_cita.url_perfil_p || process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
            });
        }
    }, [detalles_cita]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .contenedor_imagen_perfil {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            background: #fff;
                            border: 5px solid #111F62;
                            width: auto;
                            margin: auto;
                        }
                        
                        .contenedor_imagen{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        #imagen_perfil {
                            width: auto;
                            height: 100%;
                            display: block;
                            position: absolute;
                            margin: auto;
                            top: -100%;
                            right: -100%;
                            bottom: -100%;
                            left: -100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
            <Modal show={detallesModal} backdrop="static" size="lg" centered onHide={() => handleCerrar()}>
                <Modal.Header closeButton>
                    <Modal.Title>Cita de asesoria</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-5 row">
                            <label className="contenedor_imagen_perfil">
                                <div className="contenedor_imagen">
                                    <img id="imagen_perfil" src={ citaData?.url_perfil }/>
                                </div>
                            </label>
                        </div>

                        <div className="col-md-7 row">
                            <div className="col-md-12">
                                <label className="fw-bold">Fecha</label>
                                <p>{ getRangoFechas(citaData?.fecha_inicio, citaData?.fecha_fin) }</p>
                            </div>

                            <div className="col-md-12">
                                <label className="fw-bold">Joven</label>
                                <p>{citaData?.joven?.nombre}</p>
                            </div>

                            <div className="col-md-12">
                                <label className="fw-bold">Asesor</label>
                                <p>{citaData?.asesor?.nombre}</p>
                            </div>

                            <div className="col-md-12">
                                <label className="fw-bold">Observaciones</label>
                                <p>{citaData?.observaciones ? citaData?.observaciones : 'Sin observaciones'}</p>
                            </div>
                        </div>
                    </div>                    
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-group" role="group">
                        <button type="button" className={'btn dropdown-toggle btn-' + (citaData.status == 0 ? 'warning' : (citaData.status == 1 ? 'success' : 'danger'))} data-bs-toggle="dropdown" aria-expanded="false">
                        <i className={'fas fa-sm fa-' + (citaData.status == 0 ? 'pause' : (citaData.status == 1 ? 'check' : 'times'))}></i>
                            { citaData.status == 0 ? ' Pendiente' : (citaData.status == 1 ? ' Aprobada' : ' Rechazada') }
                        </button>
                        <ul class="dropdown-menu">
                            {
                                citaData.status != 0 ?
                                    <li><button type="button" class="dropdown-item" onClick={() => handleStatus(0)}>Pendiente</button></li>
                                :
                                    null
                            }

                            {
                                citaData.status != 1 ?
                                    <li><button type="button" class="dropdown-item" onClick={() => handleStatus(1)}>Autorizar</button></li>
                                :
                                    null
                            }

                            {
                                citaData.status != 2 ?
                                    <li><button type="button" class="dropdown-item" onClick={() => handleStatus(2)}>Rechazar</button></li>
                                :
                                    null
                            }
                        </ul>
                    </div>
                    <button type="button" className="btn btn-danger mr-3" onClick={() => handleEliminar()}><i className="fas fa-trash fa-sm"></i> Eliminar</button>
                    {
                        citaData.status_asesoria != 2 ?
                            <button type="button" className="btn btn-warning mr-3" onClick={() => handleEditar()}><i className="fas fa-pencil fa-sm"></i> Editar</button>
                        :
                            null
                    }
                    <button type="button" className="btn btn-primary" onClick={() => handleDetalles()}><i className="fas fa-eye fa-sm"></i> Detalles de asesoria</button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default DetallesCita;