import React, { useState, useEffect, createRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { setAlert } from '../../actions/alert';
import { editarArchivo, detallesArchivo } from '../../actions/programas';

const EditarArchivo = ({ archivo, reestablecerArchivo }) => {

    const dispatch =  useDispatch();

    const { detalles_archivo } = useSelector(state => state.programas);

    const [archivoData, setArchivoData] = useState({
        id_archivo: null, 
        id_programa: null, 
        nombre: null, 
        descripcion: null, 
        archivo: null,
        extension: null,
        url: null
    });

    const [archivoModal, setArchivoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    let archivo_ref = createRef();

    /* Agregar archivo */
    const handleInputChange = ({ target }) => {
        setArchivoData({
            ...archivoData,
            [target.name]: target.value
        });
    }

    const handleArchivoElegir = () => {
        return archivo_ref.current?.click();
    }

    const handleChangeFile = () => {
        let nombre_separado = archivo_ref.current.files[0] ? archivo_ref.current.files[0].name.split('.') : null;

        setArchivoData({
            ...archivoData,
            archivo: nombre_separado ? archivo_ref.current.files[0] : null,
            extension: nombre_separado ? nombre_separado[nombre_separado.length - 1].toLowerCase() : null,
            url: nombre_separado ? URL.createObjectURL(archivo_ref.current.files[0]) : null
        });
    }

    const handleViewFile = (url) => {
        if(url){
            window.open(url);
        }else{
            dispatch(setAlert('No file selected', 'danger'));
        }
    }

    const handleProcesar = async () => {
        await setStatusProcesar(true);
        
        let formData = new FormData();

        formData.append('id_archivo', archivoData.id_archivo);
        formData.append('id_programa', archivoData.id_programa);
        formData.append('nombre', archivoData.nombre);
        formData.append('descripcion', archivoData.descripcion);
        formData.append('extension', archivoData.extension);
        formData.append('archivo', archivoData.archivo);

        await dispatch(editarArchivo(formData));

        await handleCerrar(null);
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setArchivoData({
                id_archivo: null, 
                id_programa: null, 
                nombre: null, 
                descripcion: null, 
                archivo: null,
                extension: null,
                url: null
            });

            reestablecerArchivo(null);

            setArchivoModal(false);
        }
    }

    useEffect(() => {
        if(archivo){
            dispatch(detallesArchivo({
                id_archivo: archivo.id_archivo, 
                id_programa: archivo.id_programa, 
            }));
            
            setArchivoData({
                ...archivoData, 
                id_archivo: archivo.id_archivo, 
                id_programa: archivo.id_programa, 
                nombre: archivo.nombre, 
                descripcion: archivo.descripcion, 
                archivo: archivo.archivo,
                extension: archivo.extension,
                url: archivo.url
            });

            setArchivoModal(true);
        }
    }, [archivo]);

    useEffect(() => {        
        if(detalles_archivo && detalles_archivo?.extension && detalles_archivo?.url){
            setArchivoData({
                ...archivoData, 
                extension: detalles_archivo.extension,
                url: detalles_archivo.url
            });
        }
    }, [detalles_archivo]);

    return (
        <Modal show={archivoModal} backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Editar archivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-md-12 form-group mb-3">
                    <div className="input-group">
                        <div className="form-floating">
                            <input className="form-control" type="text" placeholder="Nombre" id="nombre" name="nombre" value={archivoData.nombre} onChange={e => handleInputChange(e)}/>
                            <label htmlFor="nombre">Nombre</label>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 form-group mb-3">
                    <div className="input-group">
                        <div className="form-floating">
                            <textarea className="form-control textareCampo" placeholder="Descripción" id="descripcion" name="descripcion" value={archivoData.descripcion} onChange={e => handleInputChange(e)}></textarea>
                            <label htmlFor="descripcion">Descripción</label>
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="btn-group w-100" role="group">
                        <button type="button" className="btn btn-outline-secondary" onClick={() => handleArchivoElegir()}>{archivoData.url ? ( archivoData.archivo ? 'Cambiar archivo' : 'Archivo existente' ) : 'Elegir archivo'}</button>
                        {
                            archivoData.archivo || archivoData.url ?
                                <button type="button" className="btn btn-secondary" onClick={() => handleViewFile(archivoData.url)}><i className="fa-solid fa-eye"></i> Ver archivo</button>
                            :
                                null
                        }
                    </div>
                    <input type="file" className="d-none" id="archivo" name="archivo" ref={archivo_ref} onChange={handleChangeFile} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={ statusProcesar }><i className="fas fa-times fa-sm"></i> No</button>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Si</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EditarArchivo;