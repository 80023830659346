import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate, Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { sortTableDateFechaInicio, sortTableDateFechaFin, sortTableStatusStatus } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { buscarAsesorias } from '../../actions/psicologica';

import FiltrosAsesorias from './FiltrosAsesorias';
import NuevaAsesoria from './NuevaAsesoria';
import DetallesAsesoria from './DetallesAsesoria';
import EditarAsesoria from './EditarAsesoria';
import EliminarAsesoria from './EliminarAsesoria';

const Asesorias = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Asesorias psicológicas',
            path: '/psicologica'
        }
    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { listado_asesorias } = useSelector(state => state.psicologica);

    const [accesos, setAccesos] = useState([]);
    const [busquedaData, setBusquedaData] = useState({
        id_joven: null,
        id_asesor: null, 
        fecha_inicio: null,
        fecha_fin: null, 
        mensajes: null, 
        status: [0, 1], 
        busqueda: false
    });

    const [asesoriaAgregar, setAsesoriaAgregar] = useState(null);
    const [asesoriaDetalles, setAsesoriaDetalles] = useState(null);
    const [asesoriaEditar, setAsesoriaEditar] = useState(null);
    const [asesoriaEliminar, setAsesoriaEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.joven.nombre || '-'
        },
        {
            name: 'Apellido paterno',
            sortable: true, 
            selector: row => row.joven.apellido_paterno || '-'
        },
        {
            name: 'Apellido materno',
            sortable: true, 
            selector: row => row.joven.apellido_materno || '-'
        },
        {
            name: 'Inició',
            sortable: true, 
            sortFunction: sortTableDateFechaInicio, 
            cell: item => new Date(item.fecha_inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })
        },
        {
            name: 'Finalizó',
            sortable: true, 
            sortFunction: sortTableDateFechaFin, 
            cell: item => item.fecha_fin ? new Date(item.fecha_fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'
        },
        {
            name: 'Asesor',
            sortable: true, 
            selector: row => row.usuario ? [row.usuario.nombre, row.usuario.apellido_paterno, row.usuario.apellido_materno].join(' ') : 'Sin definir', 
            grow: 2
        }, 
        {
            name: 'Mensajes sin leer',
            sortable: true, 
            grow: 1.5, 
            selector: row => row.mensajes_sin_leer,
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusStatus, 
            selector: row => {
                switch(row.status){
                    case 0:
                        return(
                            <h5><Badge pill bg="warning text-black">Pendiente</Badge></h5>
                        )
                    case 1:
                        return(
                            <h5><Badge pill bg="success">Activa</Badge></h5>
                        )
        
                    case 2:
                        return(
                            <h5><Badge pill bg="danger">Finalizada</Badge></h5>
                        )
                    default:
                        return(
                            <h5><Badge pill bg="secondary">Sin definir</Badge></h5>
                        )
                        break;
                }
            }
        }, 
        {
            name: '',
            grow: 0.5, 
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleDetallesAsesoria({ _id: row._id })}><i className="fas fa-eye text-info"></i></button>
                    </div>
                )
            }
        }
    ];

    /* Filtros */
    const handleFiltrosBusqueda = (busqueda) => {
        setAsesoriaAgregar(null);
        setAsesoriaDetalles(null);
        setAsesoriaEditar(null);
        setAsesoriaEliminar(null);

        setBusquedaData({
            ...busqueda
        });
    }

    /* Crear asesoria */
    const handleAgregarAsesoria = (reservacion) => {
        setBusquedaData({
            ...busquedaData, 
            busqueda: false
        });
        setAsesoriaDetalles(null);
        setAsesoriaEditar(null);
        setAsesoriaEliminar(null);

        setAsesoriaAgregar(reservacion);
    }

    /* Detalles asesoria */
    const handleDetallesAsesoria = (asesoria) => {
        setBusquedaData({
            ...busquedaData, 
            busqueda: false
        });
        setAsesoriaAgregar(null);
        setAsesoriaEditar(null);
        setAsesoriaEliminar(null);

        setAsesoriaDetalles(asesoria?._id || null);
    }

    /* Editar asesoria */
    const handleEditarAsesoria = (asesoria) => {
        setBusquedaData({
            ...busquedaData, 
            busqueda: false
        });
        setAsesoriaAgregar(null);
        setAsesoriaDetalles(null);
        setAsesoriaEliminar(null);
        
        setAsesoriaEditar(asesoria); 
    }

    /* Eliminar asesoria */
    const handleEliminarAsesoria = (asesoria) => {
        setBusquedaData({
            ...busquedaData, 
            busqueda: false
        });
        setAsesoriaAgregar(null);
        setAsesoriaDetalles(null);
        setAsesoriaEditar(null);
        
        setAsesoriaEliminar(asesoria); 
    }

    /* Detalles completos asesoria */
    const handleDetallesCompletosAsesoria = (asesoria) => {
        setBusquedaData({
            ...busquedaData, 
            busqueda: false
        });
        setAsesoriaAgregar(null);
        setAsesoriaDetalles(null);
        setAsesoriaEditar(null);
        setAsesoriaEliminar(null);
        
        navigate('/psicologica/detalles/' + asesoria);
    }

    useEffect(() => {
        dispatch(buscarAsesorias(busquedaData));
    }, []);

    useEffect(() => {
        setAccesos(listado_asesorias);
    }, [listado_asesorias]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);    

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .card-subtitle {
                            font-size: 1.5rem;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <FiltrosAsesorias busqueda={busquedaData} reestablecerFiltros={handleFiltrosBusqueda} />
            <NuevaAsesoria asesoria={asesoriaAgregar} reestablecerAsesoria={handleAgregarAsesoria} />
            <DetallesAsesoria asesoria={asesoriaDetalles} reestablecerAsesoria={handleDetallesAsesoria} editarAsesoria={handleEditarAsesoria} eliminarAsesoria={handleEliminarAsesoria} detallesCompletosAsesoria={handleDetallesCompletosAsesoria} />
            <EditarAsesoria asesoria={asesoriaEditar} reestablecerAsesoria={handleEditarAsesoria} />
            <EliminarAsesoria asesoria={asesoriaEliminar} reestablecerAsesoria={handleEliminarAsesoria} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Asesorias psicológicas</h3>

                    <div className="d-flex justify-content-end">
                        <Link to='/psicologica/citas_calendario'><button type="button" className="btn btn-primary me-2">Citas</button></Link>
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleFiltrosBusqueda({ ...busquedaData, busqueda: true })}>Filtros de búsqueda</button>
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarAsesoria({ })}>Nueva asesoria</button>
                    </div>

                    <div className="row my-5">
                        <DataTable 
                            columns={columns}
                            data={accesos}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Asesorias;