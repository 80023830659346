import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import { formatNumber, sortTableNumberLimite, sortTableNumberSolicitantes, sortTableNumberSeleccionados, sortTableStatusStatus, sortTableDateInicio, sortTableDateFin } from '../../utils/generalFunctions';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { buscarProgramas, filtrarProgramas } from '../../actions/programas';

import FiltrosProgramas from './FiltrosProgramas';
import EliminarPrograma from './EliminarPrograma';

const Programas = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Programas',
            path: '/programas'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.programas);

    const [programas, setProgramas] = useState([]);

    const [programaEliminar, setProgramaEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre
        },
        {
            name: 'Fecha de inicio',
            sortable: true, 
            sortFunction: sortTableDateInicio, 
            cell: item => new Date(item.inicio).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            )
        },
        {
            name: 'Fecha de finalización',
            sortable: true, 
            sortFunction: sortTableDateFin, 
            cell: item => new Date(item.fin).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            )
        },
        {
            name: 'Limite de solicitantes',
            sortable: true, 
            sortFunction: sortTableNumberLimite, 
            selector: row => row.limite ? formatNumber(row.limite, 0, '', '') : 'Sin limite'
        },
        {
            name: 'Solicitantes',
            sortable: true, 
            sortFunction: sortTableNumberSolicitantes, 
            selector: row => formatNumber(row.solicitantes, 0, '', '')
        },
        {
            name: 'Seleccionados',
            sortable: true, 
            sortFunction: sortTableNumberSeleccionados, 
            selector: row => formatNumber(row.seleccionados, 0, '', '')
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusStatus, 
            grow: 2, 
            selector: row => {
                switch(row.status){
                    case 0:
                        return(
                            <h5><Badge pill bg="success">Activo</Badge></h5>
                        )
                    case 1:
                        return(
                            <h5><Badge pill bg="danger">Inactivo</Badge></h5>
                        )
                    case 2:
                        return(
                            <h5><Badge pill bg="warning">Finalizado y activo</Badge></h5>
                        )
                    case 3:
                        return(
                            <h5><Badge pill bg="danger">Finalizado e inactivo</Badge></h5>
                        )
                    default:
                        break;
                }
            }
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarPrograma(row)}><i className="fas fa-trash text-danger"></i></button>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/programas/editar/${row._id}`}><i className="fas fa-edit text-warning"></i></Link>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/programas/detalles/${row._id}`}><i className="fas fa-circle-info text-info"></i></Link>
                    </div>
                )
            }
        }
    ];
    
    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtrarProgramas(true));
    }

    /* Eliminar programa */
    const handleEliminarPrograma = (programa) => {
        setProgramaEliminar(programa);
    }

    useEffect(() => {
        dispatch(buscarProgramas({
            nombre: null,
            status: null, 
            fecha_inicio: null,
            fecha_fin: null
        }));
    }, []);

    useEffect(() => {
        setProgramas(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <FiltrosProgramas />
            <EliminarPrograma programa={programaEliminar} reestablecerPrograma={handleEliminarPrograma} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Programas</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary me-2" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>

                        <Link to="/programas/nuevo">
                            <button className="btn btn-primary">Agregar programa</button>
                        </Link>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={programas}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Programas;