import axios from 'axios';

import { DASHBOARD } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

// LOAD USER
export const buscarDashboard = (tipo) => async dispatch => {
    try {
        if(tipo){
            await dispatch(changeLoader(true));
            const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/dashboard/${tipo}`);
            await dispatch(changeLoader(false));

            await dispatch({
                type: DASHBOARD.OBTENER,
                payload: res.data.datos
            });
        }else{
            await dispatch({
                type: DASHBOARD.OBTENER,
                payload: null
            });
        }
    } catch (error) {
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}