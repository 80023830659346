import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QrReader } from 'react-qr-reader';

import { setAlert } from '../../actions/alert';
import { canjearCupon, limpiarEscaner } from '../../actions/promociones';

const EscanearCupon = ({ cupon, reestablecerCupon }) => {

    const dispatch =  useDispatch();

    const { escaner } = useSelector(state => state.promociones);

    const [escanearModal, setEscanearModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [cuponData, setCuponData] = useState({
        codigo: null
    });

    const lastResult = useRef();

    /* Escanear cupon */
    const onReadResult = (result, error) => {
        if (!result) return;

        if (lastResult.current === result.text) {
          return
        }
        
        lastResult.current = result.text;

        setCuponData({
            codigo: result.text
        });
    }

    const handleInputChange = ({ target }) => {
        setCuponData({
            ...cuponData,
            [target.name]: target.value
        });
    }

    const handleProcesar = async () => {
        if(cuponData.codigo){
            await setStatusProcesar(true);
            await dispatch(canjearCupon(cuponData));
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Completa todos los campos', 'danger'));
        }
    }

    const handleCerrar = () => {
        window.location.reload();
    }

    useEffect(() => {
        if(cupon){
            setEscanearModal(true);

            setCuponData({
                ...cuponData, 
                codigo: null
            });
        }        
    }, [cupon]);

    useEffect(() => {
        if(escaner){
            dispatch(limpiarEscaner());

            setTimeout(() => {
                handleCerrar();
            }, 2000);
        }        
    }, [escaner]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        input[type="file"] {
                            display: none;
                        }
                        
                        .custom-file-upload {
                            border-radius: 5%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            cursor: pointer;
                            background: #fff;
                            margin-bottom: 25px;
                            border: 5px solid #111F62;
                        }
                        
                        .img-wrap{
                            position: relative;
                            //width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 5%;
                        }

                        .img-upload:before{
                            content: "";
                            font-family: "Font Awesome 6 Free" !important;
                            font-weight: 900;
                            font-size: 90px;
                            position: absolute;
                            padding-top: 50px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-align: center;
                            color: #21B4E9;
                            width: 100%;
                            height: 200px;
                            border-radius: 5%;
                            opacity: 0;
                            transition: .5s ease;
                            background-color: #fff;
                        }

                        .img-upload:hover:before{
                            opacity: 1;
                        }

                        #img-photo {
                            width: auto;
                            height: 100%;
                        }

                        .selectIconos, .select-group {
                            height: 100%;
                        }

                        .delete {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 25% 25% 0 !important;
                            height: 100%;
                        }

                        #label_limite_check:before {
                            content: "";
                            display: inline-block;
                            height: 100%;
                            vertical-align: middle;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
            
            <Modal show={escanearModal} size="sm" backdrop="static" centered onHide={() => handleCerrar()}>
                <Modal.Header closeButton>
                    <Modal.Title>Escanear cupon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 form-group text-center">
                            <QrReader
                                onResult={onReadResult}
                                videoId="modelvideo" 
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="col-md-12 form-group my-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Código del cupon" id="codigo" name="codigo" value={cuponData.codigo} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="codigo">Código del cupon</label>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </Modal.Body>
                <Modal.Footer>
                    {
                        statusProcesar ?
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Aceptar</button>
                        :
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Aceptar</button>
                    }
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default EscanearCupon;