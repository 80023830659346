import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarGimnasiosConfiguracion } from '../../../actions/configuracion';

import NuevoGimnasio from './NuevoGimnasio';
import EditarGimnasio from './EditarGimnasio';
import EliminarGimnasio from './EliminarGimnasio';

const Gimnasios = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Gimnasio',
            path: '/configuracion/gimnasio'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.gimnasios);

    const [gimnasios, setGimnasios] = useState([]);

    const [gimnasioAgregar, setGimnasioAgregar] = useState(null);
    const [gimnasioEditar, setGimnasioEditar] = useState(null);
    const [gimnasioEliminar, setGimnasioEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
        }, 
        {
            name: 'Capacidad',
            sortable: true, 
            selector: row => row.capacidad,
        },
        {
            name: 'Lunes',
            sortable: true, 
            cell: item => `${item.horarios[1] ? horariosToText(item.horarios[1]) : '-'}`
        },
        {
            name: 'Martes',
            sortable: true, 
            cell: item => `${item.horarios[2] ? horariosToText(item.horarios[2]) : '-'}`
        },
        {
            name: 'Miercoles',
            sortable: true, 
            cell: item => `${item.horarios[3] ? horariosToText(item.horarios[3]) : '-'}`
        },
        {
            name: 'Jueves',
            sortable: true, 
            cell: item => `${item.horarios[4] ? horariosToText(item.horarios[4]) : '-'}`
        },
        {
            name: 'Viernes',
            sortable: true, 
            cell: item => `${item.horarios[5] ? horariosToText(item.horarios[5]) : '-'}`
        },
        {
            name: 'Sabado',
            sortable: true, 
            cell: item => `${item.horarios[6] ? horariosToText(item.horarios[6]) : '-'}`
        },
        {
            name: 'Domingo',
            sortable: true, 
            cell: item => `${item.horarios[0] ? horariosToText(item.horarios[0]) : '-'}`
        },
        {
            name: '',
            cell: row => {
                if(row._id){
                    return(
                        <div className="text-right w-100">
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarGimnasio(row)}><i className="fas fa-trash text-danger"></i></button>
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarGimnasio(row)}><i className="fas fa-edit text-warning"></i></button>
                        </div>
                    )
                }else{
                    return(<></>)
                }
            }
        }
    ];

    /* Crear gimnasio */
    const handleAgregarGimnasio = (gimnasio) => {
        setGimnasioEditar(null);
        setGimnasioEliminar(null);

        setGimnasioAgregar(gimnasio);        
    }

    /* Editar gimnasio */
    const handleEditarGimnasio = (gimnasio) => {
        setGimnasioAgregar(null);
        setGimnasioEliminar(null);

        setGimnasioEditar(gimnasio);
    }

    /* Eliminar gimnasio */
    const handleEliminarGimnasio = (gimnasio) => {
        setGimnasioAgregar(null);
        setGimnasioEditar(null);

        setGimnasioEliminar(gimnasio);
    }

    /* Funciones generales */
    const horariosToText = (horarios) => {
        let horarios_formateados = [];

        for (let i = 0; i < horarios.length; i++) {
            horarios_formateados.push(horarios[i].hora_inicio + ' - ' + horarios[i].hora_fin);
        }

        return horarios_formateados.join(', ');
    }

    useEffect(() => {
        dispatch(buscarGimnasiosConfiguracion());
    }, []);

    useEffect(() => {
        setGimnasios(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <NuevoGimnasio gimnasio={gimnasioAgregar} reestablecerGimnasio={handleAgregarGimnasio} />
            <EditarGimnasio gimnasio={gimnasioEditar} reestablecerGimnasio={handleEditarGimnasio} />
            <EliminarGimnasio gimnasio={gimnasioEliminar} reestablecerGimnasio={handleEliminarGimnasio} />

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Gimnasio</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarGimnasio({ })}>Agregar gimnasio</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={gimnasios}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Gimnasios;