import axios from 'axios';

import { EVENTO } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

/* Eventos */
export const buscarEventos = (filtros) => async dispatch => {
    if(filtros){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify(filtros);

        try{
            await dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/eventos/listar`, body, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: EVENTO.BUSCAR,
                payload: res.data.eventos
            });
        } catch (error){ 
            await dispatch(changeLoader(false));

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        await dispatch({
            type: EVENTO.BUSCAR,
            payload: []
        });
    }
}

export const crearEvento = (evento) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/eventos/crear`, evento, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const informacionEvento = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/eventos/informacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EVENTO.DETALLES,
            payload: res.data.evento
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerEvento = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/eventos/detalles`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EVENTO.DETALLES,
            payload: res.data.evento
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarEvento = () => dispatch => {
    dispatch({
        type: EVENTO.DETALLES,
        payload: null
    });
}

export const editarEvento = (evento) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/eventos/editar`, evento, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const cambioStatus = (id, status, detalles) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id, 
        status
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/eventos/status`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EVENTO.DETALLES,
            payload: {
                ...detalles, 
                informacion_detallada: {
                    ...detalles.informacion_detallada, 
                    status
                }
            }
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarEvento = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/eventos/eliminar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EVENTO.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const filtrarEventos = (opcion) => dispatch => {
    dispatch({
        type: EVENTO.FILTROS,
        payload: opcion
    });
}

export const filtrarReservaciones = (opcion) => dispatch => {
    dispatch({
        type: EVENTO.RESERVACIONES.FILTROS,
        payload: opcion
    });
}

/* Reservaciones */
export const buscarReservaciones = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(filtros);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/eventos/listar_reservaciones`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EVENTO.RESERVACIONES.BUSCAR,
            payload: res.data.reservaciones
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const confirmarReservacion = (codigo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    console.log(codigo);

    const body = JSON.stringify(codigo);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/eventos/confirmar_reservacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));

        await dispatch({
            type: EVENTO.RESERVACIONES.ESCANER,
            payload: true
        });
    } catch (error){         
        await dispatch(changeLoader(false));
        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarEscaner = () => dispatch => {
    dispatch({
        type: EVENTO.RESERVACIONES.ESCANER,
        payload: null
    });
}