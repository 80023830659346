import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { buscarDashboard } from '../../actions/dashboard';
import { editarBreadcrumb } from '../../actions/breadcrumb';

import DashboardAdministrador from './DashboardAdministrador';
import DashboardCabina from './DashboardCabina';
import DashboardAuditorio from './DashboardAuditorio';
import DashboardGimnasio from './DashboardGimnasio';
import DashboardPsicologico from './DashboardPsicologico';
import DashboardNutricional from './DashboardNutricional';
import DashboardDeportivo from './DashboardDeportivo';
import DashboardEmpresa from './DashboardEmpresa';

const Dashboard = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Inicio',
            path: '/dashboard'
        }
    ];

    const dispatch = useDispatch();

    const usuario = useSelector(state => state.auth.usuario);

    useEffect(() => {
        return () => {
            dispatch(buscarDashboard(null));
        }
    }, []);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <div className="row">
            <div className="col-md-10 offset-md-1 row">
                {
                    usuario.tipo === 0 || usuario.tipo === '0' ?
                        <DashboardAdministrador />
                    :
                        null
                }

                {
                    usuario.tipo === 1 || usuario.tipo === '1' ?
                        <DashboardCabina />
                    :
                        null
                }

                {
                    usuario.tipo === 2 || usuario.tipo === '2' ?
                        <DashboardAuditorio />
                    :
                        null
                }

                {
                    usuario.tipo === 3 || usuario.tipo === '3' ?
                        <DashboardGimnasio />
                    :
                        null
                }

                {
                    usuario.tipo === 4 || usuario.tipo === '4' ?
                        <DashboardPsicologico />
                    :
                        null
                }

                {
                    usuario.tipo === 5 || usuario.tipo === '5' ?
                        <DashboardNutricional />
                    :
                        null
                }

                {
                    usuario.tipo === 6 || usuario.tipo === '6' ?
                        <DashboardDeportivo />
                    :
                        null
                }

                {
                    usuario.tipo === 7 || usuario.tipo === '7' ?
                        <DashboardEmpresa />
                    :
                        null
                }
            </div>
        </div>
    )
}

export default Dashboard;