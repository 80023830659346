import axios from 'axios';

import { GIMNASIO } from "../actions/types";

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const buscarGimnasios = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/gimnasio/gimnasios`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: GIMNASIO.LISTAR,
            payload: res.data.gimnasios
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const buscarJovenes = (busqueda) => async dispatch => {
    if(busqueda){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = {
            busqueda
        };

        try{
            await dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/gimnasio/jovenes`, body, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: GIMNASIO.BUSCAR,
                payload: res.data.jovenes
            });
        } catch (error){ 
            await await dispatch(changeLoader(false));

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        await dispatch({
            type: GIMNASIO.BUSCAR,
            payload: []
        });
    }
}

export const obtenerInscrito = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/gimnasio/detalles_joven`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: GIMNASIO.DETALLES,
            payload: res.data.joven
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const buscarInscritos = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(filtros);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/gimnasio/inscritos`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: GIMNASIO.LISTAR_INSCRITOS,
            payload: res.data.inscritos
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearRenovacion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/gimnasio/crear_renovacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: GIMNASIO.DETALLES,
            payload: res.data.joven
        });

        await dispatch({
            type: GIMNASIO.RENOVAR,
            payload: res.data.joven
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearInscripcion = (inscripcion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(inscripcion);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/gimnasio/crear_inscripcion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: GIMNASIO.INSCRIBIR,
            payload: res.data.inscripcion
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const cancelarInscripcion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/gimnasio/cancelar_inscripcion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: GIMNASIO.ELIMINAR,
            payload: body
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearAcceso = (acceso) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(acceso);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/gimnasio/crear_acceso`, body, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const buscarAccesos = (busqueda) => async dispatch => {
    if(busqueda){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify(busqueda);

        try{
            await dispatch(changeLoader(true));
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/gimnasio/accesos`, body, config);
            await dispatch(changeLoader(false));

            await dispatch({
                type: GIMNASIO.LISTAR_ACCESOS,
                payload: res.data.accesos
            });
        } catch (error){ 
            await await dispatch(changeLoader(false));

            if(error?.response?.data?.msg) {
                await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
            }
        }
    }else{
        await dispatch({
            type: GIMNASIO.BUSCAR,
            payload: []
        });
    }
}

export const filtrarInscritos = (opcion) => dispatch => {
    dispatch({
        type: GIMNASIO.FILTROS,
        payload: opcion
    });
}