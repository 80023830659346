import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { buscarUsuarios, filtarUsuarios } from '../../actions/usuarios';

const FiltrosUsuarios = () => {

    const dispatch =  useDispatch();

    const { usuario } = useSelector(state => state.auth);
    const { ventana_filtros } = useSelector(state => state.usuarios);

    const [statusProcesar, setStatusProcesar] = useState(false);

    const [filtrosData, setFiltrosData] = useState({
        id: null, 
        nombre: null,
        apellido_paterno: null,
        apellido_materno: null,
        tipo: null,
        fecha_inicio: null,
        fecha_fin: null
    });

    const [tipoData, setTipoData] = useState(null);
    const tiposFilter = [
        { value: 0, label: 'Administrador' }, 
        { value: 1, label: 'Asistente de cabina' }, 
        { value: 2, label: 'Asistente de auditorio' }, 
        { value: 3, label: 'Asistente de gimnasio' }, 
        { value: 4, label: 'Asesor psicológico' }, 
        { value: 5, label: 'Asesor nutricional' }, 
        { value: 6, label: 'Asesor deportivo' }
    ];

    /* Filtros */
    const handleAbrir = () => {
        setFiltrosData({
            ...filtrosData,
            id: usuario.empresa || null
        });
    }

    const handleSelectTipo = (option) => {
        setTipoData(option);

        setFiltrosData({
            ...filtrosData,
            tipo: option != null ? option.value : null
        });
    }

    const handleInputChange = ({ target }) => {
        setFiltrosData({
            ...filtrosData,
            [target.name]: target.value
        });
    }

    const handleDatePicker = (name, value) => {
        setFiltrosData({
            ...filtrosData,
            [name]: value
        });
    }
    
    const handleProcesar = async (filtros) => {
        await setStatusProcesar(true);

        if(!filtros){
            await dispatch(buscarUsuarios(filtrosData));
        }else{
            let datos_base = {
                ...filtrosData, 
                nombre: null,
                apellido_paterno: null,
                apellido_materno: null,
                tipo: null,
                fecha_inicio: null,
                fecha_fin: null
            };

            await dispatch(buscarUsuarios(datos_base));

            await setFiltrosData(datos_base);

            await setTipoData(null);
        } 
        
        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            dispatch(filtarUsuarios(false));
        }
    }

    return (
        <Modal show={ventana_filtros} size="lg" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Filtrar usuarios</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={filtrosData.nombre} onChange={e => handleInputChange(e)} />
                                <label htmlFor="nombre">Nombre</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Apellido paterno" id="apellido_paterno" name="apellido_paterno" value={filtrosData.apellido_paterno} onChange={e => handleInputChange(e)} />
                                <label htmlFor="apellido_paterno">Apellido paterno</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Apellido materno" id="apellido_materno" name="apellido_materno" value={filtrosData.apellido_materno} onChange={e => handleInputChange(e)} />
                                <label htmlFor="apellido_materno">Apellido materno</label>
                            </div>
                        </div>
                    </div>

                    {
                        usuario.empresa ? 
                            <></>
                        :
                            <div className="col-md-4 form-group mb-3">
                                <div className="input-group contenedor-select">
                                    <Select
                                        id="tipo"
                                        name="tipo"
                                        className="select-group"
                                        classNamePrefix="react-select"
                                        placeholder="Seleccionar tipo"
                                        isClearable
                                        options={tiposFilter}
                                        value={tipoData}
                                        onChange={(value) => handleSelectTipo(value)}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                </div>
                            </div>
                    }

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_inicio"
                                    name="fecha_inicio"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    selected={ filtrosData.fecha_inicio != null ? new Date(filtrosData.fecha_inicio) : '' }
                                    onChange={ date => handleDatePicker('fecha_inicio', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_inicio">Inicio de registro</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_fin"
                                    name="fecha_fin"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    selected={ filtrosData.fecha_fin != null ? new Date(filtrosData.fecha_fin) : '' }
                                    onChange={ date => handleDatePicker('fecha_fin', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_fin">Fin de registro</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <>
                            <button type="button" className="btn btn-primary" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Buscar todos</button>
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Buscar</button>
                        </>
                    :
                        <>
                            <button type="button" className="btn btn-primary" onClick={() => handleProcesar(true)}><i className="fas fa-search fa-sm"></i> Buscar todos</button>
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar(false)}><i className="fas fa-search fa-sm"></i> Buscar</button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default FiltrosUsuarios;