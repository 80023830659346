import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

import { setAlert } from '../../../actions/alert';
import { crearCabinaConfiguracion } from '../../../actions/configuracion';

const NuevaCabina = ({ cabina, reestablecerCabina }) => {

    const dispatch =  useDispatch();

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [cabinaData, setCabinaData] = useState({
        nombre: null,
        intervalo_reserva: null,
        descripcion: null, 
        horarios: [
            [],
            [],
            [],
            [],
            [],
            [],
            []
        ]
    });

    const [intervaloCitaData, setIntervaloCitaData] = useState(null);
    const intervalosCitasFilter = [
        { value: 15, label: '15 minutos' }, 
        { value: 30, label: '30 minutos' }, 
        { value: 60, label: '1 hora' }, 
        { value: 90, label: '1 hora y media' }, 
        { value: 120, label: '2 horas' }
    ];

    /* Crear cabina */
    const handleInputChange = ({ target }) => {
        setCabinaData({
            ...cabinaData,
            [target.name]: target.value
        });
    }

    const handleSelectIntervalo = (option) => {
        setIntervaloCitaData(option);

        setCabinaData({
            ...cabinaData,
            intervalo_reserva: option != null ? option.value : null
        });
    }

    const handleChangeTime = (posicion, dia, hora, tipo) => {
        let horarios = cabinaData.horarios;

        if(tipo == 1){
            horarios[dia][posicion] = {
                ...horarios[dia][posicion], 
                hora_inicio: hora
            };
        }else{
            horarios[dia][posicion] = {
                ...horarios[dia][posicion], 
                hora_fin: hora
            };
        }

        setCabinaData({
            ...cabinaData,
            horarios
        });
    }

    const handleAgregarHorario = (dia) => {
        let horarios = cabinaData.horarios;

        horarios[dia].push({
            dia, 
            hora_inicio: '12:00',
            hora_fin: '12:00'
        });

        setCabinaData({
            ...cabinaData,
            horarios
        });
    }

    const handleEliminarTime = (dia, posicion) => {
        let horarios = cabinaData.horarios;

        horarios[dia].splice(posicion, 1);

        setCabinaData({
            ...cabinaData,
            horarios
        });
    }
    
    const handleProcesar = async () => {
        if(cabinaData.nombre && cabinaData.intervalo_reserva !== null){
            for (let x = 0; x < cabinaData.horarios.length; x++) {
                let horarios_dia = cabinaData.horarios[x];

                for (let i = 0; i < horarios_dia.length; i++) {
                    if(Date.parse('2024-05-25 ' + horarios_dia[i].hora_inicio + ':00') > Date.parse('2024-05-25 ' + horarios_dia[i].hora_fin + ':00')){
                        await dispatch(setAlert('Un horario de inicio es mayor a uno de finalización', 'danger'));

                        return;
                    }

                    for (let z = 0; z < horarios_dia.length; z++) {
                        if(i !== z){
                            if(Date.parse('2024-05-25 ' + horarios_dia[i].hora_inicio + ':00') == Date.parse('2024-05-25 ' + horarios_dia[z].hora_inicio + ':00')){
                                await dispatch(setAlert('Un horario de inicio es igual a otro en el mismo día', 'danger'));
        
                                return;
                            }else{
                                if(Date.parse('2024-05-25 ' + horarios_dia[i].hora_inicio + ':00') < Date.parse('2024-05-25 ' + horarios_dia[z].hora_inicio + ':00')){
                                    if(Date.parse('2024-05-25 ' + horarios_dia[i].hora_fin + ':00') >= Date.parse('2024-05-25 ' + horarios_dia[z].hora_inicio + ':00')){
                                        await dispatch(setAlert('Un horario interfiere con la hora de inicio de otro en el mismo día', 'danger'));
                
                                        return;
                                    }
                                }else{
                                    if(Date.parse('2024-05-25 ' + horarios_dia[i].hora_inicio + ':00') <= Date.parse('2024-05-25 ' + horarios_dia[z].hora_fin + ':00')){
                                        await dispatch(setAlert('Un horario interfiere con la hora de finalización de otro en el mismo día', 'danger'));
                
                                        return;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            await setStatusProcesar(true);
            await dispatch(crearCabinaConfiguracion(cabinaData));
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Completa todos los campos', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setCabinaData({
                nombre: null,
                intervalo_reserva: null,
                descripcion: null, 
                horarios: [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    []
                ]
            });

            setIntervaloCitaData(null);
            setNuevoModal(false);
            reestablecerCabina(null);
        }
    }

    /* Funciones generales */
    const createFilasHorarios = (dia) => {

        let horarios_campos = [];

        let horarios = cabinaData.horarios[dia];

        if(horarios?.length > 0){
            for (let x = 0; x < horarios?.length; x++) {
                horarios_campos.push(
                    <div className="col-md-12 mb-3 row" key={'horario_' + dia  + '_' + x}>
                        <div className="col-md-6 form-group">
                            <div className="form-floating">
                                <TimePicker className="form-control text-center" clearAriaLabel="Reestablecer" dateFormat="h:m" value={horarios[x]?.hora_inicio || new Date()} onChange={ time => handleChangeTime(x, dia, time, 1)} />
                                <label className="font-weight-bold">Hora inicial</label>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <div className="input-group h-100">
                                <div className="form-floating">
                                    <TimePicker className="form-control text-center" clearAriaLabel="Reestablecer" dateFormat="h:m" value={horarios[x]?.hora_fin || new Date()} onChange={ time => handleChangeTime(x, dia, time, 2)} />
                                    <label className="font-weight-bold">Hora final</label>
                                </div>

                                <div className="input-group-append">
                                    <button className="btn btn-danger deleteField" onClick={e => handleEliminarTime(dia, x)}><i className="fa-solid fa-xmark"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }else{
            horarios_campos.push(
                <div className="col-md-12 mt-5 pt-3 text-center" key={'sin_horarios_' + dia}>
                    <h5>Agrega horarios de operación</h5>
                </div>
            );
        }

        return horarios_campos;
    }

    useEffect(() => {
        if(cabina){
            setNuevoModal(true);

            setCabinaData({
                ...cabinaData
            });
        }        
    }, [cabina]);

    return (
        <Modal show={nuevoModal} size="lg" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar cabina</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-3 form-group mb-2">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={cabinaData.nombre} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="nombre">Nombre</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 form-group mb-2">
                        <div className="input-group h-100">
                            <Select
                                id="intervalo_cita"
                                name="intervalo_cita"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Intervalo de cita"
                                options={intervalosCitasFilter}
                                value={intervaloCitaData}
                                isClearable
                                onChange={(value) => handleSelectIntervalo(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-2">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Descripción" id="descripcion" name="descripcion" value={cabinaData.descripcion} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="descripcion">Descripción</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mt-2">
                        <div className="d-flex align-items-start">
                            <div className="nav flex-column nav-pills me-3" id="dias-tab" role="tablist" aria-orientation="vertical">
                                <button className="nav-link active" id="dia-lunes-tab" data-bs-toggle="pill" data-bs-target="#dia-lunes" type="button" role="tab" aria-controls="dia-lunes" aria-selected="true">Lunes</button>
                                <button className="nav-link" id="dia-martes-tab" data-bs-toggle="pill" data-bs-target="#dia-martes" type="button" role="tab" aria-controls="dia-martes" aria-selected="false">Martes</button>
                                <button className="nav-link" id="dia-miercoles-tab" data-bs-toggle="pill" data-bs-target="#dia-miercoles" type="button" role="tab" aria-controls="dia-miercoles" aria-selected="false">Miercoles</button>
                                <button className="nav-link" id="dia-jueves-tab" data-bs-toggle="pill" data-bs-target="#dia-jueves" type="button" role="tab" aria-controls="dia-jueves" aria-selected="false">Jueves</button>
                                <button className="nav-link" id="dia-viernes-tab" data-bs-toggle="pill" data-bs-target="#dia-viernes" type="button" role="tab" aria-controls="dia-viernes" aria-selected="false">Viernes</button>
                                <button className="nav-link" id="dia-sabado-tab" data-bs-toggle="pill" data-bs-target="#dia-sabado" type="button" role="tab" aria-controls="dia-sabado" aria-selected="false">Sabado</button>
                                <button className="nav-link" id="dia-domingo-tab" data-bs-toggle="pill" data-bs-target="#dia-domingo" type="button" role="tab" aria-controls="dia-domingo" aria-selected="false">Domingo</button>
                            </div>
                            <div className="tab-content w-100">
                                <div className="tab-pane fade row show active" id="dia-lunes" role="tabpanel" aria-labelledby="dia-lunes-tab">
                                    <div className="col-md-12 mb-4 text-end">
                                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarHorario(1)}><i className="fas fa-plus fa-sm"></i> Horario</button>
                                    </div>

                                    { createFilasHorarios(1) }
                                </div>
                                <div className="tab-pane fade" id="dia-martes" role="tabpanel" aria-labelledby="dia-martes-tab">
                                    <div className="col-md-12 mb-4 text-end">
                                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarHorario(2)}><i className="fas fa-plus fa-sm"></i> Horario</button>
                                    </div>

                                    { createFilasHorarios(2) }
                                </div>
                                <div className="tab-pane fade" id="dia-miercoles" role="tabpanel" aria-labelledby="dia-miercoles-tab">
                                    <div className="col-md-12 mb-4 text-end">
                                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarHorario(3)}><i className="fas fa-plus fa-sm"></i> Horario</button>
                                    </div>

                                    { createFilasHorarios(3) }
                                </div>
                                <div className="tab-pane fade" id="dia-jueves" role="tabpanel" aria-labelledby="dia-jueves-tab">
                                    <div className="col-md-12 mb-4 text-end">
                                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarHorario(4)}><i className="fas fa-plus fa-sm"></i> Horario</button>
                                    </div>

                                    { createFilasHorarios(4) }
                                </div>
                                <div className="tab-pane fade" id="dia-viernes" role="tabpanel" aria-labelledby="dia-viernes-tab">
                                    <div className="col-md-12 mb-4 text-end">
                                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarHorario(5)}><i className="fas fa-plus fa-sm"></i> Horario</button>
                                    </div>

                                    { createFilasHorarios(5) }
                                </div>
                                <div className="tab-pane fade" id="dia-sabado" role="tabpanel" aria-labelledby="dia-sabado-tab">
                                    <div className="col-md-12 mb-4 text-end">
                                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarHorario(6)}><i className="fas fa-plus fa-sm"></i> Horario</button>
                                    </div>

                                    { createFilasHorarios(6) }
                                </div>
                                <div className="tab-pane fade" id="dia-domingo" role="tabpanel" aria-labelledby="dia-domingo-tab">
                                    <div className="col-md-12 mb-4 text-end">
                                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarHorario(0)}><i className="fas fa-plus fa-sm"></i> Horario</button>
                                    </div>

                                    { createFilasHorarios(0) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Agregar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Agregar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default NuevaCabina;