import { combineReducers } from 'redux';

import alert from './alert';
import loader from './loader';
import breadcrumb from './breadcrumb';

import auth from './auth';
import dashboard from './dashboard';
import empresas from './empresas';
import jovenes from './jovenes';
import usuarios from './usuarios';
import promociones from './promociones';
import eventos from './eventos';
import programas from './programas';
import apoyos from './apoyos';
import becas from './becas';
import cabinas from './cabinas';
import auditorios from './auditorios';
import gimnasios from './gimnasios';
import psicologica from './psicologica';
import nutricional from './nutricional';
import deportiva from './deportiva';
import reportes from './reportes';
import notificaciones from './notificaciones';
import configuracion from './configuracion';

export default combineReducers({
    alert, loader, breadcrumb, auth, dashboard, empresas, jovenes, usuarios, promociones, eventos, programas, apoyos, becas, cabinas, auditorios, gimnasios, psicologica, nutricional, deportiva, reportes, notificaciones, configuracion
});