// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Breadcrumb
export const EDITAR_BREADCRUMB = 'EDITAR_BREADCRUMB';

// Loader
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

// Auth
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const OBTENER_USUARIO = 'OBTENER_USUARIO';
export const OBTENER_USUARIO_ERROR = 'OBTENER_USUARIO_ERROR';
export const LOGOUT = 'LOGOUT';
export const RECUPERAR_USUARIO = 'RECUPERAR_USUARIO';
export const RESTABLECER_USUARIO = 'RESTABLECER_USUARIO';

// Dashboard
export const DASHBOARD = {
    OBTENER: 'OBTENER_DASHBOARD'
};

// Empresas
export const EMPRESAS = {
    BUSCAR: 'BUSCAR_EMPRESAS', 
    DETALLES: 'DETALLES_EMPRESA', 
    EDITAR: 'EDITAR_EMPRESA', 
    ELIMINAR: 'ELIMINAR_EMPRESA', 
    FILTROS: 'FILTRAR_EMPRESAS', 
    USUARIOS: {
        BUSCAR: 'BUSCAR_USUARIOS_EMPRESAS', 
        AGREGAR: 'AGREGAR_USUARIO_EMPRESA', 
        DETALLES: 'DETALLES_USUARIO_EMPRESA', 
        EDITAR: 'EDITAR_USUARIO_EMPRESA', 
        ELIMINAR: 'ELIMINAR_USUARIO_EMPRESA'
    }, 
    PROMOCIONES: {
        BUSCAR: 'BUSCAR_PROMOCIONES_EMPRESAS', 
        AGREGAR: 'AGREGAR_PROMOCION_EMPRESA', 
        EDITAR: 'EDITAR_PROMOCION_EMPRESA', 
        DETALLES: 'DETALLES_PROMOCION_EMPRESA', 
        ELIMINAR: 'ELIMINAR_PROMOCION_EMPRESA'
    }
};

// Jovenes
export const JOVENES = {
    BUSCAR: 'BUSCAR_JOVENES',
    DETALLES: 'DETALLES_JOVEN',
    ELIMINAR: 'ELIMINAR_JOVEN',
    FILTROS: 'FILTRAR_JOVENES'
};

// Usuarios
export const USUARIO = {
    BUSCAR: 'BUSCAR_USUARIOS', 
    AGREGAR: 'AGREGAR_USUARIO', 
    DETALLES: 'DETALLES_USUARIO', 
    EDITAR: 'EDITAR_USUARIO', 
    ELIMINAR: 'ELIMINAR_USUARIO', 
    FILTROS: 'FILTRAR_USUARIOS'
};

// Promociones
export const PROMOCIONES = {
    BUSCAR: 'BUSCAR_PROMOCIONES', 
    AGREGAR: 'AGREGAR_PROMOCION', 
    DETALLES: 'DETALLES_PROMOCION', 
    EDITAR: 'EDITAR_PROMOCION', 
    ELIMINAR: 'ELIMINAR_PROMOCION', 
    ESCANER: 'ESCANER_PROMOCION', 
    FILTROS: 'FILTRAR_PROMOCIONES'
};

// Eventos
export const EVENTO = {
    BUSCAR: 'BUSCAR_EVENTOS', 
    DETALLES: 'DETALLES_EVENTO', 
    ELIMINAR: 'ELIMINAR_EVENTO', 
    FILTROS: 'FILTRAR_EVENTOS', 
    RESERVACIONES: {
        BUSCAR: 'BUSCAR_RESERVACIONES_EVENTO', 
        DETALLES: 'DETALLES_RESERVACION_EVENTO', 
        STATUS: 'STATUS_RESERVACION_EVENTO', 
        FILTROS: 'FILTRAR_SOLICITUDES_PROGRAMA', 
        ESCANER: 'ESCANER_RESERVACION_EVENTO'
    }
};

// Programas
export const PROGRAMA = {
    BUSCAR: 'BUSCAR_PROGRAMAS', 
    BUSCAR_REQUERIMIENTOS: 'BUSCAR_REQUERIMIENTOS_PROGRAMAS', 
    DETALLES: 'DETALLES_PROGRAMA', 
    BUSCAR_SOLICITUDES: 'BUSCAR_SOLICITUDES_PROGRAMA', 
    ELIMINAR: 'ELIMINAR_PROGRAMA', 
    FILTROS: 'FILTRAR_PROGRAMAS', 
    SOLICITUDES: {
        DETALLES: 'DETALLES_SOLICITUD_PROGRAMA', 
        STATUS: 'STATUS_SOLICITUD_PROGRAMA', 
        REQUERIMIENTO: {
            DETALLES: 'DETALLES_MENSAJE_SOLICITUD_PROGRAMA', 
            STATUS: 'STATUS_MENSAJE_SOLICITUD_PROGRAMA'
        }, 
        MENSAJES: {
            LISTAR: 'LISTAR_MENSAJES_SOLICITUD_PROGRAMA', 
            CREAR: 'CREAR_MENSAJE_SOLICITUD_PROGRAMA'
        }, 
        FILTROS: 'FILTRAR_SOLICITUDES_PROGRAMA'
    }, 
    ARCHIVOS: {
        LISTAR: 'LISTAR_ARCHIVOS_PROGRAMA', 
        DETALLES: 'DETALLES_ARCHIVO_PROGRAMA'
    }
};

// Apoyos
export const APOYO = {
    BUSCAR: 'BUSCAR_APOYOS', 
    BUSCAR_REQUERIMIENTOS: 'BUSCAR_REQUERIMIENTOS_APOYOS', 
    DETALLES: 'DETALLES_APOYO', 
    BUSCAR_SOLICITUDES: 'BUSCAR_SOLICITUDES_APOYO', 
    ELIMINAR: 'ELIMINAR_APOYO', 
    FILTROS: 'FILTRAR_APOYOS', 
    SOLICITUDES: {
        DETALLES: 'DETALLES_SOLICITUD_APOYO', 
        STATUS: 'STATUS_SOLICITUD_APOYO', 
        REQUERIMIENTO: {
            DETALLES: 'DETALLES_MENSAJE_SOLICITUD_APOYO', 
            STATUS: 'STATUS_MENSAJE_SOLICITUD_APOYO'
        }, 
        MENSAJES: {
            LISTAR: 'LISTAR_MENSAJES_SOLICITUD_APOYO', 
            CREAR: 'CREAR_MENSAJE_SOLICITUD_APOYO'
        }, 
        FILTROS: 'FILTRAR_SOLICITUDES_APOYO'
    }, 
    ARCHIVOS: {
        LISTAR: 'LISTAR_ARCHIVOS_APOYO', 
        DETALLES: 'DETALLES_ARCHIVO_APOYO'
    }
};

// Becas
export const BECA = {
    BUSCAR: 'BUSCAR_BECAS', 
    BUSCAR_REQUERIMIENTOS: 'BUSCAR_REQUERIMIENTOS_BECAS', 
    DETALLES: 'DETALLES_BECA', 
    BUSCAR_SOLICITUDES: 'BUSCAR_SOLICITUDES_BECA', 
    ELIMINAR: 'ELIMINAR_BECA', 
    FILTROS: 'FILTRAR_BECAS', 
    SOLICITUDES: {
        DETALLES: 'DETALLES_SOLICITUD_BECA', 
        STATUS: 'STATUS_SOLICITUD_BECA', 
        REQUERIMIENTO: {
            DETALLES: 'DETALLES_MENSAJE_SOLICITUD_BECA', 
            STATUS: 'STATUS_MENSAJE_SOLICITUD_BECA'
        }, 
        MENSAJES: {
            LISTAR: 'LISTAR_MENSAJES_SOLICITUD_BECA', 
            CREAR: 'CREAR_MENSAJE_SOLICITUD_BECA'
        }, 
        FILTROS: 'FILTRAR_SOLICITUDES_BECA'
    }, 
    ARCHIVOS: {
        LISTAR: 'LISTAR_ARCHIVOS_BECA', 
        DETALLES: 'DETALLES_ARCHIVO_BECA'
    }
};

// Cabina de grabación
export const CABINA = {
    BUSCAR: 'BUSCAR_CABINA_CITAS', 
    BUSCAR_HORARIOS_RESERVACION: 'BUSCAR_CABINA_HORARIOS_RESERVACION', 
    AGREGAR: 'AGREGAR_CABINA_CITA', 
    DETALLES: 'DETALLES_CABINA_CITA', 
    EDITAR: 'EDITAR_CABINA_CITA', 
    ELIMINAR: 'ELIMINAR_CABINA_CITA', 
    FILTROS: 'FILTRAR_CABINA_RESERVACIONES'
};

// Auditorio
export const AUDITORIO = {
    BUSCAR: 'BUSCAR_AUDITORIO_CITAS', 
    BUSCAR_HORARIOS_RESERVACION: 'BUSCAR_AUDITORIO_HORARIOS_RESERVACION', 
    AGREGAR: 'AGREGAR_AUDITORIO_CITA', 
    DETALLES: 'DETALLES_AUDITORIO_CITA', 
    EDITAR: 'EDITAR_AUDITORIO_CITA', 
    ELIMINAR: 'ELIMINAR_AUDITORIO_CITA', 
    FILTROS: 'FILTRAR_AUDITORIO_RESERVACIONES'
};

// Gimnasio
export const GIMNASIO = {
    LISTAR: 'LISTAR_GIMNASIOS', 
    BUSCAR: 'BUSCAR_JOVENES_GIMNASIO', 
    DETALLES: 'DETALLES_JOVEN_GIMNASIO', 
    LISTAR_ACCESOS: 'LISTAR_ACCESOS_GIMNASIO', 
    LISTAR_INSCRITOS: 'LISTAR_INSCRITOS_GIMNASIO', 
    RENOVAR: 'RENOVAR_JOVEN_GIMNASIO', 
    INSCRIBIR: 'INSCRIBIR_JOVEN_GIMNASIO', 
    ELIMINAR: 'ELIMINAR_JOVEN_GIMNASIO', 
    FILTROS: 'FILTRAR_INSCRITOS'
};

// Asesoria psicologica
export const PSICOLOGICA = {
    BUSCAR_ASESORIAS: 'BUSCAR_PSICOLOGICA_ASESORIAS', 
    AGREGAR_ASESORIA: 'AGREGAR_PSICOLOGICA_ASESORIA', 
    DETALLES_ASESORIA: 'DETALLES_PSICOLOGICA_ASESORIA', 
    MENSAJES_ASESORIA: 'MENSAJES_PSICOLOGICA_ASESORIA', 
    CREAR_MENSAJE: 'CREAR_PSICOLOGICA_MENSAJE', 
    EDITAR_ASESORIA: 'EDITAR_PSICOLOGICA_ASESORIA', 
    ELIMINAR_ASESORIA: 'ELIMINAR_PSICOLOGICA_ASESORIA', 
    AGREGAR_NOTA: 'AGREGAR_PSICOLOGICA_NOTA', 
    DETALLES_NOTA: 'DETALLES_PSICOLOGICA_NOTA', 
    EDITAR_NOTA: 'EDITAR_PSICOLOGICA_NOTA', 
    ELIMINAR_NOTA: 'ELIMINAR_PSICOLOGICA_NOTA', 
    BUSCAR_CITAS: 'BUSCAR_PSICOLOGICA_CITAS', 
    BUSCAR_ASESORIAS_CITA: 'BUSCAR_PSICOLOGICA_ASESORIAS_CITA', 
    BUSCAR_HORARIOS_CITA: 'BUSCAR_PSICOLOGICA_HORARIOS_CITA', 
    AGREGAR_CITA: 'AGREGAR_PSICOLOGICA_CITA', 
    DETALLES_CITA: 'DETALLES_PSICOLOGICA_CITA', 
    EDITAR_CITA: 'EDITAR_PSICOLOGICA_CITA', 
    ELIMINAR_CITA: 'ELIMINAR_PSICOLOGICA_CITA', 
    FILTROS: 'FILTRAR_PSICOLOGICA_CITAS'
};

// Asesoria nutricional
export const NUTRICIONAL = {
    BUSCAR_ASESORIAS: 'BUSCAR_NUTRICIONAL_ASESORIAS', 
    AGREGAR_ASESORIA: 'AGREGAR_NUTRICIONAL_ASESORIA', 
    DETALLES_ASESORIA: 'DETALLES_NUTRICIONAL_ASESORIA', 
    MENSAJES_ASESORIA: 'MENSAJES_NUTRICIONAL_ASESORIA', 
    CREAR_MENSAJE: 'CREAR_NUTRICIONAL_MENSAJE', 
    EDITAR_ASESORIA: 'EDITAR_NUTRICIONAL_ASESORIA', 
    ELIMINAR_ASESORIA: 'ELIMINAR_NUTRICIONAL_ASESORIA', 
    AGREGAR_NOTA: 'AGREGAR_NUTRICIONAL_NOTA', 
    DETALLES_NOTA: 'DETALLES_NUTRICIONAL_NOTA', 
    EDITAR_NOTA: 'EDITAR_NUTRICIONAL_NOTA', 
    ELIMINAR_NOTA: 'ELIMINAR_NUTRICIONAL_NOTA', 
    BUSCAR_CITAS: 'BUSCAR_NUTRICIONAL_CITAS', 
    BUSCAR_ASESORIAS_CITA: 'BUSCAR_NUTRICIONAL_ASESORIAS_CITA', 
    BUSCAR_HORARIOS_CITA: 'BUSCAR_NUTRICIONAL_HORARIOS_CITA', 
    AGREGAR_CITA: 'AGREGAR_NUTRICIONAL_CITA', 
    DETALLES_CITA: 'DETALLES_NUTRICIONAL_CITA', 
    EDITAR_CITA: 'EDITAR_NUTRICIONAL_CITA', 
    ELIMINAR_CITA: 'ELIMINAR_NUTRICIONAL_CITA', 
    FILTROS: 'FILTRAR_NUTRICIONAL_CITAS'
};

// Asesoria deportiva
export const DEPORTIVA = {
    BUSCAR_ASESORIAS: 'BUSCAR_DEPORTIVA_ASESORIAS', 
    AGREGAR_ASESORIA: 'AGREGAR_DEPORTIVA_ASESORIA', 
    DETALLES_ASESORIA: 'DETALLES_DEPORTIVA_ASESORIA', 
    MENSAJES_ASESORIA: 'MENSAJES_DEPORTIVA_ASESORIA', 
    CREAR_MENSAJE: 'CREAR_DEPORTIVA_MENSAJE', 
    EDITAR_ASESORIA: 'EDITAR_DEPORTIVA_ASESORIA', 
    ELIMINAR_ASESORIA: 'ELIMINAR_DEPORTIVA_ASESORIA', 
    AGREGAR_NOTA: 'AGREGAR_DEPORTIVA_NOTA', 
    DETALLES_NOTA: 'DETALLES_DEPORTIVA_NOTA', 
    EDITAR_NOTA: 'EDITAR_DEPORTIVA_NOTA', 
    ELIMINAR_NOTA: 'ELIMINAR_DEPORTIVA_NOTA', 
    BUSCAR_CITAS: 'BUSCAR_DEPORTIVA_CITAS', 
    BUSCAR_ASESORIAS_CITA: 'BUSCAR_DEPORTIVA_ASESORIAS_CITA', 
    BUSCAR_HORARIOS_CITA: 'BUSCAR_DEPORTIVA_HORARIOS_CITA', 
    AGREGAR_CITA: 'AGREGAR_DEPORTIVA_CITA', 
    DETALLES_CITA: 'DETALLES_DEPORTIVA_CITA', 
    EDITAR_CITA: 'EDITAR_DEPORTIVA_CITA', 
    ELIMINAR_CITA: 'ELIMINAR_DEPORTIVA_CITA', 
    FILTROS: 'FILTRAR_DEPORTIVA_CITAS'
};

// Reportes
export const REPORTES = {
    BUSCAR_REQUERIMIENTOS: 'BUSCAR_REQUERIMIENTOS_REPORTES'
};

// Notificaciones
export const NOTIFICACIONES = {
    BUSCAR: 'BUSCAR_NOTIFICACIONES', 
    ENVIAR: 'AGREGAR_NOTIFICACION'
};

// Configuración
export const CONFIGURACION_CATEGORIAS_EMPRESAS = {
    BUSCAR: 'BUSCAR_CATEGORIAS_EMPRESAS', 
    AGREGAR: 'AGREGAR_CATEGORIA_EMPRESA', 
    DETALLES: 'DETALLES_CATEGORIA_EMPRESA', 
    EDITAR: 'EDITAR_CATEGORIA_EMPRESA', 
    ELIMINAR: 'ELIMINAR_CATEGORIA_EMPRESA'
};

export const CONFIGURACION_EXTENSIONES = {
    BUSCAR: 'BUSCAR_EXTENSIONES', 
    AGREGAR: 'AGREGAR_EXTENSION', 
    DETALLES: 'DETALLES_EXTENSION', 
    EDITAR: 'EDITAR_EXTENSION', 
    ELIMINAR: 'ELIMINAR_EXTENSION'
};

export const CONFIGURACION_REQUERIMIENTOS = {
    BUSCAR: 'BUSCAR_REQUERIMIENTOS', 
    AGREGAR: 'AGREGAR_REQUERIMIENTO', 
    DETALLES: 'DETALLES_REQUERIMIENTO', 
    EDITAR: 'EDITAR_REQUERIMIENTO', 
    ELIMINAR: 'ELIMINAR_REQUERIMIENTO'
};

export const CONFIGURACION_DEPENDENCIAS = {
    BUSCAR: 'BUSCAR_DEPENDENCIAS', 
    EDITAR: 'EDITAR_DEPENDENCIA'
};

export const CONFIGURACION_GIMNASIOS = {
    BUSCAR: 'BUSCAR_GIMNASIOS', 
    AGREGAR: 'AGREGAR_GIMNASIO', 
    DETALLES: 'DETALLES_GIMNASIO', 
    EDITAR: 'EDITAR_GIMNASIO', 
    ELIMINAR: 'ELIMINAR_GIMNASIO'
};

export const CONFIGURACION_CABINAS = {
    BUSCAR: 'BUSCAR_CABINAS', 
    AGREGAR: 'AGREGAR_CABINA', 
    DETALLES: 'DETALLES_CABINA', 
    EDITAR: 'EDITAR_CABINA', 
    ELIMINAR: 'ELIMINAR_CABINA'
};

export const CONFIGURACION_AUDITORIOS = {
    BUSCAR: 'BUSCAR_AUDITORIOS', 
    AGREGAR: 'AGREGAR_AUDITORIO', 
    DETALLES: 'DETALLES_AUDITORIO', 
    EDITAR: 'EDITAR_AUDITORIO', 
    ELIMINAR: 'ELIMINAR_AUDITORIO'
};

export const CONFIGURACION_ASESORES = {
    BUSCAR: 'BUSCAR_ASESORES', 
    DETALLES: 'DETALLES_ASESOR', 
    EDITAR: 'EDITAR_ASESOR'
};

export const CONFIGURACION_PERFIL = {
    EDITAR_PERSONAL: 'EDITAR_PERFIL_PERSONAL', 
    EDITAR_EMPRESARIAL: 'EDITAR_PERFIL_EMPRESARIAL'
};