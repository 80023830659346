import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { setAlert } from '../../actions/alert';
import { buscarCabinasConfiguracion, buscarAuditoriosConfiguracion } from '../../actions/configuracion';
import { generarReporteReservaciones } from '../../actions/reportes';

const FiltrosReservaciones = ({ reservacionReporte, reestablecerFiltros }) => {

    const dispatch =  useDispatch();

    const cabinas = useSelector(state => state.configuracion.cabinas);
    const auditorios = useSelector(state => state.configuracion.auditorios);

    const [statusProcesar, setStatusProcesar] = useState(false);

    const [busquedaData, setBusquedaData] = useState({
        tipo: null,
        instalacion: null,
        status: null,
        fecha_inicio: null,
        fecha_fin: null
    });

    const [tipoData, setTipoData] = useState(null);
    const tiposFilter = [
        { value: 0, label: 'Cabina' }, 
        { value: 1, label: 'Auditorio' }
    ];

    const [instalacionData, setInstalacionData] = useState(null);
    const [instalacionesFilter, setInstalacionesFilter] = useState([]);

    const [statusData, setStatusData] = useState(null);
    const statusFilter = [
        { value: 0, label: 'Pendiente' }, 
        { value: 1, label: 'Autorizada' }, 
        { value: 2, label: 'Rechazada' }
    ];

    /* Buscar */
    const handleAbrir = () => {}

    const handleDatePicker = (name, value) => {
        setBusquedaData({
            ...busquedaData,
            [name]: value
        });
    }

    const handleSelectTipo = (option) => {
        setTipoData(option);
        setInstalacionData(null);

        setBusquedaData({
            ...busquedaData,
            tipo: option != null ? option.value : null, 
            instalacion: null
        });

        let valor_cabina_buscar = false;
        let valor_auditorio_buscar = false;

        if(option){
            if(option.value == 0){
                valor_cabina_buscar = true;
            }else{
                valor_auditorio_buscar = true;
            }
        }

        dispatch(buscarCabinasConfiguracion(valor_cabina_buscar));
        dispatch(buscarAuditoriosConfiguracion(valor_auditorio_buscar));
    }

    const handleSelectInstalacion = (option) => {
        setInstalacionData(option);

        setBusquedaData({
            ...busquedaData,
            instalacion: option != null ? option.value : null
        });
    }

    const handleSelectStatus = (option) => {
        setStatusData(option);

        setBusquedaData({
            ...busquedaData,
            status: option != null ? option.value : null
        });
    }
    
    const handleProcesar = async () => {
        if(new Date(busquedaData.fecha_inicio) <= new Date(busquedaData.fecha_fin)){
            await setStatusProcesar(true);

            await dispatch(generarReporteReservaciones(busquedaData));
                        
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Tu fecha de inicio es mayor a tu fecha de finalización', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setBusquedaData({
                tipo: null,
                instalacion: null,
                status: null,
                fecha_inicio: null,
                fecha_fin: null
            });

            setTipoData(null);
            setInstalacionData(null);
            setStatusData(null);

            dispatch(buscarCabinasConfiguracion(false));
            dispatch(buscarAuditoriosConfiguracion(false));

            reestablecerFiltros(null);
        }
    }

    useEffect(() => {
        let options = [];

        for (var i = 0; i < cabinas.listado?.length; i++) {
            options.push({ value: cabinas.listado[i]._id, label: cabinas.listado[i].nombre });
        }

        setInstalacionesFilter(options);
    }, [cabinas]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < auditorios.listado?.length; i++) {
            options.push({ value: auditorios.listado[i]._id, label: auditorios.listado[i].nombre });
        }

        setInstalacionesFilter(options);
    }, [auditorios]);

    return (
        <Modal show={reservacionReporte} size="lg" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Filtros de búsqueda</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="tipo"
                                name="tipo"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar tipo de instalación"
                                isClearable
                                options={tiposFilter}
                                value={tipoData}
                                onChange={(value) => handleSelectTipo(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="instalacion"
                                name="instalacion"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder={'Seleccionar ' + (tipoData ? (tipoData.value == 0 ? 'cabina' : 'auditorio') : 'instalación')}
                                isClearable
                                options={instalacionesFilter}
                                value={instalacionData}
                                onChange={(value) => handleSelectInstalacion(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_inicio"
                                    name="fecha_inicio"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    locale="es-ES"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={ busquedaData.fecha_inicio != null ? new Date(busquedaData.fecha_inicio) : '' }
                                    onChange={ date => handleDatePicker('fecha_inicio', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_inicio">Fecha de inicio de reservación</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_fin"
                                    name="fecha_fin"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    locale="es-ES"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={ busquedaData.fecha_fin != null ? new Date(busquedaData.fecha_fin) : '' }
                                    onChange={ date => handleDatePicker('fecha_fin', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_fin">Fecha de finalización de reservación</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="status"
                                name="status"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar status"
                                isClearable
                                options={statusFilter}
                                value={statusData}
                                onChange={(value) => handleSelectStatus(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>   
                </div>                  
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Buscar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-magnifying-glass fa-sm"></i> Buscar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default FiltrosReservaciones;