import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { setAlert } from '../../actions/alert';
import { obtenerAsesoria } from '../../actions/nutricional';

const DetalleAsesoria = ({ asesoria, reestablecerAsesoria, editarAsesoria, eliminarAsesoria, detallesCompletosAsesoria }) => {

    const dispatch =  useDispatch();

    const { detalles_asesoria } = useSelector(state => state.nutricional);

    const [detalleModal, setDetalleModal] = useState(false);

    const [detallesData, setDetallesData] = useState({
        id: null, 
        nombre_asesor: null, 
        apellido_paterno_asesor: null, 
        apellido_materno_asesor: null, 
        nombre_joven: null, 
        apellido_paterno_joven: null, 
        apellido_materno_joven: null, 
        fecha_inicio: null, 
        fecha_fin: null, 
        fecha_cita: null, 
        status: null, 
        url_perfil: null
    });

    /* Eliminar asesoria */
    const handleEliminar = () => {
        if(detallesData.id){
            handleCerrar();
            eliminarAsesoria(detallesData);
        }else{
            dispatch(setAlert('Selecciona primero una asesoria', 'danger'));
        }
    }

    /* Editar asesoria */
    const handleEditar = () => {
        if(detallesData.id){
            handleCerrar();
            editarAsesoria(detallesData.id);
        }else{
            dispatch(setAlert('Selecciona primero una asesoria', 'danger'));
        }
    }

    /* Detalles completos */
    const handleDetalles = async () => {
        if(detallesData.id){
            detallesCompletosAsesoria(detallesData.id);
            handleCerrar();
        }else{
            dispatch(setAlert('Selecciona primero una asesoria', 'danger'));
        }
    }

    const handleCerrar = () => {
        setDetallesData({
            id: null, 
            nombre_asesor: null, 
            apellido_paterno_asesor: null, 
            apellido_materno_asesor: null, 
            nombre_joven: null, 
            apellido_paterno_joven: null, 
            apellido_materno_joven: null, 
            fecha_inicio: null, 
            fecha_fin: null, 
            fecha_cita: null, 
            status: null, 
            url_perfil: null
        });
        setDetalleModal(false);
        reestablecerAsesoria(null);
    }

    /* Funciones generales */
    const handleStatus = (status) => {
        switch(status){
            case 0:
                return(
                    <h5><Badge pill bg="warning text-black">Pendiente</Badge></h5>
                )
            case 1:
                return(
                    <h5><Badge pill bg="success">Activa</Badge></h5>
                )

            case 2:
                return(
                    <h5><Badge pill bg="danger">Finalizada</Badge></h5>
                )
            default:
                return(
                    <h5><Badge pill bg="secondary">Sin definir</Badge></h5>
                )
                break;
        }
    }

    useEffect(() => {
        if(asesoria){
            setDetalleModal(true);

            dispatch(obtenerAsesoria(asesoria));
        }        
    }, [asesoria]);

    useEffect(() => {
        if(detalles_asesoria){
            setDetallesData({
                ...detallesData,
                id: detalles_asesoria?._id, 
                nombre_asesor: detalles_asesoria?.usuario?.nombre, 
                apellido_paterno_asesor: detalles_asesoria?.usuario?.apellido_paterno,  
                apellido_materno_asesor: detalles_asesoria?.usuario?.apellido_materno, 
                nombre_joven: detalles_asesoria?.joven?.nombre, 
                apellido_paterno_joven: detalles_asesoria?.joven?.apellido_paterno,  
                apellido_materno_joven: detalles_asesoria?.joven?.apellido_materno, 
                fecha_inicio: detalles_asesoria?.fecha_inicio,  
                fecha_fin: detalles_asesoria?.fecha_fin,  
                fecha_cita: detalles_asesoria?.fecha_cita,  
                status: detalles_asesoria?.status,  
                url_perfil: detalles_asesoria.url_perfil_p || process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
            });
        }
    }, [detalles_asesoria]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .contenedor_imagen_perfil {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            background: #fff;
                            border: 5px solid #111F62;
                            width: auto;
                            margin: auto;
                        }
                        
                        .contenedor_imagen{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        #imagen_perfil {
                            width: auto;
                            height: 100%;
                            display: block;
                            position: absolute;
                            margin: auto;
                            top: -100%;
                            right: -100%;
                            bottom: -100%;
                            left: -100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
            <Modal show={detalleModal} size="lg" backdrop="static" centered onHide={() => handleCerrar()}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalle de asesoria</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-5 row">
                            <label className="contenedor_imagen_perfil">
                                <div className="contenedor_imagen">
                                    <img id="imagen_perfil" src={ detallesData?.url_perfil }/>
                                </div>
                            </label>
                        </div>

                        <div className="col-md-7 row">
                            <div className="col-md-12">
                                <label className="fw-bold">Jóven</label>
                                <p>{[detallesData?.nombre_joven, detallesData?.apellido_paterno_joven, detallesData?.apellido_materno_joven].join(' ')}</p>
                            </div>
                            
                            <div className="col-md-12">
                                <label className="fw-bold">Asesor</label>
                                <p>{[detallesData?.nombre_asesor, detallesData?.apellido_paterno_asesor, detallesData?.apellido_materno_asesor].join(' ')}</p>
                            </div>

                            <div className="col-md-6">
                                <label className="fw-bold">Inicio</label>
                                <p>{detallesData?.fecha_inicio ? new Date(detallesData?.fecha_inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                            </div>

                            <div className="col-md-6">
                                <label className="fw-bold">Finalización</label>
                                <p>{detallesData?.fecha_fin ? new Date(detallesData?.fecha_fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'}</p>
                            </div>

                            <div className="col-md-12">
                                <label className="fw-bold">Próxima cita</label>
                                <p>{detallesData?.fecha_cita ? new Date(detallesData?.fecha_cita).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' a las ' + new Date(detallesData?.fecha_cita).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Sin definir'}</p>
                            </div>

                            <div className="col-md-12">
                                <label className="fw-bold">Status actual</label>
                                <p>{handleStatus(detallesData?.status)}</p>
                            </div>
                        </div>                    
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        detallesData?.status === 0 || detallesData?.status === 1 ?
                            <>
                                <button type="button" className="btn btn-danger" onClick={() => handleEliminar()}><i className="fas fa-trash fa-sm"></i> Eliminar</button>
                                <button type="button" className="btn btn-warning" onClick={() => handleEditar()}><i className="fas fa-pencil fa-sm"></i> Editar</button>
                            </>
                        :
                            null
                    }
                    <button type="button" className="btn btn-primary" onClick={() => handleDetalles()}><i className="fas fa-eye fa-sm"></i> Más detalles</button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default DetalleAsesoria;