import React, { useState, useEffect, createRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { editarBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { crearEmpresa } from '../../actions/empresas';
import { buscarCategoriasEmpresasConfiguracion } from '../../actions/configuracion';

const NuevaEmpresa = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Empresas',
            path: '/empresas'
        },
        {
            activo: true,
            nombre: 'Nueva empresa',
            path: '/empresas/nueva'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const categorias_empresas = useSelector(state => state.configuracion.categorias_empresas);

    const [empresaData, setEmpresaData] = useState({
        nombre: null,
        categoria_empresa: null,
        direccion: null,
        soluciones: false,
        descripcion: null,
        contactos: [], 
        imagen: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'
    });

    const [profilePicture, setProfilePicture] = useState({
        img: undefined
    });

    const [categoriaData, setCategoriaData] = useState(null);
    const [categoriasFilter, setCategoriasFilter] = useState([]);

    const [tiposContactoData, setTiposContactoData] = useState([]);
    const [tiposContactosFilter, setTiposContactosFilter] = useState([
        { value: 0, label: 'Teléfono', icon: 'fa-solid fa-phone' }, 
        { value: 1, label: 'WhatsApp', icon: 'fa-brands fa-whatsapp' }, 
        { value: 2, label: 'Facebook', icon: 'fa-brands fa-facebook' }, 
        { value: 3, label: 'Instagram', icon: 'fa-brands fa-instagram' }, 
        { value: 4, label: 'TikTok', icon: 'fa-brands fa-tiktok' }, 
        { value: 5, label: 'Twitter', icon: 'fa-brands fa-twitter' }, 
        { value: 6, label: 'Correo', icon: 'fa-solid fa-envelope' }, 
        { value: 7, label: 'Sitio web', icon: 'fa-solid fa-globe' }
    ]);

    let img = createRef();

    /* Nueva empresa */
    const handleChangeImg = () => {
        setProfilePicture({
            ...profilePicture, 
            img: img.current.files[0]
        });
    }

    const handleInputChange = ({ target }) => {
        setEmpresaData({
            ...empresaData,
            [target.name]: target.value
        });
    }

    const handleSelectCategoria = (option) => {
        setCategoriaData(option);

        setEmpresaData({
            ...empresaData,
            categoria_empresa: option != null ? option.value : null
        });
    }

    const handleCheckbox = ({ target }) => {
        setEmpresaData({
            ...empresaData,
            soluciones: target.checked
        });
    }

    const handleSelectTipo = (value, action) => {
        let nombre_separado = action.name.split('_');

        let posicion = nombre_separado[1];

        let contactos = empresaData.contactos;
        let tipos_elegidos = tiposContactoData;

        contactos[posicion].tipo = value?.value != null && value?.value != undefined ? value?.value : null;
        tipos_elegidos[posicion] = value;

        setTiposContactoData(tipos_elegidos);

        setEmpresaData({
            ...empresaData,
            contactos
        });
    }

    const handleContactoChange = ({ target }) => {
        let nombre_separado = target.name.split('_');

        let posicion = nombre_separado[1];
        
        let contactos = empresaData.contactos;

        contactos[posicion].contenido = target.value || '';

        setEmpresaData({
            ...empresaData,
            contactos
        });
    }

    const handleGuardarEmpresa = async () => {
        if(empresaData.nombre){
            let formData = new FormData();

            formData.append('nombre', empresaData.nombre);
            formData.append('categoria_empresa', empresaData.categoria_empresa);
            formData.append('direccion', empresaData.direccion);
            formData.append('soluciones', empresaData.soluciones);
            formData.append('descripcion', empresaData.descripcion);
            formData.append('contactos', JSON.stringify(empresaData.contactos));
            formData.append('perfil', profilePicture.img);

            await dispatch(crearEmpresa(formData));

            await navigate("/empresas");
        }else{
            await dispatch(setAlert('Todos los campos son obligatorios', 'danger'));
        }
    }

    /* Funciones generales */
    const handleAgregarContacto = () => {
        let contactos = empresaData.contactos;
        let tipos_contactos = tiposContactoData;

        contactos.push({
            tipo: 0,
            contenido: null
        });

        tipos_contactos.push({ value: 0, label: 'Teléfono', icon: 'fa-solid fa-phone' });

        setEmpresaData({ 
            ...empresaData, 
            contactos
        });

        setTiposContactoData(tipos_contactos);
    }

    const contactosCampos = (contactos) => {

        let contactos_campos = [];

        if(contactos){
            for (var x = 0; x < contactos?.length; x++) {
                contactos_campos.push(
                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group h-100">
                            <div className="input-group-preppend">
                                <Select
                                    id={'tipoContacto_' + x}
                                    name={'tipoContacto_' + x}
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Tipo"
                                    options={tiposContactosFilter}
                                    value={tiposContactoData[x]}
                                    onChange={handleSelectTipo}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    getOptionLabel={e => (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <i className={e.icon}></i>
                                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Contacto" id={'contenidoContacto_' + x} name={'contenidoContacto_' + x} value={contactos[x].contenido || ''} onChange={e => handleContactoChange(e)}/>
                                <label htmlFor={'contenidoContacto_' + x}>Contacto {x + 1}</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-danger delete" id={'eliminarContacto_' + x} onClick={e => handleEliminarContacto(e)}><i className="fa-solid fa-xmark"></i></button>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return contactos_campos;
    }

    const handleEliminarContacto = async ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');    

        let contactos = empresaData.contactos;
        let tipos_contactos = tiposContactoData;

        contactos.splice(id_separado[1], 1);
        tipos_contactos.splice(id_separado[1], 1);

        setEmpresaData({ 
            ...empresaData, 
            contactos
        });

        setTiposContactoData(tipos_contactos);
    }

    useEffect(() => {
        dispatch(buscarCategoriasEmpresasConfiguracion());
    }, []);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < categorias_empresas.listado?.length; i++) {
            options.push({ value: categorias_empresas.listado[i]._id, label: categorias_empresas.listado[i].nombre });
        }

        setCategoriasFilter(options);
    }, [categorias_empresas]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);    

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        input[type="file"] {
                            display: none;
                        }
                        
                        .custom-file-upload {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            cursor: pointer;
                            background: #fff;
                            margin-bottom: 25px;
                            border: 5px solid #111F62;
                        }
                        
                        .img-wrap{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        .img-upload:before{
                            content: "";
                            font-family: "Font Awesome 6 Free" !important;
                            font-weight: 900;
                            font-size: 90px;
                            position: absolute;
                            padding-top: 50px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-align: center;
                            color: #21B4E9;
                            width: 200px;
                            height: 200px;
                            border-radius: 50%;
                            opacity: 0;
                            transition: .5s ease;
                            background-color: #fff;
                        }

                        .img-upload:hover:before{
                            opacity: 1;
                        }

                        #img-photo {
                            width: auto;
                            height: 100%;
                        }

                        .selectIconos, .select-group {
                            height: 100%;
                        }

                        .delete {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 25% 25% 0 !important;
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Nueva empresa</h3>
                    
                    <div className="row mb-5">
                        <div className="col-12 form-group text-center">
                            <label htmlFor="photo-upload" className="custom-file-upload fas">
                                <div className="img-wrap img-upload">
                                    <img id="img-photo" htmlFor="photo-upload" src={profilePicture.img != null ? URL.createObjectURL(profilePicture.img) : empresaData.imagen}/>
                                </div>
                                <input id="photo-upload" type="file" accept="image/*" ref={img} onChange={handleChangeImg}/> 
                            </label>
                        </div>
                    </div>
                
                    <div className="row">
                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={empresaData.nombre} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="nombre">Nombre</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    id="categoria_empresa"
                                    name="categoria_empresa"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Categoría"
                                    isClearable={true}
                                    options={categoriasFilter}
                                    value={categoriaData}
                                    onChange={(value) => handleSelectCategoria(value)}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>

                        <div className="col-md-4 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Apellido materno" id="direccion" name="direccion" value={empresaData.direccion} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="direccion">Dirección</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2 form-group mb-3">
                            <div className="input-group h-100">
                                <div className="input-group-preppend w-100">
                                    <input type="checkbox" className="btn-check w-100" id="soluciones_check" autoComplete="off" onChange={e => handleCheckbox(e, 'soluciones')} checked={empresaData.soluciones ? true : false}/>
                                    <label className="btn btn-outline-primary w-100 h-100" id="label_soluciones_check" htmlFor="soluciones_check">{empresaData.soluciones ? 'Tarjeta soluciones requerida' : 'Tarjeta soluciones sin requerir'}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <textarea className="form-control textareCampo" placeholder="Descripción" id="descripcion" name="descripcion" value={empresaData.descripcion} onChange={e => handleInputChange(e)}></textarea>
                                    <label htmlFor="descripcion">Descripción</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        { contactosCampos(empresaData.contactos) }
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleGuardarEmpresa}>Guardar</button>
                            <button className="btn btn-primary float-end me-2" onClick={() => handleAgregarContacto()}><i className="fa-solid fa-plus"></i> Contacto</button>
                            <Link to='/empresas'><button className="btn btn-danger float-end me-2">Cancelar</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NuevaEmpresa;