import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { setAlert } from '../../actions/alert';
import { editarReservacion, buscarHorariosReservacion } from '../../actions/cabinas';
import { buscarJovenes } from '../../actions/jovenes';
import { buscarCabinasConfiguracion } from '../../actions/configuracion';

const EditarReservacion = ({ reservacion, reestablecerReservacion }) => {

    const dispatch =  useDispatch();

    const jovenes = useSelector(state => state.jovenes);
    const cabinas = useSelector(state => state.configuracion.cabinas);
    const { detalles, listado_horarios_reservacion } = useSelector(state => state.cabinas);

    const [editarModal, setEditarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [reservacionData, setReservacionData] = useState({
        id: null,
        id_cabina: null,
        id_joven: null,
        nombre: null,
        fecha: null,
        hora_inicio: null,
        hora_fin: null,
        observaciones: null
    });

    const [jovenData, setJovenData] = useState(null);
    const [jovenesFilter, setJovenesFilter] = useState([]);

    const [cabinaData, setCabinaData] = useState(null);
    const [cabinasFilter, setCabinasFilter] = useState([]);

    const [horarioData, setHorarioData] = useState(null);
    const [horariosFilter, setHorariosFilter] = useState([]);

    /* Editar reservacion */
    const handleSelectJoven = (option) => {
        setJovenData(option);

        setReservacionData({
            ...reservacionData,
            id_joven: option != null ? option.value : null, 
            nombre: null
        });
    }

    const handleCreateJoven = (value) => {
        setReservacionData({
            ...reservacionData,
            id_joven: null, 
            nombre: value
        });

        setJovenesFilter((prev) => [...prev, {
            value: null, 
            label: value
        }]);

        setJovenData({
            value: null, 
            label: value
        });
    }

    const handleSelectCabina = (option) => {
        setCabinaData(option);

        setReservacionData({
            ...reservacionData,
            id_cabina: option != null ? option.value : null, 
            fecha_inicio: null,
            fecha_fin: null,
            hora_inicio: null,
            hora_fin: null
        });

        setHorarioData(null);
        setHorariosFilter([]);
    }

    const handleInputChange = ({ target }) => {
        setReservacionData({
            ...reservacionData,
            [target.name]: target.value
        });
    }

    const handleDatePicker = (name, value) => {
        setReservacionData({
            ...reservacionData,
            [name]: value, 
            hora_inicio: null, 
            hora_fin: null
        });

        setHorarioData(null);

        if(value){
            dispatch(buscarHorariosReservacion({
                id_reservacion: reservacionData.id, 
                id_cabina: reservacionData.id_cabina, 
                fecha_inicio: value, 
                fecha_fin: value
            }));
        }
    }

    const handleSelectHorario = (option) => {
        setHorarioData(option);

        setReservacionData({
            ...reservacionData,
            hora_inicio: option != null ? option.hora_inicio : null,
            hora_fin: option != null ? option.hora_fin : null
        });
    }
    
    const handleProcesar = async () => {
        if(reservacionData.nombre || reservacionData.id_joven){
            if(reservacionData.id_cabina){
                if(reservacionData.fecha){
                    if(reservacionData.hora_inicio <= reservacionData.hora_fin){
                        let validacion_horarios = [];

                        let dia_verificar = new Date(reservacionData.fecha);
                        let dia_semana = dia_verificar.getDay();

                        if(cabinaData.horarios[dia_semana].length > 0){
                            let validacion_horario_dia = false;

                            for (var x = 0; x < cabinaData.horarios[dia_semana].length; x++) {
                                if(reservacionData.hora_inicio >= cabinaData.horarios[dia_semana][x].hora_inicio && reservacionData.hora_fin <= cabinaData.horarios[dia_semana][x].hora_fin){
                                    validacion_horario_dia = true;
                                    continue;
                                }
                            }

                            if(!validacion_horario_dia){
                                validacion_horarios.push(dia_verificar);
                            }
                        }else{
                            validacion_horarios.push(dia_verificar);
                        }

                        if(validacion_horarios.length == 0){
                            let validacion_dia_bloqueo = true;

                            for (var i = 0; i < cabinaData.bloqueos.length; i++) {
                                if(new Date(reservacionData.fecha) >= new Date(cabinaData.bloqueos[i].fecha_inicio) && new Date(reservacionData.fecha) <= new Date(cabinaData.bloqueos[i].fecha_fin)){
                                    validacion_dia_bloqueo = false;
                                    continue;
                                }
                            }

                            if(validacion_dia_bloqueo){
                                await setStatusProcesar(true);
                                await dispatch(editarReservacion(reservacionData));
                                await handleCerrar();
                                await setStatusProcesar(false);
                            }else{
                                await dispatch(setAlert('Hay un bloqueo de fecha en el rango seleccionado', 'danger'));
                            }
                        }else{
                            await dispatch(setAlert('El horario elegido no es compatible con la fecha seleccionada', 'danger'));
                        }
                    }else{
                        await dispatch(setAlert('La hora de inicio debe ser menor o igual a la de finalización', 'danger'));
                    }
                }else{
                    await dispatch(setAlert('Selecciona una fecha', 'danger'));
                }
            }else{
                await dispatch(setAlert('Selecciona la cabina', 'danger'));
            }
        }else{
            await dispatch(setAlert('Ingresa el joven al que se le reservara', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setReservacionData({
                id: null,
                id_cabina: null,
                id_joven: null,
                nombre: null,
                fecha: null,
                hora_inicio: null,
                hora_fin: null,
                observaciones: null
            });

            setJovenData(null);
            setCabinaData(null);
            setHorarioData(null);
            setHorariosFilter([]);
            setEditarModal(false);
            reestablecerReservacion(null);
        }
    }

    useEffect(() => {
        dispatch(buscarJovenes({
            nombre: null,
            apellido_paterno: null,
            apellido_materno: null,
            celular: null,
            email: null,
            curp: null
        }));
        dispatch(buscarCabinasConfiguracion(true));
    }, []);

    useEffect(() => {
        if(reservacion){
            setEditarModal(true);

            setReservacionData({
                ...reservacionData
            });
        }        
    }, [reservacion]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < jovenes.listado?.length; i++) {
            options.push({ value: jovenes.listado[i]._id, label: [jovenes.listado[i].nombre, jovenes.listado[i].apellido_paterno, jovenes.listado[i].apellido_materno].join(' ') });
        }

        setJovenesFilter(options);
    }, [jovenes]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < cabinas.listado?.length; i++) {
            options.push({ value: cabinas.listado[i]._id, label: cabinas.listado[i].nombre, horarios: cabinas.listado[i].horarios, bloqueos: cabinas.listado[i].bloqueos });
        }

        setCabinasFilter(options);
    }, [cabinas]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_horarios_reservacion.length; i++) {
            if([listado_horarios_reservacion[i].hora_inicio, listado_horarios_reservacion[i].hora_fin].join(' - ') === String(detalles?.horario_elegido)){
                setHorarioData({value: i, label: [listado_horarios_reservacion[i].hora_inicio, listado_horarios_reservacion[i].hora_fin].join(' - '), hora_inicio: listado_horarios_reservacion[i].hora_inicio, hora_fin: listado_horarios_reservacion[i].hora_fin });
            }

            options.push({ value: i, label: [listado_horarios_reservacion[i].hora_inicio, listado_horarios_reservacion[i].hora_fin].join(' - '), hora_inicio: listado_horarios_reservacion[i].hora_inicio, hora_fin: listado_horarios_reservacion[i].hora_fin });
        }

        setHorariosFilter(options);
    }, [listado_horarios_reservacion]);

    useEffect(() => {
        if(detalles){
            setReservacionData({
                ...reservacionData, 
                id: detalles?._id || null,
                id_cabina: detalles?.cabina?._id || null,
                id_joven: detalles?.joven?._id || null,
                nombre: detalles?.nombre || null,
                fecha: detalles?.fecha_inicio || null,
                hora_inicio: detalles?.hora_inicio || null,
                hora_fin: detalles?.hora_fin || null,
                observaciones: detalles?.observaciones || null
            });

            dispatch(buscarHorariosReservacion({
                id_reservacion: detalles?._id, 
                id_cabina: detalles?.cabina?._id, 
                fecha_inicio: detalles?.fecha_inicio, 
                fecha_fin: detalles?.fecha_inicio
            }));

            if(jovenesFilter.length > 0){

                let joven_elegido = jovenesFilter.find( joven => String(joven.value) === String(detalles?.joven?._id) );
    
                if(joven_elegido){
                    setJovenData({value: joven_elegido.value, label: joven_elegido.label});
                }else{
                    setJovenData({value: null, label: detalles?.nombre});
                }
            }

            if(cabinasFilter.length > 0){

                let cabina_elegida = cabinasFilter.find( cabina => String(cabina.value) === String(detalles?.cabina?._id) );
    
                if(cabina_elegida){
                    setCabinaData({value: cabina_elegida.value, label: cabina_elegida.label, horarios: cabina_elegida.horarios, bloqueos: cabina_elegida.bloqueos});
                }
            }

            if(horariosFilter.length > 0){

                let horario_elegido = horariosFilter.find( horario => String(horario.label) === String(detalles.horario_elegido) );
    
                if(horario_elegido){
                    setHorarioData({value: horario_elegido.value, label: horario_elegido.label, hora_inicio: horario_elegido.hora_inicio, hora_fin: horario_elegido.hora_fin});
                }
            }
        }
    }, [detalles]);

    return (
        <Modal show={editarModal} size="lg" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Editar reservación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <CreatableSelect
                                id="id_joven"
                                name="id_joven"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar joven"
                                isClearable
                                options={jovenesFilter}
                                value={jovenData}
                                onChange={(value) => handleSelectJoven(value)}
                                onCreateOption={handleCreateJoven}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="id_cabina"
                                name="id_cabina"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar cabina"
                                isClearable
                                options={cabinasFilter}
                                value={cabinaData}
                                onChange={(value) => handleSelectCabina(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha"
                                    name="fecha"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    selected={ reservacionData.fecha != null ? new Date(reservacionData.fecha) : '' }
                                    onChange={ date => handleDatePicker('fecha', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha">Fecha de reservación</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="horario"
                                name="horario"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar horario"
                                isClearable
                                options={horariosFilter}
                                value={horarioData}
                                onChange={(value) => handleSelectHorario(value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>

                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <textarea className="form-control textareCampo" placeholder="Observaciones" id="observaciones" name="observaciones" value={reservacionData.observaciones} onChange={e => handleInputChange(e)}></textarea>
                                <label htmlFor="observaciones">Observaciones</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Guardar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Guardar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EditarReservacion;