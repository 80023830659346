import React, { useState, useEffect, createRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { login, recoverUser, resetUser } from '../../actions/auth';
import { setAlert } from '../../actions/alert';

const Login = () => {

    const dispatch = useDispatch();

    const { isAuthenticated, codigo } = useSelector(state => state.auth);

    const [loginData, setLoginData] = useState({
        email: null,
        password: null
    });
    
    const [recuperarData, setRecuperarData] = useState({
        email: null,
        password: '',
        codigo_recuperar_1: '',
        codigo_recuperar_2: '',
        codigo_recuperar_3: '',
        codigo_recuperar_4: '',
        recuperando: false,
        confirmando: false,
        restableciendo: false
    });

    let ref_numero_2 = createRef();
    let ref_numero_3 = createRef();
    let ref_numero_4 = createRef();

    /* Login */
    const onChange = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value
        })
    }

    const handleKeyPressLogin = (e) => {
        if(e.keyCode === 13){
            submitLogin();
        }
    }

    const submitLogin = () => {
        dispatch(login(loginData));
    }

    /* Recuperar */
    const onChangeEmailRecuperar = (e) => {
        setRecuperarData({
            ...recuperarData, [e.target.name]: e.target.value
        })
    }

    const handleKeyPressRecuperar = (e) => {
        if(e.keyCode === 13){
            submitRecuperar();
        }
    }

    const submitRecuperar = async () => {
        dispatch(recoverUser(recuperarData.email));
    }

    /* Confirmar */
    const onChangeCodigoRecuperar = (e) => {
        setRecuperarData({
            ...recuperarData, [e.target.name]: e.target.value
        })
    }

    const handleKeyPressConfirmar = (e) => {
        if(e.keyCode === 13){
            submitConfirmar();
        }
    }

    const submitConfirmar = async () => {

        let {codigo_recuperar_1, codigo_recuperar_2, codigo_recuperar_3, codigo_recuperar_4} = recuperarData;

        let codigo_usuario = codigo_recuperar_1 + '' + codigo_recuperar_2 + '' + codigo_recuperar_3 + '' + codigo_recuperar_4;

        if (codigo == codigo_usuario) {
            dispatch(setAlert('Código correcto', 'success'));
            
            setRecuperarData({
                ...recuperarData,
                password: '',
                codigo_recuperar_1: '',
                codigo_recuperar_2: '',
                codigo_recuperar_3: '',
                codigo_recuperar_4: '',
                recuperando: false,
                confirmando: false,
                restableciendo: true
            });
        }else{
            dispatch(setAlert('El código no coincide', 'danger'));
        }
    }

    /* Restablecer */
    const handleKeyPressRestablecer = (e) => {
        if(e.keyCode === 13){
            submitRestablecer();
        }
    }

    const submitRestablecer = async () => {
        dispatch(resetUser({ email: recuperarData.email, password: recuperarData.password }));

        setRecuperarData({
            ...recuperarData,
            email: null,
            password: '',
            codigo_recuperar_1: '',
            codigo_recuperar_2: '',
            codigo_recuperar_3: '',
            codigo_recuperar_4: '',
            recuperando: false,
            confirmando: false,
            restableciendo: false
        });

    }

    /* Funciones generales */
    const onViewPassword = (origen) => {
        let loginInputType = document.getElementById(origen).type;
        
        if(loginInputType == 'password')document.getElementById(origen).type = 'text';
        else document.getElementById(origen).type = 'password';
    }

    const handleOpenRecuperar = () => {
        setLoginData({ email: '', password: '' })
        setRecuperarData({
            ...recuperarData,
            recuperando: true,
            confirmando: false,
            restableciendo: false
        });
    }

    const handleOpenLogin = () => {
        setRecuperarData({
            ...recuperarData,
            email: null,
            codigo_recuperar_1: '',
            codigo_recuperar_2: '',
            codigo_recuperar_3: '',
            codigo_recuperar_4: '',
            recuperando: false,
            confirmando: false,
            restableciendo: false
        });
    }

    useEffect(() => {
        if(codigo){
            setRecuperarData({
                ...recuperarData,
                password: '',
                codigo_recuperar_1: '',
                codigo_recuperar_2: '',
                codigo_recuperar_3: '',
                codigo_recuperar_4: '',
                recuperando: false,
                confirmando: true,
                restableciendo: false
            });
        }
    }, [codigo]);

    useEffect(() => {
        if(recuperarData.codigo_recuperar_1 != ''){
            ref_numero_2.current.focus();
        }
    }, [recuperarData.codigo_recuperar_1]);

    useEffect(() => {
        if(recuperarData.codigo_recuperar_2 != ''){
            ref_numero_3.current.focus();
        }
    }, [recuperarData.codigo_recuperar_2]);
    
    useEffect(() => {
        if(recuperarData.codigo_recuperar_3 != ''){
            ref_numero_4.current.focus();
        }
    }, [recuperarData.codigo_recuperar_3]);

    useEffect(() => {
        if(isAuthenticated){
            window.location.href = '/dashboard';
        }
    }, [isAuthenticated]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        body{
                            background-color: #060A21;
                        }
                        
                        .centradoVertical {
                            margin: 0;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        
                        .btnIniciar {
                            background: #0d6efd;
                            border-color: #0d6efd;
                        }
                        
                        .btnRecuperar {
                            color: #464AC0;
                        }
                        
                        .bgImg {
                            height: 100%;
                            opacity: 0.2;
                            position: absolute;
                        }
                        
                        .logoHeader {
                            height: 8vw;
                            min-height: 50px;
                            max-height: 80px;
                        }

                        .codigo {
                            margin: 0.8rem;
                            text-align: center;
                            border-radius: 25%;
                            width: 2.8rem;
                            height: 3rem;
                            background-color: #dddddd;
                            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
                            border-color: #989898;
                            font-weight: bold;
                        }
                        
                        .codigo:focus {
                            background-color: white;
                            border-color: #b7b7b7;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-6 offset-md-3 centradoVertical">
                    <div className="card bg-light"> 
                        <div className="card-body">
                            {
                                !recuperarData.recuperando && !recuperarData.confirmando && !recuperarData.restableciendo ?
                                    <div className="row">
                                        <div className="col-md-12 text-center mb-3">
                                            <img src={process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/logo_IAJU-Color.png'} className="d-inline-block mt-2 mb-4 logoHeader" alt="logo"/>
                                            <h5 className="card-title">Iniciar sesión</h5>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="input-group mb-3">
                                                <div className="form-floating">
                                                    <input type="email" className="form-control" placeholder="Correo" id="email_login" name="email" value={loginData.email || ''} onChange={e => onChange(e)} onKeyDown={handleKeyPressLogin}></input>
                                                    <label htmlFor="email_login">Correo</label>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-12">
                                            <div className="input-group mb-3">
                                                <div className="form-floating">
                                                    <input type="password" id="password_login" className="form-control" placeholder="Contraseña" name="password" value={loginData.password || ''} onChange={e => onChange(e)} onKeyDown={handleKeyPressLogin}></input>
                                                    <label htmlFor="password_login">Contraseña</label>
                                                </div>
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary viewPassword" onClick={() => onViewPassword('password_login')}><i className="fa-solid fa-eye"></i></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group text-left">
                                            <button type="button" className="btn btn-link btnRecuperar" onClick={() => handleOpenRecuperar()}>¿No recuerdas tu contraseña?</button>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group mt-3 text-center">
                                                <button type="button" className="btn btn-primary w-100" onClick={() => submitLogin()}>Iniciar sesión</button>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null
                            }

                            {
                                recuperarData.recuperando ?
                                    <div className="row">
                                        <div className="col-md-12 text-center mb-3">
                                            <img src={process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/logo_IAJU-Color.png'} className="d-inline-block mt-2 mb-4 logoHeader" alt="logo"/>
                                            <h5 className="card-title">Recuperación de contraseña</h5>
                                        </div>

                                        <div className="col-md-12 p-3">
                                            <p>Ingresa tu cuenta de correo para reestablecer tu contraseña. Te enviaremos un código de 4 dígitos a tu correo para este proceso.</p>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="input-group mb-3">
                                                <div className="form-floating">
                                                    <input type="email" className="form-control" placeholder="Correo" id="email_recuperar" name="email" value={recuperarData.email || ''} onChange={e => onChangeEmailRecuperar(e)} onKeyDown={handleKeyPressRecuperar}></input>
                                                    <label htmlFor="email_recuperar">Correo</label>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-12 form-group text-left">
                                            <button type="button" className="btn btn-link btnRecuperar" onClick={() => handleOpenLogin()}>Iniciar sesión</button>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group mt-3 text-center">
                                                <button type="button" className="btn btn-primary w-100" onClick={() => submitRecuperar()}>Envíar código</button>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null
                            }

                            {
                                recuperarData.confirmando ?
                                    <div className="row">
                                        <div className="col-md-12 text-center mb-3">
                                            <img src={process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/logo_IAJU-Color.png'} className="d-inline-block mt-2 mb-4 logoHeader" alt="logo"/>
                                            <h5 className="card-title">Código de recuperación</h5>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group text-center">
                                                <input
                                                    type="text"
                                                    className="codigo"
                                                    name="codigo_recuperar_1"
                                                    min="0"
                                                    max="9"
                                                    maxLength="1"
                                                    value={ recuperarData.codigo_recuperar_1 || ''}
                                                    onChange={e => onChangeCodigoRecuperar(e)}
                                                    onKeyDown={handleKeyPressConfirmar}></input>
                                                <input
                                                    type="text"
                                                    className="codigo"
                                                    name="codigo_recuperar_2"
                                                    min="0"
                                                    max="9"
                                                    maxLength="1"
                                                    ref={ref_numero_2}
                                                    value={ recuperarData.codigo_recuperar_2 || ''}
                                                    onChange={e => onChangeCodigoRecuperar(e)}
                                                    onKeyDown={handleKeyPressConfirmar}></input>
                                                <input
                                                    type="text"
                                                    className="codigo"
                                                    name="codigo_recuperar_3"
                                                    min="0"
                                                    max="9"
                                                    maxLength="1"
                                                    ref={ref_numero_3}
                                                    value={ recuperarData.codigo_recuperar_3 || ''}
                                                    onChange={e => onChangeCodigoRecuperar(e)}
                                                    onKeyDown={handleKeyPressConfirmar}></input>
                                                <input
                                                    type="text"
                                                    className="codigo"
                                                    name="codigo_recuperar_4"
                                                    min="0"
                                                    max="9"
                                                    maxLength="1"
                                                    ref={ref_numero_4}
                                                    value={ recuperarData.codigo_recuperar_4 || ''}
                                                    onChange={e => onChangeCodigoRecuperar(e)}
                                                    onKeyDown={handleKeyPressConfirmar}></input>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-12 form-group text-left">
                                            <button type="button" className="btn btn-link btnRecuperar" onClick={() => handleOpenLogin()}>Iniciar sesión</button>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group mt-3 text-center">
                                                <button type="button" className="btn btn-primary w-100" onClick={() => submitConfirmar()}>Recuperar cuenta</button>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null
                            }

                            {
                                recuperarData.restableciendo ?
                                    <div className="row">
                                        <div className="col-md-12 text-center mb-3">
                                            <img src={process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/logo_IAJU-Color.png'} className="d-inline-block mt-2 mb-4 logoHeader" alt="logo"/>
                                            <h5 className="card-title">Modificación de contraseña</h5>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <div className="input-group mb-3">
                                                    <div className="form-floating">
                                                        <input type="password" className="form-control" placeholder="Nueva contraseña" id="password_restablecer" name="password" value={recuperarData.password} onChange={e => onChangeEmailRecuperar(e)} onKeyDown={handleKeyPressRestablecer}/>
                                                        <label htmlFor="password_restablecer">Nueva contraseña</label>
                                                    </div>
                                                    <div className="input-group-prepend">
                                                        <button className="btn btn-primary viewPassword" onClick={() => onViewPassword('password_restablecer')}><i className="fa-solid fa-eye"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group text-left">
                                            <button type="button" className="btn btn-link btnRecuperar" onClick={() => handleOpenLogin()}>Iniciar sesión</button>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group mt-3 text-center">
                                                <button type="button" className="btn btn-primary w-100" onClick={() => submitRestablecer()}>Cambiar contraseña</button>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Login;