import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { eliminarReservacion } from '../../actions/cabinas';

const EliminarReservacion = ({ reservacion, reestablecerReservacion }) => {

    const dispatch =  useDispatch();

    const [eliminarModal, setEliminarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Eliminar reservacion */
    const handleProcesar = async () => {
        await setStatusProcesar(true);
        await dispatch(eliminarReservacion(reservacion?.id));
        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            reestablecerReservacion(null);
            setEliminarModal(false);
        }
    }

    useEffect(() => {
        if(reservacion){
            setEliminarModal(true);
        }
    }, [reservacion]);

    return (
        <Modal show={eliminarModal} backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar reservación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Deseas eliminar la reservación de {reservacion?.joven ? reservacion?.joven.nombre : reservacion?.nombre}?</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={ statusProcesar }><i className="fas fa-times fa-sm"></i> No</button>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Si</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EliminarReservacion;